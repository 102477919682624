import {
  ECDynamicPageTemplate,
  ECEasyFormFieldType,
  MAX_SELECTABLE_OPTIONS,
} from 'app/components';
import { useTranslation } from 'react-i18next';
import { loadPageScopes } from 'utils/pageScopes';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setFilterEndpoint } from 'store/slice/page';
import { useMediaQuery, useTheme } from '@mui/material';
import {
  useLazyGetInventoryItemsListQuery,
  useGetInventoryStorageLocationsListQuery,
  useCreateInventoryMutation,
  useLazyGetValidatePartNumberQuery,
  useGetMeasurementUnitsListQuery,
} from 'services/inventoryApi';
import { useGetManufactureListQuery } from 'services/manufactureApi';
import {
  useGetAssetTypesForInventoryListQuery,
  useGetAssetTypesListQuery,
} from 'services/assetTypeApi';
import { themes } from 'styles/theme/themes';
import _ from 'lodash';
import { PartNumberType } from 'types/Inventory';
import { P } from 'types/Permission';
import { useHasPermission } from 'app/hooks/hasPermission.use-case';
import { useGetMarkupTypesQuery } from 'services/lookupApi';
import { useCompanyCustomerProfile } from 'app/hooks/useCompanyCustomerProfile.use-case';

const createFormElements = require('./fancy_form_config_create.json');

export const InventoryPage = ({ marginTop = true }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const theme = useTheme();
  const companyProfile = useCompanyCustomerProfile();

  const initializeFormFields = useCallback((fields, isMarkupEnabled) => {
    return fields.map(field => ({
      ...field,
      visible:
        field.groupName === 'markupGroup' ? isMarkupEnabled : field.visible,
    }));
  }, []);

  const [formElementsCreateFields, setFormElementsCreateFields] = useState(() =>
    initializeFormFields(
      createFormElements.fields,
      !!companyProfile?.enableInventoryMarkup,
    ),
  );

  const [lastChangedControl, setLastChangedControl] = useState<PartNumberType>(
    PartNumberType.None,
  );
  const [disableSaveButton, setDisableSaveButton] = useState<boolean>(false);

  const isMobileSize = useMediaQuery(theme.breakpoints.down('md'));
  const [hasEditInventoryPermission] = useHasPermission([
    P.GetInventoryStorage,
  ]);

  const [trigger, result] = useLazyGetValidatePartNumberQuery();
  const { data: validatePartNumberData, isLoading } = result;

  const { data: assetTypes, isLoading: isLoadingAssetTypes } =
    useGetAssetTypesForInventoryListQuery({
      t: 0,
    });

  const { data: markupTypes, isSuccess: isSuccessMarkupTypes } =
    useGetMarkupTypesQuery();

  const markupTypesOptions: any[] = useMemo(() => {
    if (isSuccessMarkupTypes && markupTypes) {
      return markupTypes?.map(markupType => ({
        id: markupType.id,
        name: markupType.displayName,
        code: markupType.code,
        symbol: markupType.symbol,
      }));
    }
    return [];
  }, [isSuccessMarkupTypes, markupTypes]);

  useEffect(() => {
    loadPageScopes('inventory');
    dispatch(setFilterEndpoint('/filter/inventory'));
  }, []);

  const {
    data: dataMeasurementUnits,
    isLoading: isLoadingMeasurementUnits,
    isSuccess: isSuccessMeasurementUnits,
  } = useGetMeasurementUnitsListQuery();

  const unitTypeOptions: any[] = useMemo(() => {
    if (isSuccessMeasurementUnits && dataMeasurementUnits) {
      return dataMeasurementUnits;
    }
    return [];
  }, [isSuccessMeasurementUnits, dataMeasurementUnits]);

  useEffect(() => {
    const manufactureField = formElementsCreateFields
      ?.find(field => field.groupName === 'groupManufacturer')
      ?.subFields?.find(field => field.fieldName === 'manufacturer');

    manufactureField.useQuery = isDrawerOpen ? useGetManufactureListQuery : {};
  }, [isDrawerOpen]);

  useEffect(() => {
    const assetGroupField = formElementsCreateFields?.find(
      field => field.fieldName === 'assetGroup',
    );
    assetGroupField.useQuery = isDrawerOpen ? useGetAssetTypesListQuery : {};
    assetGroupField.obAlias = 'assttp.name';
    assetGroupField.queryParams = { st: 1 };
  }, [isDrawerOpen]);

  const {
    data: dataStorageLocations,
    isLoading: isLoadingStorageLocations,
    isSuccess: isSuccessStorageLocations,
  } = useGetInventoryStorageLocationsListQuery(
    { st: 1 },
    { skip: !hasEditInventoryPermission },
  );

  const storageLocationsListOptions: any[] = useMemo(() => {
    if (isSuccessStorageLocations && dataStorageLocations) {
      return dataStorageLocations.data.map(storageLoc => ({
        id: storageLoc.id,
        label: storageLoc.name,
        name: storageLoc.name,
      }));
    }
    return [];
  }, [isSuccessStorageLocations, dataStorageLocations]);

  useEffect(() => {
    if (
      dataStorageLocations &&
      isSuccessStorageLocations &&
      formElementsCreateFields
    ) {
      const storageLocationsField = formElementsCreateFields?.find(
        field => field.fieldName === 'storageLocations',
      );
      storageLocationsField.options = storageLocationsListOptions;
    }
  }, [
    isSuccessStorageLocations,
    dataStorageLocations,
    storageLocationsListOptions,
    formElementsCreateFields,
  ]);

  const handleRowClick = useCallback((row: any) => {}, []);

  useEffect(() => {
    const updatedFormFields = [...formElementsCreateFields];

    // units field
    if (unitTypeOptions.length) {
      const unitTypeField = updatedFormFields
        .find(field => field.groupName === 'quantityAndUnitGroup')
        ?.subFields?.find(field => field.fieldName === 'measurementUnit');

      if (unitTypeField) {
        unitTypeField.options = unitTypeOptions;
      }
    }

    // markup type field
    if (markupTypesOptions.length) {
      const markupTypeField = updatedFormFields
        .find(field => field.groupName === 'markupGroup')
        ?.subFields?.find(field => field.fieldName === 'markupType');

      if (markupTypeField) {
        markupTypeField.options = markupTypesOptions;
        markupTypeField.value = markupTypesOptions[0];

        markupTypeField.toolTip = {
          content: (
            <div>
              <strong>Cost per Unit Calculations with Markup</strong>
              <p>Example: If the Item current Value is $10.00</p>
              <ul>
                <li>
                  Value Based: $10.00 with $5.00 (Markup Applied) = New Item
                  Value $5.00
                </li>
                <li>
                  Cost Plus: $10.00 with $5.00 (Markup Applied) = New Item Value
                  $15.00
                </li>
                <li>
                  Percentage Applied: $10.00 with 50% (Markup Applied) = New
                  Item Value $15.00
                </li>
              </ul>
            </div>
          ),
        };
      }

      const markupAppliedField = updatedFormFields
        .find(field => field.groupName === 'markupGroup')
        ?.subFields?.find(field => field.fieldName === 'markupApplied');

      if (markupAppliedField) {
        markupAppliedField.endAdornment = markupTypesOptions[0]?.symbol;
        markupAppliedField.value = '0';
      }
    }

    // Part # section
    const partNumberField = updatedFormFields?.find(
      field => field.fieldName === PartNumberType.PartNumber,
    );
    const supplierPartNumberField = updatedFormFields
      ?.find(field => field.groupName === 'groupSupplier')
      ?.subFields?.find(
        field => field.fieldName === PartNumberType.SupplierPartNumber,
      );
    const manufacturerPartNumberField = updatedFormFields
      ?.find(field => field.groupName === 'groupManufacturer')
      ?.subFields?.find(
        field => field.fieldName === PartNumberType.ManufacturePartNumber,
      );

    const isDuplicatePartNumber =
      validatePartNumberData?.isDuplicate &&
      validatePartNumberData.matchingField === PartNumberType.PartNumber &&
      lastChangedControl === PartNumberType.PartNumber;

    if (isDuplicatePartNumber) {
      if (partNumberField) {
        partNumberField.validationMessage = 'Part # is already in use';
        partNumberField.isValid = false;
      }
      setDisableSaveButton(true);
    } else if (lastChangedControl === PartNumberType.PartNumber) {
      setDisableSaveButton(false);
    }
    const isDuplicateOtherPartNumber =
      validatePartNumberData?.isDuplicate &&
      (validatePartNumberData.matchingField ===
        PartNumberType.ManufacturePartNumber ||
        validatePartNumberData.matchingField ===
          PartNumberType.SupplierPartNumber) &&
      lastChangedControl === PartNumberType.PartNumber;
    if (isDuplicateOtherPartNumber && partNumberField) {
      partNumberField.helperText = `This Part # is used as ${_.startCase(validatePartNumberData.matchingField)} in ${validatePartNumberData.inventoryName}`;
      partNumberField.showErrorIcon = true;
    }
    if (
      validatePartNumberData &&
      !validatePartNumberData.isDuplicate &&
      lastChangedControl === PartNumberType.PartNumber &&
      partNumberField
    ) {
      partNumberField.helperText = '';
      partNumberField.showErrorIcon = false;
    }

    // Supplier Part # section
    const isDuplicateSupplierPartNumber =
      validatePartNumberData?.isDuplicate &&
      validatePartNumberData.matchingField !==
        PartNumberType.SupplierPartNumber &&
      lastChangedControl === PartNumberType.SupplierPartNumber;
    if (isDuplicateSupplierPartNumber) {
      supplierPartNumberField.helperText = `This Supplier Part # is used as ${_.startCase(validatePartNumberData.matchingField)} in ${validatePartNumberData.inventoryName}`;
      supplierPartNumberField.showErrorIcon = true;
    }
    if (
      validatePartNumberData &&
      !validatePartNumberData.isDuplicate &&
      lastChangedControl === PartNumberType.SupplierPartNumber &&
      supplierPartNumberField
    ) {
      supplierPartNumberField.helperText = '';
      supplierPartNumberField.showErrorIcon = false;
    }

    // Manufacturer Part # section
    const isDuplicateManufacturerPartNumber =
      validatePartNumberData?.isDuplicate &&
      validatePartNumberData.matchingField !==
        PartNumberType.ManufacturePartNumber &&
      lastChangedControl === PartNumberType.ManufacturePartNumber;
    if (isDuplicateManufacturerPartNumber) {
      manufacturerPartNumberField.helperText = `This Manufacturer Part # is used as ${_.startCase(validatePartNumberData.matchingField)} in ${validatePartNumberData.inventoryName}`;
      manufacturerPartNumberField.showErrorIcon = true;
    }
    if (
      validatePartNumberData &&
      !validatePartNumberData.isDuplicate &&
      lastChangedControl === PartNumberType.ManufacturePartNumber &&
      manufacturerPartNumberField
    ) {
      manufacturerPartNumberField.helperText = '';
      manufacturerPartNumberField.showErrorIcon = false;
    }
    setFormElementsCreateFields(updatedFormFields);
  }, [
    validatePartNumberData,
    lastChangedControl,
    isLoading,
    unitTypeOptions,
    markupTypesOptions,
  ]);

  const validateAndTrigger = (value: string, fieldName: string) => {
    if (value && value.trim() !== '') {
      trigger({ inputPartNumber: value, fieldName });
    } else {
      trigger({ inputPartNumber: '!!!', fieldName });
    }
  };

  const handleOutputChange = useCallback(
    (output: ECEasyFormFieldType[], fieldName?: string) => {
      if (fieldName) {
        let partNumberField: ECEasyFormFieldType | undefined;

        if (fieldName === PartNumberType.PartNumber) {
          partNumberField = output.find(field => field.fieldName === fieldName);
        } else if (fieldName === PartNumberType.ManufacturePartNumber) {
          partNumberField = output
            .find(field => field.groupName === 'groupManufacturer')
            ?.subFields?.find(field => field.fieldName === fieldName);
        } else if (fieldName === PartNumberType.SupplierPartNumber) {
          partNumberField = output
            .find(field => field.groupName === 'groupSupplier')
            ?.subFields?.find(field => field.fieldName === fieldName);
        }
        if (partNumberField) {
          validateAndTrigger(partNumberField.value, fieldName);
          setLastChangedControl(fieldName as PartNumberType);
          setFormElementsCreateFields([...output]);
        }

        if (fieldName === 'markupType') {
          const markupTypeField = output
            .find(field => field.groupName === 'markupGroup')
            ?.subFields?.find(field => field.fieldName === 'markupType');

          const markupAppliedField = output
            .find(field => field.groupName === 'markupGroup')
            ?.subFields?.find(field => field.fieldName === 'markupApplied');

          if (markupTypeField && markupAppliedField) {
            if (markupTypeField.value?.symbol === '$') {
              markupAppliedField.startAdornment = markupTypeField.value?.symbol;
              markupAppliedField.endAdornment = '';
              markupAppliedField.value = '00.00';
            } else {
              markupAppliedField.endAdornment = markupTypeField.value?.symbol;
              markupAppliedField.startAdornment = '';
              markupAppliedField.value = '0';
            }
            setFormElementsCreateFields([...output]);
          }
        }
      }
    },
    [],
  );

  const resetFields = useCallback(() => {
    setFormElementsCreateFields(
      initializeFormFields(
        createFormElements.fields,
        !!companyProfile?.enableInventoryMarkup,
      ),
    );
    setDisableSaveButton(false);
    setLastChangedControl(PartNumberType.None);
    validateAndTrigger('', PartNumberType.PartNumber);
  }, [initializeFormFields, companyProfile?.enableInventoryMarkup]);

  const [
    doCreateInventory,
    {
      data: createInventoryData,
      isError: isAddError,
      error: addError,
      isLoading: isAddLoading,
      isSuccess: isAddSuccess,
      reset: resetAdd,
    },
  ] = useCreateInventoryMutation();

  const useCreateInventory = useCallback(() => {
    const doCreate = async data => {
      if (isLoading) {
        return;
      }
      if (
        validatePartNumberData?.isDuplicate &&
        validatePartNumberData.matchingField === PartNumberType.PartNumber
      ) {
        return;
      }

      if (!companyProfile?.enableInventoryMarkup) {
        delete data.markupType;
        delete data.markupApplied;
      }

      doCreateInventory(data);
    };

    return [
      doCreate,
      {
        data: doCreateInventory,
        isError: isAddError,
        error: addError,
        isLoading: isAddLoading,
        isSuccess: isAddSuccess,
        reset: resetAdd,
        validatePartNumberData,
      },
    ];
  }, [
    doCreateInventory,
    addError,
    isAddError,
    isAddLoading,
    isAddSuccess,
    resetAdd,
    isLoading,
  ]);

  const assetTypeFilterOptions = useMemo(() => {
    return (
      _.orderBy(assetTypes?.data, 'name')?.map(assetType => ({
        id: assetType?.id,
        label: assetType?.name,
        fieldName: assetType?.name,
        isInitialSelected:
          assetTypes?.data &&
          assetTypes?.data?.length <= MAX_SELECTABLE_OPTIONS,
      })) || []
    );
  }, [assetTypes?.data]);
  return (
    <ECDynamicPageTemplate
      pageTitle={t('translation:pages.inventory.title')}
      showStatusActiveFilter={!isMobileSize}
      useLazyGetListQuery={useLazyGetInventoryItemsListQuery}
      useCreateMutation={useCreateInventory}
      detailsConfig={undefined}
      detailsFields={undefined}
      onRowClick={handleRowClick}
      createFormConfig={createFormElements.config}
      createFormFields={formElementsCreateFields}
      onChange={handleOutputChange}
      onDrawerClose={resetFields}
      withDrawer={false}
      moduleName="inventory"
      marginTop={marginTop}
      showLocationFilter
      useLocationFilterQuery={
        hasEditInventoryPermission
          ? useGetInventoryStorageLocationsListQuery
          : undefined
      }
      showManufacturerFilter
      useManufacturerFilterQuery={useGetManufactureListQuery}
      drawerTitleBarBGColor={themes.light.palette.other.divider}
      onAddNewClick={() => setIsDrawerOpen(true)}
      createSaveButtonDisabled={disableSaveButton}
      shouldNotUseActiveFilter
      showAssetTypeNameFilter
      useGetAssetTypesFilterQuery={useGetAssetTypesForInventoryListQuery}
      assetTypeFilterOptions={assetTypeFilterOptions}
    />
  );
};
