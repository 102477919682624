import React, { useState } from 'react';
import { ECButton } from 'app/components/ECButton';
import { themes } from 'styles/theme/themes';
import { ECDrawerDetails } from '../ECDrawerDetails';
import { ECBox } from '../ECBox';
import { StyleConstants } from 'styles/StyleConstants';
import { ECTypography } from '../ECTypography';
import { ECIconButton } from '../ECIconButton';
import { Close } from '@mui/icons-material';
import { SpendBudgetCard } from '../ECBudgetCard/SpendBudget';
import { useTranslation } from 'react-i18next';
import { useGetBranchBudgetByModuleQuery } from 'services/budgetApi';
import BarChartIcon from '@mui/icons-material/BarChart';

interface ECBudgetButtonProps {
  id: number;
  moduleName: string;
  branchId: number;
  branchName: string;
  disabled?: boolean;
  sx?: any;
}

export const ECBudgetButton = ({
  id,
  moduleName,
  branchId,
  branchName,
  disabled,
  sx,
}: ECBudgetButtonProps) => {
  const { t } = useTranslation();

  const { data: budgetData, isLoading: isBudgetDataLoading } =
    useGetBranchBudgetByModuleQuery(
      {
        branchId,
        moduleName,
        moduleId: id,
      },
      {
        skip: !branchId || !id,
      },
    );

  const color = isBudgetDataLoading
    ? themes.light.palette.grey[600]
    : budgetData?.currentPeriodColor;

  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <ECButton
        disabled={disabled}
        isLoading={isBudgetDataLoading}
        onClick={handleOpen}
        sx={{
          ...sx,
          margin: 1,
          borderColor: `${color} !important`,
          color: `${color} !important`,
        }}
        startIcon={<BarChartIcon />}
        variant="text"
      >
        {t('budget.buttonTitle')}
      </ECButton>

      <ECDrawerDetails open={isOpen} anchor="right" onClose={handleClose}>
        <ECBox
          display="flex"
          flexDirection="column"
          position="relative"
          bgcolor={theme => theme.palette.background.primary}
          pt={StyleConstants.NAV_BAR_HEIGHT}
        >
          <ECBox
            display="flex"
            justifyContent="space-between"
            px={4}
            py={2}
            bgcolor={theme => theme.palette.action.focus}
          >
            <ECTypography variant="h4">Budget</ECTypography>

            <ECIconButton withBorder squared onClick={handleClose}>
              <Close />
            </ECIconButton>
          </ECBox>
        </ECBox>
        <ECBox p={3}>
          <SpendBudgetCard {...budgetData} location={branchName} />
        </ECBox>
      </ECDrawerDetails>
    </>
  );
};
