import { Check } from '@mui/icons-material';
import { ECButton } from 'app/components';
import { usePurchaseOrderActionClickMutation } from 'services/purchaseOrderApi';
import { themes } from 'styles/theme/themes';

export const ECApprovePOButton = ({ id, approveStatusId }) => {
  const [
    doUpdatePurchaseOrderStatus,
    { isLoading: isLoadingAction, isSuccess: isSuccessUpdateAction },
  ] = usePurchaseOrderActionClickMutation();

  return (
    <ECButton
      variant="outlined"
      color="success"
      startIcon={
        <Check sx={{ color: theme => theme.palette.graphic.alert.success }} />
      }
      sx={theme => ({
        border: 1,
        borderColor: `${theme.palette.success.outlinedRestingBackground} !important`,
        color: `${theme.palette.graphic.alert.success} !important`,
        marginRight: 1,
      })}
      onClick={() => {
        doUpdatePurchaseOrderStatus({
          id: +(id || 0),
          statusTo: approveStatusId,
        });
      }}
      isLoading={isLoadingAction}
      loadingSpinnerColor={themes.light.palette.graphic.alert.success}
    >
      Approve
    </ECButton>
  );
};
