import React, { useCallback, useMemo, useState } from 'react';
import { ECBox } from '../ECBox';
import { ECPaper } from '../ECPaper';
import { ECTypography } from '../ECTypography';
import {
  ECDivider,
  ECList,
  ECListItem,
  ECLink,
  ECAccordion,
  ECAccordionSummary,
} from '..';
import { useTranslation } from 'react-i18next';
import { useServiceProviderUser } from 'app/hooks';
import { ECIconButton } from '../ECIconButton';
import { Link } from '@mui/icons-material';
import LinkOffIcon from '@mui/icons-material/LinkOff';

import Accordion from '@mui/material/Accordion';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { themes } from 'styles/theme/themes';

interface ECWorkflowSidebarProps {
  location?: LinkItem;
  asset?: LinkItem;
  generalAsset?: LinkItem;
  replacedAsset?: LinkItem;
  replacedByAsset?: LinkItem;
  workOrder?: LinkItem;
  invoices?: LinkItemsList;
  proposals?: LinkItemsList;
  rfps?: LinkItemsList;
  pm?: LinkItem;
  po?: LinkItem;
  supplier?: LinkItem;
  linkedWO?: LinkItemsList;
  onLinkedWODeleteClick?: (string) => void;
  disableSidebarOnEdit?: boolean;
}

const webPage: string | undefined = process.env.REACT_APP_URL;

const listItemSx = {
  minHeight: '50px',
  flexDirection: 'column',
  alignItems: 'flex-start',
};
const selectedListItemsSx = {
  minHeight: '50px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  backgroundColor: themes.light.palette.table.table,
  color: themes.light.palette.text.primary,
};

const titleItemSx = {
  color: themes.light.palette.text.primary,
  whiteSpace: 'nowrap',
  fontWeight: '700',
};

const selectedLinkSx = {
  color: themes.light.palette.text.primary,
  maxWidth: '170px',
};

const linkSx = {
  color: `${themes.light.palette.text.links} !important`,
  maxWidth: '170px',
};
const linkedWOSx = {
  position: 'relative',
  '.link-icon': {
    visibility: 'visible',
  },
  '.link-off-icon': {
    position: 'absolute',
    visibility: 'hidden',
    color: 'red',
  },
  '&:hover': {
    '.link-icon': {
      visibility: 'hidden',
    },
    '.link-off-icon': {
      visibility: 'visible',
    },
  },
};
const accordionSx = {
  boxShadow: '0',
  justifyContent: 'space-around',
  alignItems: 'flex-start',
  width: '100%',
  position: 'relative',
};
const summarySx = { px: 2 };
export interface LinkItem {
  href: string;
  text: string;
  order?: number;
}

export interface LinkItemsList {
  content: LinkItem[];
  order?: number;
}

export const ECWorkflowSidebar = ({
  location,
  asset,
  workOrder,
  replacedAsset,
  replacedByAsset,
  invoices,
  proposals,
  rfps,
  linkedWO,
  onLinkedWODeleteClick,
  generalAsset,
  pm,
  po,
  supplier,
  disableSidebarOnEdit,
}: ECWorkflowSidebarProps) => {
  const { t } = useTranslation();

  const currentUrl = window.location.href;

  const isSelected = useCallback(
    (item: string) => currentUrl.includes(item),
    [currentUrl],
  );

  const handleWOLinkedButtonClick = href => {
    onLinkedWODeleteClick?.(href);
  };

  const isSPUser = useServiceProviderUser();

  const renderSidebarItems = useMemo(() => {
    const sidebarItems: any = [];
    if (asset) {
      sidebarItems.push({
        content: (
          <React.Fragment key="sidebar-asset">
            <ECListItem
              sx={isSelected('asset') ? selectedListItemsSx : listItemSx}
              disabled={disableSidebarOnEdit}
            >
              <ECTypography id={'asset'} sx={titleItemSx}>
                {t('translation:workflow.sidebar.asset')}
              </ECTypography>

              <ECLink
                sx={isSelected('asset') ? selectedLinkSx : linkSx}
                rel="noreferrer"
                href={`/panel/${isSPUser ? 'sp/' : ''}asset/${asset.href}`}
                notClickable={isSelected('asset') || disableSidebarOnEdit}
              >
                {asset.text}
              </ECLink>
            </ECListItem>
            <ECDivider />
          </React.Fragment>
        ),
        order: asset.order,
      });
    }

    if (replacedAsset) {
      sidebarItems.push({
        content: (
          <React.Fragment key="sidebar-replaced-asset">
            <ECListItem
              sx={
                isSelected('replaced-asset') ? selectedListItemsSx : listItemSx
              }
              disabled={disableSidebarOnEdit}
            >
              <ECTypography id={'replaced-asset'} sx={titleItemSx}>
                {t('translation:workflow.sidebar.replacedAsset')}
              </ECTypography>

              <ECLink
                sx={isSelected('replaced-asset') ? selectedLinkSx : linkSx}
                rel="noreferrer"
                href={`/panel/${isSPUser ? 'sp/' : ''}asset/${
                  replacedAsset.href
                }`}
                notClickable={
                  isSelected('replaced-asset') || disableSidebarOnEdit
                }
              >
                {replacedAsset.text}
              </ECLink>
            </ECListItem>
            <ECDivider />
          </React.Fragment>
        ),
        order: replacedAsset.order,
      });
    }

    if (replacedByAsset) {
      sidebarItems.push({
        content: (
          <React.Fragment key="sidebar-replaced-by-asset">
            <ECListItem
              sx={
                isSelected('replaced-by-asset')
                  ? selectedListItemsSx
                  : listItemSx
              }
              disabled={disableSidebarOnEdit}
            >
              <ECTypography id={'replaced-by-asset'} sx={titleItemSx}>
                {t('translation:workflow.sidebar.assetReplacedBy')}
              </ECTypography>

              <ECLink
                sx={isSelected('replaced-by-asset') ? selectedLinkSx : linkSx}
                rel="noreferrer"
                href={`/panel/${isSPUser ? 'sp/' : ''}asset/${
                  replacedByAsset.href
                }`}
                notClickable={
                  isSelected('replaced-by-asset') || disableSidebarOnEdit
                }
              >
                {replacedByAsset.text}
              </ECLink>
            </ECListItem>
            <ECDivider />
          </React.Fragment>
        ),
        order: replacedByAsset.order,
      });
    }

    if (generalAsset) {
      sidebarItems.push({
        content: (
          <React.Fragment key="sidebar-gen-asset">
            <ECListItem
              sx={
                isSelected('general-asset') ? selectedListItemsSx : listItemSx
              }
              disabled={disableSidebarOnEdit}
            >
              <ECTypography id={'general-asset'} sx={titleItemSx}>
                {t('translation:workflow.sidebar.generalAsset')}
              </ECTypography>

              <ECLink
                sx={isSelected('general-asset') ? selectedLinkSx : linkSx}
                rel="noreferrer"
                href={`/panel/${isSPUser ? 'sp/' : ''}general-asset/${
                  generalAsset.href
                }`}
                notClickable={
                  isSelected('general-asset') || disableSidebarOnEdit
                }
              >
                {generalAsset.text}
              </ECLink>
            </ECListItem>
            <ECDivider />
          </React.Fragment>
        ),
        order: generalAsset.order,
      });
    }

    if (workOrder) {
      sidebarItems.push({
        content: (
          <React.Fragment key="sidebar-wo">
            <ECListItem
              sx={isSelected('workorder') ? selectedListItemsSx : listItemSx}
              disabled={disableSidebarOnEdit}
            >
              <ECTypography id={'workorder'} sx={titleItemSx}>
                {t('translation:workflow.sidebar.workOrder')}
              </ECTypography>
              <ECLink
                sx={isSelected('workorder') ? selectedLinkSx : linkSx}
                rel="noreferrer"
                href={`/panel/${isSPUser ? 'sp/' : ''}workorder/${
                  workOrder.href
                }`}
                notClickable={isSelected('workorder') || disableSidebarOnEdit}
              >
                {workOrder.href}
              </ECLink>
            </ECListItem>
            <ECDivider />
          </React.Fragment>
        ),
        order: workOrder.order,
      });
    }

    if (rfps?.content?.length) {
      sidebarItems.push({
        content: (rfps.content?.length > 1 ? (
          <ECListItem
            sx={listItemSx}
            disabled={disableSidebarOnEdit}
            key="rfp-sidebar"
          >
            <ECAccordion
              title={`${t('translation:workflow.sidebar.rfps')} (${
                rfps.content?.length
              })`}
              accordionSx={accordionSx}
              summarySx={summarySx}
              defaultExpanded={isSelected('rfp')}
            >
              {rfps.content?.map(rfp => (
                <ECBox
                  key={`sidebar-rfp-${rfp.href}`}
                  pl={2}
                  sx={
                    isSelected('rfp') && isSelected(rfp.href)
                      ? selectedListItemsSx
                      : listItemSx
                  }
                >
                  <ECLink
                    sx={isSelected('rfp') ? selectedLinkSx : linkSx}
                    rel="noreferrer"
                    href={`/panel/${isSPUser ? 'sp/' : ''}rfp/${rfp.href}`}
                    notClickable={isSelected('rfp') || disableSidebarOnEdit}
                  >
                    {rfp.text}
                  </ECLink>
                </ECBox>
              ))}
            </ECAccordion>
          </ECListItem>
        ) : (
          <ECListItem
            key="rfp-sidebar"
            sx={isSelected('rfp') ? selectedListItemsSx : listItemSx}
            disabled={disableSidebarOnEdit}
          >
            <ECTypography id={'rfp'} sx={titleItemSx}>
              {t('translation:workflow.sidebar.rfp')}
            </ECTypography>
            <ECLink
              sx={isSelected('rfp') ? selectedLinkSx : linkSx}
              rel="noreferrer"
              href={`/panel/${isSPUser ? 'sp/' : ''}rfp/${
                rfps.content?.[0].href
              }`}
              notClickable={isSelected('rfp') || disableSidebarOnEdit}
            >
              {rfps.content?.[0].text}
            </ECLink>
          </ECListItem>
        )) || <></>,
        order: rfps.order,
      });
    }

    if (proposals?.content?.length) {
      sidebarItems.push({
        content: (proposals.content?.length > 1 ? (
          <ECListItem
            key="sidebar-proposals"
            sx={{ ...listItemSx, p: 0 }}
            disabled={disableSidebarOnEdit}
          >
            <ECAccordion
              title={`${t('translation:workflow.sidebar.proposals')} (${
                proposals.content?.length
              })`}
              accordionSx={accordionSx}
              summarySx={summarySx}
              detailsSx={{ p: 0 }}
              defaultExpanded={isSelected('proposal')}
            >
              {proposals.content?.map(proposal => (
                <ECBox
                  key={`sidebar-proposal-${proposal.href}`}
                  pl={2}
                  sx={
                    isSelected('proposal') && isSelected(proposal.href)
                      ? selectedListItemsSx
                      : listItemSx
                  }
                >
                  <ECLink
                    sx={isSelected('proposal') ? selectedLinkSx : linkSx}
                    rel="noreferrer"
                    href={`/panel/${isSPUser ? 'sp/' : ''}proposal/${
                      proposal.href
                    }`}
                    notClickable={
                      isSelected('proposal') || disableSidebarOnEdit
                    }
                  >
                    {proposal.text}
                  </ECLink>
                </ECBox>
              ))}
            </ECAccordion>
          </ECListItem>
        ) : (
          <ECListItem
            key="sidebar-proposals"
            sx={isSelected('proposal') ? selectedListItemsSx : listItemSx}
            disabled={disableSidebarOnEdit}
          >
            <ECTypography id={'proposal'} sx={titleItemSx}>
              {t('translation:workflow.sidebar.proposal')}
            </ECTypography>
            <ECLink
              sx={isSelected('proposal') ? selectedLinkSx : linkSx}
              rel="noreferrer"
              href={`/panel/${isSPUser ? 'sp/' : ''}proposal/${
                proposals.content?.[0]?.href
              }`}
              notClickable={isSelected('proposal') || disableSidebarOnEdit}
            >
              {proposals.content?.[0]?.href}
            </ECLink>
          </ECListItem>
        )) || <></>,
        order: proposals.order,
      });
    }

    if (po) {
      sidebarItems.push({
        content: (
          <React.Fragment key="sidebar-po">
            <ECListItem
              sx={
                isSelected('purchase-orders') ? selectedListItemsSx : listItemSx
              }
              disabled={disableSidebarOnEdit}
            >
              <ECTypography id={'po'} sx={titleItemSx}>
                {t('translation:workflow.sidebar.po')}
              </ECTypography>
              <ECLink
                sx={isSelected('purchase-orders') ? selectedLinkSx : linkSx}
                rel="noreferrer"
                href={`/panel/${isSPUser ? 'sp/' : ''}purchase-orders/${
                  po.href
                }/details`}
                notClickable={
                  isSPUser ||
                  isSelected('purchase-orders') ||
                  disableSidebarOnEdit
                }
              >
                {po.text}
              </ECLink>
            </ECListItem>
            <ECDivider />
          </React.Fragment>
        ),
        order: po.order,
      });
    }

    if (supplier) {
      sidebarItems.push({
        content: (
          <React.Fragment key="sidebar-supplier">
            <ECListItem
              sx={isSelected('supplier') ? selectedListItemsSx : listItemSx}
              disabled={disableSidebarOnEdit}
            >
              <ECTypography id={'supplier'} sx={titleItemSx}>
                {t('translation:workflow.sidebar.supplier')}
              </ECTypography>
              <ECLink
                sx={isSelected('supplier') ? selectedLinkSx : linkSx}
                rel="noreferrer"
                href=""
                notClickable
              >
                {supplier.text}
              </ECLink>
            </ECListItem>
            <ECDivider />
          </React.Fragment>
        ),
        order: supplier.order,
      });
    }

    if (invoices?.content?.length) {
      sidebarItems.push({
        content: (invoices.content?.length > 1 ? (
          <React.Fragment key="invoices-sidebar">
            <ECListItem
              sx={{ ...listItemSx, p: 0 }}
              disabled={disableSidebarOnEdit}
            >
              <ECAccordion
                title={`${t('translation:workflow.sidebar.invoices')} (${
                  invoices.content?.length
                })`}
                accordionSx={accordionSx}
                summarySx={summarySx}
                defaultExpanded={isSelected('invoice')}
              >
                {invoices.content?.map(invoice => (
                  <ECBox
                    key={`sidebar-invoice-${invoice.href}`}
                    pl={2}
                    sx={
                      isSelected('invoice') && isSelected(invoice.href)
                        ? selectedListItemsSx
                        : listItemSx
                    }
                  >
                    <ECLink
                      sx={isSelected('invoice') ? selectedLinkSx : linkSx}
                      rel="noreferrer"
                      href={`/panel/${isSPUser ? 'sp/' : ''}invoice/${
                        invoice.href
                      }`}
                      notClickable={
                        isSelected('invoice') || disableSidebarOnEdit
                      }
                    >
                      {invoice.text}
                    </ECLink>
                  </ECBox>
                ))}
              </ECAccordion>
            </ECListItem>
            <ECDivider />
          </React.Fragment>
        ) : (
          <React.Fragment key="invoices-sidebar">
            <ECListItem
              sx={
                (currentUrl.includes('invoice') && selectedListItemsSx) ||
                listItemSx
              }
              disabled={disableSidebarOnEdit}
            >
              <ECTypography
                id={'invoices'}
                sx={{ whiteSpace: 'nowrap', fontWeight: '700' }}
              >
                {t('translation:workflow.sidebar.invoice')}
              </ECTypography>
              <ECLink
                sx={isSelected('invoice') ? selectedLinkSx : linkSx}
                rel="noreferrer"
                href={`/panel/${isSPUser ? 'sp/' : ''}invoice/${
                  invoices.content?.[0]?.href
                }`}
                notClickable={isSelected('invoice') || disableSidebarOnEdit}
              >
                {invoices.content?.[0].text}
              </ECLink>
            </ECListItem>
            <ECDivider />
          </React.Fragment>
        )) || <></>,
        order: invoices.order,
      });
    }

    if (pm) {
      sidebarItems.push({
        content: (
          <React.Fragment key="sidebar-wo">
            <ECListItem
              sx={isSelected('pm') ? selectedListItemsSx : listItemSx}
              disabled={disableSidebarOnEdit}
            >
              <ECTypography id={'pm'} sx={titleItemSx}>
                {t('translation:workflow.sidebar.pm')}
              </ECTypography>
              <ECLink
                sx={isSelected('pm') ? selectedLinkSx : linkSx}
                rel="noreferrer"
                href={`/panel/${isSPUser ? 'sp/' : ''}preventivemaintenance/${
                  pm.href
                }/details`}
                notClickable={
                  isSPUser || isSelected('pm') || disableSidebarOnEdit
                }
              >
                {pm.text}
              </ECLink>
            </ECListItem>
            <ECDivider />
          </React.Fragment>
        ),
        order: pm.order,
      });
    }

    if (location) {
      sidebarItems.push({
        content: (
          <React.Fragment key="sidebar-location">
            <ECListItem
              sx={isSelected('location') ? selectedListItemsSx : listItemSx}
              disabled={disableSidebarOnEdit}
            >
              <ECTypography id={'location'} sx={titleItemSx}>
                {t('translation:workflow.sidebar.location')}
              </ECTypography>
              <ECLink
                sx={isSelected('location') ? selectedLinkSx : linkSx}
                rel="noreferrer"
                href={`/panel/location/${encodeURI(location.href)}`}
                notClickable={isSelected('location') || disableSidebarOnEdit}
              >
                {location.text}
              </ECLink>
            </ECListItem>
            <ECDivider />
          </React.Fragment>
        ),
        order: location.order,
      });
    }

    if (linkedWO?.content?.length) {
      sidebarItems.push({
        content: (linkedWO.content?.length > 1 ? (
          <ECListItem
            sx={listItemSx}
            key="linkedwo-sidebar"
            disabled={disableSidebarOnEdit}
          >
            <Accordion
              sx={{
                boxShadow: '0',
                justifyContent: 'space-around',
                alignItems: 'flex-start',
                width: '100%',
                position: 'relative',
              }}
              defaultExpanded={false}
            >
              <ECAccordionSummary
                sx={{ px: 0 }}
                expandIcon={<ExpandMoreIcon />}
              >
                <ECTypography sx={{ fontWeight: '700' }}>{`${t(
                  'translation:workflow.sidebar.linkedWO',
                )} (${linkedWO.content?.length})`}</ECTypography>
              </ECAccordionSummary>
              <ECPaper
                sx={{
                  width: '100%',
                  marginLeft: 2,
                  border: 'none',
                  borderLeft: theme => `3px solid ${theme.palette.grey[500]}`,
                }}
              >
                <MenuList>
                  {linkedWO.content?.map((lwo, index) => (
                    <MenuItem
                      key={lwo.href}
                      sx={{
                        justifyContent: 'space-between',
                        '&:hover': { cursor: 'auto' },
                        height: '35px',
                      }}
                    >
                      <ECLink
                        sx={linkSx}
                        rel="noreferrer"
                        href={`/panel/${isSPUser ? 'sp/' : ''}workorder/${
                          lwo.href
                        }`}
                      >
                        {lwo.text}
                      </ECLink>
                      <ECIconButton
                        onClick={() => handleWOLinkedButtonClick(lwo.href)}
                        sx={linkedWOSx}
                      >
                        <>
                          <Link className="link-icon" />
                          <LinkOffIcon className="link-off-icon" />
                        </>
                      </ECIconButton>
                    </MenuItem>
                  ))}
                </MenuList>
              </ECPaper>
            </Accordion>
          </ECListItem>
        ) : (
          <ECListItem
            key="linkedWO-sidebar"
            sx={isSelected('linkedWO') ? selectedListItemsSx : listItemSx}
            disabled={disableSidebarOnEdit}
          >
            <ECTypography id={'linkedWO'} sx={titleItemSx}>
              {t('translation:workflow.sidebar.linkedWO')} (1)
            </ECTypography>
            <MenuList>
              <MenuItem
                key={linkedWO?.content?.[0]?.href}
                sx={{
                  justifyContent: 'space-between',
                  '&:hover': { cursor: 'auto' },
                  height: '35px',
                }}
              >
                <ECLink
                  sx={isSelected('linkedWO') ? selectedLinkSx : linkSx}
                  rel="noreferrer"
                  href={`/panel/${isSPUser ? 'sp/' : ''}workorder/${
                    linkedWO?.content?.[0]?.href
                  }`}
                  notClickable={isSelected('linkedWO') || disableSidebarOnEdit}
                >
                  {linkedWO?.content?.[0]?.text}
                </ECLink>
                &nbsp;
                <ECIconButton
                  onClick={() =>
                    handleWOLinkedButtonClick(linkedWO?.content?.[0]?.href)
                  }
                  sx={linkedWOSx}
                >
                  <>
                    <Link className="link-icon" />
                    <LinkOffIcon className="link-off-icon" />
                  </>
                </ECIconButton>
              </MenuItem>
            </MenuList>
          </ECListItem>
        )) || <></>,
        order: linkedWO.order,
      });
    }

    return sidebarItems
      .sort((a, b) => a.order - b.order)
      .map(item => item.content);
  }, [
    location,
    asset,
    workOrder,
    invoices,
    proposals,
    rfps,
    pm,
    po,
    supplier,
    generalAsset,
    disableSidebarOnEdit,
    linkedWO,
    isSPUser,
    replacedAsset,
    replacedByAsset,
    currentUrl,
    isSelected,
  ]);

  return (
    <ECBox>
      <ECBox
        display="flex"
        justifyContent="space-between"
        width="100%"
        height="100%"
        bgcolor={theme => theme.palette.action.hover}
        py={1}
        px={3}
        mt={0}
      >
        <ECList
          sx={{
            width: '200px',
            bgcolor: 'background.paper',
            overflow: 'auto',
            padding: 0,
          }}
          dense
          role="list"
        >
          {renderSidebarItems}
        </ECList>
      </ECBox>
    </ECBox>
  );
};
