import { ECButton, ECEasyForm } from 'app/components';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'store/slice/page';
import { useTranslation } from 'react-i18next';
import { moduleApi } from 'services/moduleApi';
import { Close } from '@mui/icons-material';
import { themes } from 'styles/theme/themes';
import { useUpdateRfpStatusMutation } from 'services/requestForPricingApi';
import { ECModal } from 'app/components/ECModal';

interface CancelRfpModalButtonProps {
  rfpId: number;
  onSave?: () => void;
  disabled?: boolean;
  statusToId?: number;
}

const fancyFormCancelRfp = require('./fancy_form_config_cancel_rfp.json');

export function CancelRfpModalButton({
  onSave,
  disabled,
  rfpId,
  statusToId,
}: CancelRfpModalButtonProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (rfpId) {
      const addCommentField = fancyFormCancelRfp.fields.find(
        f => f.fieldName === 'cancelReason',
      );

      if (addCommentField) {
        addCommentField.moduleId = rfpId;
      }
    }
  }, [rfpId, fancyFormCancelRfp]);

  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

  const [
    doCancelRfp,
    { isLoading: isLoadingCancelRfp, isSuccess: isSuccessCancelRfp },
  ] = useUpdateRfpStatusMutation();

  useEffect(() => {
    if (isSuccessCancelRfp) {
      dispatch(moduleApi.util.invalidateTags(['ModuleDetails']));
      dispatch(
        setSnackbar({
          severity: 'success',
          message: 'RFP was Canceled.',
        }),
      );
      onSave?.();
      setIsCancelModalOpen(false);
    }
  }, [isSuccessCancelRfp, dispatch, onSave]);

  const handleCancelRfp = useCallback(
    (formData, fieldData) => {
      const cancelReasonField = fieldData.find(
        data => data.fieldName === 'cancelReason',
      );
      const cancelReason = cancelReasonField?.comment;

      const recipientIds = cancelReasonField?.selectedUsers?.map(
        user => user.nodeId,
      );
      doCancelRfp({
        id: rfpId,
        statusTo: statusToId,
        declineReason: cancelReason,
        recipientIds,
      });
    },
    [doCancelRfp, rfpId],
  );

  return (
    <>
      <ECButton
        variant="outlined"
        color="error"
        startIcon={
          <Close sx={{ color: theme => theme.palette.graphic.alert.error }} />
        }
        sx={theme => ({
          border: 1,
          borderColor: `${theme.palette.error.outlinedRestingBackground} !important`,
          color: `${theme.palette.graphic.alert.error} !important`,
          marginRight: 1,
        })}
        onClick={() => setIsCancelModalOpen(true)}
        isLoading={isLoadingCancelRfp}
        loadingSpinnerColor={themes.light.palette.graphic.alert.success}
        disabled={disabled}
      >
        Cancel
      </ECButton>
      <ECModal isOpen={isCancelModalOpen} onClose={() => onSave?.()} noPadding>
        <ECEasyForm
          pattern="modal"
          config={fancyFormCancelRfp.config}
          fields={fancyFormCancelRfp.fields}
          isSendingData={isLoadingCancelRfp}
          isLoading={isLoadingCancelRfp}
          isLoadingForm={false}
          onFormSubmit={handleCancelRfp}
          saveButtonColor="error"
          customDrawerTitle={`Confirm you want to Cancel RFP #${rfpId}`}
          additionalActions={
            <ECButton
              type="button"
              variant="text"
              onClick={() => setIsCancelModalOpen(false)}
            >
              {t('translation:dynamicForm.cancel')}
            </ECButton>
          }
        />
      </ECModal>
    </>
  );
}
