export interface Permission {
  permissionId: number;
  name: string;
  action: string;
}

export enum P {
  Unset = '',
  GetUser = 'GET_USER',
  GetAllUsers = 'GET_ALL_USERS',
  EditUser = 'EDIT_USER',
  AddUser = 'ADD_USER',
  DeleteUser = 'DELETE_USER',
  GetAsset = 'GET_ASSET',
  GetAllAssets = 'GET_ALL_ASSETS',
  EditAsset = 'EDIT_ASSET',
  AddAsset = 'ADD_ASSET',
  DeleteAsset = 'DELETE_ASSET',
  ReplaceAsset = 'REPLACE_ASSET',
  CopyAsset = 'COPY_ASSET',
  GetGeneralAsset = 'GET_GENERAL_ASSET',
  GetAllGeneralAssets = 'GET_ALL_GENERAL_ASSETS',
  EditGeneralAsset = 'EDIT_GENERAL_ASSET',
  AddGeneralAsset = 'ADD_GENERAL_ASSET',
  DeleteGeneralAsset = 'DELETE_GENERAL_ASSET',
  GetBranch = 'GET_BRANCH',
  GetAllBranches = 'GET_ALL_BRANCHES',
  EditBranch = 'EDIT_BRANCH',
  AddBranch = 'ADD_BRANCH',
  DeleteBranch = 'DELETE_BRANCH',
  GetProblemType = 'GET_PROBLEM_TYPES',
  GetAllProblemTypes = 'GET_ALL_PROBLEM_TYPES',
  AddProblemType = 'ADD_PROBLEM_TYPE',
  DeleteProblemType = 'DELETE_PROBLEM_TYPE',
  EditProblemType = 'EDIT_PROBLEM_TYPE',
  GetTrade = 'GET_TRADE',
  GetAllTrades = 'GET_ALL_TRADES',
  EditTrade = 'EDIT_TRADE',
  AddTrade = 'ADD_TRADE',
  DeleteTrade = 'DELETE_TRADE',
  GetWarranty = 'GET_WARRANTY',
  GetAllWarranties = 'GET_ALL_WARRANTIES',
  EditWarranty = 'EDIT_WARRANTY',
  AddWarranty = 'ADD_WARRANTY',
  DeleteWarranty = 'DELETE_WARRANTY',
  GetWorkOrder = 'GET_WORK_ORDER',
  GetAllWorkOrders = 'GET_ALL_WORK_ORDERS',
  EditWorkOrder = 'EDIT_WORK_ORDER',
  ReassignWorkOrder = 'REASSIGN_WORKORDER',
  AddWorkOrder = 'ADD_WORK_ORDER',
  DeleteWorkOrder = 'DELETE_WORK_ORDER',
  ChangeWorkorderStatus = 'CHANGE_WORKORDER_STATUS',
  ChangeProposalStatus = 'CHANGE_PROPOSAL_STATUS',
  ChangeInvoiceStatus = 'CHANGE_INVOICE_STATUS',
  GetJobTitle = 'GET_JOB_TITLE',
  GetAllJobTitle = 'GET_ALL_JOB_TITLE',
  EditJobTitle = 'EDIT_JOB_TITLE',
  AddJobTitle = 'ADD_JOB_TITLE',
  DeleteJobTitle = 'DELETE_JOB_TITLE',
  GetAllManufactures = 'Get_ALL_MANUFACTURES',
  GetAllAssetTypesWithNames = 'GET_ALL_ASSET_TYPES_WITH_NAMES',
  GetAllAssetTypesWithProblems = 'GET_ALL_ASSET_TYPES_WITH_PROBLEMS',
  GetAllAssetTypesWithRepairs = 'GET_ALL_ASSET_TYPES_WITH_REPAIRS',
  GetAssetType = 'GET_ASSET_TYPE',
  GetAllAssetTypes = 'GET_ALL_ASSET_TYPES',
  AddAssetType = 'ADD_ASSET_TYPE',
  DeleteAssetType = 'DELETE_ASSET_TYPE',
  EditAssetType = 'EDIT_ASSET_TYPE',
  DeleteAssetTemplateNames = 'DELETE_ASSET_TEMPLATE_NAMES',
  DeleteRepairTypeFromAssetType = 'DELETE_REPAIR_TYPE_FROM_ASSET_TYPE',
  DeleteProblemTypeFromAssetType = 'DELETE_PROBLEM_TYPE_FROM_ASSET_TYPE',
  AddProblemToAssetType = 'ADD_PROBLEM_TYPE_TO_ASSET_TYPE',
  AddTradeProblemToAssetType = 'ADD_TRADE_PROBLEM_TYPE_TO_ASSET_TYPE',
  AddRepairToAssetType = 'ADD_REPAIR_TYPE_TO_ASSET_TYPE',
  GetOrgTreeByCompany = 'GET_ORG_TREE',
  AddOrganization = 'ADD_ORG',
  UpdateOrganization = 'UPDATE_ORG',
  AddAssetTemplateName = 'ADD_ASSET_TEMPLATE_NAME',
  GetBranchCategory = 'GET_BRANCH_CATEGORY',
  GetAllBranchCategories = 'GET_ALL_BRANCH_CATEGORIES',
  AddBranchCategory = 'ADD_BRANCH_CATEGORY',
  DeleteBranchCategory = 'DELETE_BRANCH_CATEGORY',
  EditBranchCategory = 'EDIT_BRANCH_CATEGORY',
  DeleteRolesFromJobTitle = 'DELETE_ROLES_FROM_JOB_TITLE',
  AddRolesToJobTitle = 'ADD_ROLES_TO_JOB_TITLE',
  GetAllJobTitlesWithRoles = 'GET_ALL_JOB_TITLE_WITH_ROLES',
  GetRepairType = 'GET_REPAIR_TYPE',
  GetAllRepairTypes = 'GET_ALL_REPAIR_TYPES',
  AddRepairType = 'ADD_REPAIR_TYPE',
  DeleteRepairType = 'DELETE_REPAIR_TYPE',
  EditRepairType = 'EDIT_REPAIR_TYPE',
  AddRolesToUser = 'ADD_ROLES_TO_USER',
  RemoveRolesFromUser = 'REMOVE_ROLES_FROM_USER',
  GetCoreTrades = 'GET_CORE_TRADES',
  DeleteOrganization = 'DELETE_ORGANIZATION',
  GetLookupData = 'GET_LOOKUP_DATA',
  GetGeneralAssets = 'GET_GENERAL_ASSETS',
  AddUserToOrg = 'ADD_USER_TO_ORG',
  GetAllFlags = 'GET_ALL_FLAGS',
  GetFlag = 'GET_FLAG',
  AddFlag = 'ADD_FLAG',
  EditFlag = 'EDIT_FLAG',
  DeleteFlag = 'DELETE_FLAG',
  GetComment = 'GET_COMMENT',
  AddComment = 'ADD_COMMENT',
  UpdateComment = 'UPDATE_COMMENT',
  DeleteComment = 'DELETE_COMMENT',
  AddFlagsToAsset = 'ADD_FLAGS_TO_ASSET',
  RemoveFlagsFromAsset = 'REMOVE_FLAGS_FROM_ASSET',
  GetJobTitlesByIndustry = 'GET_JOB_TITLES_BY_INDUSTRY',
  GetAllAreas = 'GET_ALL_AREAS',
  GetArea = 'GET_AREA',
  AddArea = 'ADD_AREA',
  DeleteArea = 'DELETE_AREA',
  EditArea = 'EDIT_AREA',
  GetAllCategories = 'GET_ALL_CATEGORIES',
  GetCategory = 'GET_CATEGORY',
  AddCategory = 'ADD_CATEGORY',
  DeleteCategory = 'DELETE_CATEGORY',
  EditCategory = 'EDIT_CATEGORY',
  AddUserNotificationConfiguration = 'ADD_USER_NOTIFICATION_CONFIGURATION',
  EditUserNotificationConfiguration = 'EDIT_USER_NOTIFICATION_CONFIGURATION',
  DeleteUserNotificationConfiguration = 'DELETE_USER_NOTIFICATION_CONFIGURATION',
  AddFlagsToUser = 'ADD_FLAGS_TO_USER',
  RemoveFlagsFromUser = 'REMOVE_FLAGS_FROM_USER',
  AddAttachment = 'ADD_ATTACHMENT',
  GetAttachment = 'GET_ATTACHMENT',
  EditAttachment = 'EDIT_ATTACHMENT',
  AddTroubleshoot = 'ADD_TROUBLESHOOT',
  DeleteTroubleshoot = 'DELETE_TROUBLESHOOT',
  EditTroubleshoot = 'EDIT_TROUBLESHOOT',
  GetTroubleshoot = 'GET_TROUBLESHOOT',
  GetAllTroubleshoot = 'GET_ALL_TROUBLESHOOT',
  AddProblemTroubleshootToAssetGroup = 'ADD_PROBLEM_TROUBLESHOOT_TO_ASSET_TYPE',
  RemoveProblemTroubleshootFromAssetGroup = 'REMOVE_PROBLEM_TROUBLESHOOT_FROM_ASSET_TYPE',
  GetAllAssetGroupProblemTroubleshoot = 'GET_ALL_ASSET_GROUP_PROBLEM_TROUBLESHOOT',
  GetAllPriorities = 'GET_ALL_PRIORITY',
  GetPriority = 'GET_PRIORITY',
  AddPriority = 'ADD_PRIORITY',
  EditPriority = 'EDIT_PRIORITY',
  EditPriorityOnWorkOrder = 'EDIT_PRIORITY_ON_WORK_ORDER',
  DeletePriority = 'DELETE_PRIORITY',
  GetAllApproval = 'GET_ALL_APPROVAL',
  GetApproval = 'GET_APPROVAL',
  AddApproval = 'ADD_APPROVAL',
  EditApproval = 'EDIT_APPROVAL',
  DeleteApproval = 'DELETE_APPROVAL',
  GetAllRFPs = 'GET_ALL_RFPS',
  GetRFP = 'GET_RFP',
  AddRFP = 'ADD_RFP',
  EditRFP = 'EDIT_RFP',
  DeleteRFP = 'DELETE_RFP',
  GetAllServiceProviders = 'GET_ALL_SERVICE_PROVIDERS',
  GetServiceProvider = 'GET_SERVICE_PROVIDER',
  AddServiceProvider = 'ADD_SERVICE_PROVIDER',
  UpdateServiceProvider = 'UPDATE_SERVICE_PROVIDER',
  DeleteServiceProvider = 'DELETE_SERVICE_PROVIDER',
  GetAllCompanyServiceProviders = 'GET_ALL_COMPANY_SERVICE_PROVIDERS',
  GetAllInvoices = 'GET_ALL_INVOICES',
  GetInvoice = 'GET_INVOICE',
  EditInvoice = 'EDIT_INVOICE',
  AddInvoice = 'ADD_INVOICE',
  GetAllProposals = 'GET_ALL_PROPOSALS',
  GetProposal = 'GET_PROPOSAL',
  EditProposal = 'EDIT_PROPOSAL',
  GetAllRequestsForPricing = 'GET_ALL_RFPS',
  SPMenu = 'SP_MENU',
  GetOrg = 'GET_ORG',
  GetPreventiveMaintenancesList = 'GET_PREVENTIVE_MAINTENANCES_LIST',
  GetPreventiveMaintenance = 'GET_PREVENTIVE_MAINTENANCE',
  AddPreventiveMaintenance = 'ADD_PREVENTIVE_MAINTENANCE',
  EditPreventiveMaintenance = 'UPDATE_PREVENTIVE_MAINTENANCE',
  DeletePreventiveMaintenance = 'DELETE_PREVENTIVE_MAINTENANCE',
  GetAllCustomers = 'GET_ALL_CUSTOMERS',
  GetCustomer = 'GET_CUSTOMER',
  GetServiceProviderInvitation = 'GET_SERVICE_PROVIDER_INVITATION',
  UserOverride = 'USER_OVERRIDE',
  GetScheduledTask = 'GET_SCHEDULED_TASK',
  GetTradeAssignments = 'GET_TRADE_ASSIGNMENTS',
  GetQsReport = 'GET_QS_REPORT',
  GetAllRolesWithPermissions = 'GET_ALL_ROLES_WITH_PERMISSIONS',
  AssetVerification = 'ASSET_VERIFICATION',
  SearchGenericNotification = 'SEARCH_GENERIC_NOTIFICATION',
  GetInventory = 'GET_INVENTORY',
  EditInventory = 'EDIT_INVENTORY',
  AddInventory = 'ADD_INVENTORY',
  GetInventoryStorage = 'GET_INVENTORY_STORAGE',
  AddInventoryStorage = 'ADD_INVENTORY_STORAGE',
  EditInventoryStorage = 'EDIT_INVENTORY_STORAGE',
  CustomerManageUser = 'CUSTOMER_MANAGE_USER',
  EditCompanyConfiguration = 'EDIT_COMPANY_CONFIGURATION',
  GetCompanyConfiguration = 'GET_COMPANY_CONFIGURATION',
  AddCompanyConfiguration = 'ADD_COMPANY_CONFIGURATION',
  IgnoreLocationAssignment = 'IGNORE_LOCATION_ASSIGNMENT',
  GetGlCodes = 'GET_GL_CODES',
  GetDispatch = 'GET_DISPATCH',
  UpdateTradeAssignments = 'UPDATE_TRADE_ASSIGNMENTS',
  GetDmt = 'GET_DMT',
  GetSurveyFeedback = 'GET_SURVEY_FEEDBACK',
  AddSurveyFeedback = 'ADD_SURVEY_FEEDBACK',
  GetSurveyTemplate = 'GET_SURVEY_TEMPLATE',
  AddSupplier = 'ADD_SUPPLIER',
  GetSupplier = 'GET_SUPPLIER',
  GetPurchaseOrder = 'GET_PURCHASE_ORDER',
  EditPurchaseOrder = 'EDIT_PURCHASE_ORDER',
  ViewLambdaExports = 'VIEW_LAMBDA_EXPORTS',
  ReviewRFPProposal = 'REVIEW_RFP_PROPOSAL',
  AllocateStock = 'ALLOCATE_STOCK',
  AdjustStock = 'ADJUST_STOCK',
  TransferStock = 'TRANSFER_STOCK',
  DeleteTradeProblemFromAssetType = 'DELETE_TRADE_PROBLEM_TYPE_FROM_ASSET_TYPE',
  GetBudget = 'GET_BUDGET',
  GetAllBudgets = 'GET_ALL_BUDGETS',
  AddPaymentMethod = 'ADD_PAYMENT_METHOD',
  DeferWorkOrder = 'DEFER_WORK_ORDER',
}

export enum CompanyConfigurationFields {
  CostCenterEnabled = 'costCenterEnabled',
  DefaultGlRequestCategoryId = 'defaultGlRequestCategoryId',
  EnableArea = 'enableArea',
  enableBudget = 'enableBudget',
  EnableCoupa = 'enableCoupa',
  EnableInventory = 'enableInventory',
  EnableRejectReason = 'enableRejectReason',
  EnableSynuma = 'enableSynuma',
  EnableWorkday = 'enableWorkday',
  EnableDispatch = 'enableDispatch',
}
