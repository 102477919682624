import { useEffect, useState, useMemo, Fragment } from 'react';
import {
  ECBox,
  ECButton,
  ECChipAutocomplete,
  ECFormControl,
  ECGrid,
  ECIconButton,
  ECMenuItem,
  ECPaper,
  ECSelect,
  ECStack,
  ECTextField,
  ECTypography,
  FieldTypes,
} from '..';
import _ from 'lodash';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import ECCurrencyField from '../ECCurrencyField';
import { InputLabel } from '@mui/material';
import { ECModal } from '../ECModal';
import { addStockAction } from './AddStock';
import { addInventoryItemAction } from './AddInventory';
import { useGetInventoryItemsListQuery } from 'services/inventoryApi';
import { P } from 'types/Permission';
import { useCompanyCustomerProfile } from 'app/hooks/useCompanyCustomerProfile.use-case';
import { useParams } from 'react-router';

export interface Location {
  id: number;
  name: string;
  avgPrice: string;
  quantity: number;
  count: number;
  inventoryStorageId?: number;
  measurementUnit?: {
    symbol: string;
  };
}

export interface InventoryItem {
  id: number;
  label: string;
  name: string;
  count?: number;
  avgPrice?: number;
  selectedLocations?: Location[];
  storageLocations?: any[];
  showEmptyRow?: boolean;
  measurementUnit?: {
    id: number;
    symbol: string;
    unitName: string;
    unitType: string;
  };
  unitValueWithMarkup?: number | null;
}

interface ECInventoryItemsProps {
  onChange?: (value: InventoryItem[]) => void;
  value?: InventoryItem[] | null;
  options?: any[];
  label?: string;
  placeholder?: string;
  required?: boolean;
  validationMessage?: string;
  allocatedInventoryItems?: InventoryItem[];
  assetGroupId?: any;
}

const localRed = '#d50000';
const quantityColor = count => {
  return count <= 0 ? localRed : 'black';
};

function calculateWeightedAveragePrice(item: InventoryItem): number {
  if (!item || !item.storageLocations || item.storageLocations.length === 0) {
    return 0; // Return 0 if storageLocations is undefined or empty
  }

  const totalData = item.storageLocations
    .map(location => ({
      value: location.count * location.avgPrice,
      count: location.count,
    }))
    .reduce(
      (acc, curr) => {
        return {
          totalValue: acc.totalValue + curr.value,
          totalCount: acc.totalCount + curr.count,
        };
      },
      { totalValue: 0, totalCount: 0 },
    );

  const { totalValue, totalCount } = totalData;
  const average = totalCount === 0 ? 0 : totalValue / totalCount;
  return parseFloat(average.toFixed(2));
}

export const ECInventoryItems: React.FC<ECInventoryItemsProps> = ({
  onChange,
  options,
  label,
  placeholder,
  required,
  validationMessage,
  allocatedInventoryItems,
  value,
  assetGroupId,
}) => {
  const [originalInventoryItems, setOriginalInventoryItems] = useState<
    InventoryItem[]
  >([]);
  const [selectedInventoryItems, setSelectedInventoryItems] = useState<
    InventoryItem[]
  >([]);
  const [addStockSelectedInventoryItem, setAddStockSelectedInventoryItem] =
    useState<InventoryItem>();
  const [showAddStockModal, setShowAddStockModal] = useState<boolean>(false);
  const [showAddInventoryItemModal, setShowAddInventoryItemModal] =
    useState<boolean>(false);

  const [newInventoryItemLabel, setNewInventoryItemLabel] = useState('');

  const companyProfile = useCompanyCustomerProfile();

  const canEditUnitCost = useMemo(
    () => !!companyProfile?.enablePriceEdit,
    [companyProfile],
  );

  const shouldShowUnitMarkupValue = useMemo(
    () => !!companyProfile?.enableInventoryMarkup,
    [companyProfile],
  );

  const updateNewInventoryItemName = newLabel => {
    setNewInventoryItemLabel(newLabel);
  };

  const [inventoryItemsListOptions, setInventoryItemsListOptions] = useState<
    InventoryItem[]
  >([]);

  const {
    data: dataInventoryItems,
    isLoading: isLoadingInventoryItems,
    isSuccess: isSuccessInventoryItems,
  } = useGetInventoryItemsListQuery({
    st: 1,
    t: 10000,
    assetTypeId: assetGroupId,
  });

  const newInventoryItemsListOptions: any[] = useMemo(() => {
    if (!isSuccessInventoryItems || !dataInventoryItems) return [];
    return (
      dataInventoryItems.data
        ?.filter(item => item?.inventoryItemStorage?.length > 0)
        ?.map(inventoryItem => {
          const totalQuantityOnHand = inventoryItem.inventoryItemStorage.reduce(
            (acc, storage) => acc + storage.count,
            0,
          );

          const newStorageLocations = originalInventoryItems?.length
            ? inventoryItem.inventoryItemStorage.map(storage => {
                const allocatedItem = originalInventoryItems.find(
                  item => item.id === inventoryItem.id,
                );
                if (allocatedItem) {
                  const allocatedStorage =
                    allocatedItem.selectedLocations?.find(
                      i => i.id === storage.id,
                    );
                  if (allocatedStorage) {
                    return {
                      ...storage,
                      count: storage.count + allocatedStorage.quantity,
                      avgPrice: allocatedStorage.avgPrice,
                    };
                  }
                }
                return storage;
              })
            : inventoryItem.inventoryItemStorage;

          return {
            id: inventoryItem.id,
            label: inventoryItem.name,
            subLabel: `Quantity on Hand: ${totalQuantityOnHand}`,
            count: totalQuantityOnHand,
            subLabelColor: quantityColor(totalQuantityOnHand),
            name: inventoryItem.name,
            storageLocations: newStorageLocations,
            measurementUnit: inventoryItem.measurementUnit,
            unitValueWithMarkup: inventoryItem.unitValueWithMarkup,
            group: inventoryItem.group,
          };
        }) ?? []
    );
  }, [
    isSuccessInventoryItems,
    dataInventoryItems,
    newInventoryItemLabel,
    originalInventoryItems,
    showAddStockModal,
  ]);

  const updateSelectedInventoryItems = (
    rowId: number,
    updater: (item: InventoryItem) => InventoryItem | null,
  ) => {
    setSelectedInventoryItems(prevItems => {
      return prevItems
        .map(item => (item.id === rowId ? updater(item) : item))
        .filter(item => item !== null) as InventoryItem[];
    });
  };

  useEffect(() => {
    if (!newInventoryItemLabel) return;

    const newInsertInventory = newInventoryItemsListOptions?.find(
      item => item.label === newInventoryItemLabel,
    );

    if (newInsertInventory) {
      setNewInventoryItemLabel('');

      const updatedSelectedLocations = newInsertInventory.storageLocations.map(
        (location, index) => {
          if (index === 0) {
            return { ...location, quantity: location.count };
          }
          return location;
        },
      );

      const updatedInventory = {
        ...newInsertInventory,
        showEmptyRow: false,
        selectedLocations: updatedSelectedLocations,
      };

      setSelectedInventoryItems(prevItems => [...prevItems, updatedInventory]);
    }
  }, [newInventoryItemLabel, newInventoryItemsListOptions]);

  useEffect(() => {
    setInventoryItemsListOptions(newInventoryItemsListOptions);
    if (addStockSelectedInventoryItem) {
      const matchedNewItem = newInventoryItemsListOptions.find(
        newItem => newItem.id === addStockSelectedInventoryItem.id,
      );
      if (matchedNewItem) {
        updateSelectedInventoryItems(
          addStockSelectedInventoryItem.id,
          existingItem => {
            if (existingItem) {
              const updatedStorageLocations = matchedNewItem.storageLocations;
              const updatedSelectedLocations =
                existingItem?.selectedLocations?.map(location => {
                  const matchingNewLocation = updatedStorageLocations.find(
                    newLocation => newLocation.id === location.id,
                  );
                  if (matchingNewLocation) {
                    return {
                      ...location,
                      ...matchingNewLocation,
                      avgPrice: location?.avgPrice,
                    };
                  }
                  return location;
                });

              return {
                ...existingItem,
                storageLocations: updatedStorageLocations,
                selectedLocations: updatedSelectedLocations,
              };
            }
            return existingItem;
          },
        );
      }
    }
  }, [newInventoryItemsListOptions, addStockSelectedInventoryItem]);

  const addInventoryItemPaperComponent = props => (
    <ECPaper onMouseDown={event => event.preventDefault()}>
      {props.children}
      <ECButton
        variant="text"
        color="primary"
        style={{ display: 'inline-block' }}
        startIcon={<AddIcon color="info" />}
        onClick={() => {
          setShowAddInventoryItemModal(true);
        }}
      >
        Add Inventory Item
      </ECButton>
    </ECPaper>
  );

  const handleChipAutocompleteChange = newValue => {
    if (newValue[newValue.length - 1]?.count > 0) {
      setSelectedInventoryItems(
        newValue.map(item => ({
          ...item,
          showEmptyRow: _.isNil(item.showEmptyRow) ? true : item.showEmptyRow,
        })),
      );
    }
  };

  useEffect(() => {
    if (allocatedInventoryItems && !selectedInventoryItems?.length) {
      setOriginalInventoryItems(allocatedInventoryItems);

      if (isSuccessInventoryItems && dataInventoryItems) {
        const updatedAllocatedInventories = allocatedInventoryItems.map(
          item => {
            const selectedItem = dataInventoryItems?.data?.find(
              i => i.id === item.id,
            );

            const updatedStorageLocations =
              selectedItem?.inventoryItemStorage || [];
            const updatedSelectedLocations =
              item.selectedLocations?.map(location => {
                const matchedStorage = updatedStorageLocations.find(
                  i => i.id === location.id,
                );
                if (matchedStorage) {
                  return {
                    ...location,
                    count: matchedStorage.count + location.count,
                  };
                }
                return location;
              }) || item.selectedLocations;

            return {
              ...item,
              unitValueWithMarkup: selectedItem?.unitValueWithMarkup,
              storageLocations: updatedStorageLocations,
              selectedLocations: updatedSelectedLocations?.map(location => ({
                ...location,
                avgPrice: shouldShowUnitMarkupValue
                  ? String(selectedItem?.unitValueWithMarkup ?? 0)
                  : location.avgPrice,
              })),
            };
          },
        );

        setSelectedInventoryItems(updatedAllocatedInventories);
      }
    }
  }, [allocatedInventoryItems, isSuccessInventoryItems, dataInventoryItems]);

  useEffect(() => {
    if (_.isNil(value)) {
      onChange?.(selectedInventoryItems);
    }
  }, [value]);

  useEffect(() => {
    onChange?.(selectedInventoryItems);
  }, [selectedInventoryItems, setSelectedInventoryItems]);

  const handleLocationChange = (rowId: number, locationId: number) => {
    updateSelectedInventoryItems(rowId, item => {
      const selectedLocation = item?.storageLocations?.find(
        location => location.id === locationId,
      );
      if (!selectedLocation || selectedLocation?.count <= 0) return item;
      return {
        ...item,
        selectedLocations: [
          ...(item.selectedLocations || []),
          {
            ...selectedLocation,
            quantity: 0,
            avgPrice: shouldShowUnitMarkupValue
              ? String(item?.unitValueWithMarkup ?? 0)
              : selectedLocation.avgPrice,
          },
        ],
        showEmptyRow: false,
      };
    });
  };

  const handleShowAddStockModal = (selectedInventory: InventoryItem) => {
    setShowAddStockModal(true);
    const optionItem = inventoryItemsListOptions?.find(
      item => item.id === selectedInventory.id,
    );
    if (optionItem) {
      selectedInventory.avgPrice = calculateWeightedAveragePrice(optionItem);
    }
    setAddStockSelectedInventoryItem(selectedInventory);
  };

  const handleCloseAddStockModal = () => {
    setShowAddStockModal(false);
  };

  const handleAddLocation = (rowId: number) => {
    updateSelectedInventoryItems(rowId, item => ({
      ...item,
      showEmptyRow: true,
    }));
  };

  const handleClearEmptyRow = (rowId: number) => {
    updateSelectedInventoryItems(rowId, item => {
      if (item.id === rowId) {
        if (item.selectedLocations && item.selectedLocations.length > 0) {
          return {
            ...item,
            showEmptyRow: false,
          };
        } else {
          return null;
        }
      }
      return item;
    });
  };

  const handleRowRemove = rowId => {
    updateSelectedInventoryItems(rowId, item => {
      if (item.id === rowId) {
        return null;
      }
      return item;
    });
  };

  const handleremoveItem = (rowId: number, locationId: number) => {
    updateSelectedInventoryItems(rowId, item => {
      if (item.id === rowId) {
        const updatedSelectedLocations =
          item.selectedLocations?.filter(
            location => location.id !== locationId,
          ) || [];
        return updatedSelectedLocations.length === 0
          ? null
          : {
              ...item,
              selectedLocations: updatedSelectedLocations,
              showEmptyRow: false,
            };
      }
      return item;
    });
  };

  const handleInnerLocationChange = (
    rowId: number,
    newLocationId: number,
    currentLocationId: number,
  ) => {
    updateSelectedInventoryItems(rowId, item => {
      if (item.id === rowId) {
        const updatedSelectedLocations = item.selectedLocations
          ? item.selectedLocations.filter(
              location => location.id !== currentLocationId,
            )
          : [];
        const selectedLocation = item.storageLocations?.find(
          location => location.id === newLocationId,
        );
        if (selectedLocation && selectedLocation?.count > 0) {
          const newLocation: Location = {
            id: selectedLocation.id,
            name: selectedLocation.inventoryStorage?.name || '',
            avgPrice: selectedLocation.avgPrice,
            quantity: 0,
            count: selectedLocation.count,
          };
          updatedSelectedLocations.push(newLocation);
        } else {
          const currentLocation = item.storageLocations?.find(
            location => location.id === currentLocationId,
          );
          const newLocation: Location = {
            id: currentLocation.id,
            name: currentLocation.inventoryStorage?.name || '',
            avgPrice: currentLocation.avgPrice,
            quantity: 0,
            count: currentLocation.count,
          };
          updatedSelectedLocations.push(newLocation);
        }
        return {
          ...item,
          selectedLocations: updatedSelectedLocations,
          showEmptyRow: false,
        };
      }
      return item;
    });
  };

  const handleQuantityChange = (
    rowId: number,
    locationId: number,
    newValue: number,
  ) => {
    updateSelectedInventoryItems(rowId, item => {
      if (item.id === rowId && item.selectedLocations) {
        const updatedLocations = item.selectedLocations.map(location => {
          if (location.id === locationId) {
            return { ...location, quantity: newValue };
          }
          return location;
        });
        return { ...item, selectedLocations: updatedLocations };
      }
      return item;
    });
  };

  const groupBy = (item: { [key: string]: any }) => {
    return item.group === 'Other Asset Groups'
      ? 'Other Asset Groups'
      : item.group;
  };

  const groupByTransform = (items: any[]) => {
    if (!Array.isArray(items)) {
      return [];
    }

    const groupedData: Record<string, any[]> = {};

    items.forEach(item => {
      const group = item.group || 'Other Asset Groups';
      if (!groupedData[group]) {
        groupedData[group] = [];
      }
      groupedData[group].push(item);
    });

    const sortedGroupedData = Object.keys(groupedData)
      .sort()
      .reduce(
        (acc, groupName) => {
          acc[groupName] = groupedData[groupName].sort((a, b) =>
            (a.label || '').localeCompare(b.label || ''),
          );
          return acc;
        },
        {} as Record<string, any[]>,
      );

    return Object.values(sortedGroupedData).flat();
  };

  const handleAvgPriceChange = (
    rowId: number,
    locationId: number,
    newValue: string,
  ) => {
    updateSelectedInventoryItems(rowId, item => {
      if (item.id === rowId && item.selectedLocations) {
        const updatedLocations = item.selectedLocations.map(location => {
          if (location.id === locationId) {
            if (newValue.trim() === '') {
              return { ...location, avgPrice: newValue };
            }
            const numDigitsAfterDecimal = (newValue.split('.')[1] || '').length;
            const roundValue =
              numDigitsAfterDecimal > 2
                ? (Math.floor(Number(newValue) * 100) / 100).toFixed(2)
                : newValue;
            return {
              ...location,
              avgPrice: roundValue.toString(),
            };
          }
          return location;
        });
        return { ...item, selectedLocations: updatedLocations };
      }
      return item;
    });
  };

  const memorizedSelectedInventoryItemsRows = useMemo(() => {
    return selectedInventoryItems?.map((row, index) => {
      return (
        <ECBox
          key={`box_main_${index}`}
          display="flex"
          bgcolor={theme => theme.palette.grey[100]}
          flexDirection="column"
          height="100%"
          marginTop={1}
        >
          <ECGrid
            container
            spacing={1}
            sx={{ marginTop: 1, marginLeft: 1 }}
            key={index}
          >
            <ECGrid item xs={10.5}>
              <ECTypography variant="h6" marginLeft={1} marginTop={-1}>
                {row?.label}
              </ECTypography>
            </ECGrid>
            <ECGrid item xs={1.5}>
              <ECButton
                color="error"
                variant="text"
                onClick={() => handleRowRemove(row?.id ?? 0)}
              >
                Remove
              </ECButton>
            </ECGrid>
            {row?.selectedLocations?.map(location => {
              const filteredLocations = row?.storageLocations?.filter(
                storageLocation =>
                  !row?.selectedLocations?.some(
                    selectedLocation =>
                      selectedLocation.id === storageLocation.id &&
                      selectedLocation.id !== location.id,
                  ),
              );
              const errorInQuantity =
                (location?.quantity < 1 && !!validationMessage) ||
                location?.quantity > location?.count;
              return (
                <Fragment key={location.id}>
                  <ECGrid item xs={3.5}>
                    <ECFormControl sx={{ width: '100%' }}>
                      <InputLabel>Location *</InputLabel>
                      <ECSelect
                        id={`location_main_${row.id}_${location.id}`}
                        label="Location"
                        variant="outlined"
                        value={location.id}
                        onChange={event =>
                          handleInnerLocationChange(
                            row.id,
                            Number(event.target.value),
                            location.id,
                          )
                        }
                      >
                        {filteredLocations?.map(storageLocation => {
                          return (
                            <ECMenuItem
                              key={storageLocation.id}
                              id={`location_${row.id}_${storageLocation.id}`}
                              value={storageLocation.id}
                            >
                              <ECStack dir="column">
                                <ECTypography variant="body1">
                                  {storageLocation?.inventoryStorage?.name ??
                                    ''}
                                </ECTypography>
                                {storageLocation.id !== location.id && (
                                  <ECTypography
                                    variant="body2"
                                    color={quantityColor(
                                      storageLocation?.count,
                                    )}
                                  >
                                    Quantity on Hand:{' '}
                                    {storageLocation?.count ?? ''}
                                  </ECTypography>
                                )}
                              </ECStack>
                            </ECMenuItem>
                          );
                        })}
                      </ECSelect>
                    </ECFormControl>
                  </ECGrid>
                  <ECGrid item xs={2.5}>
                    <ECTextField
                      id={`quantityOnHand_${row.id}_${location.id}`}
                      key={`quantityOnHand_${row.id}_${location.id}`}
                      name={`quantityOnHand_${row.id}_${location.id}`}
                      sx={{ width: '100%' }}
                      label="Quantity"
                      placeholder="Quantity"
                      error={errorInQuantity}
                      type={FieldTypes.Number}
                      value={location?.quantity || '0'}
                      inputProps={{
                        min: 0,
                      }}
                      InputProps={{
                        endAdornment: (
                          <ECTypography
                            variant="body2"
                            color="textSecondary"
                            sx={{
                              padding: 1,
                              minWidth: '4rem',
                              textAlign: 'right',
                            }}
                          >
                            {row?.measurementUnit?.symbol ||
                              row?.selectedLocations?.[0]?.measurementUnit
                                ?.symbol ||
                              ''}
                          </ECTypography>
                        ),
                      }}
                      onChange={e => {
                        handleQuantityChange(
                          row.id,
                          location.id,
                          Number(e.target.value),
                        );
                      }}
                      variant="filled"
                    />
                  </ECGrid>
                  <ECGrid item xs={2.5}>
                    <ECCurrencyField
                      id={`costPerUnit_${row.id}_${location.id}`}
                      label="Cost per Unit"
                      placeholder="Cost per Unit"
                      value={location?.avgPrice}
                      onChange={(event, value) => {
                        const formattedValue =
                          value !== '' && !value?.toString().includes('.')
                            ? isNaN(Number(value))
                              ? ''
                              : Number(value)
                            : value;
                        handleAvgPriceChange(
                          row.id,
                          location.id,
                          formattedValue?.toString() ?? '',
                        );
                      }}
                      error={
                        (location?.avgPrice === '' ||
                          Number(location?.avgPrice) < 0) &&
                        !!validationMessage
                      }
                      variant={canEditUnitCost ? 'filled' : 'standard'}
                      style={{ marginTop: canEditUnitCost ? 0 : 8 }}
                      readOnly={!canEditUnitCost}
                    />
                  </ECGrid>
                  <ECGrid item xs={2.5}>
                    <ECCurrencyField
                      id={`total_${row.id}_${location.id}`}
                      label="Total"
                      variant="standard"
                      value={location?.quantity * Number(location?.avgPrice)}
                      readOnly
                    />
                  </ECGrid>
                  <ECGrid item xs={1}>
                    <ECIconButton
                      key={`button_${row.id}_${location.id}`}
                      type="info"
                      onClick={() => {
                        handleremoveItem(row.id, location.id);
                      }}
                      size="small"
                      sx={{
                        marginTop: 0.5,
                        color: localRed,
                      }}
                    >
                      <ClearIcon />
                    </ECIconButton>
                  </ECGrid>
                  <ECGrid item xs={4}></ECGrid>
                  <ECGrid item xs={2.5} sx={{ marginTop: '-0.5rem' }}>
                    <ECTypography
                      variant="caption"
                      color={theme =>
                        errorInQuantity
                          ? theme.palette.error.dark
                          : theme.palette.text.secondary
                      }
                      sx={{ fontSize: '0.7rem' }}
                    >
                      {`Quantity on Hand: ${location?.count}`}
                    </ECTypography>
                  </ECGrid>
                  <ECGrid item xs={5.5}></ECGrid>
                </Fragment>
              );
            })}
            {row.showEmptyRow && (
              <>
                <ECGrid item xs={4}>
                  <ECFormControl sx={{ width: '100%' }}>
                    <InputLabel key={`temp_title_${row.id}`}>
                      Location *
                    </InputLabel>
                    <ECSelect
                      id={`temp_select_${row.id}`}
                      label="Location"
                      variant="outlined"
                      value={
                        row?.storageLocations?.find(
                          location => location.selected,
                        )?.id || ''
                      }
                      error={!!validationMessage}
                      onChange={event =>
                        handleLocationChange(row.id, Number(event.target.value))
                      }
                    >
                      {row?.storageLocations
                        ?.filter(
                          location =>
                            !row?.selectedLocations?.some(
                              selectedLocation =>
                                selectedLocation.id === location.id,
                            ),
                        )
                        ?.map(location => {
                          return (
                            <ECMenuItem
                              id={`location_${row.id}_${location.id}`}
                              value={location.id}
                              key={`storage_location_${location.id}`}
                            >
                              <ECStack dir="column">
                                <ECTypography variant="body1">
                                  {location?.inventoryStorage?.name ?? ''}
                                </ECTypography>
                                <ECTypography
                                  variant="body2"
                                  color={quantityColor(location?.count)}
                                >
                                  Quantity on Hand: {location?.count ?? ''}
                                </ECTypography>
                              </ECStack>
                            </ECMenuItem>
                          );
                        })}
                    </ECSelect>
                  </ECFormControl>
                </ECGrid>
                <ECGrid item xs={7}></ECGrid>
                <ECGrid item xs={1}>
                  <ECIconButton
                    key={`button_${row.id}`}
                    type="info"
                    onClick={() => {
                      handleClearEmptyRow(row.id);
                    }}
                    size="small"
                    sx={{
                      marginTop: 0.5,
                      color: localRed,
                    }}
                  >
                    <ClearIcon />
                  </ECIconButton>
                </ECGrid>
              </>
            )}
            <ECGrid item xs={12}>
              <ECStack direction="row" spacing={1}>
                {!row.showEmptyRow &&
                  (row?.storageLocations?.length ?? 0) >
                    (row?.selectedLocations?.length ?? 0) && (
                    <ECButton
                      variant="text"
                      color="info"
                      startIcon={<AddIcon color="info" />}
                      onClick={() => {
                        handleAddLocation(row.id);
                      }}
                    >
                      More Locations
                    </ECButton>
                  )}
                {!row.showEmptyRow && (
                  <ECButton
                    variant="text"
                    color="info"
                    scopes={[P.AdjustStock]}
                    startIcon={<AddIcon color="info" />}
                    onClick={() => {
                      handleShowAddStockModal(row);
                    }}
                  >
                    Add Stock
                  </ECButton>
                )}
              </ECStack>
            </ECGrid>
          </ECGrid>
        </ECBox>
      );
    });
  }, [
    selectedInventoryItems,
    validationMessage,
    addStockSelectedInventoryItem,
  ]);

  return (
    inventoryItemsListOptions.length > 0 && (
      <>
        <ECGrid container spacing={2} sx={{ marginTop: -3 }}>
          <ECGrid item xs={12}>
            <ECTypography variant="h6">Select an Inventory Item</ECTypography>
          </ECGrid>
        </ECGrid>
        <ECChipAutocomplete
          loading={false}
          options={
            Array.isArray(inventoryItemsListOptions)
              ? inventoryItemsListOptions
              : []
          }
          paperComponent={addInventoryItemPaperComponent}
          placeholder={placeholder}
          title={label}
          values={selectedInventoryItems}
          variant={'filled'}
          onChange={handleChipAutocompleteChange}
          groupBy={groupBy}
          groupByTransform={groupByTransform}
        />
        {memorizedSelectedInventoryItemsRows}
        <ECModal
          isOpen={showAddStockModal}
          onClose={() => setShowAddStockModal(false)}
          noPadding
        >
          <addStockAction.modalContent
            onClose={handleCloseAddStockModal}
            isOpen={showAddStockModal}
            selectedInventory={addStockSelectedInventoryItem}
          />
        </ECModal>
        <ECModal
          isOpen={showAddInventoryItemModal}
          onClose={() => setShowAddInventoryItemModal(false)}
          noPadding
        >
          <addInventoryItemAction.modalContent
            onClose={() => setShowAddInventoryItemModal(false)}
            isOpen={showAddInventoryItemModal}
            updateNewInventoryItemName={updateNewInventoryItemName}
          />
        </ECModal>
      </>
    )
  );
};
