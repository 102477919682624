import { ECBox, ECButton, ECGrid, ECPaper } from 'app/components';
import { useNavigate, useMatch } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import DashboardTable, {
  DashboardTableFilter,
  ECDashboardCategoryFilter,
} from './DashboardTable';
import NavigationBar from './NavigationBar';
import { useGetCategoryListQuery } from 'services/categoryApi';
import {
  useGetUnassignedWorkOrdersQuery,
  useGetPendingWorkOrdersCountQuery,
  useGetUnassignedWorkOrdersCountQuery,
  useGetPendingWorkOrdersQuery,
  useGetWorkordersAwaitingInvoiceCountQuery,
  useGetWorkordersAwaitingInvoiceQuery,
  useGetPendingProposalsQuery,
  useGetPendingProposalsCountQuery,
  useGetPendingInvoicesQuery,
  useGetPendingInvoicesCountQuery,
} from 'services/dashboardApi';
import { ECDrawerDetails } from 'app/components/ECDrawerDetails';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import MultiselectTable from './MultiselectTable';
import { Speed, Description, MonetizationOn, Close } from '@mui/icons-material';
import { Typography } from '@mui/material';
import {
  approveAction,
  addNoteAction,
  cancelAction,
  rejectAction,
  reassignAction,
  DashboardAction,
  QueryTag,
} from './actions';
import { ECModal } from 'app/components/ECModal';
import { P } from 'types/Permission';
import { DashboardTableData } from 'types';
import { useCustomerUser } from 'app/hooks';
import { useHasPermission } from 'app/hooks/hasPermission.use-case';
import { ForbiddenPage } from '../ForbiddenPage';
import { setTitle } from 'store/slice/page';
import { useDispatch } from 'react-redux';
import { usePersistedDashboardFilters } from './DashboardHooks';

export enum Modules {
  WORKORDER,
  INVOICE,
  PROPOSAL,
}

export enum DashboardTables {
  unassignedWO,
  pendingWO,
  pendingProposals,
  pendingInvoices,
  awaitingInvoice,
}

interface ExpandedData {
  table: DashboardTables;
  count: number | string;
  module: Modules;
  tableData?: DashboardTableData;
  actions: DashboardAction[];
  filters?: DashboardTableFilter;
  queryTag: QueryTag;
  handlePageChange?: (page: number) => void;
  handleRowsPerPageChange?: (page: number) => void;
}

const EXPANDED_COUNT = 20;
const SMALL_COUNT = 5;

//TODO - show loading state
export function DashboardPage() {
  const navigate = useNavigate();
  const match = useMatch('/panel/*');
  const dispatch = useDispatch();

  const [drawer, setDrawer] = useState<DashboardTables>();
  const [expandedSelection, setExpandedSelection] = useState<any[]>([]);
  const [actionModal, setActionModal] = useState<string>();
  const [filters, setFilters] = useState<DashboardTableFilter[]>([]);

  const [pageUnassignedWO, setPageUnassignedWO] = useState<number>(0);
  const [rowsPerPageUnassignedWO, setRowsPerPageUnassignedWO] =
    useState<number>(EXPANDED_COUNT);

  const [pagePendingWO, setPagePendingWO] = useState<number>(0);
  const [rowsPerPagePendingWO, setRowsPerPagePendingWO] =
    useState<number>(EXPANDED_COUNT);

  const [pageProposals, setPageProposals] = useState<number>(0);
  const [rowsPerPageProposals, setRowsPerPageProposals] =
    useState<number>(EXPANDED_COUNT);

  const [pagePendingInvoices, setPagePendingInvoices] = useState<number>(0);
  const [rowsPerPagePendingInvoices, setRowsPerPagePendingInvoices] =
    useState<number>(EXPANDED_COUNT);

  const [pageAwaitingInvoice, setPageAwaitingInvoice] = useState<number>(0);
  const [rowsPerPageAwaitingInvoice, setRowsPerPageAwaitingInvoice] =
    useState<number>(EXPANDED_COUNT);

  const {
    getDashboardPersistedFilters,
    saveDashboardFilters,
    updateDashboardFilter,
  } = usePersistedDashboardFilters();

  const [hasWorkorderPermission, hasProposalPermission, hasInvoicePermission] =
    useHasPermission([P.GetWorkOrder, P.GetProposal, P.GetInvoice]);

  const { data: unassignedWorkorders } = useGetUnassignedWorkOrdersQuery(
    {
      ...filters.find(filter => filter.table === DashboardTables.unassignedWO),
      t:
        drawer === DashboardTables.unassignedWO
          ? rowsPerPageUnassignedWO
          : SMALL_COUNT,
    },
    {
      skip: !hasWorkorderPermission || !filters.length,
    },
  );
  const { data: unassignedWorkordersExpanded } =
    useGetUnassignedWorkOrdersQuery(
      {
        ...filters.find(
          filter => filter.table === DashboardTables.unassignedWO,
        ),
        p: pageUnassignedWO,
        t:
          drawer === DashboardTables.unassignedWO
            ? rowsPerPageUnassignedWO
            : SMALL_COUNT,
      },
      {
        skip:
          !hasWorkorderPermission ||
          !filters.length ||
          drawer !== DashboardTables.unassignedWO,
      },
    );
  const { data: pendingWorkorders } = useGetPendingWorkOrdersQuery(
    {
      ...filters.find(filter => filter.table === DashboardTables.pendingWO),
      t:
        drawer === DashboardTables.pendingWO
          ? rowsPerPagePendingWO
          : SMALL_COUNT,
    },
    {
      skip: !hasWorkorderPermission || !filters.length,
    },
  );
  const { data: pendingWorkordersExpanded } = useGetPendingWorkOrdersQuery(
    {
      ...filters.find(filter => filter.table === DashboardTables.pendingWO),
      p: pagePendingWO,
      t:
        drawer === DashboardTables.pendingWO
          ? rowsPerPagePendingWO
          : SMALL_COUNT,
    },
    {
      skip:
        !hasWorkorderPermission ||
        !filters.length ||
        drawer !== DashboardTables.pendingWO,
    },
  );
  const { data: awaitingInvoice } = useGetWorkordersAwaitingInvoiceQuery(
    {
      ...filters.find(
        filter => filter.table === DashboardTables.awaitingInvoice,
      ),
      t:
        drawer === DashboardTables.awaitingInvoice
          ? rowsPerPageAwaitingInvoice
          : SMALL_COUNT,
    },
    {
      skip: !hasWorkorderPermission || !filters.length,
    },
  );
  const { data: awaitingInvoiceExpanded } =
    useGetWorkordersAwaitingInvoiceQuery(
      {
        ...filters.find(
          filter => filter.table === DashboardTables.awaitingInvoice,
        ),
        p: pageAwaitingInvoice,
        t:
          drawer === DashboardTables.awaitingInvoice
            ? rowsPerPageAwaitingInvoice
            : SMALL_COUNT,
      },
      {
        skip:
          !hasWorkorderPermission ||
          !filters.length ||
          drawer !== DashboardTables.awaitingInvoice,
      },
    );

  const {
    data: unassignedWorkordersCount,
    isLoading: loadingUnassignedWoCount,
  } = useGetUnassignedWorkOrdersCountQuery(
    {
      ...filters.find(filter => filter.table === DashboardTables.unassignedWO),
      p: pageUnassignedWO,
      t:
        drawer === DashboardTables.unassignedWO
          ? rowsPerPageUnassignedWO
          : SMALL_COUNT,
    },
    {
      skip: !hasWorkorderPermission || !filters.length,
    },
  );
  const { data: pendingWorkordersCount, isLoading: loadingPendingWoCount } =
    useGetPendingWorkOrdersCountQuery(
      {
        ...filters.find(filter => filter.table === DashboardTables.pendingWO),
        p: pagePendingWO,
        t:
          drawer === DashboardTables.pendingWO
            ? rowsPerPagePendingWO
            : SMALL_COUNT,
      },
      {
        skip: !hasWorkorderPermission || !filters.length,
      },
    );
  const { data: awaitingInvoiceCount, isLoading: loadingAwaitingInvoiceCount } =
    useGetWorkordersAwaitingInvoiceCountQuery(
      {
        ...filters.find(
          filter => filter.table === DashboardTables.awaitingInvoice,
        ),
        p: pageAwaitingInvoice,
        t:
          drawer === DashboardTables.awaitingInvoice
            ? rowsPerPageAwaitingInvoice
            : SMALL_COUNT,
      },
      {
        skip: !hasWorkorderPermission || !filters.length,
      },
    );

  const { data: pendingProposals } = useGetPendingProposalsQuery(
    {
      ...filters.find(
        filter => filter.table === DashboardTables.pendingProposals,
      ),
      t:
        drawer === DashboardTables.pendingProposals
          ? rowsPerPageProposals
          : SMALL_COUNT,
    },
    {
      skip: !hasProposalPermission || !filters.length,
    },
  );
  const { data: pendingProposalsExpanded } = useGetPendingProposalsQuery(
    {
      ...filters.find(
        filter => filter.table === DashboardTables.pendingProposals,
      ),
      p: pageProposals,
      t:
        drawer === DashboardTables.pendingProposals
          ? rowsPerPageProposals
          : SMALL_COUNT,
    },
    {
      skip:
        !hasProposalPermission ||
        !filters.length ||
        drawer !== DashboardTables.pendingProposals,
    },
  );
  const {
    data: pendingProposalsCount,
    isLoading: isLoadingPendingProposalsCount,
  } = useGetPendingProposalsCountQuery(
    {
      ...filters.find(
        filter => filter.table === DashboardTables.pendingProposals,
      ),
      p: pageProposals,
      t:
        drawer === DashboardTables.pendingProposals
          ? rowsPerPageProposals
          : SMALL_COUNT,
    },
    {
      skip: !hasProposalPermission || !filters.length,
    },
  );

  const { data: pendingInvoices } = useGetPendingInvoicesQuery(
    {
      ...filters.find(
        filter => filter.table === DashboardTables.pendingInvoices,
      ),
      t:
        drawer === DashboardTables.pendingInvoices
          ? rowsPerPagePendingInvoices
          : SMALL_COUNT,
    },
    {
      skip: !hasInvoicePermission || !filters.length,
    },
  );
  const { data: pendingInvoicesExpanded } = useGetPendingInvoicesQuery(
    {
      ...filters.find(
        filter => filter.table === DashboardTables.pendingInvoices,
      ),
      p: pagePendingInvoices,
      t:
        drawer === DashboardTables.pendingInvoices
          ? rowsPerPagePendingInvoices
          : SMALL_COUNT,
    },
    {
      skip:
        !hasInvoicePermission ||
        !filters.length ||
        drawer !== DashboardTables.pendingInvoices,
    },
  );
  const {
    data: pendingInvoicesCount,
    isLoading: isLoadingPendingInvoicesCount,
  } = useGetPendingInvoicesCountQuery(
    {
      ...filters.find(
        filter => filter.table === DashboardTables.pendingInvoices,
      ),
      p: pagePendingInvoices,
      t:
        drawer === DashboardTables.pendingInvoices
          ? rowsPerPagePendingInvoices
          : SMALL_COUNT,
    },
    {
      skip: !hasInvoicePermission || !filters.length,
    },
  );

  const isLargeViewport = useMediaQuery('(min-width:1440px)');

  const approveActionMemoProposal = useMemo(() => {
    return approveAction([P.ChangeProposalStatus]);
  }, []);

  const rejectActionMemoProposal = useMemo(() => {
    return rejectAction([P.ChangeProposalStatus]);
  }, []);

  const approveActionMemoInvoice = useMemo(() => {
    return approveAction([P.ChangeInvoiceStatus]);
  }, []);

  const rejectActionMemoInvoice = useMemo(() => {
    return rejectAction([P.ChangeInvoiceStatus]);
  }, []);

  const handleExpandTable = useCallback((target: DashboardTables) => {
    setDrawer(target);
  }, []);

  const clearPages = useCallback(() => {
    setPageUnassignedWO(0);
    setPagePendingWO(0);
    setPageProposals(0);
    setPagePendingInvoices(0);
    setPageAwaitingInvoice(0);
  }, []);

  const clearRowsPerPages = useCallback(() => {
    setRowsPerPageUnassignedWO(EXPANDED_COUNT);
    setRowsPerPagePendingWO(EXPANDED_COUNT);
    setRowsPerPageProposals(EXPANDED_COUNT);
    setRowsPerPagePendingInvoices(EXPANDED_COUNT);
    setRowsPerPageAwaitingInvoice(EXPANDED_COUNT);
  }, []);

  const { data: categories, isSuccess: isSuccessCategories } =
    useGetCategoryListQuery({ st: 1 });

  const [preSelectionDone, setPreSelectionDone] = useState<boolean>(false);

  useEffect(() => {
    if (isSuccessCategories && categories && !preSelectionDone) {
      let preSelected = categories.data.map(category => category.id);
      let newFilter = [
        {
          table: DashboardTables.unassignedWO,
          cat: preSelected,
        },
        {
          table: DashboardTables.pendingWO,
          cat: preSelected,
        },
        {
          table: DashboardTables.pendingProposals,
          cat: preSelected,
          assignedToMe: 1,
        },
        {
          table: DashboardTables.pendingInvoices,
          cat: preSelected,
          assignedToMe: 1,
        },
        {
          table: DashboardTables.awaitingInvoice,
          cat: preSelected,
        },
      ];
      const persistedFilters = getDashboardPersistedFilters();
      if (persistedFilters) {
        setFilters(persistedFilters);
      } else {
        setFilters(newFilter);
        saveDashboardFilters(newFilter);
      }
      setPreSelectionDone(true);
    }
  }, [isSuccessCategories, categories, preSelectionDone]);

  useEffect(() => {
    dispatch(setTitle('Dashboard'));
  }, []);

  const onDrawerClose = useCallback(() => {
    setDrawer(undefined);
    setExpandedSelection([]);
    clearPages();
    clearRowsPerPages();
  }, [clearPages, clearRowsPerPages]);

  const handleExpandedSelection = useCallback((rows: any[]) => {
    setExpandedSelection(rows);
  }, []);

  const renderOpenModuleButton = (module: Modules) => {
    switch (module) {
      case Modules.WORKORDER:
        return (
          <ECButton
            variant="contained"
            onClick={() => navigate(`${match?.pathnameBase}/work-orders`)}
            startIcon={<Speed />}
          >
            Open Work orders
          </ECButton>
        );
      case Modules.INVOICE:
        return (
          <ECButton
            variant="contained"
            onClick={() => navigate(`${match?.pathnameBase}/invoices`)}
            startIcon={<MonetizationOn />}
          >
            Open Invoices
          </ECButton>
        );
      case Modules.PROPOSAL:
        return (
          <ECButton
            variant="contained"
            onClick={() => navigate(`${match?.pathnameBase}/proposals`)}
            startIcon={<Description />}
          >
            Open Proposals
          </ECButton>
        );
      default:
        return '';
    }
  };

  const expandedTableData: ExpandedData | undefined | any = useMemo(() => {
    switch (drawer) {
      case DashboardTables.unassignedWO:
        return {
          table: DashboardTables.unassignedWO,
          count: unassignedWorkordersCount ?? -1,
          tableData: {
            ...unassignedWorkordersExpanded,
            totalCount: unassignedWorkordersCount ?? -1,
          },
          actions: [reassignAction, cancelAction],
          module: Modules.WORKORDER,
          queryTag: [QueryTag.UnassignedWorkorders, QueryTag.PendingWorkorders],
          handlePageChange: setPageUnassignedWO,
          handleRowsPerPageChange: setRowsPerPageUnassignedWO,
          filters: filters.find(
            filter => filter.table === DashboardTables.unassignedWO,
          ),
        };
      case DashboardTables.pendingWO:
        return {
          table: DashboardTables.pendingWO,
          count: pendingWorkordersCount ?? -1,
          tableData: {
            ...pendingWorkordersExpanded,
            totalCount: pendingWorkordersCount ?? -1,
          },
          actions: [reassignAction, cancelAction],
          module: Modules.WORKORDER,
          queryTag: [QueryTag.UnassignedWorkorders, QueryTag.PendingWorkorders],
          handlePageChange: setPagePendingWO,
          handleRowsPerPageChange: setRowsPerPagePendingWO,
          filters: filters.find(
            filter => filter.table === DashboardTables.pendingWO,
          ),
        };
      case DashboardTables.pendingProposals:
        return {
          table: DashboardTables.pendingProposals,
          count: pendingProposalsCount ?? -1,
          tableData: {
            ...pendingProposalsExpanded,
            totalCount: pendingProposalsCount ?? -1,
          },
          actions: [
            addNoteAction,
            approveActionMemoProposal,
            rejectActionMemoProposal,
          ],
          module: Modules.PROPOSAL,
          queryTag: QueryTag.PendingProposals,
          handlePageChange: setPageProposals,
          handleRowsPerPageChange: setRowsPerPageProposals,
          filters: filters.find(
            filter => filter.table === DashboardTables.pendingProposals,
          ),
        };
      case DashboardTables.pendingInvoices:
        return {
          table: DashboardTables.pendingInvoices,
          count: pendingInvoicesCount ?? -1,
          tableData: {
            ...pendingInvoicesExpanded,
            totalCount: pendingInvoicesCount ?? -1,
          },
          actions: [
            addNoteAction,
            approveAction([P.ChangeInvoiceStatus]),
            rejectActionMemoInvoice,
          ],
          module: Modules.INVOICE,
          queryTag: QueryTag.PendingInvoices,
          handlePageChange: setPagePendingInvoices,
          handleRowsPerPageChange: setRowsPerPagePendingInvoices,
          filters: filters.find(
            filter => filter.table === DashboardTables.pendingInvoices,
          ),
        };
      case DashboardTables.awaitingInvoice:
        return {
          table: DashboardTables.awaitingInvoice,
          count: awaitingInvoiceCount ?? -1,
          tableData: {
            ...awaitingInvoiceExpanded,
            totalCount: awaitingInvoiceCount ?? -1,
          },
          module: Modules.WORKORDER,
          actions: [addNoteAction],
          queryTag: QueryTag.AwaitingInvoice,
          handlePageChange: setPageAwaitingInvoice,
          handleRowsPerPageChange: setRowsPerPageAwaitingInvoice,
          filters: filters.find(
            filter => filter.table === DashboardTables.awaitingInvoice,
          ),
        };
      default:
        return undefined;
    }
  }, [
    drawer,
    unassignedWorkordersExpanded,
    pendingWorkordersExpanded,
    pendingProposalsExpanded,
    pendingInvoicesExpanded,
    awaitingInvoiceExpanded,
    filters,
  ]);

  const selectedAction = useMemo(
    () =>
      expandedTableData?.actions.find(
        action => action.buttonLabel === actionModal,
      ),
    [actionModal, expandedTableData],
  );

  const handleCloseModal = useCallback(() => {
    setActionModal(undefined);
  }, []);

  const handleClearModal = useCallback(() => {
    handleCloseModal();
    setExpandedSelection([]);
  }, []);

  const selectedAdditionalData = useMemo(
    () =>
      expandedSelection.length > 0 &&
      expandedTableData?.tableData?.additionalData?.[0],
    [expandedSelection.length, expandedTableData],
  );

  const handleFilterChange = ({
    table,
    ...filterParams
  }: DashboardTableFilter) => {
    const untouchedFilters = filters.filter(filter => filter.table !== table);
    clearPages();
    clearRowsPerPages();
    setFilters([
      ...untouchedFilters,
      {
        table,
        ...filterParams,
      },
    ]);
    updateDashboardFilter(table, {
      table,
      ...filterParams,
    });
  };

  const isCustomer = useCustomerUser();

  if (!isCustomer) {
    return <ForbiddenPage />;
  }

  return (
    <>
      <Helmet>
        <title>Dashboard</title>
        <meta name="description" content="user dashboard" />
      </Helmet>
      <ECBox px={3} justifyItems="center" height="100%">
        <NavigationBar />
        <ECGrid
          container
          display="grid"
          gridTemplateColumns={`repeat(${isLargeViewport ? 2 : 1}, 1fr)`}
          gap={3}
          mt={3}
        >
          {unassignedWorkorders && (
            <ECGrid item scopesOptional={[P.GetAllWorkOrders, P.EditWorkOrder]}>
              <DashboardTable
                actions={[reassignAction, cancelAction]}
                count={
                  loadingUnassignedWoCount ? '-' : unassignedWorkordersCount
                }
                categories={categories}
                tableData={unassignedWorkorders}
                onExpand={handleExpandTable}
                dashboardId={DashboardTables.unassignedWO}
                queryTag={QueryTag.UnassignedWorkorders}
                onFiltersChange={handleFilterChange}
                clearSelection={
                  expandedTableData?.table === DashboardTables.unassignedWO
                }
                filters={filters.find(
                  filter => filter.table === DashboardTables.unassignedWO,
                )}
              />
            </ECGrid>
          )}
          {pendingProposals && (
            <ECGrid item scopesOptional={[P.GetAllProposals, P.EditProposal]}>
              <DashboardTable
                actions={[
                  addNoteAction,
                  approveActionMemoProposal,
                  rejectActionMemoProposal,
                ]}
                count={
                  isLoadingPendingProposalsCount ? '-' : pendingProposalsCount
                }
                categories={categories}
                tableData={pendingProposals}
                onExpand={handleExpandTable}
                dashboardId={DashboardTables.pendingProposals}
                queryTag={QueryTag.PendingProposals}
                onFiltersChange={handleFilterChange}
                clearSelection={
                  expandedTableData?.table === DashboardTables.pendingProposals
                }
                filters={filters.find(
                  filter => filter.table === DashboardTables.pendingProposals,
                )}
              />
            </ECGrid>
          )}
          {pendingWorkorders && (
            <ECGrid item scopesOptional={[P.GetAllWorkOrders, P.EditWorkOrder]}>
              <DashboardTable
                actions={[reassignAction, cancelAction]}
                count={loadingPendingWoCount ? '-' : pendingWorkordersCount}
                categories={categories}
                tableData={pendingWorkorders}
                onExpand={handleExpandTable}
                dashboardId={DashboardTables.pendingWO}
                queryTag={[
                  QueryTag.UnassignedWorkorders,
                  QueryTag.PendingWorkorders,
                ]}
                onFiltersChange={handleFilterChange}
                clearSelection={
                  expandedTableData?.table === DashboardTables.pendingWO
                }
                filters={filters.find(
                  filter => filter.table === DashboardTables.pendingWO,
                )}
              />
            </ECGrid>
          )}
          {pendingInvoices && (
            <ECGrid item scopesOptional={[P.GetAllInvoices, P.EditInvoice]}>
              <DashboardTable
                actions={[
                  addNoteAction,
                  approveActionMemoInvoice,
                  rejectActionMemoInvoice,
                ]}
                count={
                  isLoadingPendingInvoicesCount ? '-' : pendingInvoicesCount
                }
                categories={categories}
                tableData={pendingInvoices}
                onExpand={handleExpandTable}
                dashboardId={DashboardTables.pendingInvoices}
                queryTag={QueryTag.PendingInvoices}
                onFiltersChange={handleFilterChange}
                clearSelection={
                  expandedTableData?.table === DashboardTables.pendingInvoices
                }
                filters={filters.find(
                  filter => filter.table === DashboardTables.pendingInvoices,
                )}
              />
            </ECGrid>
          )}
          {awaitingInvoice && (
            <ECGrid item scopesOptional={[P.GetAllWorkOrders, P.EditWorkOrder]}>
              <DashboardTable
                actions={[addNoteAction, cancelAction]}
                categories={categories}
                count={loadingAwaitingInvoiceCount ? '-' : awaitingInvoiceCount}
                tableData={awaitingInvoice}
                onExpand={handleExpandTable}
                dashboardId={DashboardTables.awaitingInvoice}
                queryTag={QueryTag.AwaitingInvoice}
                onFiltersChange={handleFilterChange}
                clearSelection={
                  expandedTableData?.table === DashboardTables.awaitingInvoice
                }
                filters={filters.find(
                  filter => filter.table === DashboardTables.awaitingInvoice,
                )}
              />
            </ECGrid>
          )}
          <ECGrid item></ECGrid>
        </ECGrid>
      </ECBox>
      <ECDrawerDetails
        open={drawer !== undefined}
        anchor="right"
        onClose={onDrawerClose}
      >
        <ECPaper
          sx={{ height: '100%', paddingTop: '80px' }}
          role="presentation"
        >
          {expandedTableData && (
            <ECBox p={2} display="flex" gap={2} flexDirection="column">
              <Typography
                variant="h4"
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <span>
                  {expandedTableData.count}
                  {' |'} {expandedTableData.tableData?.title}
                </span>
                <ECButton variant="outlined" onClick={onDrawerClose}>
                  <Close />
                </ECButton>
              </Typography>
              <ECBox display="flex" gap={2}>
                {renderOpenModuleButton(expandedTableData.module)}
                {expandedTableData.actions.map(action => (
                  <ECButton
                    variant="outlined"
                    onClick={() => setActionModal(action.buttonLabel)}
                    scopes={action.scopes}
                    disabled={action.disabledRule(expandedSelection.length)}
                  >
                    {action.buttonLabel}
                  </ECButton>
                ))}
                <ECDashboardCategoryFilter
                  categories={categories}
                  dashboardId={expandedTableData?.table}
                  initialSelected={expandedTableData?.filters?.cat}
                  onFiltersChange={handleFilterChange}
                />
              </ECBox>
              <ECBox display="flex" gap={2}>
                <Typography variant="h6">
                  {expandedSelection.length}
                  {' /'} {expandedTableData.count}
                </Typography>
                <ECButton
                  variant="text"
                  onClick={() => setExpandedSelection([])}
                >
                  Deselect All
                </ECButton>
              </ECBox>
              {expandedTableData.tableData && (
                <MultiselectTable
                  selected={expandedSelection}
                  onSelect={handleExpandedSelection}
                  tableData={expandedTableData.tableData}
                  handlePageChange={expandedTableData.handlePageChange}
                  handleRowsPerPageChange={
                    expandedTableData.handleRowsPerPageChange
                  }
                  hasPagination={true}
                />
              )}
            </ECBox>
          )}
          {selectedAction && expandedTableData && (
            <ECModal
              isOpen={!!actionModal}
              onClose={handleClearModal}
              noPadding
            >
              <selectedAction.modalContent
                onClose={handleClearModal}
                onCancel={handleCloseModal}
                selectedRows={expandedSelection}
                module={expandedTableData.tableData?.module}
                scopes={[P.AddComment]}
                queryTag={expandedTableData.queryTag}
                additionalData={selectedAdditionalData}
              />
            </ECModal>
          )}
        </ECPaper>
      </ECDrawerDetails>
    </>
  );
}
