import { Helmet } from 'react-helmet-async';
import { useTheme, useMediaQuery } from '@mui/material';
import {
  ECBox,
  ECSignUpTemplate,
  ECTypography,
  ECImage,
  ECButton,
  ECAlert,
  ECLink,
  ECAnimationText,
} from 'app/components';
import ecotrakLogo from './assets/ecotrak_logo_black.png';
import emaHello from './assets/ema-hello.gif';

export function SignUp() {
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('md'));
  const animationText = `Hi I’m EMA, your AI Assistant created by ecotrak!`;

  return (
    <>
      <Helmet>
        <title>Sign In Page</title>
        <meta name="description" content="Welcome to Ecotrak" />
      </Helmet>
      <ECSignUpTemplate>
        <ECBox maxWidth={isMobileSize ? '774px' : '1024px'}>
          <ECBox
            sx={{
              marginBottom: isMobileSize ? '14px' : '114px',
              marginTop: isMobileSize ? '48px' : 0,
            }}
          >
            <img
              height={isMobileSize ? 32 : 50}
              src={ecotrakLogo}
              alt="Ecotrac LLC"
            />
          </ECBox>
          <ECBox
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            width={'100%'}
            flexDirection={isMobileSize ? 'column' : 'row'}
          >
            <ECBox width={isMobileSize ? '318px' : '100%'}>
              <ECTypography
                variant="h4"
                fontWeight="bold"
                marginBottom={2}
                fontSize={isMobileSize ? '32px' : '40px'}
              >
                Get Started and Go!
              </ECTypography>
              <ECTypography fontSize={isMobileSize ? '12px' : '20px'}>
                Ecotrak is your all-in-one platform to keep your facilities
                running and your focus on growing your business. Manage repairs
                and maintenance instantly, on your own terms. Place a service
                request in seconds, find the right provider, and get the job
                done faster. Start your 30-day FREE trial today!
              </ECTypography>
            </ECBox>
            <ECBox
              sx={{
                position: 'relative',
                marginTop: isMobileSize ? '50px' : 0,
              }}
            >
              <ECBox
                sx={{
                  position: 'absolute',
                  right: isMobileSize ? -20 : -70,
                  top: isMobileSize ? -10 : -50,
                  borderRadius: '30px 56px 56px 0px',
                  width: isMobileSize ? '160px' : '293px',
                  height: isMobileSize ? '55px' : '112px',
                  boxShadow:
                    '0px 8px 10px -5px rgba(0, 0, 0, 0.20), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12)',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <ECAnimationText
                  animationText={animationText}
                  margin={isMobileSize ? '5px' : '10px'}
                  variant={isMobileSize ? 'body2' : 'body1'}
                  fontWeight={700}
                  fontSize={isMobileSize ? '10px' : '20px'}
                  maxWidth={'60%'}
                  color={theme => theme.palette.primary.main}
                />
              </ECBox>
              <ECImage
                src={emaHello}
                sx={{
                  maxHeight: isMobileSize ? '291px' : '444px',
                  marginTop: isMobileSize ? '30px' : 0,
                }}
              />
            </ECBox>
          </ECBox>
          <ECBox
            display={'flex'}
            width={'100%'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <ECButton
              variant="contained"
              sx={{ width: isMobileSize ? '280px' : '400px' }}
            >
              TRY FOR FREE
            </ECButton>
          </ECBox>

          <ECBox
            width={'100%'}
            marginTop={isMobileSize ? '15px' : '65px'}
            marginBottom={'24px'}
          >
            <ECAlert variant="outlined" severity="info">
              <ECTypography variant="body2">
                Need help with Ecotrak?{' '}
                <ECLink
                  href="https://helpcenter.ecotrak.com/en"
                  target="_blank"
                >
                  Click Here
                </ECLink>
              </ECTypography>
            </ECAlert>
          </ECBox>
        </ECBox>
      </ECSignUpTemplate>
    </>
  );
}
