import { useEffect, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { ECBox } from '../ECBox';
import { ECButton } from '../ECButton';
import { ECTypography } from '../ECTypography';
import PhotoLibraryOutlinedIcon from '@mui/icons-material/PhotoLibraryOutlined';

export interface ECDropzoneProps {
  fieldName?: string;
  onFileInput?: (file: any) => void;
  onFileDrop?: (file: File) => void;
  maxWidth?: number;
  maxHeight?: number;
  sx?: any;
  disabled?: boolean;
  hideDragDropArea?: boolean;
}

export const ECDropzone = ({
  fieldName,
  onFileInput,
  onFileDrop,
  maxWidth,
  maxHeight,
  sx,
  disabled,
  hideDragDropArea,
}: ECDropzoneProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    noClick: true,
  });

  useEffect(() => {
    onFileDrop?.(acceptedFiles?.[0]);
  }, [acceptedFiles]);

  const handleBrowseFileClick = () => {
    inputRef?.current?.click();
  };

  const disableBox = disabled ? { pointerEvents: 'none', opacity: 0.5 } : {};

  return (
    <ECBox
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      minHeight={'15rem'}
      maxWidth={maxWidth || '100%'}
      maxHeight={maxHeight || '100%'}
      p={2}
      my={1.5}
      mx={1}
      sx={{
        display: hideDragDropArea ? 'none' : undefined,
        ...sx,
        ...disableBox,
      }}
      border="2px dashed"
      borderRadius={2}
      borderColor={theme => theme.palette.primary.main}
      {...getRootProps()}
    >
      <input {...getInputProps()} disabled={true} />
      <PhotoLibraryOutlinedIcon color="primary" fontSize="large" />
      <ECTypography
        color={theme => theme.palette.text.secondary}
        variant="body1"
      >
        Drag and Drop here
      </ECTypography>
      <ECTypography
        color={theme => theme.palette.text.secondary}
        variant="body1"
      >
        or
      </ECTypography>

      <ECButton
        variant="text"
        component="label"
        onClick={handleBrowseFileClick}
      >
        Browse File
        <input
          ref={inputRef}
          type="file"
          id={fieldName}
          name={fieldName}
          onChange={onFileInput}
          hidden
        />
      </ECButton>
    </ECBox>
  );
};
