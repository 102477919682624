/* eslint-disable react-hooks/exhaustive-deps */
import { styled } from '@mui/material/styles';
import Moment from 'react-moment';
import {
  CircularProgress,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableBodyProps,
  TableCell,
  TableCellProps,
  TableContainer,
  TableContainerProps,
  TableFooter,
  TableFooterProps,
  TableHead,
  TableHeadProps,
  TablePagination,
  TablePaginationProps,
  TableProps,
  TableRow,
  TableRowProps,
  TableSortLabel,
  TableSortLabelProps,
  ListItemText,
  ListItemIcon,
  Checkbox,
} from '@mui/material';

import { ECBox } from '../ECBox';
import { ECTypography } from '../ECTypography';
import { cleanedPhoneNumber, formatPhone } from 'utils/strings/phone';
import {
  Download,
  KeyboardArrowUp,
  KeyboardArrowDown,
  DeleteForever,
  DragIndicator,
  ArrowDropDown,
  ViewColumn,
  Check,
} from '@mui/icons-material';
import { FieldTypes, isEmptyValue } from '../ECForm';
import { ECDedicatedTableCardContainer } from '../ECCard';
import * as React from 'react';
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  useLayoutEffect,
} from 'react';
import { ECIconButton } from '../ECIconButton';
import {
  ECButton,
  ECMenuItem,
  ECSelect,
  ECCollapse,
  ECImageTableCell,
  ECVideoTableCell,
  ECLink,
  ECTextField,
  ECChipAutocomplete,
  Chip,
  ECRankTableCell,
  ECActionsTableCell,
  ECSwitch,
  ECPopper,
  ECRequirePermission,
  ECExportPopover,
  ECGrid,
} from '..';
import { ECCircularProgress } from '../ECCircularProgress';
import { ECChip } from '../ECChip';
import csvDownload from 'json-to-csv-export';
import {
  formatAgeForTable,
  formatDateForTable,
  formatDateOnlyForTable,
} from 'utils/strings/formatDate';
import { EmptyStatePage, EmptyType } from 'app/pages/EmptyStatePage';
import * as _ from 'lodash';
import { useSelector } from 'react-redux';
import { RootState } from 'index';
import { useDispatch } from 'react-redux';
import { setActiveFilter, setSnackbar } from 'store/slice/page';
import {
  useGetAllSavedColumnsFiltersQuery,
  useGetAllSavedFiltersQuery,
  useSaveFilterMutation,
  useUpdateFilterMutation,
} from 'services/filterApi';
import { useTranslation } from 'react-i18next';
import { formatEtaHours } from 'utils/strings/format-eta';
import {
  DndContext,
  closestCenter,
  TouchSensor,
  useSensor,
  useSensors,
  MouseSensor,
  KeyboardSensor,
  DragEndEvent,
} from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
  useSortable,
  sortableKeyboardCoordinates,
  horizontalListSortingStrategy,
  arrayMove,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import ECCurrencyField from '../ECCurrencyField';
import { ECLightTooltip, ECTooltip } from '../ECTooltip';
import { StyleConstants } from 'styles/StyleConstants';
import { ECChipListTableCell } from './ECChipTableCell';
import { ECWarrantyBadge, WarrantyBadgeTypes } from '../ECWarrantyBadge';
import { useServiceProviderUser } from 'app/hooks';
import { ECCheckbox } from '../ECCheckbox';
import { ECPopover } from '../ECPopover';
import { DateRangeFilterType, ECDateRangePicker } from '../ECDateTimePicker';
import { ECPhoneField } from 'app/components/ECPhoneField';
import { ECAutocompleteStyled } from '../ECAutocomplete';
import { useLocation, useSearchParams } from 'react-router-dom';
import { convertRouteNameToFilterEndpoint } from 'services/utils';
import moment from 'moment';
import ECChipTextTableCell from '../ECChipTextTableCell';
import { P } from 'types/Permission';
import { ExportType, ExportFormat } from '../ECExportPopover';
import { useRequestExportMutation } from 'services/exportApi';
import { ECHierarchyTableFilter } from './ECHierarchyTableFilter';
import { ECEtaFilter } from './ECEtaFilter';
import { ECTechNotAssignedFilter } from './ECTechNotAssignedFilter';
import { ECTechAssignedFilter } from './ECTechAssignedFilter';
import { ECDrawerActivities } from '../ECDrawerActivities';
import { ECUserTableFilter } from 'app/components/ECTable/ECUserTableFilter';
import { ActivityType } from 'types/Activity';
import { ECCustomersFilter } from './ECCustomersFilter';
import { ECCalendarViewFilter } from './ECCalendarViewFilter';
import { WorkflowStatus } from '../ECWorkflowStatusBadge';
import { ECStatusFilter } from './ECStatusFilter';
import { ECCustomSimpleSelectFilter } from './ECCustomSimpleSelectFilter';
import { ECTableSearchFilters } from './ECTableSearchFilters';
import { ECConceptsFilter } from './ECConceptsFilter';
import { ECSetBy } from '../ECSetBy';
import { ECAssetGroupSingleFilter } from './ECAssetGroupSingleFilter';
import { ECOnlyMeFilter } from './ECOnlyMeFilter';
import ECAssetTypeNameFilter from './ECAssetTypeNameFilter';
import { ECChipOptions } from '../ECChipOptions';
import { themes } from 'styles/theme/themes';

export const ECTable = styled(Table)<TableProps>(() => ({}));

export const ECTableBody = styled(TableBody)<TableBodyProps>(() => ({
  whiteSpace: 'nowrap',
  height: StyleConstants.TABLE_ROW_MAX_HEIGHT,
}));

export const ECTableCell = styled(TableCell)<TableCellProps>(() => ({
  height: StyleConstants.TABLE_ROW_MAX_HEIGHT,
  maxWidth: StyleConstants.TABLE_ROW_MAX_WIDTH,
  paddingTop: 0,
  paddingBottom: 0,
}));

export const ECSwitchTableCell = props => {
  const { col, row } = props;
  const { t } = useTranslation();

  const [checked, setChecked] = useState(_.get(row, col.dataPath));

  useEffect(() => {
    setChecked(_.get(row, col.dataPath));
  }, [col.dataPath, row]);

  const label = useMemo(() => {
    if (col.title === 'Status') {
      return checked
        ? t('translation:switch.active')
        : t('translation:switch.inactive');
    }

    if (col.showSwitchAddedLabel) {
      return checked
        ? t('translation:switch.added')
        : t('translation:switch.notAdded');
    }
    return checked
      ? col.switchActiveLabel || t('translation:switch.yes')
      : col.switchInactiveLabel || t('translation:switch.no');
  }, [checked, col.title, t]);

  return (
    <ECTableCell
      key={`${col.title}-${row?.id}`}
      sx={{ height: '100%', alignItems: 'center', ...col.optionalSx }}
    >
      <ECBox display="flex" alignItems="center">
        <ECChip
          label={label}
          variant="filled"
          sx={{
            bgcolor: theme =>
              checked
                ? theme.palette.success.outlinedHoverBackground
                : theme.palette.error.outlinedHoverBackground,
            color: theme =>
              checked ? theme.palette.success.dark : theme.palette.error.dark,
          }}
        />
      </ECBox>
    </ECTableCell>
  );
};

export const ECChipContent = ({
  chipList,
  col,
  row,
  onChipClick,
  onChipDelete,
  setChips,
}) => {
  if (!chipList) return null;
  return chipList.map((chipData, index) => (
    <ECChip
      key={`${index}-chip`}
      label={chipData[`${col.chipTitleDataPath}`]}
      color={chipData?.color}
      variant="outlined"
      sx={{
        margin: 1,
      }}
      onClick={e => {
        e.stopPropagation();
        onChipClick?.(row, chipData, col);
      }}
      onDelete={
        onChipDelete
          ? () => {
              setChips(
                chipList.filter(
                  chip => JSON.stringify(chip) !== JSON.stringify(chipData),
                ),
              );
              onChipDelete(row[`${col.dataPath}`], chipData, col);
            }
          : undefined
      }
    />
  ));
};

export const ECChipTooltipTableCell = props => {
  const { col, row } = props;

  const [chips, setChips] = useState<any[]>(row[`${col.dataPath}`]);

  useEffect(() => {
    setChips(row[`${col.dataPath}`]);
  }, [row, col]);

  return (
    <ECTableCell
      key={col.title}
      scope="row"
      sx={{ height: '100%', alignItems: 'center' }}
    >
      <ECLightTooltip
        title={
          <ECChipContent chipList={chips} setChips={setChips} {...props} />
        }
      >
        <ECTypography
          variant="body2"
          bgcolor="#EEE"
          borderRadius="50%"
          textAlign="center"
          position="relative"
          width="2em"
          height="2em"
          pt="0.33em"
          sx={{
            '&:hover': {
              backgroundColor: theme => theme.palette.primary.main,
              color: theme => theme.palette.common.white,
            },
          }}
        >
          {chips?.length}
        </ECTypography>
      </ECLightTooltip>
    </ECTableCell>
  );
};

export const ECJSONTableCell = props => {
  const { col, row } = props;
  const data = row[`${col.dataPath}`];
  return (
    <ECTableCell
      key={col.title}
      scope="row"
      sx={{ height: '100%', alignItems: 'center' }}
    >
      <ECTooltip
        title={
          <ECTypography fontSize={18}>
            {JSON.stringify(data, null, 'data')
              .replace(/\\n/g, '')
              .replaceAll('\\', '')
              .replaceAll('"', '')
              .replaceAll('{', '')
              .replaceAll('}', '')}
          </ECTypography>
        }
      >
        <ECTypography
          variant="body2"
          bgcolor="#EEE"
          borderRadius="50%"
          textAlign="center"
          position="relative"
          width="2em"
          height="2em"
          pt="0.33em"
        >
          {data ? 'JSON' : ''}
        </ECTypography>
      </ECTooltip>
    </ECTableCell>
  );
};

export const ECTHCell = styled(TableCell)<TableCellProps>(({ theme }) => ({
  color: theme.palette.text.disabled,
  fontVariantCaps: 'all-petite-caps',
  fontWeight: 'bold',
  fontSize: 14,
  paddingLeft: 16,
  paddingRight: 16,
  paddingTop: 0,
  paddingBottom: 0,
  height: 36,
  whiteSpace: 'nowrap',
}));

export const ECTRCell = styled(TableCell)<TableCellProps>(
  (/* { theme } */) => ({
    minWidth: 200,
    paddingTop: 0,
    paddingBottom: 0,
    height: StyleConstants.TABLE_ROW_MAX_HEIGHT,
  }),
);

export const ECTableContainer = styled(TableContainer)<TableContainerProps>(
  () => ({}),
);

export const ECTableFooter = styled(TableFooter)<TableFooterProps>(() => ({}));

export const ECTableHead = styled(TableHead)<TableHeadProps>(({ theme }) => ({
  backgroundColor: theme.palette.table.table,
  minHeight: 0,
  maxHeight: '26px',
  maxWidth: StyleConstants.TABLE_ROW_MAX_WIDTH,
  fontWeight: 'bold',
}));

// the prop component is not correctly exported by MUI for the TablePaginationProps (see: https://github.com/mui/material-ui/issues/24372)
export type ECTablePaginationProps = TablePaginationProps & { component?: any };

export const ECTablePagination = styled(
  TablePagination,
)<ECTablePaginationProps>(() => ({
  borderBottom: 'none',
  color: 'black',
}));

export const ECTableRow = styled(TableRow)<TableRowProps>(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.background.secondary,
  },
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.table.tableDarkRowTable,
  },
  minHeight: StyleConstants.TABLE_ROW_MAX_HEIGHT,
}));

export const ECTableSortLabel = styled(TableSortLabel)<TableSortLabelProps>(
  () => ({}),
);

export enum ColType {
  Chip = 'chip',
  ChipOptions = 'chip_options',
  ChipsList = 'chipList',
  Switch = 'switch',
  Date = 'date',
  DateOnly = 'date_only', // Date Only only shows the date, no time
  Age = 'age',
  Boolean = 'boolean',
  Text = 'text',
  Phone = 'phone',
  Collapsible = 'collapsible',
  Image = 'image',
  Video = 'video',
  RichText = 'rich_text',
  Link = 'link',
  HourETA = 'hour_eta',
  Select = 'select',
  ChipAutocomplete = 'chip_autocomplete',
  ChipTooltip = 'chip_tooltip',
  JSONTooltip = 'json_tooltip',
  Rank = 'rank',
  Actions = 'actions',
  Attachments = 'attachments',
  RemoveButton = 'remove_button',
  DeleteButton = 'delete_button',
  DragHandle = 'drag_handle',
  Currency = 'currency',
  WarrantyBadge = 'warranty_badge',
  LinearProgress = 'linear_progress',
  UsersTable = 'users_table',
  NotificationEmail = 'notification_email',
  NotificationMobile = 'notification_mobile',
  NotificationText = 'notification_text',
  SendingStatus = 'sending_status',
  ExpiredableText = 'text_expiredable',
  ChipText = 'chip_text',
  SingleChip = 'single_chip',
  Number = 'number',
  TechAssigned = 'tech_assigned',
  CustomLink = 'custom_link',
}

export interface ECEasyTableColsProps {
  title: string;
  dataPath: string;
  fieldName: string;
  alias?: string;
  sortAlias?: string;
  searchAlias?: string;
  exactSearch?: boolean;
  searchGroup?: string;
  isViewId?: boolean;
  value: string;
  filterable: boolean;
  sortable: boolean;
  visible: boolean;
  type?: string;
  nestedCols?: ECEasyTableColsProps[];
  colSpan?: number;
  exportTitle?: string;
  exportDataPath?: string;
  chipTitleDataPath?: string;
  color?: keyof typeof chipColors;
  searchable?: boolean;
  endpoint?: string;
  disabled?: boolean;
  options?: any[];
  optionValues?: any[];
  placeholder?: string;
  optionalSx?: any;
  moduleName?: string;
  moduleId?: string | number;
  showNaIfEmpty?: boolean;
  minValue?: number;
  label?: string;
  showTooltip?: boolean;
}

export const chipColors = {
  Red: '#e31b0c',
  Pink: '#ec76ae',
  Orange: '#ed6c02',
  Yellow: '#ffb400',
  Purple: '#6c3ff6',
  Grey: '#5c5c5c',
  'Dark Green': '#3bb23e',
  'Light Green': '#62bd66',
  'Dark Blue': '#0374cb',
  'Light Blue': '#6cbfff',
};

export interface MultiSelectTableConfigType {
  actionButton?: {
    label: string;
    onClick: (selectedRows: any[]) => void;
  };
  confirmActionModal?: {
    body: {
      action: string;
      module: string;
    };
    confirmButtonLabel: string;
    cancelButtonLabel: string;
  };
}

export interface ECEasyTableSortState {
  fieldName: string;
  value: string;
}
export interface ECEasyTablePaginationProps {
  isPaginated: boolean;
  totalResults: number;
  currentPage: number;
  perPage: number;
}

export interface ECEasyTableHeaderProps {
  variant: string;
  title: string;
  subtitle: string;
  searchable: boolean;
  searchFieldName: string;
  searchType?: 'simple' | 'advanced';
  showSelfPerformingFilter?: boolean;
  minSearchLength?: number;
  statusAlias?: string;
  searchValue?: string;
}

export interface ECEasyTableDataRequestParamsType {
  pagination: Pick<ECEasyTablePaginationProps, 'currentPage' | 'perPage'>;
  sort?: any;
}

export interface ECEasyTableSortConfigType {
  fieldName: string;
  value: string;
}

export interface ECEasyTableConfigType {
  cols: ECEasyTableColsProps[];
  pagination: ECEasyTablePaginationProps;
  sort: ECEasyTableSortConfigType;
  header: ECEasyTableHeaderProps;
}

export const initTableConfig = {
  cols: [],
  pagination: {
    isPaginated: true,
    totalResults: 0,
    currentPage: 0,
    perPage: 50,
  },
  sort: {
    fieldName: '',
    value: '',
  },
  header: {
    variant: 'fancy',
    title: '',
    subtitle: '',
    searchable: true,
    searchFieldName: '',
  },
};

export interface ECEasyTableProps {
  config?: ECEasyTableConfigType | null;
  additionalChildren?: React.ReactNode;
  data?: any[] | null;
  clickEvent?: any[] | any;
  onDataRequest?: (params?: ECEasyTableDataRequestParamsType) => void;
  onRowPress?: (row: any) => void;
  onDuplicatePress?: (row: any) => void;
  showStatusActiveFilter?: boolean;
  showMissedVendorIdFilter?: boolean;
  showDateFilter?: boolean;
  showAssignedToMeFilter?: boolean;
  showAssignedLocationsFilter?: boolean;
  showAssetTypeNameFilter?: boolean;
  showConceptsFilter?: boolean;
  showAssetGroupSingleFilter?: boolean;
  showSetByFilter?: boolean;
  setByFilterOptions?: string[];
  showLocationFilter?: boolean;
  showHierarchyFilter?: boolean;
  showUserFilter?: boolean;
  maxSelectionUsersFilter?: number;
  showEtaFilter?: boolean;
  showTechAssginedFilter?: boolean;
  showCalendarViewFilter?: boolean;
  showCustomersFilter?: boolean;
  showTechNotAssignedToggleFilter?: boolean;
  showManufacturerFilter?: boolean;
  userCompany?: any;
  showAwaitingInvoiceFilter?: boolean;
  showAssetTypeFilter?: boolean;
  showCategoryFilter?: boolean;
  showStatusFilter?: boolean;
  showCustomFilter?: boolean;
  customSimpleSelectFilterOptions?: FilterFieldOption[];
  customSimpleSelectFilterLabel?: string;
  customSimpleSelectFilterName?: string;
  dateRangeDefaultSelectedOption?: string;
  dateFilterFieldsOptions?: FilterFieldOption[];
  categoryFilterOptions?: FilterFieldOption[];
  statusFilterOptions?: FilterFieldOption[];
  assetTypeFilterOptions?: FilterFieldOption[];
  locationFilterOptions?: FilterFieldOption[];
  manufacturerFilterOptions?: FilterFieldOption[];
  useLocationFilterQuery?: any;
  useManufacturerFilterQuery?: any;
  useGetAssetTypesFilterQuery?: any;
  locationFilterQueryParams?: any;
  manufacturerFilterQueryParams?: any;
  statusFilterInitialSelected?: boolean;
  activeStatusFilterActiveLabel?: string;
  activeStatusFilterInactiveLabel?: string;
  hidePagination?: boolean;
  hidePerPageSelector?: boolean;
  showSelfPerformingFilter?: boolean;
  isSendingRequest?: boolean;
  isLoading?: boolean;
  headerChildren?: React.ReactNode;
  onChipDelete?: (row, chipData, colData: ECEasyTableColsProps) => void;
  onChipClick?: (row, chipData, colData: ECEasyTableColsProps) => void;
  onSwitchChange?: (newValue, row, colData: ECEasyTableColsProps) => void;
  onLayout?: (height: number, width: number) => void;
  downloadFileName?: string;
  enableExport?: boolean;
  isEmptyState?: boolean;
  headerHeight?: number;
  isEditable?: boolean;
  ignoreHyperlink?: boolean;
  moduleRedirectElement?: React.ReactNode;
  usingGlobalSearch?: boolean;
  headerBackgroundColor?: string;
  showBadgeExpirationDate?: boolean;
  onChange?: (
    value: any,
    col: any,
    row: any,
    config: ECEasyTableConfigType,
    index: number,
    action?: any,
  ) => void;
  onPageChange?: (newPage: number, perPage: number) => void;
  onPerPageChange?: (newPage: number, perPage: number) => void;
  onRemove?: (index: number) => void;
  isDraggable?: boolean;
  onDragEnd?: (row: any) => void;
  renderCustomActionComponent?: (row: any, parentRow?: any) => React.ReactNode;
  renderCustomAttachmentComponent?: (row: any) => React.ReactNode;
  onClickViewUsersTable?: (row: any, event: any) => void;
  hasUsersTablePreviewData?: boolean;
  onClickRecipientsNotificationEmail?: (row: any, event: any) => void;
  onClickViewNotificationEmail?: (row: any, event: any) => void;
  onClickRecipientsNotificationMobile?: (row: any, event: any) => void;
  onClickViewNotificationMobile?: (row: any, event: any) => void;
  onClickRecipientsNotificationText?: (row: any, event: any) => void;
  onClickViewNotificationText?: (row: any, event: any) => void;
  selectedChipUsersTable?: string | number;
  marginTop?: boolean;
  multiSelectTable?: boolean;
  showSelectAllPageRows?: boolean;
  emptyType?: EmptyType;
  multiSelectTableConfig?: MultiSelectTableConfigType;
  preSelectRowsFieldname?: string;
  onMultiTableSelect?: (selectedRows: any[]) => void;
  customMultiSelectActionButton?: (rows?: any[]) => React.ReactNode;
  multiSelectedRows?: ((rows: any) => void) | undefined;
  allChecked?: boolean;
  clearSelection?: boolean;
  customTableBodyMaxHeight?: string;
  disableTableContent?: boolean;
  hideSearchHeadContent?: boolean;
  showColumnVisibilityFilter?: boolean;
  isDraggableColumns?: boolean;
  shouldUseSearchParams?: boolean;
  selectRowsMaxCount?: number;
  shouldNotUseActiveFilter?: boolean;
  moduleName?: string;
  selectedRows?: any[];
  currentQueryParams?: any;
  exportModuleName?: string;
  exportTradeId?: number;
  exportBranchId?: number;
  exportUserId?: number;
  enableTableCsvExport?: boolean;
  showActivityButton?: boolean;
  searchBarCustomElements?: React.ReactNode;
  activityType?: ActivityType;
  hideSimpleSearchBar?: boolean;
  hideSaveFilterButton?: boolean;
  renderTableCustomLink?: (url: string) => React.ReactNode;
  conditionalLinearProgressColor?: boolean;
  sx?: any;
}

export interface ECEasyTableContentProps {
  config: ECEasyTableConfigType;
  data: any[];
  additionalChildren?: React.ReactNode;
  clickEvent: any[] | any;
  onDataRequest?: (params: ECEasyTableDataRequestParamsType) => void;
  showStatusActiveFilter?: boolean;
  showMissedVendorIdFilter?: boolean;
  showDateFilter?: boolean;
  showAssignedToMeFilter?: boolean;
  showAssignedLocationsFilter?: boolean;
  showAssetTypeNameFilter?: boolean;
  useGetAssetTypesFilterQuery?: any;
  showConceptsFilter?: boolean;
  showAssetGroupSingleFilter?: boolean;
  showSetByFilter?: boolean;
  setByFilterOptions?: string[];
  showLocationFilter?: boolean;
  showHierarchyFilter?: boolean;
  showUserFilter?: boolean;
  maxSelectionUsersFilter?: number;
  showEtaFilter?: boolean;
  showTechAssginedFilter?: boolean;
  showCalendarViewFilter?: boolean;
  showCustomersFilter?: boolean;
  showTechNotAssignedToggleFilter?: boolean;
  showManufacturerFilter?: boolean;
  userCompany?: any;
  showAwaitingInvoiceFilter?: boolean;
  showAssetTypeFilter?: boolean;
  showCategoryFilter?: boolean;
  showStatusFilter?: boolean;
  showCustomFilter?: boolean;
  customSimpleSelectFilterOptions?: FilterFieldOption[];
  customSimpleSelectFilterLabel?: string;
  customSimpleSelectFilterName?: string;
  dateRangeDefaultSelectedOption?: string;
  dateFilterFieldsOptions?: FilterFieldOption[];
  categoryFilterOptions?: FilterFieldOption[];
  statusFilterOptions?: FilterFieldOption[];
  assetTypeFilterOptions?: FilterFieldOption[];
  locationFilterOptions?: FilterFieldOption[];
  manufacturerFilterOptions?: FilterFieldOption[];
  useLocationFilterQuery?: any;
  useManufacturerFilterQuery?: any;
  locationFilterQueryParams?: any;
  manufacturerFilterQueryParams?: any;
  statusFilterInitialSelected?: boolean;
  activeStatusFilterActiveLabel?: string;
  activeStatusFilterInactiveLabel?: string;
  showSelfPerformingFilter?: boolean;
  isSendingRequest?: boolean;
  isSendingFullDataRequest?: boolean;
  isLoading?: boolean;
  onRowPress?: (row: any) => void;
  onDuplicatePress?: (row: any) => void;
  renderCustomActionComponent?: (row: any, parentRow?: any) => React.ReactNode;
  renderCustomAttachmentComponent?: (row: any) => React.ReactNode;
  onClickViewUsersTable?: (row: any, event: any) => void;
  hasUsersTablePreviewData?: boolean;
  onClickRecipientsNotificationEmail?: (row: any, event: any) => void;
  onClickViewNotificationEmail?: (row: any, event: any) => void;
  onClickRecipientsNotificationMobile?: (row: any, event: any) => void;
  onClickViewNotificationMobile?: (row: any, event: any) => void;
  onClickRecipientsNotificationText?: (row: any, event: any) => void;
  onClickViewNotificationText?: (row: any, event: any) => void;
  selectedChipUsersTable?: string | number;
  headerChildren?: React.ReactNode;
  onChipDelete?: (row, chipData, colData: ECEasyTableColsProps) => void;
  onChipClick?: (row, chipData, colData: ECEasyTableColsProps) => void;
  onSwitchChange?: (newValue, row, colData: ECEasyTableColsProps) => void;
  onExportClick?: (e: any) => void;
  isEmptyState?: boolean;
  headerHeight?: number;
  isEditable?: boolean;
  ignoreHyperlink?: boolean;
  moduleRedirectElement?: React.ReactNode;
  usingGlobalSearch?: boolean;
  headerBackgroundColor?: string;
  hideSearchHeadContent?: boolean;
  hidePagination?: boolean;
  hidePerPageSelector?: boolean;
  onChange?: (
    value: any,
    col: any,
    row: any,
    config: ECEasyTableConfigType,
    index: number,
    action?: any,
  ) => void;
  onPageChange?: (newPage: number, perPage: number) => void;
  onPerPageChange?: (newPage: number, perPage: number) => void;
  onRemove?: (index: number) => void;
  isDraggable?: boolean;
  onDragEnd?: (row: any) => void;
  marginTop?: boolean;
  multiSelectTable?: boolean;
  showSelectAllPageRows?: boolean;
  emptyType?: EmptyType;
  multiSelectTableConfig?: MultiSelectTableConfigType;
  preSelectRowsFieldname?: string;
  onMultiTableSelect?: (
    selectedRows: any[],
    isUnselecting?: boolean,
    row?: any,
  ) => void;
  customMultiSelectActionButton?: (rows?: any[]) => React.ReactNode;
  multiSelectedRows?: ((rows: any) => void) | undefined;
  allChecked?: boolean;
  clearSelection?: boolean;
  customTableBodyMaxHeight?: string;
  disableTableContent?: boolean;
  showColumnVisibilityFilter?: boolean;
  isDraggableColumns?: boolean;
  shouldUseSearchParams?: boolean;
  selectRowsMaxCount?: number;
  shouldNotUseActiveFilter?: boolean;
  moduleName?: string;
  selectedRowsOnTable?: any[];
  showActivityButton?: boolean;
  searchBarCustomElements?: React.ReactNode;
  activityType?: ActivityType;
  hideSimpleSearchBar?: boolean;
  hideSaveFilterButton?: boolean;
  renderTableCustomLink?: (url: string) => React.ReactNode;
  conditionalLinearProgressColor?: boolean;
}

export interface FilterFieldOption {
  id: number;
  label: string;
  fieldName: string;
  isInitialSelected?: boolean;
  status?: number;
}

export interface ECEasyTableContainerProps {
  pagination: ECEasyTablePaginationProps;
  hidePagination?: boolean;
  hidePerPageSelector?: boolean;
  header: ECEasyTableHeaderProps;
  cols: ECEasyTableColsProps[];
  hiddenCols: ECEasyTableColsProps[];
  onPageRequest?: (page: number) => void;
  onPerPageRequest?: (perPage: number) => void;
  onFilterSubmit?: () => void;
  children: React.ReactNode;
  headerChildren?: React.ReactNode;
  showStatusActiveFilter?: boolean;
  showMissedVendorIdFilter?: boolean;
  showDateFilter?: boolean;
  showAssignedToMeFilter?: boolean;
  showAssignedLocationsFilter?: boolean;
  showAssetTypeNameFilter?: boolean;
  showConceptsFilter?: boolean;
  showAssetGroupSingleFilter?: boolean;
  showSetByFilter?: boolean;
  setByFilterOptions?: string[];
  showLocationFilter?: boolean;
  showHierarchyFilter?: boolean;
  showUserFilter?: boolean;
  maxSelectionUsersFilter?: number;
  showEtaFilter?: boolean;
  showTechAssginedFilter?: boolean;
  showCalendarViewFilter?: boolean;
  showCustomersFilter?: boolean;
  showTechNotAssignedToggleFilter?: boolean;
  showManufacturerFilter?: boolean;
  userCompany?: any;
  showAwaitingInvoiceFilter?: boolean;
  showAssetTypeFilter?: boolean;
  showCategoryFilter?: boolean;
  showStatusFilter?: boolean;
  showCustomFilter?: boolean;
  customSimpleSelectFilterOptions?: FilterFieldOption[];
  customSimpleSelectFilterLabel?: string;
  customSimpleSelectFilterName?: string;
  dateRangeDefaultSelectedOption?: string;
  dateFilterFieldsOptions?: FilterFieldOption[];
  categoryFilterOptions?: FilterFieldOption[];
  statusFilterOptions?: FilterFieldOption[];
  assetTypeFilterOptions?: FilterFieldOption[];
  locationFilterOptions?: FilterFieldOption[];
  manufacturerFilterOptions?: FilterFieldOption[];
  useLocationFilterQuery?: any;
  useManufacturerFilterQuery?: any;
  locationFilterQueryParams?: any;
  manufacturerFilterQueryParams?: any;
  statusFilterInitialSelected?: boolean;
  activeStatusFilterActiveLabel?: string;
  activeStatusFilterInactiveLabel?: string;
  showColumnVisibilityFilter?: boolean;
  isDraggableColumns?: boolean;
  showSelfPerformingFilter?: boolean;
  moduleRedirectElement?: React.ReactNode;
  usingGlobalSearch?: boolean;
  isLoading?: boolean;
  isSendingRequest?: boolean;
  isSendingFullDataRequest?: boolean;
  onExportClick?: (e: any) => void;
  isEmptyState?: boolean;
  headerHeight?: number;
  multiSelectTable?: boolean;
  showSelectAllPageRows?: boolean;
  emptyType?: EmptyType;
  multiSelectTableConfig?: MultiSelectTableConfigType;
  selectedRows?: any[];
  onSelectedRowsAction?: (rows: any[]) => void;
  customMultiSelectActionButton?: (rows: any[]) => React.ReactNode;
  multiSelectedRows?: ((rows: any) => void) | undefined;
  allChecked?: boolean;
  disableTableContent?: boolean;
  hideSearchHeadContent?: boolean;
  shouldUseSearchParams?: boolean;
  shouldNotUseActiveFilter?: boolean;
  moduleName?: string;
  showActivityButton?: boolean;
  searchBarCustomElements?: React.ReactNode;
  activityType?: ActivityType;
  hideSimpleSearchBar?: boolean;
  hideSaveFilterButton?: boolean;
  conditionalLinearProgressColor?: boolean;
  useGetAssetTypesFilterQuery?: any;
}

export const MAX_SELECTABLE_OPTIONS = 1500;

export const ECEasyTableContainer = (props: ECEasyTableContainerProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const pmId = location.state?.pmId;

  const {
    pagination,
    header,
    onPageRequest,
    onPerPageRequest,
    onFilterSubmit,
    children,
    showStatusActiveFilter,
    showMissedVendorIdFilter,
    hidePagination,
    hidePerPageSelector,
    showDateFilter,
    showAssignedToMeFilter,
    showAssignedLocationsFilter,
    showAssetTypeNameFilter,
    showConceptsFilter,
    showAssetGroupSingleFilter,
    showSetByFilter,
    setByFilterOptions,
    showLocationFilter,
    showHierarchyFilter,
    showUserFilter,
    maxSelectionUsersFilter,
    showEtaFilter,
    showTechAssginedFilter,
    showCalendarViewFilter,
    showCustomersFilter,
    showTechNotAssignedToggleFilter,
    showManufacturerFilter,
    userCompany,
    showAwaitingInvoiceFilter,
    showAssetTypeFilter,
    showCategoryFilter,
    showStatusFilter,
    showCustomFilter,
    customSimpleSelectFilterOptions,
    customSimpleSelectFilterLabel,
    customSimpleSelectFilterName = 'custom',
    dateRangeDefaultSelectedOption,
    dateFilterFieldsOptions,
    categoryFilterOptions,
    assetTypeFilterOptions,
    useLocationFilterQuery,
    useManufacturerFilterQuery,
    locationFilterQueryParams,
    manufacturerFilterQueryParams,
    statusFilterOptions,
    statusFilterInitialSelected = true,
    activeStatusFilterActiveLabel = 'Active',
    activeStatusFilterInactiveLabel = 'Inactive',
    showSelfPerformingFilter,
    isLoading,
    isSendingFullDataRequest,
    headerChildren,
    onExportClick,
    moduleRedirectElement,
    usingGlobalSearch,
    isEmptyState,
    cols,
    hiddenCols,
    isSendingRequest,
    headerHeight,
    multiSelectTable,
    showSelectAllPageRows = true,
    emptyType,
    multiSelectTableConfig,
    selectedRows,
    onSelectedRowsAction,
    customMultiSelectActionButton,
    multiSelectedRows,
    disableTableContent,
    hideSearchHeadContent,
    showColumnVisibilityFilter,
    isDraggableColumns,
    shouldUseSearchParams,
    shouldNotUseActiveFilter = false,
    moduleName,
    showActivityButton,
    searchBarCustomElements,
    activityType,
    hideSimpleSearchBar,
    hideSaveFilterButton,
    conditionalLinearProgressColor,
    useGetAssetTypesFilterQuery,
  } = props;
  const { searchable, searchType, minSearchLength } = header;

  const [paginationState, setPaginationState] = useState({
    ...pagination,
  });

  const { activeFilter: activeFilterFromSelector, endpoint } = useSelector(
    (state: RootState) => state.page.filter,
  );

  const activeFilter = useMemo(() => {
    return activeFilterFromSelector?.[window.location.href?.split('?')?.[0]];
  }, [window.location.href, activeFilterFromSelector]);

  const { data: allSavedFilters } = useGetAllSavedFiltersQuery(endpoint, {
    skip: !endpoint || shouldNotUseActiveFilter,
  }); //as long as the request params which is filterEndPoint here is not changed, redux wont make a new api call it just returns whatever is stored in the redux from previous api call.

  const { data: assetGroups = {} } =
    useGetAssetTypesFilterQuery?.({ t: 0 }) || {};
  const {
    config: { pagination: { totalCount: assetGroupsTotalCount = 0 } = {} } = {},
  } = assetGroups;

  const { data: allSavedColumnsFilters } = useGetAllSavedColumnsFiltersQuery(
    endpoint,
    {
      skip: !endpoint || shouldNotUseActiveFilter,
    },
  );

  const [activeStatusFilter, setActiveStatusFilter] = useState<boolean>(
    activeFilter?.activeStatus !== undefined
      ? activeFilter?.activeStatus
      : statusFilterInitialSelected,
  );
  const [missingVendorIdFilter, setMissingVendorIdFilter] = useState<boolean>(
    activeFilter?.missingVendorId !== undefined
      ? activeFilter?.missingVendorId
      : false,
  );

  const [selfPerformingFilter, setSelfPerformingFilter] = useState<boolean>(
    activeFilter?.selfPerforming !== undefined
      ? activeFilter?.selfPerforming
      : true,
  );

  const [assignedToMeFilter, setAssignedToMeFilter] = useState(
    pmId
      ? false
      : activeFilter?.assignedToMe === undefined
        ? true
        : activeFilter?.assignedToMe,
  );
  const [assignedLocationsFilter, setAssignedLocationsFilter] = useState(
    activeFilter?.assignedLocations,
  );
  const [awaitingInvoiceFilter, setAwaitingInvoiceFilter] = useState(
    activeFilter?.awaitingInvoice,
  );
  const [assetTypeFilter, setAssetTypeFilter] = useState(
    activeFilter?.assetType ?? true,
  );

  const [createdDateRange, setCreatedDateRange] = useState<{
    dateRange: any[];
    dateRangeFilterType?: DateRangeFilterType;
    dateAlias: string;
    dateAliasLabel: string;
    selectedOption?: string;
  }>({
    dateRange: _.compact([
      activeFilter?.dateAt ||
        activeFilter?.dateAfter ||
        activeFilter?.dateBefore,
      activeFilter?.dateBefore || activeFilter?.dateAfter,
    ]),
    dateRangeFilterType:
      activeFilter?.dateRangeFilterType || DateRangeFilterType.Between,
    dateAlias:
      activeFilter?.dateAlias || dateFilterFieldsOptions?.[0]?.fieldName || '',
    dateAliasLabel:
      activeFilter?.dateAliasLabel || dateFilterFieldsOptions?.[0]?.label || '',
    selectedOption:
      activeFilter?.selectedOption || dateRangeDefaultSelectedOption,
  });

  useEffect(() => {
    if (!activeFilter?.statuses?.length) {
      dispatch(
        setActiveFilter({
          ...activeFilter,
          statuses:
            statusFilterOptions
              ?.filter(option => option.isInitialSelected)
              ?.map(option => option.fieldName) ||
            activeFilter?.statuses ||
            undefined,
          endpoint,
        }),
      );
    }
  }, [statusFilterOptions]);

  useEffect(() => {
    if (customSimpleSelectFilterName && customSimpleSelectFilterOptions) {
      dispatch(
        setActiveFilter({
          ...activeFilter,
          [customSimpleSelectFilterName]:
            customSimpleSelectFilterOptions
              ?.filter(option => option.isInitialSelected)
              ?.map(option => option.id) ||
            activeFilter?.[customSimpleSelectFilterName] ||
            undefined,
          endpoint,
        }),
      );
    }
  }, [customSimpleSelectFilterOptions, customSimpleSelectFilterName]);

  const [pageLocation, setPageLocation] = useState(0);
  const [pageManufacturer, setPageManufacturer] = useState(0);
  const [shouldFetchAllLocations, setShouldFetchAllLocations] = useState<
    boolean | undefined
  >(undefined);
  const [shouldFetchAllManufacturer, setShouldFetchAllManufacturer] = useState<
    boolean | undefined
  >(undefined);
  const [locationFilterOptions, setLocationFilterOptions] = useState<any>([]);
  const [manufacturerFilterOptions, setManufacturerFilterOptions] =
    useState<any>([]);
  const [text, setText] = useState('');
  const [searchTextManufacturer, setSearchTextManufacturer] = useState('');

  const { data: branches, isFetching: isFetchingBranch } =
    useLocationFilterQuery?.({
      ...(locationFilterQueryParams || {}),
      t: shouldFetchAllLocations ? 0 : 10,
      s: text ? `*${text}*` : '',
      sb: moduleName === 'inventory' ? 'invntrystrg.name' : 'brnch.name',
      ob: moduleName === 'inventory' ? 'invntrystrg.name' : '',
      p: pageLocation,
    }) || {};

  const { data: manufacturer, isFetching: isFetchingManufacturer } =
    useManufacturerFilterQuery?.({
      ...(manufacturerFilterQueryParams || {}),
      t: shouldFetchAllLocations ? 0 : 10,
      s: searchTextManufacturer ? `*${searchTextManufacturer}*` : '',
      // sb: moduleName === 'inventory' ? 'invntrystrg.name' : 'brnch.name',
      p: pageManufacturer,
    }) || {};

  const noMoreDataToFetchLocation = useMemo(() => {
    if (!branches) {
      return false;
    }

    if (!branches?.config?.pagination || shouldFetchAllLocations) {
      return true;
    }

    return (pageLocation + 1) * 10 >= branches?.config?.pagination?.totalCount;
  }, [branches, pageLocation, shouldFetchAllLocations]);

  const noMoreDataToFetchManufacturer = useMemo(() => {
    if (!manufacturer) {
      return false;
    }

    if (!manufacturer?.config?.pagination || shouldFetchAllManufacturer) {
      return true;
    }

    return (
      (pageManufacturer + 1) * 10 >=
      manufacturer?.config?.pagination?.totalCount
    );
  }, [manufacturer, pageManufacturer, shouldFetchAllManufacturer]);

  useEffect(() => {
    if (branches?.data && !isFetchingBranch) {
      setLocationFilterOptions(prev => {
        return _.uniqBy(
          [
            ...prev,
            ...(_.orderBy(branches?.data, 'name')?.map(branch => ({
              id: branch?.id,
              label: branch?.status
                ? branch?.name
                : `(INACTIVE) ${branch?.name}`,
              fieldName: branch?.name,
            })) || []),
          ],
          'id',
        );
      });

      if (shouldFetchAllLocations === undefined) {
        setShouldFetchAllLocations(
          branches?.config?.pagination?.totalCount <= MAX_SELECTABLE_OPTIONS,
        );
      }
    }
  }, [branches?.data, isFetchingBranch]);

  const isUsersOrJobTitleModule = useMemo(() => {
    return moduleName === 'users' || moduleName === 'jobtitle';
  }, [moduleName]);

  useEffect(() => {
    const manufacturerData = manufacturer?.data || manufacturer;
    const normalizedManufacturerData = Array.isArray(manufacturerData)
      ? [...manufacturerData]
      : [];

    const finalManufacturerData = isUsersOrJobTitleModule
      ? [...normalizedManufacturerData, userCompany]
      : normalizedManufacturerData;

    if (manufacturerData && !isFetchingManufacturer) {
      setManufacturerFilterOptions(prev => {
        return _.uniqBy(
          [
            ...prev,
            ...(_.orderBy(finalManufacturerData, 'name')?.map(manufacture => ({
              id: manufacture?.id,
              label: manufacture?.name,
              fieldName: manufacture?.name,
            })) || []),
          ],
          'id',
        );
      });

      if (shouldFetchAllManufacturer === undefined) {
        setShouldFetchAllManufacturer(
          manufacturer?.config?.pagination?.totalCount <=
            MAX_SELECTABLE_OPTIONS,
        );
      }
    }
  }, [
    manufacturer?.data,
    isFetchingManufacturer,
    isUsersOrJobTitleModule,
    userCompany,
  ]);

  const [categoryFilter, setCategoryFilter] = useState<string[]>(
    activeFilter?.categories ||
      categoryFilterOptions
        ?.filter(option => option.isInitialSelected)
        ?.map(option => option.fieldName) ||
      [],
  );

  useEffect(() => {
    setCategoryFilter(
      activeFilter?.categories ||
        categoryFilterOptions
          ?.filter(option => option.isInitialSelected)
          ?.map(option => option.fieldName) ||
        [],
    );
  }, [categoryFilterOptions]);

  const dispatch = useDispatch();

  useEffect(() => {
    setPaginationState({
      ...pagination,
    });
  }, [pagination]);
  const { isPaginated, totalResults, currentPage, perPage } = paginationState;

  const showSaveFilterButton: boolean = useMemo(() => {
    if (
      activeFilter?.filterFields &&
      activeFilter?.filterFields.length > 0 &&
      (isEmptyValue(activeFilter?.name) || activeFilter?.isEdited)
    ) {
      return true;
    }
    return false;
  }, [activeFilter]);

  useEffect(() => {
    dispatch(
      setActiveFilter({
        activeStatus: activeStatusFilter,
        endpoint,
      }),
    );
  }, [activeStatusFilter]);

  useEffect(() => {
    dispatch(
      setActiveFilter({
        missingVendorId: missingVendorIdFilter,
        endpoint,
      }),
    );
  }, [missingVendorIdFilter]);

  useEffect(() => {
    dispatch(
      setActiveFilter({
        selfPerforming: selfPerformingFilter,
        endpoint,
      }),
    );
  }, [selfPerformingFilter]);

  useEffect(() => {
    dispatch(
      setActiveFilter({
        assignedToMe: assignedToMeFilter,
        endpoint,
      }),
    );
  }, [assignedToMeFilter]);

  useEffect(() => {
    dispatch(
      setActiveFilter({
        assignedLocations: assignedLocationsFilter,
        endpoint,
      }),
    );
  }, [assignedLocationsFilter]);

  useEffect(() => {
    dispatch(
      setActiveFilter({
        awaitingInvoice: awaitingInvoiceFilter,
        statuses: awaitingInvoiceFilter
          ? statusFilterOptions
              ?.filter(status => status.label === 'Completed')
              ?.map(st => st.fieldName) || []
          : activeFilter?.statuses ||
            statusFilterOptions
              ?.filter(option => option.isInitialSelected)
              ?.map(option => option.fieldName) ||
            undefined,
        endpoint,
      }),
    );
  }, [awaitingInvoiceFilter]);

  useEffect(() => {
    dispatch(
      setActiveFilter({
        assetType: assetTypeFilter,
        endpoint,
      }),
    );
  }, [assetTypeFilter]);

  useEffect(() => {
    if (endpoint) {
      const endpointLastSegment = endpoint.split('/').pop();
      const locationLastSegment = convertRouteNameToFilterEndpoint()
        .split('/')
        .pop();

      if (
        endpointLastSegment &&
        !locationLastSegment?.includes(endpointLastSegment)
      ) {
        //cleanupSimpleSearch();
      }
    }
  }, [endpoint, location.pathname]);

  useEffect(() => {
    let dateRange = {};
    if (createdDateRange?.dateRangeFilterType === DateRangeFilterType.Between) {
      dateRange = {
        dateAt: null,
        dateAfter: createdDateRange?.dateRange?.[0]?.toString(),
        dateBefore: createdDateRange?.dateRange?.[1]?.toString(),
      };
    } else if (
      createdDateRange?.dateRangeFilterType === DateRangeFilterType.After
    ) {
      dateRange = {
        dateAt: null,
        dateAfter: createdDateRange?.dateRange?.[0]?.toString(),
        dateBefore: null,
      };
    } else if (
      createdDateRange?.dateRangeFilterType === DateRangeFilterType.Before
    ) {
      dateRange = {
        dateAt: null,
        dateAfter: null,
        dateBefore: createdDateRange?.dateRange?.[0]?.toString(),
      };
    } else if (
      createdDateRange?.dateRangeFilterType === DateRangeFilterType.Exact
    ) {
      dateRange = {
        dateAt: createdDateRange?.dateRange?.[0]?.toString(),
        dateAfter: null,
        dateBefore: null,
      };
    } else {
      dateRange = {
        dateAt: null,
        dateAfter: null,
        dateBefore: null,
      };
    }

    dispatch(
      setActiveFilter({
        ...dateRange,
        dateAlias: createdDateRange?.dateAlias || activeFilter?.dateAlias,
        dateAliasLabel:
          createdDateRange?.dateAliasLabel || activeFilter?.dateAliasLabel,
        dateRangeFilterType:
          createdDateRange?.dateRangeFilterType ||
          activeFilter?.dateRangeFilterType,
        selectedOption:
          createdDateRange?.selectedOption || activeFilter?.selectedOption,
      }),
    );
  }, [createdDateRange]);

  useEffect(() => {
    const isAllCategoriesSelected =
      categoryFilter?.length === categoryFilterOptions?.length;
    dispatch(
      setActiveFilter({
        categories: isAllCategoriesSelected ? null : categoryFilter,
        endpoint,
      }),
    );
  }, [categoryFilter]);

  const simpleSearch = useMemo(
    () => searchType === 'simple', // || !activeFilter?.isAdvanced,
    [activeFilter?.isAdvanced, searchType],
  );

  const handleChangeKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const [
    multiSelectActionConfirmModalOpen,
    setMultiSelectActionConfirmModalOpen,
  ] = useState<boolean>(false);

  const renderMultiSelectActionButton = useCallback(() => {
    return customMultiSelectActionButton ? (
      customMultiSelectActionButton?.(selectedRows || [])
    ) : (
      <ECButton
        variant="outlined"
        disabled={selectedRows?.length === 0}
        color="primary"
        onClick={() => {
          if (!multiSelectTableConfig?.confirmActionModal) {
            multiSelectTableConfig?.actionButton?.onClick?.(selectedRows || []);
          } else {
            setMultiSelectActionConfirmModalOpen(true);
          }
        }}
      >
        {multiSelectTableConfig?.actionButton?.label}
        {selectedRows?.length ? ` (${selectedRows?.length})` : ''}
      </ECButton>
    );
  }, [selectedRows, customMultiSelectActionButton]);

  const renderMultiSelectConfirmModal = () => {
    return (
      <ECPopover
        id={'confirmation-modal'}
        open={multiSelectActionConfirmModalOpen}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        <ECBox p={2}>
          <ECTypography variant="body2">
            {multiSelectTableConfig?.confirmActionModal?.body?.action}{' '}
            {selectedRows?.length || 0}{' '}
            {multiSelectTableConfig?.confirmActionModal?.body?.module} ?
          </ECTypography>

          <ECBox mt={2} display="flex" gap={2} justifyContent="flex-end">
            <ECButton
              variant="text"
              onClick={() => {
                setMultiSelectActionConfirmModalOpen(false);
                onSelectedRowsAction?.([]);
              }}
            >
              Cancel
            </ECButton>
            <ECButton
              variant="contained"
              onClick={() => {
                multiSelectTableConfig?.actionButton?.onClick?.(
                  selectedRows || [],
                );
                setMultiSelectActionConfirmModalOpen(false);
              }}
            >
              {multiSelectTableConfig?.confirmActionModal?.confirmButtonLabel ??
                'Confirm'}
            </ECButton>
          </ECBox>
        </ECBox>
      </ECPopover>
    );
  };

  const renderCreateDateFilter = useCallback(
    () => (
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        width="392px"
        height="40px"
        border="1px solid #E0E0E0"
        borderRadius="4px"
        display="flex"
        justifyContent="space-around"
      >
        {!!dateFilterFieldsOptions?.length && (
          <ECSelect
            defaultValue={
              createdDateRange?.dateAlias ||
              dateFilterFieldsOptions?.[0]?.fieldName
            }
            variant="standard"
            MenuProps={{
              transformOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              anchorOrigin: {
                vertical: 40,
                horizontal: 110,
              },
            }}
            disableUnderline
            onChange={e => {
              setCreatedDateRange(prev => ({
                ...prev,
                dateAliasLabel:
                  dateFilterFieldsOptions?.find(
                    option => option.fieldName === e.target.value,
                  )?.label || '',
                dateAlias: e.target.value as string,
              }));
              onFilterSubmit?.();
            }}
          >
            {dateFilterFieldsOptions?.map((option, index) => (
              <ECMenuItem
                value={option.fieldName}
                key={`${option.fieldName}-${option.label}`}
                id={`${option.fieldName}-${option.label}`}
              >
                {option.label}
              </ECMenuItem>
            ))}
          </ECSelect>
        )}
        <ECDateRangePicker
          defaultSelectedOption={
            createdDateRange?.selectedOption || dateRangeDefaultSelectedOption
          }
          defaultSelectedDates={createdDateRange?.dateRange}
          onChange={(dateRange, dateRangeFilterType, optionLabel) => {
            setCreatedDateRange(prev => ({
              ...prev,
              dateRange,
              dateRangeFilterType,
              selectedOption: optionLabel || prev.selectedOption,
            }));
            onFilterSubmit?.();
          }}
        />
      </Stack>
    ),
    [dateFilterFieldsOptions, dateRangeDefaultSelectedOption],
  );

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const openAssetType = useMemo(() => {
    return Boolean(anchorEl);
  }, [anchorEl]);
  const idAssetTypePopover = useMemo(() => {
    return openAssetType ? 'asset-types-popover' : undefined;
  }, [openAssetType]);

  const assetTypeRef = useRef(null);
  const handleClickAssetType = useCallback(
    (event: React.MouseEvent<HTMLSpanElement>) => {
      setAnchorEl(assetTypeRef?.current);
    },
    [],
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    if (
      assetTypeFilterOptions &&
      assetTypeFilterOptions?.length <= MAX_SELECTABLE_OPTIONS &&
      activeFilter?.assetTypes?.length === 0
    ) {
      dispatch(
        setActiveFilter({
          assetTypes:
            assetTypeFilterOptions?.map(option => ({
              id: option?.id,
              name: option?.fieldName,
            })) || [],
          endpoint,
        }),
      );
      onFilterSubmit?.();
    }
  }, [activeFilter?.assetTypes, assetTypeFilterOptions]);

  const assetTypesChip = useMemo(() => {
    if (
      !activeFilter?.assetTypes?.length ||
      activeFilter?.assetTypes?.length === assetTypeFilterOptions?.length ||
      activeFilter?.assetTypes?.length === 0
    ) {
      return (
        <ECChip
          sx={{ width: '48px', height: '24px' }}
          label="All"
          color="Light Grey"
        />
      );
    }

    return (
      <ECChip
        sx={{ width: '48px', height: '24px' }}
        label={activeFilter?.assetTypes?.length}
        color="Dark Blue"
      />
    );
  }, [activeFilter?.assetTypes]);

  const assetTypeFilterOptionsWithAll = useMemo(() => {
    return [
      {
        id: 0,
        label: 'all',
        fieldName: 'all',
      },
      ...(assetTypeFilterOptions || []),
    ];
  }, [assetTypeFilterOptions]);

  const renderPopper = useCallback(props => {
    return (
      <ECPopper
        {...props}
        sx={{
          transform: 'translate3d(8px, 53px, 0px) !important',
          minWidth: 'fit-content !important',
          mt: '8px !important',
          '& .MuiPopover-paper': {
            boxShadow: 'none',
          },
          '.MuiAutocomplete-listbox': {
            boxShadow: 'none',
          },
          '.MuiPaper-elevation1': {
            boxShadow: 'none',
          },
          '.MuiPaper-elevation': {
            boxShadow: 'none',
          },
        }}
      />
    );
  }, []);

  const renderAssetTypeNameFilter = useCallback(() => {
    return (
      <ECAssetTypeNameFilter
        assetTypeFilterOptions={assetTypeFilterOptions}
        endpoint={endpoint}
        onFilterSubmit={onFilterSubmit}
        useGetAssetTypesListQuery={useGetAssetTypesFilterQuery}
        totalCount={assetGroupsTotalCount}
      />
    );
  }, [activeFilter?.assetTypes]);

  const [anchorElLocation, setAnchorElLocation] =
    useState<HTMLDivElement | null>(null);

  const [anchorElManufacturer, setAnchorElManufacturer] =
    useState<HTMLDivElement | null>(null);

  const openLocation = useMemo(() => {
    return Boolean(anchorElLocation);
  }, [anchorElLocation]);

  const openManufacturer = useMemo(() => {
    return Boolean(anchorElManufacturer);
  }, [anchorElManufacturer]);

  const idLocationPopover = useMemo(() => {
    return openLocation ? 'locations-popover' : undefined;
  }, [openLocation]);

  const idManufacturerPopover = useMemo(() => {
    return openLocation ? 'manufacturer-popover' : undefined;
  }, [openManufacturer]);

  const locationRef = useRef(null);
  const manufacturerRef = useRef(null);

  const handleClickLocation = useCallback(
    (event: React.MouseEvent<HTMLSpanElement>) => {
      setAnchorElLocation(locationRef?.current);
    },
    [],
  );

  const handleClickManufacturer = useCallback(
    (event: React.MouseEvent<HTMLSpanElement>) => {
      setAnchorElManufacturer(manufacturerRef?.current);
    },
    [],
  );

  const handleCloseLocation = useCallback(() => {
    setAnchorElLocation(null);
  }, []);

  const handleCloseManufacturer = useCallback(() => {
    setAnchorElManufacturer(null);
  }, []);

  const locationsChip = useMemo(() => {
    if (
      !activeFilter?.locations?.length ||
      activeFilter?.locations?.length === locationFilterOptions?.length
    ) {
      return (
        <ECChip
          sx={{ width: '48px', height: '24px' }}
          label="All"
          color="Light Grey"
        />
      );
    }

    return (
      <ECChip
        sx={{ width: '48px', height: '24px' }}
        label={activeFilter?.locations?.length}
        color="Dark Blue"
      />
    );
  }, [activeFilter?.locations, locationFilterOptions]);

  const manufacturerChip = useMemo(() => {
    if (
      !activeFilter?.manufacturer?.length ||
      activeFilter?.manufacturer?.length === manufacturerFilterOptions?.length
    ) {
      return (
        <ECChip
          sx={{ width: '48px', height: '24px' }}
          label="All"
          color="Light Grey"
        />
      );
    }

    return (
      <ECChip
        sx={{ width: '48px', height: '24px' }}
        label={activeFilter?.manufacturer?.length}
        color="Dark Blue"
      />
    );
  }, [activeFilter?.manufacturer, manufacturerFilterOptions]);

  const locationFilterOptionsWithAll = useMemo(() => {
    return [
      {
        label: 'all',
        fieldName: 'all',
        id: 0,
      },
      ...(locationFilterOptions || []),
      ...(noMoreDataToFetchLocation
        ? []
        : [
            {
              label: 'Loading...',
              fieldName: 'loading',
              id: -1,
            },
          ]),
    ];
  }, [locationFilterOptions, noMoreDataToFetchLocation]);

  const manufacturerFilterOptionsWithAll = useMemo(() => {
    const baseOptions = [
      {
        label: 'all',
        fieldName: 'all',
        id: 0,
      },
      ...(manufacturerFilterOptions || []),
      ...(noMoreDataToFetchManufacturer
        ? []
        : [
            {
              label: 'Loading...',
              fieldName: 'loading',
              id: -1,
            },
          ]),
    ];
    if (isUsersOrJobTitleModule && manufacturerFilterOptions.length > 1) {
      baseOptions.splice(1, 0, userCompany, {
        label: 'Internal Technicians Companies',
        fieldName: 'Internal Technicians Companies',
        id: -1,
      });
    }

    return baseOptions;
  }, [
    manufacturerFilterOptions,
    isUsersOrJobTitleModule,
    noMoreDataToFetchManufacturer,
    moduleName,
  ]);

  const handleChangeLocation = useCallback(
    (event, value, reason) => {
      if (value[value?.length - 1]?.id === -1) {
        return;
      }

      setText('');
      if (value[value?.length - 1]?.fieldName === 'all') {
        dispatch(
          setActiveFilter({
            locations:
              activeFilter?.locations?.length === locationFilterOptions?.length
                ? []
                : locationFilterOptions?.map(option => ({
                    id: option.id,
                    name: option.fieldName,
                  })),
            isSelectAllLocations: !(
              activeFilter?.locations?.length === locationFilterOptions?.length
            ),
            endpoint,
          }),
        );
        onFilterSubmit?.();
        return;
      }
      dispatch(
        setActiveFilter({
          locations: value?.map(v => ({
            id: v.id,
            name: v.fieldName || v.name,
          })),
          isSelectAllLocations:
            !text && value?.length === locationFilterOptions?.length,
          endpoint,
        }),
      );
      onFilterSubmit?.();
    },
    [
      locationFilterOptionsWithAll,
      activeFilter?.locations,
      locationFilterOptions,
    ],
  );

  const handleChangeManufacturer = useCallback(
    (event, value, reason) => {
      if (value[value?.length - 1]?.id === -1) {
        return;
      }
      setText('');
      if (value[value?.length - 1]?.fieldName === 'all') {
        dispatch(
          setActiveFilter({
            manufacturer:
              activeFilter?.manufacturer?.length ===
              manufacturerFilterOptions?.length
                ? []
                : manufacturerFilterOptions?.map(option => ({
                    id: option.id,
                    name: option.fieldName,
                  })),
            isSelectAllManufacturers: !(
              activeFilter?.manufacturer?.length ===
              manufacturerFilterOptions?.length
            ),
            endpoint,
          }),
        );
        onFilterSubmit?.();
        return;
      }
      dispatch(
        setActiveFilter({
          manufacturer: value?.map(v => ({
            id: v.id,
            name: v.fieldName || v.name,
          })),
          isSelectAllManufacturers:
            !text && value?.length === manufacturerFilterOptions?.length,
          endpoint,
        }),
      );
      onFilterSubmit?.();
    },
    [
      manufacturerFilterOptionsWithAll,
      activeFilter?.manufacturer,
      manufacturerFilterOptions,
    ],
  );

  const DEBOUNCE_TIME = 500;
  const debouncedHandleSearchChange = useRef(
    _.debounce((e, newValue, reason) => {
      if (reason === 'reset') {
        return;
      }

      const optionEl = document.querySelector(`[data-name="first"]`);
      optionEl?.scrollIntoView({ block: 'nearest', inline: 'start' });

      setText(newValue);
      setPageLocation(0);
      setLocationFilterOptions([]);
    }, DEBOUNCE_TIME),
  ).current;

  const debounceHandleManufacturerSearchChange = useRef(
    _.debounce((e, newValue, reason) => {
      if (reason === 'reset') {
        return;
      }

      const optionEl = document.querySelector(`[data-name="first"]`);
      optionEl?.scrollIntoView({ block: 'nearest', inline: 'start' });

      setSearchTextManufacturer(newValue);
      setPageManufacturer(0);
    }, DEBOUNCE_TIME),
  ).current;

  const handleInputChangeLocation = (e, newValue, reason) => {
    if (reason === 'reset') {
      return;
    }
    debouncedHandleSearchChange(e, newValue, reason);
  };

  const handleInputChangeManufacturer = (e, newValue, reason) => {
    if (reason === 'reset') {
      return;
    }
    debounceHandleManufacturerSearchChange(e, newValue, reason);
  };

  const renderLocationFilter = useCallback(
    () => (
      <Stack
        direction="row"
        alignItems="center"
        width={moduleName === 'inventory' ? '240px' : '200px'}
        height="40px"
        border="1px solid #E0E0E0"
        borderRadius="4px"
        display="flex"
        justifyContent="space-around"
      >
        {locationFilterOptionsWithAll && (
          <>
            <ECTypography
              color={theme => theme.palette.text.secondary}
              variant="caption"
              minWidth={moduleName === 'inventory' ? '120px' : '50px'}
            >
              {moduleName === 'inventory' ? 'Storage Locations' : 'Location'}:
            </ECTypography>
            <ECBox
              display="flex"
              alignItems="center"
              onClick={handleClickLocation}
              ref={locationRef}
            >
              {locationsChip}
              <ECIconButton
                type="default"
                size="small"
                noPadding
                sx={{ marginLeft: '5px' }}
              >
                <ArrowDropDown />
              </ECIconButton>
            </ECBox>
            <ECPopover
              id={idLocationPopover}
              open={openLocation}
              anchorEl={anchorElLocation}
              onClose={handleCloseLocation}
              anchorOrigin={{
                vertical: 35,
                horizontal: 90,
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              sx={{
                bgcolor: 'transparent',
                '& .MuiPopover-paper': {
                  minWidth: '350px',
                  height: '544px',
                },
              }}
            >
              <ECBox
                display="flex"
                bgcolor={theme => theme.palette.common.white}
                width="350px"
              >
                <ECAutocompleteStyled
                  multiple
                  freeSolo
                  open
                  disablePortal
                  sx={{
                    width: '350px',
                    '&.MuiAutocomplete-popper': {
                      transform: 'translate3d(8px, 53px, 0px) !important',
                    },
                  }}
                  filterOptions={
                    shouldFetchAllLocations
                      ? (options, state) => {
                          if (state.inputValue?.length >= 2) {
                            return options?.filter(option =>
                              option?.label
                                ?.toLowerCase()
                                .includes(state.inputValue?.toLowerCase()),
                            );
                          }

                          return options;
                        }
                      : undefined
                  }
                  options={locationFilterOptionsWithAll}
                  value={activeFilter?.locations || []}
                  isOptionEqualToValue={(option, value) =>
                    option?.id && value?.id
                      ? option.id === value.id
                      : option?.fieldName ===
                        (value?.fieldName || value?.name || value)
                  }
                  ListboxProps={{
                    sx: {
                      maxHeight: '470px',
                    },
                    onScroll: (event: React.SyntheticEvent) => {
                      const listboxNode = event.currentTarget;
                      if (
                        listboxNode.scrollTop + listboxNode.clientHeight >=
                          listboxNode.scrollHeight - 1 &&
                        !noMoreDataToFetchLocation &&
                        !isFetchingBranch
                      ) {
                        setPageLocation(prevPage => prevPage + 1);
                      }
                    },
                    role: 'list-box',
                  }}
                  disableCloseOnSelect
                  onInputChange={handleInputChangeLocation}
                  onChange={handleChangeLocation}
                  renderInput={params => (
                    <ECBox display="flex" padding={1} bgcolor="white">
                      <ECTextField
                        {...params}
                        placeholder="Search"
                        sx={{
                          minWidth: 'fit-content !important',
                          padding: '0px !important',
                          '.MuiFilledInput-root': {
                            paddingRight: '0px !important',
                          },
                        }}
                        onKeyDown={handleChangeKeyDown}
                        hiddenLabel
                        variant="filled"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: <></>,
                        }}
                      />
                    </ECBox>
                  )}
                  renderTags={() => {
                    return '';
                  }}
                  PopperComponent={renderPopper}
                  renderOption={(props, option, state) => {
                    if (option?.fieldName === 'all') {
                      return (
                        <ECMenuItem
                          {...props}
                          key={option?.id || option}
                          id={option?.fieldName || option}
                        >
                          <ECTypography
                            variant="body1"
                            color={theme => theme.palette.primary.main}
                          >
                            {activeFilter?.locations?.length ===
                              locationFilterOptions?.length ||
                            (!shouldFetchAllLocations &&
                              activeFilter?.isSelectAllLocations)
                              ? 'Deselect All'
                              : 'Select All'}
                          </ECTypography>
                        </ECMenuItem>
                      );
                    }

                    if (option.id === -1) {
                      return (
                        <ECMenuItem
                          {...props}
                          key={option?.id || option}
                          id={option?.fieldName || option}
                        >
                          <ECTypography
                            variant="body1"
                            color={theme => theme.palette.primary.main}
                          >
                            {option?.label}
                          </ECTypography>
                        </ECMenuItem>
                      );
                    }

                    return (
                      <ECMenuItem
                        {...props}
                        data-name={
                          option?.id === locationFilterOptionsWithAll?.[0]?.id
                            ? 'first'
                            : ''
                        }
                        sx={{ width: '100%', height: '54px' }}
                        key={option?.id || option}
                        id={option?.fieldName || option}
                        disabled={
                          option?.disabled ||
                          (!shouldFetchAllLocations &&
                            activeFilter?.isSelectAllLocations)
                        }
                      >
                        <Checkbox
                          sx={{ p: 0, m: 0, mr: 1 }}
                          checked={
                            state.selected ||
                            (!shouldFetchAllLocations &&
                              activeFilter?.isSelectAllLocations)
                          }
                        />
                        <ListItemText
                          sx={{
                            '& span': {
                              maxWidth: '170px',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            },
                          }}
                          primary={option?.label}
                        />
                        <ECOnlyMeFilter
                          activeFilter={{
                            locations: [
                              {
                                id: option?.id,
                                name: option?.fieldName || option?.name,
                              },
                            ],
                            isSelectAllLocations:
                              activeFilter?.locations?.length == 1,
                            endpoint,
                          }}
                          closePopover={() => {
                            onFilterSubmit?.();
                            setAnchorElLocation(null);
                          }}
                        />
                      </ECMenuItem>
                    );
                  }}
                />
              </ECBox>
            </ECPopover>
          </>
        )}
      </Stack>
    ),
    [
      activeFilter,
      locationFilterOptionsWithAll,
      locationFilterOptions,
      openLocation,
      anchorElLocation,
    ],
  );

  const renderManufacturerFilter = useCallback(
    () => (
      <Stack
        direction="row"
        alignItems="center"
        width="250px"
        height="40px"
        border="1px solid #E0E0E0"
        borderRadius="4px"
        display="flex"
        justifyContent="space-around"
      >
        {manufacturerFilterOptionsWithAll && (
          <>
            <ECTypography
              color={theme => theme.palette.text.secondary}
              variant="body2"
            >
              {isUsersOrJobTitleModule ? 'Companies:' : 'Manufacturer:'}
            </ECTypography>
            <ECBox
              display="flex"
              alignItems="center"
              onClick={handleClickManufacturer}
              ref={manufacturerRef}
            >
              {manufacturerChip}
              <ECIconButton
                type="default"
                size="small"
                noPadding
                sx={{ marginLeft: '5px' }}
              >
                <ArrowDropDown />
              </ECIconButton>
            </ECBox>
            <ECPopover
              id={idManufacturerPopover}
              open={openManufacturer}
              anchorEl={anchorElManufacturer}
              onClose={handleCloseManufacturer}
              anchorOrigin={{
                vertical: 35,
                horizontal: 100,
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              sx={{
                bgcolor: 'transparent',
                '& .MuiPopover-paper': {
                  minWidth: '350px',
                  height: '544px',
                },
              }}
            >
              <ECBox display="flex" bgcolor="white !important" width="350px">
                <ECAutocompleteStyled
                  multiple
                  freeSolo
                  open
                  disablePortal
                  sx={{
                    width: '350px',
                    '&.MuiAutocomplete-popper': {
                      transform: 'translate3d(8px, 53px, 0px) !important',
                    },
                  }}
                  filterOptions={
                    shouldFetchAllManufacturer
                      ? (options, state) => {
                          if (state.inputValue?.length >= 2) {
                            return options?.filter(option =>
                              option?.label
                                ?.toLowerCase()
                                .includes(state.inputValue?.toLowerCase()),
                            );
                          }
                          return options;
                        }
                      : undefined
                  }
                  options={manufacturerFilterOptionsWithAll}
                  value={activeFilter?.manufacturer || []}
                  isOptionEqualToValue={(option, value) =>
                    option?.fieldName ===
                    (value?.fieldName || value?.name || value)
                  }
                  ListboxProps={{
                    sx: {
                      maxHeight: '470px',
                    },
                    onScroll: (event: React.SyntheticEvent) => {
                      const listboxNode = event.currentTarget;
                      if (
                        listboxNode.scrollTop + listboxNode.clientHeight >=
                          listboxNode.scrollHeight - 1 &&
                        !noMoreDataToFetchManufacturer &&
                        !isFetchingManufacturer
                      ) {
                        setPageManufacturer(prevPage => prevPage + 1);
                      }
                    },
                    role: 'list-box',
                  }}
                  disableCloseOnSelect
                  onInputChange={handleInputChangeManufacturer}
                  onChange={handleChangeManufacturer}
                  renderInput={params => (
                    <ECBox display="flex" padding={1} bgcolor="white">
                      <ECTextField
                        {...params}
                        placeholder="Search"
                        sx={{
                          minWidth: 'fit-content !important',
                          padding: '0px !important',
                          '.MuiFilledInput-root': {
                            paddingRight: '0px !important',
                          },
                        }}
                        hiddenLabel
                        variant="filled"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: <></>,
                        }}
                        onKeyDown={handleChangeKeyDown}
                      />
                    </ECBox>
                  )}
                  renderTags={() => {
                    return '';
                  }}
                  PopperComponent={renderPopper}
                  renderOption={(props, option, state) => {
                    if (option?.fieldName === 'all') {
                      return (
                        <ECMenuItem
                          {...props}
                          key={option?.id || option}
                          id={option?.fieldName || option}
                        >
                          <ECTypography
                            variant="body1"
                            color={theme => theme.palette.primary.main}
                          >
                            {activeFilter?.manufacturer?.length ===
                              manufacturerFilterOptions?.length ||
                            (!shouldFetchAllManufacturer &&
                              activeFilter?.isSelectAllManufacturers)
                              ? 'Deselect All'
                              : 'Select All'}
                          </ECTypography>
                        </ECMenuItem>
                      );
                    }

                    if (option.id === -1) {
                      return (
                        <ECMenuItem
                          {...props}
                          key={option?.id || option}
                          id={option?.fieldName || option}
                        >
                          <ECTypography
                            variant="body1"
                            color={theme => theme.palette.primary.main}
                          >
                            {option?.label}
                          </ECTypography>
                        </ECMenuItem>
                      );
                    }

                    if (
                      state.index > 1 &&
                      isUsersOrJobTitleModule &&
                      option.id === userCompany?.id
                    ) {
                      return null; // Do not render this option
                    }

                    return (
                      <ECMenuItem
                        {...props}
                        data-name={
                          option?.id ===
                          manufacturerFilterOptionsWithAll?.[0]?.id
                            ? 'first'
                            : ''
                        }
                        sx={{ width: '100%', height: '54px' }}
                        key={option?.id || option}
                        id={option?.fieldName || option}
                        disabled={option?.disabled}
                      >
                        <Checkbox
                          sx={{ p: 0, m: 0, mr: 1 }}
                          checked={state.selected}
                        />
                        <ListItemText
                          sx={{
                            '& span': {
                              maxWidth: '170px',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            },
                          }}
                          primary={option?.label}
                        />
                        <ECOnlyMeFilter
                          activeFilter={{
                            manufacturer: [
                              {
                                id: option?.id,
                                name: option?.fieldName,
                              },
                            ],
                            isSelectAllManufacturers:
                              activeFilter?.manufacturer?.length == 1,
                            endpoint,
                          }}
                          closePopover={() => {
                            onFilterSubmit?.();
                            setAnchorElManufacturer(null);
                          }}
                        />
                      </ECMenuItem>
                    );
                  }}
                />
              </ECBox>
            </ECPopover>
          </>
        )}
      </Stack>
    ),
    [
      activeFilter,
      manufacturerFilterOptionsWithAll,
      manufacturerFilterOptions,
      openManufacturer,
      anchorElManufacturer,
      isUsersOrJobTitleModule,
      userCompany,
    ],
  );

  const [categoryFilterOpen, setCategoryFilterOpen] = useState<boolean>(false);

  const handleCatergoryFilterOpen = useCallback(() => {
    setCategoryFilterOpen(true);
  }, []);

  const renderCategoryFilter = useCallback(
    () => (
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        width="200px"
        height="40px"
        border="1px solid #E0E0E0"
        borderRadius="4px"
        display="flex"
        justifyContent="space-around"
      >
        {!!categoryFilterOptions?.length && (
          <>
            <ECTypography
              color={theme => theme.palette.text.secondary}
              variant="caption"
            >
              Category:
            </ECTypography>
            <ECSelect
              labelId="mutiple-select-label"
              multiple
              variant="standard"
              open={categoryFilterOpen}
              onOpen={handleCatergoryFilterOpen}
              disableUnderline
              value={categoryFilter}
              displayEmpty
              MenuProps={{
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'right',
                },
                anchorOrigin: {
                  vertical: 40,
                  horizontal: 90,
                },
                // transformOrigin: { vertical: -20, horizontal: -20 }
              }}
              onClose={() => {
                if (categoryFilter.length === 0) {
                  setCategoryFilter(
                    categoryFilterOptions?.map(option => option?.fieldName) ||
                      [],
                  );
                  onFilterSubmit?.();
                }
                setCategoryFilterOpen(false);
              }}
              onChange={e => {
                const value = e.target.value as any;
                if (value[value.length - 1] === 'all') {
                  setCategoryFilter(
                    categoryFilter.length === categoryFilterOptions.length
                      ? []
                      : categoryFilterOptions?.map(option => option?.fieldName),
                  );
                  onFilterSubmit?.();
                  return;
                }
                onFilterSubmit?.();
                setCategoryFilter(value);
              }}
              renderValue={(selected: any) => {
                if (
                  !selected?.length ||
                  selected?.length === categoryFilterOptions?.length
                ) {
                  return (
                    <ECChip
                      sx={{ width: '48px', height: '24px' }}
                      label="All"
                      color="Light Grey"
                    />
                  );
                }

                return (
                  <ECChip
                    sx={{ width: '48px', height: '24px' }}
                    label={selected?.length}
                    color="Dark Blue"
                  />
                );
              }}
            >
              <ECMenuItem value="all">
                <ECTypography
                  variant="body1"
                  color={theme => theme.palette.primary.main}
                >
                  {categoryFilter?.length === categoryFilterOptions?.length
                    ? 'Deselect All'
                    : 'Select All'}
                </ECTypography>
              </ECMenuItem>
              {categoryFilterOptions.map(option => (
                <ECMenuItem key={option?.fieldName} value={option?.fieldName}>
                  <ListItemIcon>
                    <Checkbox
                      checked={categoryFilter.indexOf(option?.fieldName) > -1}
                    />
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      '& span': {
                        maxWidth: '170px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      },
                    }}
                    primary={`${option?.status === 0 ? '(INACTIVE) ' : ''}${option?.label}`}
                  />
                  <ECOnlyMeFilter
                    closePopover={() => {
                      setCategoryFilter([option?.fieldName]);
                      setCategoryFilterOpen(false);
                    }}
                  />
                </ECMenuItem>
              ))}
            </ECSelect>
          </>
        )}
      </Stack>
    ),
    [categoryFilterOptions, categoryFilter, categoryFilterOpen],
  );

  const renderStatusActiveFilter = useCallback(
    () => (
      <Stack
        direction="row"
        spacing={1}
        pr={1.5}
        alignItems="center"
        justifyContent="right"
      >
        <ECSwitch
          id="id-active-status-filter"
          name="name-active-status-filter"
          checked={activeStatusFilter ? true : false}
          value={activeStatusFilter}
          onChange={() => {
            setActiveStatusFilter(!activeStatusFilter);
            onFilterSubmit?.();
          }}
        />
        <ECTypography
          sx={theme => ({
            color: theme.palette.text.primary,
          })}
        >
          {activeStatusFilter
            ? activeStatusFilterActiveLabel
            : activeStatusFilterInactiveLabel}
        </ECTypography>
      </Stack>
    ),
    [
      activeStatusFilter,
      activeStatusFilterActiveLabel,
      activeStatusFilterInactiveLabel,
    ],
  );
  const renderMissedVendorId = useCallback(
    () => (
      <Stack
        direction="row"
        spacing={1}
        pr={1.5}
        alignItems="center"
        justifyContent="right"
      >
        <ECSwitch
          id="id-missed-vendor-id-filter"
          name="name-active-status-filter"
          checked={missingVendorIdFilter}
          value={missingVendorIdFilter}
          onChange={e => {
            setMissingVendorIdFilter(e.target.checked);
            onFilterSubmit?.();
          }}
        />
        <ECTypography
          sx={theme => ({
            color: theme.palette.text.primary,
          })}
        >
          {'Missing Vendor ID'}
        </ECTypography>
      </Stack>
    ),
    [missingVendorIdFilter],
  );

  const renderSelfPerformingFilter = useCallback(
    () => (
      <Stack
        direction="row"
        spacing={1}
        pr={1.5}
        alignItems="center"
        justifyContent="right"
      >
        <ECSwitch
          id="id-self-performing-filter"
          name="name-self-performing-filter"
          checked={selfPerformingFilter ? true : false}
          value={selfPerformingFilter}
          onChange={() => {
            setSelfPerformingFilter(!selfPerformingFilter);
            onFilterSubmit?.();
          }}
        />
        <ECTypography
          sx={theme => ({
            color: theme.palette.text.primary,
          })}
        >
          {selfPerformingFilter ? 'Self Performing' : 'Not Self Performing'}
        </ECTypography>
      </Stack>
    ),
    [selfPerformingFilter],
  );

  const renderAssignedToMeFilter = useCallback(
    () => (
      <Stack
        direction="row"
        spacing={1}
        pr={1.5}
        alignItems="center"
        justifyContent="right"
      >
        <ECSwitch
          id="id-assigned-to-me-filter"
          name="name-assigned-to-me-filter"
          checked={assignedToMeFilter}
          value={assignedToMeFilter}
          onChange={() => {
            setAssignedToMeFilter(!assignedToMeFilter);
            onFilterSubmit?.();
          }}
        />
        <ECTypography
          sx={theme => ({
            color: theme.palette.text.primary,
          })}
        >
          Assigned To Me
        </ECTypography>
      </Stack>
    ),
    [assignedToMeFilter],
  );

  const renderAssignedLocationsFilter = useCallback(
    () => (
      <ECRequirePermission scopes={[P.IgnoreLocationAssignment]}>
        <Stack
          direction="row"
          spacing={1}
          pr={1.5}
          alignItems="center"
          justifyContent="right"
        >
          <ECSwitch
            id="id-assigned-locations-filter"
            name="name-assigned-locations-filter"
            checked={assignedLocationsFilter}
            value={assignedLocationsFilter}
            onChange={() => {
              setAssignedLocationsFilter(!assignedLocationsFilter);
              onFilterSubmit?.();
            }}
          />
          <ECTypography
            sx={theme => ({
              color: theme.palette.text.primary,
            })}
          >
            Assigned Locations
          </ECTypography>
        </Stack>
      </ECRequirePermission>
    ),
    [assignedLocationsFilter],
  );

  const renderAwaitingInvoiceFilter = useCallback(
    () => (
      <Stack
        direction="row"
        spacing={1}
        pr={1.5}
        alignItems="center"
        justifyContent="right"
      >
        <ECSwitch
          id="id-show-awaiting-invoice-filter"
          name="name-awaiting-invoice-filter"
          checked={awaitingInvoiceFilter}
          value={awaitingInvoiceFilter}
          onChange={() => {
            setAwaitingInvoiceFilter(!awaitingInvoiceFilter);
            onFilterSubmit?.();
          }}
        />
        <ECTypography
          sx={theme => ({
            color: theme.palette.text.primary,
          })}
        >
          Awaiting Invoice
        </ECTypography>
      </Stack>
    ),
    [awaitingInvoiceFilter],
  );

  const renderAssetTypeFilter = useCallback(
    () => (
      <Stack
        direction="row"
        spacing={1}
        pr={1.5}
        alignItems="center"
        justifyContent="right"
      >
        <ECSwitch
          id="id-show-asset-type-filter"
          name="name-asset-type-filter"
          checked={assetTypeFilter}
          value={assetTypeFilter}
          onChange={() => {
            setAssetTypeFilter(!assetTypeFilter);
            onFilterSubmit?.();
          }}
        />
        <ECTypography
          sx={theme => ({
            color: theme.palette.text.primary,
          })}
        >
          Assigned Assets
        </ECTypography>
      </Stack>
    ),
    [assetTypeFilter],
  );

  const [anchorElViewColumns, setAnchorElViewColumns] =
    useState<HTMLButtonElement | null>(null);
  const handleViewColumnsClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setAnchorElViewColumns(event.currentTarget);
  };

  const openViewColumns = Boolean(anchorElViewColumns);
  const idViewColumns = openViewColumns ? 'view-columns-popover' : undefined;

  const handleCloseViewColumns = () => {
    setAnchorElViewColumns(null);
  };

  const visibleColFromBE = useMemo(
    () => cols?.filter(col => col.visible),
    [cols],
  );
  const [visibleColumns, setVisibleColumns] = useState<any[]>([]);

  const tableLayoutFilter = useMemo(
    () => allSavedColumnsFilters?.[0],
    [allSavedColumnsFilters],
  );

  const [doSaveFilter] = useSaveFilterMutation();
  const [doUpdateFilter] = useUpdateFilterMutation();

  useEffect(() => {
    if (tableLayoutFilter?.body?.[0]?.visibleColumns) {
      setVisibleColumns(tableLayoutFilter?.body?.[0]?.visibleColumns || []);
    } else {
      setVisibleColumns(visibleColFromBE?.map(col => col.title));
    }
  }, [tableLayoutFilter, visibleColFromBE]);

  const handleToggleColVisibiltyClick = (colTitle: string) => () => {
    const newVisibleColumns = visibleColumns?.includes(colTitle)
      ? visibleColumns?.filter(col => col !== colTitle)
      : [...visibleColumns, colTitle];

    if (
      newVisibleColumns?.length < 2 &&
      !newVisibleColumns?.includes(colTitle)
    ) {
      return;
    }

    if (tableLayoutFilter) {
      doUpdateFilter({
        endpoint,
        filterId: tableLayoutFilter?._id,
        body: {
          name: '',
          body: [
            {
              ...tableLayoutFilter?.body?.[0],
              type: FieldTypes.Text,
              label: '',
              fieldName: '',
              value: '',
              isTableLayout: true,
              visibleColumns: newVisibleColumns,
            },
          ],
        },
      });
    } else {
      doSaveFilter({
        endpoint,
        body: {
          name: '',
          body: [
            {
              type: FieldTypes.Text,
              label: '',
              fieldName: '',
              value: '',
              isTableLayout: true,
              visibleColumns: newVisibleColumns,
            },
          ],
        },
      });
    }
  };

  return (
    <ECDedicatedTableCardContainer
      color={theme => theme.palette.background.default}
      moduleRedirectElement={moduleRedirectElement}
      disableTableContent={disableTableContent}
      headerContent={
        hideSearchHeadContent ? undefined : (
          <ECBox
            color={theme => theme.palette.primary.main}
            width={'100%'}
            alignItems={'flex-start'}
            justifyContent={'space-between'}
            pr={1}
            //height={headerHeight}
            sx={{ marginBottom: '0.5rem' }}
          >
            {!usingGlobalSearch && (
              <ECBox>
                <ECGrid
                  display="flex"
                  justifyContent="flex-end"
                  height={50}
                  pr={1}
                  mb={1}
                  gap={1}
                >
                  {isLoading && (
                    <ECCircularProgress
                      size={'2rem'}
                      sx={{ color: theme => theme.palette.background.default }}
                    />
                  )}
                  {showSelfPerformingFilter && renderSelfPerformingFilter()}
                  {showStatusActiveFilter && renderStatusActiveFilter()}
                  {showMissedVendorIdFilter && renderMissedVendorId()}
                  {showTechNotAssignedToggleFilter && (
                    <ECTechNotAssignedFilter />
                  )}
                  {showAssignedLocationsFilter &&
                    renderAssignedLocationsFilter()}
                  {showAssignedToMeFilter && renderAssignedToMeFilter()}
                  {renderMultiSelectConfirmModal()}
                  {showAwaitingInvoiceFilter && renderAwaitingInvoiceFilter()}
                  {showAssetTypeFilter && renderAssetTypeFilter()}
                  {!hideSimpleSearchBar && (
                    <ECTableSearchFilters
                      cols={cols}
                      showSaveFilterButton={showSaveFilterButton}
                      allSavedFilters={allSavedFilters}
                      hiddenCols={hiddenCols}
                      isAdvancedFilter={!simpleSearch}
                      isSendingRequest={isSendingRequest}
                      hideSaveFilterButton={hideSaveFilterButton}
                    />
                  )}
                  {onExportClick && (
                    <ECBox>
                      <ECIconButton
                        size="medium"
                        withBorder
                        onClick={onExportClick}
                        sx={{ '&:hover': { backgroundColor: 'transparent' } }}
                      >
                        {isSendingFullDataRequest ? (
                          <CircularProgress size={24} />
                        ) : (
                          <Download
                            sx={theme => ({ color: theme.palette.grey[600] })}
                          />
                        )}
                      </ECIconButton>
                    </ECBox>
                  )}
                  {showColumnVisibilityFilter && (
                    <>
                      <ECBox>
                        <ECIconButton
                          size="medium"
                          withBorder
                          onClick={handleViewColumnsClick}
                          sx={theme => ({
                            '&:hover': { backgroundColor: 'transparent' },
                            color: theme.palette.primary.main || 'blue',
                          })}
                        >
                          <ViewColumn />
                        </ECIconButton>
                      </ECBox>
                      <ECPopover
                        id={idViewColumns}
                        open={openViewColumns}
                        anchorEl={anchorElViewColumns}
                        onClose={handleCloseViewColumns}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                      >
                        {visibleColFromBE?.map((col, colIndex) => {
                          const isSelected = visibleColumns?.includes(
                            col?.title,
                          );
                          return (
                            <ECBox
                              key={`${col.fieldName}-${colIndex}`}
                              sx={{ cursor: 'pointer' }}
                              onClick={handleToggleColVisibiltyClick(
                                col?.title,
                              )}
                              p={1}
                              gap={3}
                              display="flex"
                              bgcolor={theme =>
                                isSelected
                                  ? theme.palette.primary
                                      .outlinedHoverBackground
                                  : theme.palette.common.white
                              }
                            >
                              <Check
                                sx={theme => ({
                                  visibility: isSelected ? 'visible' : 'hidden',
                                  color: theme.palette.primary.main,
                                })}
                              />
                              <ECTypography>{col.title}</ECTypography>
                            </ECBox>
                          );
                        })}
                      </ECPopover>
                    </>
                  )}
                  {showActivityButton && (
                    <ECDrawerActivities activityType={activityType} />
                  )}
                  {searchBarCustomElements && searchBarCustomElements}
                </ECGrid>

                <ECGrid
                  display="flex"
                  pr={1}
                  mb={1}
                  justifyContent={'space-between'}
                >
                  <ECGrid display="flex" gap={1}>
                    {showSetByFilter && setByFilterOptions && (
                      <ECSetBy
                        setByOptions={setByFilterOptions}
                        customTitle="Set by"
                      />
                    )}
                    {headerChildren && headerChildren}
                    {multiSelectTable && renderMultiSelectActionButton()}
                  </ECGrid>

                  <ECGrid
                    display="flex"
                    gap={1}
                    flexWrap={'wrap'}
                    justifyContent={'flex-end'}
                  >
                    {showCategoryFilter && renderCategoryFilter()}
                    {showStatusFilter && (
                      <ECStatusFilter
                        statusFilterOptions={statusFilterOptions || []}
                        endpoint={endpoint}
                        onFilterSubmit={onFilterSubmit}
                      />
                    )}
                    {showCustomFilter && (
                      <ECCustomSimpleSelectFilter
                        customSimpleSelectFilterOptions={
                          customSimpleSelectFilterOptions || []
                        }
                        customSimpleSelectFilterLabel={
                          customSimpleSelectFilterLabel || ''
                        }
                        customSimpleSelectFilterName={
                          customSimpleSelectFilterName || ''
                        }
                        endpoint={endpoint}
                        onFilterSubmit={onFilterSubmit}
                      />
                    )}
                    {showManufacturerFilter &&
                      (isUsersOrJobTitleModule
                        ? manufacturerFilterOptions.length > 1
                        : true) &&
                      renderManufacturerFilter()}
                    {showCustomersFilter && <ECCustomersFilter />}
                    {showTechAssginedFilter && <ECTechAssignedFilter />}
                    {showAssetTypeNameFilter && renderAssetTypeNameFilter()}
                    {showAssetGroupSingleFilter && <ECAssetGroupSingleFilter />}
                    {showLocationFilter && renderLocationFilter()}
                    {showEtaFilter && <ECEtaFilter />}
                    {showConceptsFilter && <ECConceptsFilter />}
                    {showDateFilter && renderCreateDateFilter()}
                    {showUserFilter && (
                      <ECUserTableFilter
                        maxSelection={maxSelectionUsersFilter}
                      />
                    )}
                    {showHierarchyFilter && (
                      <ECHierarchyTableFilter
                        shouldReturnEmptyWhenSelectingAll={true}
                      />
                    )}
                  </ECGrid>
                </ECGrid>
                <ECGrid
                  display="flex"
                  gap={1}
                  flexWrap="wrap"
                  justifyContent="space-between"
                  sx={{ width: '100%', marginTop: -2, marginBottom: 1 }}
                >
                  {showCalendarViewFilter && <ECCalendarViewFilter />}
                </ECGrid>
              </ECBox>
            )}
          </ECBox>
        )
      }
      paginationComponent={
        isPaginated && !isEmptyState && !hidePagination ? (
          <ECTablePagination
            nextIconButtonProps={{
              disabled:
                isSendingRequest ||
                (totalResults !== -1 &&
                  currentPage * perPage + perPage >= totalResults),
            }}
            backIconButtonProps={{
              disabled: isSendingRequest || currentPage === 0,
            }}
            count={totalResults}
            onPageChange={(event, page) => {
              onPageRequest?.(page);
            }}
            onRowsPerPageChange={event => {
              onPerPageRequest?.(parseInt(event.target.value));
            }}
            page={currentPage}
            rowsPerPage={perPage}
            component={ECBox}
            sx={{
              display: 'flex',
              opacity: disableTableContent
                ? StyleConstants.OPACITY_DISABLED
                : '',
              pointerEvents: disableTableContent ? 'none' : '',
              ...(hidePerPageSelector && {
                '& .MuiTablePagination-selectLabel': {
                  display: 'none',
                },
                '& .MuiTablePagination-input': {
                  display: 'none',
                },
              }),
            }}
            labelDisplayedRows={({ from, to, count }) => {
              // if count is -1 this means BE is not making total count
              if (count === -1) {
                return `${from}-${to} of many`;
              }

              // this is to make sure when we are filtering and the count is less than the total
              // we show as many and with correct numbers
              if (from > to) {
                return `${from}-${to + from - 1} of many`;
              }

              return `${from}-${to} of ${count}`;
            }}
          />
        ) : null
      }
    >
      {isLoading ? (
        <ECBox
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
          width={'100%'}
          p={4}
        >
          <ECCircularProgress size={'2rem'} />
          <ECTypography ml={2} sx={{ opacity: 0.7 }} variant={'body1'}>
            {t('translation:table.loading')}
          </ECTypography>
        </ECBox>
      ) : (
        <>{children}</>
      )}
    </ECDedicatedTableCardContainer>
  );
};

export const ECEasyTable = (props: ECEasyTableProps) => {
  const { t } = useTranslation();

  const {
    data,
    config,
    clickEvent,
    onDataRequest,
    additionalChildren,
    showStatusActiveFilter,
    hidePagination,
    hidePerPageSelector,
    showMissedVendorIdFilter,
    showCategoryFilter,
    showStatusFilter,
    showCustomFilter,
    customSimpleSelectFilterOptions,
    customSimpleSelectFilterLabel,
    customSimpleSelectFilterName,
    showDateFilter,
    showAssignedToMeFilter,
    showAssignedLocationsFilter,
    showAssetTypeNameFilter,
    useGetAssetTypesFilterQuery,
    showConceptsFilter,
    showAssetGroupSingleFilter,
    showSetByFilter,
    setByFilterOptions,
    showLocationFilter,
    showHierarchyFilter,
    showUserFilter,
    maxSelectionUsersFilter,
    showEtaFilter,
    showTechAssginedFilter,
    showCalendarViewFilter,
    showCustomersFilter,
    showTechNotAssignedToggleFilter,
    showManufacturerFilter,
    userCompany,
    showAwaitingInvoiceFilter,
    showAssetTypeFilter,
    dateRangeDefaultSelectedOption,
    dateFilterFieldsOptions,
    categoryFilterOptions,
    statusFilterOptions,
    assetTypeFilterOptions,
    locationFilterOptions,
    useLocationFilterQuery,
    useManufacturerFilterQuery,
    locationFilterQueryParams,
    manufacturerFilterQueryParams,
    statusFilterInitialSelected,
    activeStatusFilterActiveLabel,
    activeStatusFilterInactiveLabel,
    showSelfPerformingFilter,
    isSendingRequest,
    isLoading,
    onRowPress,
    onDuplicatePress,
    headerChildren,
    onChipClick,
    onChipDelete,
    onSwitchChange,
    onLayout,
    downloadFileName,
    enableExport,
    isEmptyState,
    headerHeight = 80,
    isEditable = false,
    ignoreHyperlink = false,
    moduleRedirectElement,
    usingGlobalSearch,
    headerBackgroundColor,
    onChange,
    onRemove,
    onPageChange,
    onPerPageChange,
    isDraggable,
    onDragEnd,
    renderCustomActionComponent,
    renderCustomAttachmentComponent,
    onClickViewUsersTable,
    hasUsersTablePreviewData,
    onClickRecipientsNotificationEmail,
    onClickViewNotificationEmail,
    onClickRecipientsNotificationMobile,
    onClickViewNotificationMobile,
    onClickRecipientsNotificationText,
    onClickViewNotificationText,
    selectedChipUsersTable,
    marginTop,
    multiSelectTable,
    showSelectAllPageRows = true,
    emptyType,
    multiSelectTableConfig,
    preSelectRowsFieldname,
    onMultiTableSelect,
    customMultiSelectActionButton,
    multiSelectedRows,
    allChecked,
    clearSelection,
    customTableBodyMaxHeight,
    disableTableContent,
    hideSearchHeadContent,
    showColumnVisibilityFilter,
    isDraggableColumns,
    shouldUseSearchParams,
    selectRowsMaxCount,
    shouldNotUseActiveFilter,
    moduleName,
    selectedRows,
    currentQueryParams,
    exportModuleName,
    exportTradeId,
    exportBranchId,
    exportUserId,
    enableTableCsvExport,
    showActivityButton,
    searchBarCustomElements,
    activityType,
    hideSimpleSearchBar,
    hideSaveFilterButton,
    renderTableCustomLink,
    conditionalLinearProgressColor,
    sx,
  } = props;

  const [isFullData, setIsFullData] = useState(false);
  const [tableData, setTableData] = useState<any>(data);
  const tableRef = useRef<any>(null);
  const dispatch = useDispatch();

  const [showExportPopover, setShowExportPopover] = useState(false);
  const [exportAnchorEl, setExportAnchorEl] =
    useState<HTMLButtonElement | null>(null);

  const handleExportClick = (e: any) => {
    if (enableTableCsvExport) {
      setIsFullData(true);
      const params: ECEasyTableDataRequestParamsType = {
        pagination: { perPage: 10000, currentPage: 0 },
      };
      if (config?.sort) {
        params.sort = config.sort;
      }
      onDataRequest?.(params);
    } else {
      setExportAnchorEl(e.currentTarget);
      setShowExportPopover(true);
    }
  };

  const handleDataRequest = params => {
    setIsFullData(false);
    onDataRequest?.(params);
  };

  useEffect(() => {
    if (data && !isSendingRequest) {
      if (isFullData && enableTableCsvExport) {
        const exportData = data?.map(d => {
          let exportDataAccumulator: any = {};
          if (d['id']) {
            exportDataAccumulator['id'] = d['id'];
          }
          return config?.cols.reduce((acc, colConfig) => {
            const exportTitle = colConfig?.exportTitle || colConfig?.title;
            if (colConfig?.visible) {
              if (
                colConfig.type === ColType.ChipsList ||
                colConfig.type === ColType.ChipTooltip
              ) {
                return {
                  ...acc,
                  [`${exportTitle}`]:
                    _.get(d, colConfig.dataPath)
                      ?.map(chipData =>
                        _.get(chipData, colConfig.chipTitleDataPath || 'name'),
                      )
                      ?.join(', ') || '',
                };
              } else if (colConfig.type === ColType.Date) {
                return {
                  ...acc,
                  [`${exportTitle}`]: _.get(d, colConfig.dataPath)
                    ? formatDateForTable(_.get(d, colConfig.dataPath))
                    : '',
                };
              } else if (colConfig.type === ColType.DateOnly) {
                return {
                  ...acc,
                  [`${exportTitle}`]: _.get(d, colConfig.dataPath)
                    ? formatDateOnlyForTable(_.get(d, colConfig.dataPath))
                    : '',
                };
              } else if (colConfig.type === ColType.Boolean) {
                return {
                  ...acc,
                  [`${exportTitle}`]:
                    _.get(d, colConfig.dataPath) === 1 ? 'Active' : 'Inactive',
                };
              } else if (colConfig.type === ColType.HourETA) {
                return {
                  ...acc,
                  [`${exportTitle}`]: formatEtaHours(
                    _.get(d, colConfig.dataPath),
                  ),
                };
              } else if (colConfig.type === ColType.Switch) {
                return {
                  ...acc,
                  [`${exportTitle}`]: _.get(
                    d,
                    colConfig.exportDataPath ?? colConfig.dataPath,
                  ),
                };
              }

              return {
                ...acc,
                [`${exportTitle}`]: _.get(d, colConfig.dataPath),
              };
            }

            return acc;
          }, exportDataAccumulator);
        });
        csvDownload({
          data: exportData,
          delimiter: ',',
          filename: downloadFileName,
        });
      } else {
        setTableData(data);
      }
    }
  }, [data, isSendingRequest]);

  useLayoutEffect(() => {
    onLayout?.(tableRef.current?.clientHeight, tableRef.current?.clientWidth);
  }, [tableData]);

  const [
    doRequestExport,
    {
      data: exportData,
      isError: exportError,
      error: exportErrorDetails,
      isLoading: isExportLoading,
      isSuccess: exportSuccess,
    },
  ] = useRequestExportMutation();

  const handleExportRequest = useCallback(
    (exportType: ExportType, format: ExportFormat) => {
      const updatedQueryParams = { ...currentQueryParams };
      if (exportType === ExportType.ALL) {
        delete updatedQueryParams.t;
        delete updatedQueryParams.p;
      }

      if (exportModuleName) {
        doRequestExport({
          jobName: exportModuleName as
            | 'proposal'
            | 'jobtitle'
            | 'invoice'
            | 'workorder'
            | 'branch'
            | 'asset'
            | 'serviceprovider'
            | 'customer'
            | 'tradeassignmentbytrade'
            | 'tradeassignmentbybranch'
            | 'locationassignment'
            | 'assetrepair'
            | 'trade'
            | 'problem'
            | 'failure',
          exportModule: exportModuleName.toUpperCase() as
            | 'JOBTITLE'
            | 'PROPOSAL'
            | 'INVOICE'
            | 'WORKORDER'
            | 'BRANCH'
            | 'ASSET'
            | 'SERVICEPROVIDER'
            | 'CUSTOMER'
            | 'TRADEASSIGNMENTBYTRADE'
            | 'TRADEASSIGNMENTBYBRANCH'
            | 'LOCATIONASSIGNMENT'
            | 'ASSETREPAIR'
            | 'TRADE'
            | 'PROBLEM'
            | 'FAILURE',
          // if the user selected EXCEL in the dropdown, we add excel param. Otherside send nothing
          queryParams:
            format === ExportFormat.EXCEL
              ? { ...updatedQueryParams, excel: 1 }
              : updatedQueryParams,
          tradeId: exportTradeId,
          branchId: exportBranchId,
          userId: exportUserId,
        });
      } else {
        dispatch(
          setSnackbar({
            severity: 'error',
            message: t('translation:table.export.missingModuleName'),
          }),
        );
      }
    },
    [
      exportModuleName,
      currentQueryParams,
      exportBranchId,
      exportUserId,
      exportTradeId,
    ],
  );

  useEffect(() => {
    setShowExportPopover(false);
    if (exportSuccess) {
      dispatch(
        setSnackbar({
          severity: 'success',
          message: t('translation:table.export.successfulRequest'),
        }),
      );
    } else if (exportError) {
      if (exportErrorDetails) {
        if ('data' in exportErrorDetails) {
          dispatch(
            setSnackbar({
              severity: 'error',
              message: (exportErrorDetails as { data: { message: string } })
                .data?.message,
            }),
          );
        }
      }
    }
  }, [exportError, exportSuccess, exportErrorDetails]);

  if (config) {
    return (
      <div ref={tableRef} style={{ maxWidth: '100%', ...sx }}>
        <ECEasyTableContent
          data={tableData}
          config={config}
          customTableBodyMaxHeight={customTableBodyMaxHeight}
          multiSelectTable={multiSelectTable}
          selectedRowsOnTable={selectedRows}
          showSelectAllPageRows={showSelectAllPageRows}
          emptyType={emptyType}
          multiSelectTableConfig={multiSelectTableConfig}
          hidePagination={hidePagination}
          hidePerPageSelector={hidePerPageSelector}
          selectRowsMaxCount={selectRowsMaxCount}
          disableTableContent={disableTableContent}
          customMultiSelectActionButton={customMultiSelectActionButton}
          multiSelectedRows={multiSelectedRows}
          preSelectRowsFieldname={preSelectRowsFieldname}
          onMultiTableSelect={onMultiTableSelect}
          allChecked={allChecked}
          clearSelection={clearSelection}
          clickEvent={clickEvent}
          onDataRequest={handleDataRequest}
          showStatusActiveFilter={showStatusActiveFilter}
          showMissedVendorIdFilter={showMissedVendorIdFilter}
          showCategoryFilter={showCategoryFilter}
          showStatusFilter={showStatusFilter}
          showCustomFilter={showCustomFilter}
          customSimpleSelectFilterOptions={customSimpleSelectFilterOptions}
          customSimpleSelectFilterLabel={customSimpleSelectFilterLabel}
          customSimpleSelectFilterName={customSimpleSelectFilterName}
          showDateFilter={showDateFilter}
          showAssignedToMeFilter={showAssignedToMeFilter}
          showAssignedLocationsFilter={showAssignedLocationsFilter}
          showAssetTypeNameFilter={showAssetTypeNameFilter}
          showConceptsFilter={showConceptsFilter}
          showAssetGroupSingleFilter={showAssetGroupSingleFilter}
          showSetByFilter={showSetByFilter}
          setByFilterOptions={setByFilterOptions}
          showLocationFilter={showLocationFilter}
          showHierarchyFilter={showHierarchyFilter}
          showUserFilter={showUserFilter}
          maxSelectionUsersFilter={maxSelectionUsersFilter}
          showEtaFilter={showEtaFilter}
          showTechAssginedFilter={showTechAssginedFilter}
          showCalendarViewFilter={showCalendarViewFilter}
          showCustomersFilter={showCustomersFilter}
          showTechNotAssignedToggleFilter={showTechNotAssignedToggleFilter}
          showManufacturerFilter={showManufacturerFilter}
          userCompany={userCompany}
          showAwaitingInvoiceFilter={showAwaitingInvoiceFilter}
          showAssetTypeFilter={showAssetTypeFilter}
          dateRangeDefaultSelectedOption={dateRangeDefaultSelectedOption}
          dateFilterFieldsOptions={dateFilterFieldsOptions}
          categoryFilterOptions={categoryFilterOptions}
          statusFilterOptions={statusFilterOptions}
          assetTypeFilterOptions={assetTypeFilterOptions}
          useGetAssetTypesFilterQuery={useGetAssetTypesFilterQuery}
          locationFilterOptions={locationFilterOptions}
          useLocationFilterQuery={useLocationFilterQuery}
          useManufacturerFilterQuery={useManufacturerFilterQuery}
          locationFilterQueryParams={locationFilterQueryParams}
          manufacturerFilterQueryParams={manufacturerFilterQueryParams}
          statusFilterInitialSelected={statusFilterInitialSelected}
          activeStatusFilterActiveLabel={activeStatusFilterActiveLabel}
          activeStatusFilterInactiveLabel={activeStatusFilterInactiveLabel}
          isSendingRequest={isSendingRequest}
          showColumnVisibilityFilter={showColumnVisibilityFilter}
          isDraggableColumns={isDraggableColumns}
          isSendingFullDataRequest={isSendingRequest && isFullData}
          isLoading={isLoading}
          onRowPress={onRowPress}
          onDuplicatePress={onDuplicatePress}
          renderCustomActionComponent={renderCustomActionComponent}
          renderCustomAttachmentComponent={renderCustomAttachmentComponent}
          onClickViewUsersTable={onClickViewUsersTable}
          hasUsersTablePreviewData={hasUsersTablePreviewData}
          onClickRecipientsNotificationEmail={
            onClickRecipientsNotificationEmail
          }
          onClickViewNotificationEmail={onClickViewNotificationEmail}
          onClickRecipientsNotificationMobile={
            onClickRecipientsNotificationMobile
          }
          onClickViewNotificationMobile={onClickViewNotificationMobile}
          onClickRecipientsNotificationText={onClickRecipientsNotificationText}
          onClickViewNotificationText={onClickViewNotificationText}
          selectedChipUsersTable={selectedChipUsersTable}
          headerChildren={headerChildren}
          onChipClick={onChipClick}
          onChipDelete={onChipDelete}
          onSwitchChange={onSwitchChange}
          onExportClick={
            enableExport ? (e: any) => handleExportClick(e) : undefined
          }
          additionalChildren={additionalChildren}
          isEmptyState={isEmptyState}
          headerHeight={headerHeight}
          isEditable={isEditable}
          ignoreHyperlink={ignoreHyperlink}
          moduleRedirectElement={moduleRedirectElement}
          usingGlobalSearch={usingGlobalSearch}
          headerBackgroundColor={headerBackgroundColor}
          showSelfPerformingFilter={showSelfPerformingFilter}
          onChange={onChange}
          onRemove={onRemove}
          onPageChange={onPageChange}
          onPerPageChange={onPerPageChange}
          isDraggable={isDraggable}
          onDragEnd={onDragEnd}
          marginTop={marginTop}
          hideSearchHeadContent={hideSearchHeadContent}
          shouldUseSearchParams={shouldUseSearchParams}
          shouldNotUseActiveFilter={shouldNotUseActiveFilter}
          moduleName={moduleName}
          showActivityButton={showActivityButton}
          searchBarCustomElements={searchBarCustomElements}
          activityType={activityType}
          hideSimpleSearchBar={hideSimpleSearchBar}
          hideSaveFilterButton={hideSaveFilterButton}
          renderTableCustomLink={renderTableCustomLink}
          conditionalLinearProgressColor={conditionalLinearProgressColor}
        />
        <ECExportPopover
          open={showExportPopover}
          anchorEl={exportAnchorEl}
          onClose={() => setShowExportPopover(false)}
          loading={isExportLoading}
          handleExportRequest={handleExportRequest}
        />
      </div>
    );
  } else {
    return (
      <ECBox
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        width={'100%'}
        p={4}
      >
        <ECCircularProgress size={'2rem'} />
        <ECTypography ml={2} sx={{ opacity: 0.7 }} variant={'body1'}>
          {t('translation:table.loading')}
        </ECTypography>
      </ECBox>
    );
  }
};

const renderEditableTableCell = (
  col: ECEasyTableColsProps,
  index: number,
  row,
  isNestedCell?: boolean,
  onChange?: any,
) => {
  const cellData = _.get(row, col.dataPath);
  if (col.type === ColType.ChipAutocomplete) {
    return (
      <ECTableCell
        key={`${col?.title}-${col?.fieldName}-${row?.id}`}
        scope="row"
        sx={{ minWidth: '10px', ...col.optionalSx }}
      >
        <ECChipAutocomplete
          loading={false}
          options={(col?.options as Chip[]) || []}
          title={col.title}
          values={
            Array.isArray(row[col.dataPath]) && col['chipTitleDataPath']
              ? (row[col.dataPath].map(x => {
                  return {
                    label: x[col['chipTitleDataPath'] || 'name' || 'label'],
                    id: x.id,
                  };
                }) as Chip[])
              : undefined
          }
          getOptionLabel={option => {
            return option?.label || option?.name || option?.text || option;
          }}
          onChange={(newValue, event, action) => {
            onChange?.(newValue, col, row, null, index, action);
          }}
          readOnly={col.disabled}
          key={`${col?.title}-${col?.fieldName}-${row?.id}`}
        />
      </ECTableCell>
    );
  } else if (col.type === ColType.Select) {
    return (
      <ECTableCell
        key={`${col?.title}-${col?.fieldName}-${row?.id}`}
        scope="row"
        sx={{ minWidth: '10px', ...col.optionalSx }}
      >
        <ECSelect
          id={`${col?.title}-${col?.fieldName}-${row?.id}`}
          key={`${col?.title}-${col?.fieldName}-${row?.id}`}
          name={`${col?.title}-${col?.fieldName}-${row?.id}`}
          variant="filled"
          hiddenLabel
          sx={{
            width: '100%',
            '&:focus': {
              borderColor: 'white',
            },
            '&:before': {
              borderColor: 'white',
            },
            '&:after': {
              borderColor: 'white',
            },
          }}
          value={row[col.fieldName]?.[col.dataPath]}
          renderValue={() => {
            return row[col.fieldName]?.[col.dataPath];
          }}
          onChange={event => {
            onChange?.(event.target.value, col, row, null, index);
          }}
        >
          {Array.isArray(col.options) &&
            col.options?.map((option, index) => {
              return (
                <ECMenuItem
                  value={
                    col.optionValues ? col.optionValues[index] : option.label
                  }
                  key={`${option?.title}-${index}`}
                  disabled={option.disabled}
                >
                  {option.label}
                </ECMenuItem>
              );
            })}
        </ECSelect>
      </ECTableCell>
    );
  } else if (col.type === ColType.Currency) {
    return (
      <ECTableCell
        key={`${col?.title}-${col?.fieldName}-${row?.id}`}
        scope="row"
        sx={{ minWidth: '150px', ...col.optionalSx }}
      >
        <ECCurrencyField
          id={col.fieldName}
          value={cellData}
          onChange={(event, value) => {
            onChange?.(value, col, row, null, index);
          }}
          InputProps={{
            disableUnderline: true,
          }}
        />
      </ECTableCell>
    );
  } else if (col.type === ColType.Phone) {
    return (
      <ECTableCell
        key={`${col?.title}-${col?.fieldName}-${row?.id}`}
        scope="row"
        sx={{ minWidth: '150px', ...col.optionalSx }}
      >
        <ECPhoneField
          id={`${col?.title}-${col?.fieldName}-${row?.id}`}
          name={`${col?.title}-${col?.fieldName}-${row?.id}`}
          key={`${col?.title}-${col?.fieldName}-${row?.id}`}
          onChange={e => {
            e.preventDefault();
            onChange?.(e.target.value, col, row, null, index);
          }}
          value={cleanedPhoneNumber(cellData)}
        />
      </ECTableCell>
    );
  } else if (col.type === ColType.DeleteButton) {
    return (
      <ECTableCell
        key={`${col?.title}-${col?.fieldName}-${row?.id}`}
        scope="row"
        sx={{ minWidth: '10px' }}
      >
        <ECIconButton
          onClick={() => onChange?.('delete', col, row, null, index)}
          size="small"
        >
          <DeleteForever color="error" />
        </ECIconButton>
      </ECTableCell>
    );
  } else if (col.type === ColType.Number) {
    return (
      <ECTableCell
        key={`${col?.title}-${col?.fieldName}-${row?.id}`}
        scope="row"
        sx={{ minWidth: '10px', ...col.optionalSx }}
      >
        <ECTextField
          key={`${col?.title}-${col?.fieldName}-${row?.id}`}
          type="number"
          sx={{
            'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button':
              {
                WebkitAppearance: 'none',
                margin: 0,
              },
            'input[type=number]': {
              MozAppearance: 'textfield',
            },
          }}
          onChange={e => {
            e.preventDefault();
            const minValue = col?.minValue || 0;
            const newValue =
              Number(e.target.value) < minValue
                ? minValue
                : Number(e.target.value);
            onChange?.(newValue, col, row, null, index);
            e.currentTarget.focus();
          }}
          value={cellData}
          inputProps={{
            min: col?.minValue || 0,
          }}
        />
      </ECTableCell>
    );
  } else if (index === 0) {
    return (
      <ECTRCell
        sx={{ pl: isNestedCell ? 20 : 2, minWidth: '10px' }}
        key={`${col?.title}-${col?.fieldName}-${row?.id}`}
        scope="row"
      >
        {cellData}
      </ECTRCell>
    );
  } else {
    return (
      <ECTableCell
        key={`${col?.title}-${col?.fieldName}-${row?.id}`}
        scope="row"
        sx={{ minWidth: '10px', ...col.optionalSx }}
      >
        <ECTextField
          key={`${col?.title}-${col?.fieldName}-${row?.id}`}
          onChange={e => {
            e.preventDefault();
            onChange?.(e.target.value, col, row, null, index);
            e.currentTarget.focus();
          }}
          value={cellData}
        />
      </ECTableCell>
    );
  }
};

export const renderLinearProgress = (
  cellData: number,
  row: any,
  conditionalLinearProgressColor?: boolean,
) => {
  if (conditionalLinearProgressColor) {
    const workflowStatusName =
      row?.workflowStatusName || row?.workflowStatus?.name;
    if (cellData === 0) {
      if (workflowStatusName === WorkflowStatus.PendingApproval) {
        return (
          <LinearProgress variant="determinate" value={cellData} color="info" />
        );
      } else if (workflowStatusName === WorkflowStatus.Approved) {
        return (
          <LinearProgress
            variant="determinate"
            value={cellData}
            color="error"
          />
        );
      }
    } else if (cellData > 0 && cellData < 100) {
      return (
        <LinearProgress
          variant="determinate"
          value={cellData}
          color="warning"
        />
      );
    } else if (cellData === 100) {
      return (
        <LinearProgress
          variant="determinate"
          value={cellData}
          color="success"
        />
      );
    }
  }

  return <LinearProgress variant="determinate" value={cellData} />;
};

const renderTableCell = (
  col: ECEasyTableColsProps,
  index: number,
  row,
  parentRow?: any,
  isNestedCell?: boolean,
  onRowPress?: any,
  onDuplicatePress?: any,
  renderCustomActionComponent?: (row: any, parentRow?: any) => React.ReactNode,
  renderCustomAttachmentComponent?: (
    row: any,
    parentRow?: any,
  ) => React.ReactNode,
  onClickViewUsersTable?: (row: any, event: any) => void,
  onClickRecipientsNotificationEmail?: (row: any, event: any) => void,
  onClickViewNotificationEmail?: (row: any, event: any) => void,
  onClickRecipientsNotificationMobile?: (row: any, event: any) => void,
  onClickViewNotificationMobile?: (row: any, event: any) => void,
  onClickRecipientsNotificationText?: (row: any, event: any) => void,
  onClickViewNotificationText?: (row: any, event: any) => void,
  selectedChipUsersTable?: string | number,
  onRemove?: any,
  rowIndex?: number,
  t?: any,
  isSPUser?: boolean,
  ignoreHyperlink?: boolean,
  moduleName?: string,
  hasUsersTablePreviewData?: boolean,
  renderTableCustomLink?: (url: string) => React.ReactNode,
  conditionalLinearProgressColor?: boolean,
) => {
  const cellData = _.get(row, col.dataPath);
  const elementKey = `${col?.title}-${col?.fieldName}-${row?.id}`;

  if (col.showNaIfEmpty && !cellData) {
    return (
      <ECTableCell
        key={elementKey}
        scope="row"
        sx={{ minWidth: '10px', ...col.optionalSx }}
      >
        <ECTypography noWrap>N/A</ECTypography>
      </ECTableCell>
    );
  }
  if (col.type === ColType.Chip) {
    return (
      <ECTRCell key={elementKey} scope="row">
        <ECChip
          key={`colored-chip-${index}`}
          label={_.get(row, col.fieldName)}
          variant="outlined"
          color={row.color}
        />
      </ECTRCell>
    );
  }
  if (col.type === ColType.ChipOptions) {
    const status = _.get(row, col.fieldName);
    return (
      <ECTRCell key={elementKey} scope="row">
        <ECChipOptions
          key={`colored-chip-${index}`}
          label={status?.name || ''}
          color={status?.backgroundColor}
          textColor={status?.textColor}
          variant={status?.variant || 'outlined'}
          borderColor={status?.borderColor}
        />
      </ECTRCell>
    );
  } else if (col.type === ColType.ChipsList) {
    return <ECChipListTableCell key={elementKey} col={col} row={row} />;
  } else if (col.type === ColType.ChipAutocomplete) {
    return <ECChipListTableCell key={elementKey} col={col} row={row} />;
  } else if (col.type === ColType.ChipTooltip) {
    return <ECChipTooltipTableCell key={elementKey} col={col} row={row} />;
  } else if (col.type === ColType.ChipText) {
    return <ECChipTextTableCell key={elementKey} col={col} row={row} />;
  } else if (col.type === ColType.JSONTooltip) {
    return <ECJSONTableCell key={`${index}json`} col={col} row={row} />;
  } else if (col.type === ColType.UsersTable) {
    const shouldDisable =
      !cellData || (Array.isArray(cellData) && cellData.length === 0);

    const previewData =
      hasUsersTablePreviewData &&
      cellData?.length > 0 &&
      cellData.map(data => data.fullName).join(', ');
    return (
      <ECTableCell key={elementKey} sx={{ maxWidth: '650px' }}>
        {cellData ? (
          <ECBox display="flex" alignItems="center">
            {previewData && (
              <ECTypography mr={1}>
                {previewData.length > 50
                  ? `${previewData.slice(0, 50)}...`
                  : previewData}
              </ECTypography>
            )}
            <ECChip
              key={elementKey}
              label="View"
              color={
                selectedChipUsersTable == (!shouldDisable && row?.id)
                  ? themes.light.palette.primary.main
                  : undefined
              }
              textColor="white"
              variant="filled"
              onClick={e => {
                onClickViewUsersTable?.(row, e);
              }}
              disabled={shouldDisable}
            />
          </ECBox>
        ) : (
          <></>
        )}
      </ECTableCell>
    );
  } else if (col.type === ColType.NotificationEmail) {
    return (
      <ECTableCell key={elementKey}>
        {cellData ? (
          <ECBox display="flex" gap={2}>
            <ECChip
              label="Recipients"
              color={
                selectedChipUsersTable === `email-recipients-${row?.id}`
                  ? themes.light.palette.background.tertiary
                  : undefined
              }
              disabled={row?.notification?.email?.recipients?.length === 0}
              textColor="white"
              variant="filled"
              onClick={e => {
                onClickRecipientsNotificationEmail?.(row, e);
              }}
            />

            <ECChip
              label="View"
              color={
                selectedChipUsersTable === `email-view-${row?.id}`
                  ? themes.light.palette.background.tertiary
                  : undefined
              }
              disabled={!row?.notification?.email?.body}
              textColor="white"
              variant="filled"
              onClick={e => {
                onClickViewNotificationEmail?.(row, e);
              }}
            />
          </ECBox>
        ) : (
          <></>
        )}
      </ECTableCell>
    );
  } else if (col.type === ColType.NotificationMobile) {
    return (
      <ECTableCell key={elementKey}>
        {cellData ? (
          <ECBox display="flex" gap={2}>
            <ECChip
              label="Recipients"
              color={
                selectedChipUsersTable === `mobile-recipients-${row?.id}`
                  ? themes.light.palette.background.tertiary
                  : undefined
              }
              disabled={row?.notification?.push?.recipients?.length === 0}
              textColor="white"
              variant="filled"
              onClick={e => {
                onClickRecipientsNotificationMobile?.(row, e);
              }}
            />

            <ECChip
              label="View"
              color={
                selectedChipUsersTable === `mobile-view-${row?.id}`
                  ? themes.light.palette.background.tertiary
                  : undefined
              }
              disabled={
                !row?.notification?.push?.body && row?.notification?.push?.title
              }
              textColor="white"
              variant="filled"
              onClick={e => {
                onClickViewNotificationMobile?.(row, e);
              }}
            />
          </ECBox>
        ) : (
          <></>
        )}
      </ECTableCell>
    );
  } else if (col.type === ColType.NotificationText) {
    return (
      <ECTableCell key={elementKey}>
        {cellData ? (
          <ECBox display="flex" gap={2}>
            <ECChip
              label="Recipients"
              color={
                selectedChipUsersTable === `text-recipients-${row?.id}`
                  ? themes.light.palette.background.tertiary
                  : undefined
              }
              disabled={row?.notification?.sms?.recipients?.length === 0}
              textColor="white"
              variant="filled"
              onClick={e => {
                onClickRecipientsNotificationText?.(row, e);
              }}
            />

            <ECChip
              label="View"
              color={
                selectedChipUsersTable === `text-view-${row?.id}`
                  ? themes.light.palette.background.tertiary
                  : undefined
              }
              disabled={
                !row?.notification?.sms?.body && row?.notification?.sms?.title
              }
              textColor="white"
              variant="filled"
              onClick={e => {
                onClickViewNotificationText?.(row, e);
              }}
            />
          </ECBox>
        ) : (
          <></>
        )}
      </ECTableCell>
    );
  } else if (col.type === ColType.SendingStatus) {
    return (
      <ECTableCell key={elementKey}>
        {cellData ? (
          <ECChip
            key={elementKey}
            label="Sent"
            color="Dark Green"
            variant="outlined"
          />
        ) : (
          <ECChip
            key={elementKey}
            label="Failed"
            color="Red"
            variant="outlined"
          />
        )}
      </ECTableCell>
    );
  } else if (col.type === ColType.Switch) {
    return <ECSwitchTableCell key={elementKey} col={col} row={row} />;
  } else if (col.type === ColType.Link && !ignoreHyperlink) {
    return (
      <ECTableCell key={elementKey} scope="row">
        {!row?.hideDetailLink ? (
          <ECLink
            href={`/panel/${isSPUser ? 'sp/' : ''}${
              row?.hyperLinkModuleName?.includes(col?.moduleName)
                ? row?.hyperLinkModuleName
                : col?.moduleName || row?.moduleName || moduleName
            }/${col?.moduleId ? row[col?.moduleId] : row.id}`}
          >
            {/* TODO: revert these changes and solve the issue on the backend */}
            {/* {cellData} */}
            {!cellData ||
            (typeof cellData === 'string' &&
              (cellData.includes('NaN') || cellData.includes('undefined')))
              ? null
              : typeof cellData === 'string' && cellData.startsWith('WO-')
                ? cellData.slice(3)
                : cellData}
          </ECLink>
        ) : (
          <ECTypography>
            {/* TODO: revert these changes and solve the issue on the backend */}
            {/* {cellData} */}
            {typeof cellData === 'string' && cellData.startsWith('WO-')
              ? cellData.slice(3)
              : cellData}
          </ECTypography>
        )}
      </ECTableCell>
    );
  } else if (col.type === ColType.CustomLink && renderTableCustomLink) {
    return (
      <ECTableCell key={elementKey} scope="row">
        {renderTableCustomLink(cellData)}
      </ECTableCell>
    );
  } else if (col.type === ColType.Date) {
    const isCellDataIncludesInvalidString =
      typeof cellData === 'string' && cellData.includes('Invalid');
    const isInvalidDate =
      typeof cellData === 'string' && !moment(cellData).isValid();

    if (isInvalidDate) {
      return (
        <ECTableCell key={elementKey} scope="row">
          <ECTypography color={theme => theme.palette.graphic.alert.error}>
            {cellData}
          </ECTypography>
        </ECTableCell>
      );
    }

    return (
      <ECTableCell key={elementKey} scope="row">
        {!cellData || isCellDataIncludesInvalidString ? (
          'N/A'
        ) : typeof cellData === 'string' ? (
          <Moment format="MMMM D, YYYY h:mm:ss A">{cellData}</Moment>
        ) : (
          formatDateForTable(cellData)
        )}
      </ECTableCell>
    );
  } else if (col.type === ColType.DateOnly) {
    const isCellDataIncludesInvalidString =
      typeof cellData === 'string' && cellData.includes('Invalid');
    const isInvalidDate =
      typeof cellData === 'string' && !moment(cellData).isValid();

    if (isInvalidDate) {
      return (
        <ECTableCell key={elementKey} scope="row">
          <ECTypography color={theme => theme.palette.graphic.alert.error}>
            {cellData}
          </ECTypography>
        </ECTableCell>
      );
    }

    return (
      <ECTableCell key={elementKey} scope="row">
        {!cellData || isCellDataIncludesInvalidString ? (
          'N/A'
        ) : typeof cellData === 'string' ? (
          <Moment format="MMMM D, YYYY">{cellData}</Moment>
        ) : (
          formatDateOnlyForTable(cellData)
        )}
      </ECTableCell>
    );
  } else if (col.type === ColType.ExpiredableText) {
    const isExpired: boolean = cellData === 'Expired';
    if (isExpired) {
      return (
        <ECTableCell key={elementKey} scope="row">
          <ECTypography color={theme => theme.palette.graphic.alert.error}>
            {cellData}
          </ECTypography>
        </ECTableCell>
      );
    }
    return (
      <ECTableCell key={elementKey} scope="row">
        {_.isNil(cellData) ? 'N/A' : <ECTypography>{cellData}</ECTypography>}
      </ECTableCell>
    );
  } else if (col.type === ColType.Age) {
    return (
      <ECTableCell
        key={elementKey}
        scope="row"
        sx={{ minWidth: '10px', ...col.optionalSx }}
      >
        {formatAgeForTable(cellData)}
      </ECTableCell>
    );
  } else if (col.type === ColType.Phone) {
    return (
      <ECTableCell
        key={elementKey}
        scope="row"
        sx={{ minWidth: '10px', ...col.optionalSx }}
      >
        {formatPhone(cellData)}
      </ECTableCell>
    );
  } else if (col.type === ColType.Boolean) {
    return (
      <ECTableCell key={elementKey} scope="row">
        {cellData === 0 ? 'Inactive ' : 'Active'}
      </ECTableCell>
    );
  } else if (col.type === ColType.Image) {
    return (
      <ECImageTableCell
        src={cellData}
        moduleName={row?.moduleName}
        id={row?.id || row?.assetId}
        key={elementKey}
        profileAttachment={row?.profileAttachment}
      />
    );
  } else if (col.type === ColType.Video) {
    return <ECVideoTableCell src={cellData} key={elementKey} />;
  } else if (col.type === ColType.RichText) {
    return (
      <ECTableCell key={elementKey} scope="row">
        <ECTypography noWrap>
          {cellData
            .replaceAll('&amp;', '&')
            .replaceAll('&amp', '&')
            .replaceAll('&nbsp;', '')
            .replaceAll('&lt;', '<')
            .replaceAll('&gt;', '>')
            .replaceAll('&quot;', '"')
            .replaceAll('&apos;', "'")
            .replace(/<[^>]{1,400}>/g, '')
            .replace(/&lt;[^>]{1,400}&gt;/g, '')}
        </ECTypography>
      </ECTableCell>
    );
  } else if (col.type === ColType.HourETA) {
    return (
      <ECTableCell key={elementKey} scope="row">
        {formatEtaHours(cellData)}
      </ECTableCell>
    );
  } else if (col.type === ColType.Rank) {
    return (
      <ECRankTableCell
        key={elementKey}
        ranks={cellData}
        placeholder={col.placeholder}
      />
    );
  } else if (col.type === ColType.Actions) {
    // in order for custom actions to show
    // the value of the field actions must be ['custom']
    return (
      <ECActionsTableCell
        key={elementKey}
        actions={cellData}
        onEditClick={() => onRowPress?.(row)}
        onDuplicateClick={() => onDuplicatePress?.(row)}
        customActionComponent={renderCustomActionComponent?.(row, parentRow)}
      />
    );
  } else if (col.type === ColType.Attachments) {
    // in order for custom actions to show
    // the value of the field actions must be ['custom']
    return (
      <ECActionsTableCell
        key={elementKey}
        actions={cellData}
        onEditClick={() => onRowPress?.(row)}
        onDuplicateClick={() => onDuplicatePress?.(row)}
        customActionComponent={renderCustomAttachmentComponent?.(
          row,
          parentRow,
        )}
      />
    );
  } else if (col.type === ColType.RemoveButton) {
    return (
      <ECTableCell key={elementKey} scope="row" sx={{ minWidth: '10px' }}>
        <ECButton onClick={() => onRemove?.(rowIndex)} variant="text">
          {t('translation:dynamicForm.remove')}
        </ECButton>
      </ECTableCell>
    );
  } else if (col.type === ColType.Select) {
    return (
      <ECTableCell
        key={elementKey}
        scope="row"
        sx={{ minWidth: '10px', ...col.optionalSx }}
      >
        {row[col.fieldName]?.[col.dataPath]}
      </ECTableCell>
    );
  } else if (col.type === ColType.Currency) {
    return (
      <ECTableCell
        key={elementKey}
        scope="row"
        sx={{ minWidth: '150px', ...col.optionalSx }}
      >
        <ECCurrencyField
          id={col?.fieldName}
          value={cellData as number}
          readOnly
          InputProps={{
            disableUnderline: true,
          }}
        />
      </ECTableCell>
    );
  } else if (col.type === ColType.WarrantyBadge) {
    return (
      <ECTableCell
        key={elementKey}
        scope="row"
        sx={{ minWidth: '10px', ...col.optionalSx }}
      >
        <ECWarrantyBadge warrantyBadgeType={cellData} showExpiredText />
      </ECTableCell>
    );
  } else if (col.type === ColType.LinearProgress) {
    return (
      <ECTableCell
        key={elementKey}
        scope="row"
        sx={{ minWidth: '250px', ...col.optionalSx }}
      >
        <ECBox sx={{ display: 'flex', alignItems: 'center' }}>
          <ECBox sx={{ width: '100%', mr: 1 }}>
            {renderLinearProgress(
              cellData,
              row,
              conditionalLinearProgressColor,
            )}
          </ECBox>
          <ECBox sx={{ minWidth: 35 }}>
            <ECTypography variant="body2" color="text.secondary">{`${Math.round(
              cellData,
            )}%`}</ECTypography>
          </ECBox>
        </ECBox>
      </ECTableCell>
    );
  } else if (col.type === ColType.SingleChip) {
    return (
      <ECTableCell
        key={elementKey}
        scope="row"
        sx={{ minWidth: '150px', ...col.optionalSx }}
      >
        <ECTooltip arrow title={col.showTooltip ? row.tooltipContent : ''}>
          <ECChip
            label={cellData?.label || cellData?.name || ''}
            sx={{
              bgcolor: theme =>
                theme.palette[cellData.severity || 'error']
                  .outlinedHoverBackground,
              color: theme =>
                theme.palette[cellData.severity || 'error'].dark || 'black',
            }}
            variant="filled"
          />
        </ECTooltip>
      </ECTableCell>
    );
  } else if (col.type === ColType.TechAssigned) {
    return (
      <ECTableCell
        key={elementKey}
        scope="row"
        sx={{ minWidth: '150px', ...col.optionalSx }}
      >
        {cellData ? (
          <ECTypography>{cellData}</ECTypography>
        ) : (
          <ECChip
            label="Tech Not Assigned"
            sx={{
              bgcolor: theme => theme.palette.error.outlinedHoverBackground,
              color: theme => theme.palette.error.dark,
            }}
            variant="filled"
          />
        )}
      </ECTableCell>
    );
  } else if (index === 0) {
    return (
      <ECTRCell
        sx={{
          pl: isNestedCell ? 20 : 2,
          minWidth: '10px',
          ...col.optionalSx,
        }}
        key={elementKey}
        scope="row"
      >
        {cellData}
      </ECTRCell>
    );
  } else if (col.fieldName === 'expirationDate') {
    return (
      <ECTableCell
        key={elementKey}
        scope="row"
        sx={{ minWidth: '10px', ...col.optionalSx }}
      >
        <ECBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <ECBox width="85px">{cellData}</ECBox>
          {compareDatesAndShowMessage(cellData, new Date().getTime())}
        </ECBox>
      </ECTableCell>
    );
  } else {
    return (
      <ECTableCell
        key={elementKey}
        scope="row"
        sx={{ minWidth: '10px', ...col.optionalSx }}
      >
        <ECTypography noWrap>{cellData}</ECTypography>
      </ECTableCell>
    );
  }
};

const compareDatesAndShowMessage = (d1, d2) => {
  let date1 = new Date(d1).getTime();
  let date2 = new Date(d2).getTime();

  if (date1 <= date2) {
    return (
      <ECWarrantyBadge
        warrantyBadgeType={WarrantyBadgeTypes.Inactive}
        showExpiredText
      />
    );
  } else {
    return (
      <ECWarrantyBadge
        warrantyBadgeType={WarrantyBadgeTypes.Active}
        showExpiredText
      />
    );
  }
};

const SortableItem = props => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: props.row.id,
    });
  const isSPUser = useServiceProviderUser();
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  const {
    row,
    rowIndex,
    cols,
    isEditable,
    onRowPress,
    onChange,
    onDuplicatePress,
    renderCustomActionComponent,
    renderCustomAttachmentComponent,
    onClickViewUsersTable,
    hasUsersTablePreviewData,
    onClickRecipientsNotificationEmail,
    onClickViewNotificationEmail,
    onClickRecipientsNotificationMobile,
    onClickViewNotificationMobile,
    onClickRecipientsNotificationText,
    onClickViewNotificationText,
    selectedChipUsersTable,
    onRemove,
    ignoreHyperlink,
    tableLayoutFilter,
    t,
  } = props;

  return (
    <ECTableRow
      style={style}
      ref={setNodeRef}
      key={row.id}
      sx={{
        '&:hover': {
          backgroundColor: theme => theme.palette.table.selectedRow,
          cursor: 'pointer',
        },
      }}
      onClick={() => onRowPress?.(row)}
    >
      {cols.map((col, index) => {
        if (
          col.visible &&
          (!tableLayoutFilter?.body?.[0]?.visibleColumns ||
            tableLayoutFilter?.body?.[0]?.visibleColumns?.includes(col.title))
        ) {
          return isEditable && !col.disabled
            ? renderEditableTableCell(col, index, row, false, onChange)
            : renderTableCell(
                col,
                index,
                row,
                false,
                onRowPress,
                onDuplicatePress,
                renderCustomActionComponent,
                renderCustomAttachmentComponent,
                onClickViewUsersTable,
                onClickRecipientsNotificationEmail,
                onClickViewNotificationEmail,
                onClickRecipientsNotificationMobile,
                onClickViewNotificationMobile,
                onClickRecipientsNotificationText,
                onClickViewNotificationText,
                selectedChipUsersTable,
                onRemove,
                rowIndex,
                t,
                isSPUser,
                ignoreHyperlink,
                hasUsersTablePreviewData,
              );
        }
        return null;
      })}
      <ECTableCell
        key={row?.id || `rowIndex-${rowIndex}`}
        scope="row"
        sx={{ minWidth: '10px' }}
      >
        <ECIconButton
          size="small"
          sx={{ color: theme => theme.palette.button.active }}
          {...listeners}
          {...attributes}
        >
          <DragIndicator />
        </ECIconButton>
      </ECTableCell>
    </ECTableRow>
  );
};

const HEADER_COMPONENTS_HEIGHT = 325;
const HEADER_COMPONENTS_HEIGHT_WITH_MARGIN = HEADER_COMPONENTS_HEIGHT - 80;
const PAGE_REQUEST_DEBOUNCE_TIME = 500;

export const ECEasyTableContent = (props: ECEasyTableContentProps) => {
  const {
    config,
    data,
    onDataRequest,
    showStatusActiveFilter,
    showMissedVendorIdFilter,
    showCategoryFilter,
    showStatusFilter,
    showCustomFilter,
    customSimpleSelectFilterOptions,
    customSimpleSelectFilterLabel,
    customSimpleSelectFilterName,
    showDateFilter,
    showAssignedToMeFilter,
    showAssignedLocationsFilter,
    showAssetTypeNameFilter,
    useGetAssetTypesFilterQuery,
    showConceptsFilter,
    showAssetGroupSingleFilter,
    showSetByFilter,
    setByFilterOptions,
    showLocationFilter,
    showHierarchyFilter,
    showUserFilter,
    maxSelectionUsersFilter,
    showEtaFilter,
    showTechAssginedFilter,
    showCalendarViewFilter,
    showCustomersFilter,
    showTechNotAssignedToggleFilter,
    showManufacturerFilter,
    userCompany,
    showAwaitingInvoiceFilter,
    showAssetTypeFilter,
    dateRangeDefaultSelectedOption,
    dateFilterFieldsOptions,
    categoryFilterOptions,
    statusFilterOptions,
    assetTypeFilterOptions,
    locationFilterOptions,
    useLocationFilterQuery,
    useManufacturerFilterQuery,
    locationFilterQueryParams,
    manufacturerFilterQueryParams,
    statusFilterInitialSelected,
    activeStatusFilterActiveLabel,
    activeStatusFilterInactiveLabel,
    showSelfPerformingFilter,
    isSendingRequest,
    isSendingFullDataRequest,
    isLoading,
    onRowPress,
    onDuplicatePress,
    headerChildren,
    onExportClick,
    moduleRedirectElement,
    usingGlobalSearch,
    isEmptyState,
    hidePagination,
    hidePerPageSelector,
    headerHeight,
    isEditable,
    ignoreHyperlink,
    additionalChildren,
    headerBackgroundColor = themes.light.palette.table.table,
    onChange,
    onRemove,
    onPageChange,
    onPerPageChange,
    isDraggable = false,
    onDragEnd,
    renderCustomActionComponent,
    renderCustomAttachmentComponent,
    onClickViewUsersTable,
    hasUsersTablePreviewData,
    onClickRecipientsNotificationEmail,
    onClickViewNotificationEmail,
    onClickRecipientsNotificationMobile,
    onClickViewNotificationMobile,
    onClickRecipientsNotificationText,
    onClickViewNotificationText,
    selectedChipUsersTable,
    marginTop,
    multiSelectTable,
    showSelectAllPageRows = true,
    emptyType,
    multiSelectTableConfig,
    preSelectRowsFieldname,
    onMultiTableSelect,
    customMultiSelectActionButton,
    multiSelectedRows,
    allChecked,
    clearSelection,
    customTableBodyMaxHeight,
    disableTableContent,
    hideSearchHeadContent,
    showColumnVisibilityFilter,
    isDraggableColumns,
    shouldUseSearchParams = true,
    selectRowsMaxCount,
    shouldNotUseActiveFilter,
    moduleName,
    selectedRowsOnTable,
    showActivityButton,
    searchBarCustomElements,
    activityType,
    hideSimpleSearchBar,
    hideSaveFilterButton,
    renderTableCustomLink,
    conditionalLinearProgressColor,
  } = props;

  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const { cols, pagination, sort: initialSort, header } = config;
  const [page, setPage] = useState(
    +(searchParams.get('page') || 0) || pagination?.currentPage || 0,
  );
  const [perPage, setPerPage] = useState(
    +(searchParams.get('perPage') || 0) || pagination?.perPage || 100,
  );

  useEffect(() => {
    setPerPage(pagination?.perPage || 50);
  }, [pagination]);

  const [sort, setSort] = useState<ECEasyTableSortState>(initialSort);
  const [isOpenRowNumber, setIsOpenRowNumber] = useState<number>(-1);
  const [opennedRowId, setOpennedRowId] = useState<number>(-1);
  const [selectedRows, setSelectedRows] = useState<any[]>(
    selectedRowsOnTable || [],
  );
  const { activeFilter: activeFilterFromSelector } = useSelector(
    (state: RootState) => state.page.filter,
  );

  const activeFilter = useMemo(() => {
    return activeFilterFromSelector?.[window.location.href?.split('?')?.[0]];
  }, [window.location.href, activeFilterFromSelector]);

  useEffect(() => {
    setPage(+(searchParams.get('page') || 0));
    setPerPage(+(searchParams.get('perPage') || pagination?.perPage || 50));
  }, [searchParams]);

  useEffect(() => {
    if (clearSelection) {
      setSelectedRows([]);
      onMultiTableSelect?.([]);
      multiSelectedRows?.([]);
    }
  }, [clearSelection]);

  useEffect(() => {
    if (multiSelectTable && preSelectRowsFieldname && data?.length > 0) {
      const rows = data.filter(row => row[preSelectRowsFieldname]);
      if (rows.length === 0) {
        setSelectedRows([]);
      } else {
        setSelectedRows(prev => {
          const newSelectedRows = _.uniqBy([...prev, ...rows], 'id');
          if (!_.isEqual(newSelectedRows, prev)) {
            onMultiTableSelect?.(newSelectedRows);
            multiSelectedRows?.(newSelectedRows);
          }

          return newSelectedRows;
        });
      }
    }
  }, [multiSelectTable, data]);

  useEffect(() => {
    if (allChecked && multiSelectTable) {
      setSelectedRows(prev => {
        if (!_.isEqual(data, prev)) {
          onMultiTableSelect?.(data);
          multiSelectedRows?.(data);
        }

        return data;
      });
    }
  }, [allChecked, data, multiSelectTable]);

  const isSPUser: boolean = useServiceProviderUser();

  const sensors = useSensors(
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 100,
        tolerance: 100,
      },
    }),
    useSensor(MouseSensor, {
      activationConstraint: {
        delay: 100,
        tolerance: 100,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  useEffect(() => {
    const index = data?.findIndex(row => row.id === opennedRowId) ?? -1;
    setIsOpenRowNumber(index);
  }, [data]);

  const requestData = useCallback(
    (
      pageFromParam = page,
      perPageFromParam = perPage,
      sortFromParam = sort,
      filterFromParam = activeFilter,
    ) => {
      const requestParams = {
        pagination: {
          currentPage: pageFromParam,
          perPage: perPageFromParam,
        },
        sort: sortFromParam,
      };
      onDataRequest?.({
        ...requestParams,
        ...filterFromParam,
      });
    },
    [page, perPage, sort, activeFilter, onDataRequest],
  );

  const debouncedRequestData = useRef(
    _.debounce((page, perPage, sort, activeFilter) => {
      requestData(page, perPage, sort, activeFilter);
    }, PAGE_REQUEST_DEBOUNCE_TIME),
  ).current;

  useEffect(() => {
    debouncedRequestData(page, perPage, sort, activeFilter);
  }, [page, perPage, sort, activeFilter]);

  const { endpoint } = useSelector((state: RootState) => state.page.filter);
  /* const { data: allSavedFilters } = useGetAllSavedFiltersQuery(endpoint, {
    skip: !endpoint || shouldNotUseActiveFilter,
  }); */
  const { data: allSavedColumnsFilters } = useGetAllSavedColumnsFiltersQuery(
    endpoint,
    {
      skip: !endpoint || shouldNotUseActiveFilter,
    },
  );

  const tableLayoutFilter = useMemo(
    () => allSavedColumnsFilters?.[0],
    [allSavedColumnsFilters],
  );

  const renderNestedTable = (row, cols, nestedColsDataPath) => {
    return (
      <ECBox key={`${row?.id}-${row.fieldName}-${row.label}`}>
        <ECTable size="small">
          <ECTableHead sx={{ backgroundColor: '#E5E5E5' }}>
            <ECTableRow>
              {cols.map((col, index) => (
                <SortableColumn
                  key={index}
                  index={index}
                  tableLayoutFilter={tableLayoutFilter}
                  headerBackgroundColor={headerBackgroundColor}
                  sort={sort}
                  setSearchParams={setSearchParams}
                  setPage={!shouldUseSearchParams ? setPage : undefined}
                  setSort={setSort}
                  col={col}
                  row={row}
                  isDraggableColumns={isDraggableColumns}
                  setOppnenedRowId={setOpennedRowId}
                />
              ))}
            </ECTableRow>
          </ECTableHead>
          <ECTableBody>
            {row[nestedColsDataPath]?.map((nestedRow, nestedRowIndex) => (
              <ECTableRow
                onClick={() => onRowPress?.({ ...row, ...nestedRow })}
                key={`nested-row-${nestedRowIndex}`}
                sx={() => ({
                  cursor: 'pointer',
                  borderBottom:
                    nestedRowIndex === row[nestedColsDataPath].length - 1
                      ? 11
                      : 1,
                  borderColor: '#E5E5E5',
                })}
              >
                {cols.map((col, colIndex) =>
                  renderTableCell(
                    col,
                    colIndex,
                    nestedRow,
                    row,
                    true,
                    onRowPress,
                    onDuplicatePress,
                    renderCustomActionComponent,
                    renderCustomAttachmentComponent,
                    onClickViewUsersTable,
                    onClickRecipientsNotificationEmail,
                    onClickViewNotificationEmail,
                    onClickRecipientsNotificationMobile,
                    onClickViewNotificationMobile,
                    onClickRecipientsNotificationText,
                    onClickViewNotificationText,
                    selectedChipUsersTable,
                    onRemove,
                    nestedRowIndex,
                    t,
                    isSPUser,
                    ignoreHyperlink,
                    undefined,
                    hasUsersTablePreviewData,
                  ),
                )}
              </ECTableRow>
            ))}
          </ECTableBody>
        </ECTable>
      </ECBox>
    );
  };

  const [columnsOrder, setColumnsOrder] = useState<ECEasyTableColsProps[]>([]);
  const visibleColFromBE = useMemo(
    () => cols?.filter(col => col.visible) || [],
    [cols],
  );

  const hiddenColumns = useMemo(
    () => cols?.filter(col => !col.visible) || [],
    [cols],
  );

  const columns = useMemo(() => {
    return isDraggableColumns ? columnsOrder : visibleColFromBE;
  }, [isDraggableColumns, columnsOrder]);

  const renderDraggableRow = useMemo(() => {
    return isDraggable ? (
      <>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={onDragEnd || (() => false)}
        >
          <SortableContext
            items={data || []}
            strategy={verticalListSortingStrategy}
          >
            {data &&
              data?.map((row, rowIndex) => (
                <React.Fragment key={row?.id || `row-${rowIndex}`}>
                  <SortableItem
                    key={row?.id || `row-${rowIndex}`}
                    row={row}
                    rowIndex={rowIndex}
                    onRowPress={onRowPress}
                    isEditable={isEditable}
                    onChange={onChange}
                    cols={columns}
                    onClickRecipientsNotificationEmail={
                      onClickRecipientsNotificationEmail
                    }
                    onClickViewNotificationEmail={onClickViewNotificationEmail}
                    onClickRecipientsNotificationMobile={
                      onClickRecipientsNotificationMobile
                    }
                    onClickViewNotificationMobile={
                      onClickViewNotificationMobile
                    }
                    onClickRecipientsNotificationText={
                      onClickRecipientsNotificationText
                    }
                    onClickViewNotificationText={onClickViewNotificationText}
                    ignoreHyperlink={ignoreHyperlink}
                    tableLayoutFilter={tableLayoutFilter}
                    t={t}
                  />
                </React.Fragment>
              ))}
          </SortableContext>
        </DndContext>
      </>
    ) : (
      <>
        {data?.map((row, rowIndex) => {
          const collapsibleCol = columns?.find(
            col => col.type === ColType.Collapsible,
          );
          return (
            <React.Fragment key={`${row?.id}-${rowIndex}`}>
              <ECTableRow
                key={`${row?.id}-${rowIndex}`}
                sx={{
                  '&:hover': {
                    backgroundColor: theme => theme.palette.table.selectedRow,
                    cursor: 'pointer',
                  },
                  borderBottom: row.shouldShowDivision ? 11 : 1,
                  borderColor: '#E5E5E5',
                }}
                onClick={() =>
                  columns.find(col => col.type === ColType.Collapsible)
                    ? setIsOpenRowNumber(
                        isOpenRowNumber === rowIndex ? -1 : rowIndex,
                      )
                    : onRowPress?.(row)
                }
              >
                {multiSelectTable && selectedRows && (
                  <ECTRCell
                    key={`${row?.id}-checkbox`}
                    scope="row"
                    sx={{ minWidth: 0, width: 10 }}
                  >
                    <ECCheckbox
                      checked={selectedRows?.some(
                        selectedRow => selectedRow.id === row.id,
                      )}
                      disabled={
                        !!selectRowsMaxCount &&
                        selectedRows.length >= selectRowsMaxCount &&
                        !selectedRows?.some(
                          selectedRow => selectedRow.id === row.id,
                        )
                      }
                      onChange={() => {
                        setSelectedRows(prevSelectedRows => {
                          let newSelectedRows;
                          let isUnselecting = false;
                          if (
                            prevSelectedRows.some(
                              selectedRow => selectedRow.id === row.id,
                            )
                          ) {
                            newSelectedRows = prevSelectedRows.filter(
                              selectedRow => selectedRow.id !== row.id,
                            );
                            isUnselecting = true;
                          } else {
                            newSelectedRows = [...prevSelectedRows, row];
                          }

                          if (!_.isEqual(newSelectedRows, prevSelectedRows)) {
                            onMultiTableSelect?.(
                              newSelectedRows,
                              isUnselecting,
                              row,
                            );
                            multiSelectedRows?.(newSelectedRows);
                          }

                          return newSelectedRows;
                        });
                      }}
                      onClick={e => {
                        e.stopPropagation();
                      }}
                    />
                  </ECTRCell>
                )}
                {columns.map((col, index) => {
                  if (
                    col.visible &&
                    (!tableLayoutFilter?.body?.[0]?.visibleColumns ||
                      tableLayoutFilter?.body?.[0]?.visibleColumns?.includes(
                        col.title,
                      ))
                  ) {
                    if (col.type === ColType.Collapsible) {
                      return (
                        <React.Fragment key={`${col?.title}-${index}`}>
                          <ECTRCell
                            key={`${col?.title}-${index}`}
                            scope="row"
                            sx={{ minWidth: 0, width: 10 }}
                          >
                            <ECIconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() => {
                                setIsOpenRowNumber(
                                  isOpenRowNumber === rowIndex ? -1 : rowIndex,
                                );
                              }}
                            >
                              {isOpenRowNumber === rowIndex ? (
                                <KeyboardArrowUp
                                  sx={theme => ({
                                    color: theme.palette.primary.light,
                                  })}
                                />
                              ) : (
                                <KeyboardArrowDown
                                  sx={theme => ({
                                    color: theme.palette.primary.light,
                                  })}
                                />
                              )}
                            </ECIconButton>
                          </ECTRCell>
                        </React.Fragment>
                      );
                    }
                    return isEditable && !col.disabled
                      ? renderEditableTableCell(
                          col,
                          index,
                          row,
                          false,
                          onChange,
                        )
                      : renderTableCell(
                          col,
                          index,
                          row,
                          {},
                          false,
                          onRowPress,
                          onDuplicatePress,
                          renderCustomActionComponent,
                          renderCustomAttachmentComponent,
                          onClickViewUsersTable,
                          onClickRecipientsNotificationEmail,
                          onClickViewNotificationEmail,
                          onClickRecipientsNotificationMobile,
                          onClickViewNotificationMobile,
                          onClickRecipientsNotificationText,
                          onClickViewNotificationText,
                          selectedChipUsersTable,
                          onRemove,
                          rowIndex,
                          t,
                          isSPUser,
                          ignoreHyperlink,
                          moduleName,
                          hasUsersTablePreviewData,
                          renderTableCustomLink,
                          conditionalLinearProgressColor,
                        );
                  }
                  return null;
                })}
              </ECTableRow>
              {collapsibleCol && (
                <ECTableRow sx={{ backgroundColor: 'transparent' }}>
                  <ECTRCell
                    sx={{ p: 0, height: 'auto !important' }}
                    colSpan={collapsibleCol?.colSpan}
                  >
                    <ECCollapse
                      in={isOpenRowNumber === rowIndex}
                      timeout="auto"
                      unmountOnExit
                    >
                      {renderNestedTable(
                        row,
                        collapsibleCol?.nestedCols,
                        collapsibleCol?.dataPath,
                      )}
                    </ECCollapse>
                  </ECTRCell>
                </ECTableRow>
              )}
            </React.Fragment>
          );
        })}
      </>
    );
  }, [
    props,
    isOpenRowNumber,
    onRowPress,
    onDuplicatePress,
    selectedRows,
    tableLayoutFilter,
    columns,
  ]);

  const [doSaveFilter] = useSaveFilterMutation();
  const [doUpdateFilter] = useUpdateFilterMutation();

  useEffect(() => {
    if (!!visibleColFromBE?.length) {
      setColumnsOrder(
        _.sortBy(visibleColFromBE, col =>
          tableLayoutFilter?.body?.[0]?.columnsOrder?.indexOf(col.title),
        ),
      );
    }
  }, [tableLayoutFilter, visibleColFromBE]);

  const handleDragEndColumn = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over?.id && active.id !== over?.id) {
      const oldIndex = columnsOrder.findIndex(x => x?.title === active.id);
      const newIndex = columnsOrder.findIndex(x => x?.title === over?.id);

      const newColumnsOrder = arrayMove(columnsOrder, oldIndex, newIndex);

      setColumnsOrder(newColumnsOrder);

      if (!endpoint || shouldNotUseActiveFilter) {
        return;
      }

      if (
        newColumnsOrder?.length > 0 &&
        !_.isEqual(
          newColumnsOrder?.map(col => col?.title),
          tableLayoutFilter?.body?.[0]?.columnsOrder,
        )
      ) {
        if (tableLayoutFilter) {
          doUpdateFilter({
            endpoint,
            filterId: tableLayoutFilter?._id,
            body: {
              name: '',
              body: [
                {
                  ...tableLayoutFilter?.body?.[0],
                  type: FieldTypes.Text,
                  label: '',
                  fieldName: '',
                  value: '',
                  isTableLayout: true,
                  columnsOrder: newColumnsOrder?.map(col => col?.title),
                },
              ],
            },
          });
        } else {
          doSaveFilter({
            endpoint,
            body: {
              name: '',
              body: [
                {
                  type: FieldTypes.Text,
                  label: '',
                  fieldName: '',
                  value: '',
                  isTableLayout: true,
                  columnsOrder: newColumnsOrder?.map(col => col?.title),
                },
              ],
            },
          });
        }
      }
    }
  };

  const isEveryDataRowSelected = useMemo(() => {
    return (
      data?.length > 0 &&
      data?.every(row =>
        selectedRows?.some(selectedRow => selectedRow.id === row.id),
      )
    );
  }, [data, selectedRows]);

  const unselectedDataRows = useMemo(() => {
    return (
      (data || [])?.filter(
        row => !selectedRows?.some(selectedRow => selectedRow.id === row.id),
      ) || []
    );
  }, [selectedRows, data]);

  return (
    <>
      <ECEasyTableContainer
        header={header}
        headerChildren={headerChildren}
        pagination={{
          ...pagination,
          perPage,
          currentPage: page,
        }}
        hidePagination={hidePagination}
        hidePerPageSelector={hidePerPageSelector}
        cols={columns}
        hiddenCols={hiddenColumns}
        disableTableContent={disableTableContent}
        isLoading={isLoading}
        multiSelectTable={multiSelectTable}
        showSelectAllPageRows={showSelectAllPageRows}
        emptyType={emptyType}
        multiSelectTableConfig={multiSelectTableConfig}
        selectedRows={selectedRows}
        customMultiSelectActionButton={customMultiSelectActionButton}
        allChecked={allChecked}
        onSelectedRowsAction={() => {
          setSelectedRows([]);
        }}
        isSendingRequest={isSendingRequest}
        showSelfPerformingFilter={showSelfPerformingFilter}
        isSendingFullDataRequest={isSendingFullDataRequest}
        onPageRequest={(page: number) => {
          if (shouldUseSearchParams) {
            setSearchParams(prev => ({
              perPage:
                prev?.get('perPage') || pagination?.perPage.toString() || '50',
              page: page?.toString(),
            }));
          } else {
            setPage(page);
          }
          onPageChange?.(page, perPage);
        }}
        onPerPageRequest={(perPage: number) => {
          if (shouldUseSearchParams) {
            setSearchParams({ page: '0', perPage: perPage?.toString() });
          } else {
            setPage(0);
            setPerPage(perPage);
          }
          onPerPageChange?.(0, perPage);
        }}
        onFilterSubmit={() => {
          if (shouldUseSearchParams) {
            setSearchParams(prev => ({
              perPage:
                prev?.get('perPage') || pagination?.perPage.toString() || '50',
              page: '0',
            }));
          } else {
            setPage(0);
          }
        }}
        showStatusActiveFilter={showStatusActiveFilter}
        showMissedVendorIdFilter={showMissedVendorIdFilter}
        showCategoryFilter={showCategoryFilter}
        showStatusFilter={showStatusFilter}
        showCustomFilter={showCustomFilter}
        customSimpleSelectFilterOptions={customSimpleSelectFilterOptions}
        customSimpleSelectFilterLabel={customSimpleSelectFilterLabel}
        customSimpleSelectFilterName={customSimpleSelectFilterName}
        showDateFilter={showDateFilter}
        showAssignedToMeFilter={showAssignedToMeFilter}
        showAssignedLocationsFilter={showAssignedLocationsFilter}
        showAssetTypeNameFilter={showAssetTypeNameFilter}
        showConceptsFilter={showConceptsFilter}
        showAssetGroupSingleFilter={showAssetGroupSingleFilter}
        setByFilterOptions={setByFilterOptions}
        showSetByFilter={showSetByFilter}
        showLocationFilter={showLocationFilter}
        showHierarchyFilter={showHierarchyFilter}
        showUserFilter={showUserFilter}
        maxSelectionUsersFilter={maxSelectionUsersFilter}
        showEtaFilter={showEtaFilter}
        showTechAssginedFilter={showTechAssginedFilter}
        showCalendarViewFilter={showCalendarViewFilter}
        showCustomersFilter={showCustomersFilter}
        showTechNotAssignedToggleFilter={showTechNotAssignedToggleFilter}
        showManufacturerFilter={showManufacturerFilter}
        userCompany={userCompany}
        showAwaitingInvoiceFilter={showAwaitingInvoiceFilter}
        showAssetTypeFilter={showAssetTypeFilter}
        dateRangeDefaultSelectedOption={dateRangeDefaultSelectedOption}
        dateFilterFieldsOptions={dateFilterFieldsOptions}
        categoryFilterOptions={categoryFilterOptions}
        statusFilterOptions={statusFilterOptions}
        assetTypeFilterOptions={assetTypeFilterOptions}
        locationFilterOptions={locationFilterOptions}
        useGetAssetTypesFilterQuery={useGetAssetTypesFilterQuery}
        useLocationFilterQuery={useLocationFilterQuery}
        useManufacturerFilterQuery={useManufacturerFilterQuery}
        locationFilterQueryParams={locationFilterQueryParams}
        manufacturerFilterQueryParams={manufacturerFilterQueryParams}
        statusFilterInitialSelected={statusFilterInitialSelected}
        activeStatusFilterActiveLabel={activeStatusFilterActiveLabel}
        activeStatusFilterInactiveLabel={activeStatusFilterInactiveLabel}
        usingGlobalSearch={usingGlobalSearch}
        moduleRedirectElement={moduleRedirectElement}
        onExportClick={onExportClick}
        isEmptyState={isEmptyState}
        headerHeight={headerHeight || 80}
        hideSearchHeadContent={hideSearchHeadContent}
        showColumnVisibilityFilter={showColumnVisibilityFilter}
        isDraggableColumns={isDraggableColumns}
        shouldUseSearchParams={shouldUseSearchParams}
        shouldNotUseActiveFilter={shouldNotUseActiveFilter}
        moduleName={moduleName}
        showActivityButton={showActivityButton}
        searchBarCustomElements={searchBarCustomElements}
        activityType={activityType}
        hideSimpleSearchBar={hideSimpleSearchBar}
        hideSaveFilterButton={hideSaveFilterButton}
      >
        {!isEmptyState && (
          <ECTableContainer
            // this defines the height of table body
            sx={{
              maxHeight:
                customTableBodyMaxHeight ||
                window.innerHeight -
                  (marginTop
                    ? HEADER_COMPONENTS_HEIGHT_WITH_MARGIN
                    : HEADER_COMPONENTS_HEIGHT),
            }}
          >
            {additionalChildren}

            <ECTable stickyHeader aria-label="simple table">
              <ECTableHead
                sx={theme => ({
                  borderRadius: '1rem 0 0 0',
                })}
              >
                <ECTableRow sx={{ height: 26 }}>
                  {multiSelectTable && (
                    <ECTHCell
                      sx={{
                        bgcolor: headerBackgroundColor
                          ? headerBackgroundColor
                          : theme => theme.palette.grey[300],
                      }}
                      key={`select-all-checkbox`}
                      align="left"
                    >
                      {showSelectAllPageRows && (
                        <ECCheckbox
                          // every element in the data array is selected
                          checked={isEveryDataRowSelected}
                          disabled={
                            selectRowsMaxCount
                              ? !isEveryDataRowSelected &&
                                (selectedRows?.length + data?.length >
                                  selectRowsMaxCount ||
                                  selectedRows?.length >= selectRowsMaxCount ||
                                  selectRowsMaxCount -
                                    unselectedDataRows?.length <
                                    (data?.length ?? 0) -
                                      unselectedDataRows?.length)
                              : false
                          }
                          indeterminate={
                            unselectedDataRows?.length > 0 &&
                            unselectedDataRows?.length < data?.length
                          }
                          onChange={() => {
                            let newSelectedRows;
                            let isUnselecting = false;
                            if (isEveryDataRowSelected) {
                              newSelectedRows = selectedRows?.filter(
                                sr =>
                                  !data?.some(dataRow => dataRow.id === sr.id),
                              );
                              isUnselecting = true;
                              setSelectedRows(newSelectedRows);
                              onMultiTableSelect?.(
                                newSelectedRows,
                                isUnselecting,
                              );
                              multiSelectedRows?.(newSelectedRows);
                            } else {
                              newSelectedRows = [
                                ...selectedRows,
                                ...unselectedDataRows,
                              ];
                              setSelectedRows(newSelectedRows);
                              onMultiTableSelect?.(
                                newSelectedRows,
                                isUnselecting,
                              );
                              multiSelectedRows?.(newSelectedRows);
                            }
                          }}
                        />
                      )}
                    </ECTHCell>
                  )}
                  <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEndColumn || (() => false)}
                  >
                    <SortableContext
                      items={
                        columns?.map(col => ({
                          ...col,
                          id: col.title,
                        })) || []
                      }
                      strategy={horizontalListSortingStrategy}
                    >
                      {columns?.map((col, index) => {
                        return (
                          <SortableColumn
                            key={index}
                            index={index}
                            tableLayoutFilter={tableLayoutFilter}
                            headerBackgroundColor={headerBackgroundColor}
                            sort={sort}
                            setSearchParams={setSearchParams}
                            setPage={
                              !shouldUseSearchParams ? setPage : undefined
                            }
                            row={undefined}
                            setSort={setSort}
                            col={col}
                            isDraggableColumns={isDraggableColumns}
                            setOppnenedRowId={setOpennedRowId}
                          />
                        );
                      })}
                    </SortableContext>
                  </DndContext>
                </ECTableRow>
              </ECTableHead>
              <ECTableBody>{renderDraggableRow}</ECTableBody>
            </ECTable>
          </ECTableContainer>
        )}
      </ECEasyTableContainer>
      {isEmptyState && <EmptyStatePage emptyType={emptyType} />}
    </>
  );
};

const SortableColumn = ({
  col,
  tableLayoutFilter,
  headerBackgroundColor,
  sort,
  setSearchParams,
  setPage,
  setSort,
  index,
  isDraggableColumns,
  setOppnenedRowId,
  row,
}) => {
  const {
    attributes,
    listeners,
    isDragging,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id: col.title,
  });

  const style = {
    transform: transform
      ? CSS.Transform.toString({
          ...transform,
          scaleX: 1,
          scaleY: 1,
        })
      : undefined,
    transition,
    zIndex: isDragging ? 1000 : undefined,
  };

  if (
    col.visible &&
    (!tableLayoutFilter?.body?.[0]?.visibleColumns ||
      tableLayoutFilter?.body?.[0]?.visibleColumns?.includes(col.title))
  ) {
    return (
      <ECTHCell
        style={style}
        ref={setNodeRef}
        sx={{
          bgcolor: headerBackgroundColor
            ? headerBackgroundColor
            : theme => theme.palette.grey[300],
        }}
        key={`${col?.title}-${index}`}
        align="left"
      >
        <ECBox
          {...(isDraggableColumns && listeners)}
          {...(isDraggableColumns && attributes)}
          display={'flex'}
          flexDirection={'column'}
        >
          <ECBox
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            sx={{
              '&:hover': {
                '.drag-indicator': {
                  display: 'inline-block',
                },
              },
            }}
          >
            {isDraggableColumns && (
              <DragIndicator
                className="drag-indicator"
                sx={{
                  cursor: isDragging ? 'grabbing' : 'grab',
                  display: 'none',
                  color: theme => theme.palette.button.active,
                }}
              />
            )}
            <ECTypography
              variant={'body2'}
              sx={{
                fontVariantCaps: 'normal',
                fontWeight: 'bold',
                fontSize: 14,
                color: theme => theme.palette.text.primary,
              }}
            >
              {col.title}
            </ECTypography>{' '}
            {col.sortable && (
              <ECTableSortLabel
                sx={theme => ({
                  '&.MuiTableSortLabel-root': {
                    color: theme.palette.button.active,
                  },
                  '&.MuiTableSortLabel-root:hover': {
                    color: theme.palette.button.active,
                  },
                  '&.Mui-active': {
                    color: theme.palette.button.active,
                  },
                  '& .MuiTableSortLabel-icon': {
                    color: theme.palette.button.active,
                  },
                })}
                active={sort.fieldName === col.fieldName}
                direction={sort?.value === 'DESC' ? 'desc' : 'asc'}
                onMouseUp={() => {
                  const fieldName = col.sortAlias || col.alias || col.fieldName;
                  setSort({
                    fieldName,
                    value:
                      sort.fieldName === fieldName
                        ? sort.value === 'DESC'
                          ? 'ASC'
                          : 'DESC'
                        : 'DESC',
                  });
                  if (setPage) {
                    setPage(0);
                  } else {
                    setSearchParams(prev => ({
                      perPage: prev?.get('perPage') || '50',
                      page: '0',
                    }));
                    setOppnenedRowId(row?.id ?? -1);
                  }
                }}
              />
            )}
          </ECBox>
        </ECBox>
      </ECTHCell>
    );
  }

  return null;
};
