import Link, { LinkProps } from '@mui/material/Link';
import { styled as MUIStyled } from '@mui/material/styles';
import { ECTypography } from '../ECTypography';
import { Link as RouterLink } from 'react-router-dom';
import React from 'react';
import { themes } from 'styles/theme/themes';
import styled from 'styled-components';

const RouterLinkStyled = styled(RouterLink)`
  text-decoration: none;
  color: ${themes.light.palette.text.links};
  font-family: ${themes.light.typography.fontFamily};
  &:hover {
    text-decoration: underline;
  }
`;

export const LinkStyled = MUIStyled(Link)<LinkProps>(({ theme }) => ({}));

interface ECLinkProps extends LinkProps {
  startIcon?: React.ReactElement;
  endIcon?: React.ReactElement;
  children: React.ReactNode;
  notClickable?: boolean;
}

export const ECLink = ({
  startIcon,
  endIcon,
  children,
  notClickable,
  sx,
  ...props
}: ECLinkProps) => {
  if (
    !notClickable &&
    (!props.href ||
      props.href?.startsWith('http') ||
      props.href?.startsWith('blob'))
  ) {
    return (
      <Link
        fontFamily={theme => theme.typography.fontFamily}
        sx={{ color: theme => theme.palette.text.links, ...sx }}
        underline="hover"
        {...props}
      >
        {!!startIcon &&
          React.cloneElement(startIcon, {
            sx: [startIcon.props?.sx, { mr: 1 }],
          })}
        {children}
        {!!endIcon &&
          React.cloneElement(endIcon, {
            sx: [endIcon.props?.sx, { ml: 1 }],
          })}
      </Link>
    );
  }

  return (
    <>
      {!notClickable ? (
        <Link
          component={RouterLinkStyled}
          to={props.href as string}
          sx={sx}
          {...props}
        >
          {!!startIcon &&
            React.cloneElement(startIcon, {
              sx: [startIcon.props?.sx, { mr: 1 }],
            })}
          {children}
          {!!endIcon &&
            React.cloneElement(endIcon, {
              sx: [endIcon.props?.sx, { ml: 1 }],
            })}
        </Link>
      ) : (
        <ECTypography sx={sx}>
          {!!startIcon &&
            React.cloneElement(startIcon, {
              sx: [startIcon.props?.sx, { mr: 1 }],
            })}
          {children}
          {!!endIcon &&
            React.cloneElement(endIcon, {
              sx: [endIcon.props?.sx, { ml: 1 }],
            })}
        </ECTypography>
      )}
    </>
  );
};
