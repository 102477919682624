import {
  ECBox,
  ECTabContainer,
  ECTypography,
  NAV_BAR_HEIGHT_PX,
} from 'app/components';
import { useTranslation } from 'react-i18next';
import { OnboardingPage } from '../OnboardingPage';
import { DashboardPage } from '../DashboardPage';
import { useGetUserProfileQuery } from 'services/userProfileApi';
import { useCallback, useEffect, useMemo, useState } from 'react';

function getTodaysDateLongFormat() {
  const today = new Date();

  // Get the day of the week
  const weekday = new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(
    today,
  );

  // Get the month name
  const month = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(
    today,
  );

  // Get the day number
  const day = today.getDate();

  // Get the year
  const year = today.getFullYear();

  // Function to get the ordinal suffix (st, nd, rd, th)
  function getOrdinalSuffix(day) {
    if (day > 3 && day < 21) return 'th'; // Covers 4-20
    switch (day % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  }

  const dayWithSuffix = `${day}${getOrdinalSuffix(day)}`;

  return `Today is ${weekday}, ${month} ${dayWithSuffix} ${year}`;
}

export function HomePage() {
  const { t } = useTranslation();

  const [selectedTab, setSelectedTab] = useState('onboarding');
  const { data: userProfile } = useGetUserProfileQuery();
  const userFullName = userProfile?.fullName;

  /* const handleOnChangeTab = useCallback(tab => {
    setSelectedTab(tab);
    localStorage.setItem('selectedHomePageTab', tab);
  }, []); */

  const headerChildren = useMemo(() => {
    if (selectedTab === 'dashboard') {
      return (
        <ECBox display={'flex'} flexWrap={'wrap'}>
          <ECTypography fontSize={'24px'}>Welcome to Ecotrak 👋</ECTypography>
          <ECTypography fontSize={'24px'} fontWeight="bold">
            {getTodaysDateLongFormat()}
          </ECTypography>
        </ECBox>
      );
    }

    return (
      <ECTypography
        sx={{
          fontSize: '1.75rem',
          fontWeight: 'bold',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '100%',
          display: 'block',
        }}
        px={3}
        variant="h2"
        fontWeight="bold"
      >
        {userFullName
          ? `Hi ${userFullName}, Welcome to Ecotrak!`
          : 'Welcome to Ecotrak!'}
      </ECTypography>
    );
  }, [userFullName, selectedTab]);

  // should read last tab selection from local storage
  /* useEffect(() => {
    const localSelectedTab = localStorage.getItem('selectedHomePageTab');
    if (localSelectedTab) {
      setSelectedTab(localSelectedTab);
    }
  }, []); */

  return (
    <ECBox mt={`${NAV_BAR_HEIGHT_PX}px`} pt={3}>
      {headerChildren}
      <DashboardPage />
      {/* <ECTabContainer
        variant="standard"
        type="contained"
        noPadding
        onChangeSelectedTab={handleOnChangeTab}
        children={headerChildren}
        boxSx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100% !important',
          px: 3,
        }}
        tabFlexGrow="0 !important"
        selectedTab={selectedTab}
        tabs={[
          {
            value: 'onboarding',
            label: t('translation:panelDrawer.onboarding'),
            content: <OnboardingPage />,
          },
          {
            value: 'dashboard',
            label: t('translation:panelDrawer.dashboard'),
            content: <DashboardPage />,
          },
        ]}
      /> */}
    </ECBox>
  );
}
