import { useCallback, useEffect, useState } from 'react';
import { ECBox, ECEasyForm, ECEasyFormFieldType } from 'app/components';
import _ from 'lodash';
import { useMediaQuery, useTheme } from '@mui/material';
import { isValidEmail } from 'utils/validate-fields';
import {
  useLazyCompanyExistsQuery,
  useLazyGetEmailExistsQuery,
} from 'services/authApi';
import { useTranslation } from 'react-i18next';

interface Step2Props {
  existingData?: any;
  onSubmit?: (data: any, output: any) => void;
}

const fancyFormElementsCreate = require('./fancy_form_config_step2_create.json');

export const Step2 = ({ existingData, onSubmit }: Step2Props) => {
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation();

  const handleExistingData = useCallback(
    (field: ECEasyFormFieldType) => {
      if (!field) return;

      if (field.type === 'group') {
        return {
          ...field,
          subFields: field?.subFields?.map(handleExistingData),
        };
      }
      return {
        ...field,
        value: _.get(existingData, field.fieldName) || '',
      };
    },
    [existingData],
  );

  const [formFields, setFormFields] = useState<any[]>(
    () => fancyFormElementsCreate.fields?.map(handleExistingData) || [],
  );

  useEffect(() => {
    setFormFields(
      fancyFormElementsCreate.fields?.map(handleExistingData) || [],
    );
  }, [existingData, handleExistingData]);

  const [triggerEmail] = useLazyGetEmailExistsQuery({});

  const handleBlurEmail = async (value: string, field: any) => {
    if (!isValidEmail(value)) {
      field.isValid = false;
      field.validationMessage = t(
        'translation:form.validation.correctFormatEmail',
        {
          name: field.placeholder,
        },
      );
    } else {
      field.isValid = true;
      field.validationMessage = null;
      const responseUserExists = await triggerEmail({
        email: value,
        checkCompany: true,
      });
      if (responseUserExists?.data?.existed) {
        field.isValid = false;
        field.validationMessage = `Email already exists. Please try again.`;
      }
    }
  };

  const [triggerCompanyName] = useLazyCompanyExistsQuery({});

  const handleBlurCompanyName = async (value: string, field: any) => {
    field.isValid = true;
    field.validationMessage = null;
    const responseComapnyExists = await triggerCompanyName({
      name: value,
    });
    if (responseComapnyExists?.data?.existed) {
      field.isValid = false;
      field.validationMessage = `Name already exists. Please try again.`;
    }
  };

  useEffect(() => {
    const emailField = formFields
      .find(field => field.label === 'contacts')
      ?.subFields?.find(field => field.fieldName === 'company.email');

    emailField.onBlur = handleBlurEmail;

    const companyField = formFields
      .find(field => field.label === 'company')
      ?.subFields?.find(field => field.fieldName === 'company.name');

    companyField.onBlur = handleBlurCompanyName;

    setFormFields([...formFields]);
  }, []);

  const submitForm = useCallback(
    (formData, output) => {
      const body = {
        name: formData.get('company.name'),
        phone: formData.get('company.phone').trim().replace(/\D/g, ''),
        email: formData.get('company.email'),
        files: formData.get('company.files'),
        address: {
          line1: formData.get('address.line1'),
          line2: formData.get('address.line2'),
          cityName: formData.get('address.cityName'),
          stateProvinceCode: formData.get('address.stateProvinceCode'),
          zipCodePostalCode: formData.get('address.zipCodePostalCode'),
          countryCode: output.find(
            field => field.fieldName === 'address.countryCode',
          )?.value,
        },
      };

      onSubmit?.(
        {
          company: {
            ...body,
          },
        },
        output,
      );
    },
    [formFields],
  );

  return (
    <ECBox
      paddingInline={isMobileSize ? 0 : `15%`}
      alignItems={'center'}
      justifyContent={'center'}
      display={'flex'}
      flexDirection={'column'}
    >
      <ECEasyForm
        isLoadingForm={false}
        isSendingData={false}
        config={fancyFormElementsCreate.config}
        fields={formFields}
        existingData={existingData}
        showSaveButton={false}
        onFormSubmit={(formData, output) => {
          submitForm(formData, output);
        }}
        showWideSaveButton
      />
    </ECBox>
  );
};
