import { ECMainTabBox } from 'app/components';
import { ECCircularProgress } from '../../components/ECCircularProgress';
import { hasPermission } from 'utils/permissions';
import { P } from 'types/Permission';
import { useMatch, useNavigate } from 'react-router';
import { useGetUserProfileQuery } from 'services/userProfileApi';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useGetProfileQuery } from 'services/profileApi';

export function DecideUserPage() {
  const navigate = useNavigate();
  const match = useMatch('/panel/*');
  const { state } = useLocation();
  const { data: userProfile, isSuccess } = useGetUserProfileQuery();
  const { data: companyProfile } = useGetProfileQuery();

  localStorage.setItem('ignoreCacheOnceForCompanyProfile', '1');
  const customerType = useMemo(() => {
    switch (userProfile?.subscriptionScoreCompany) {
      case 0:
        return 'Build';
      case 1:
      default:
        return 'Grow';
      case 2:
        return 'Scale';
    }
  }, [userProfile]);

  useEffect(() => {
    if (userProfile && userProfile.userId && companyProfile) {
      // @ts-ignore
      pendo.initialize({
        visitor: {
          id: userProfile.userId,
          email: userProfile.email,
          firstName: userProfile.firstName,
          lastName: userProfile.lastName,
          createdAt: userProfile.createdAt,
          full_name: userProfile.fullName,
          timezone: userProfile.timezone,
          job_title_id: userProfile.jobTitle?.id,
          job_title_name: userProfile.jobTitle?.name,
        },

        account: {
          id: userProfile.company?.id,
          company_name: userProfile.company?.name,
          company_type: userProfile.company?.companyType.id,
          company_type_name: userProfile.company?.companyType.name,
          customerType,
          parentId: userProfile.company?.parentId,
          spTypeId: userProfile.company?.spProfile?.spTypeId,
          spTypeName: userProfile.company?.spProfile?.spType?.name,
          internal: !!userProfile.company?.spProfile?.internal,
          industryId: userProfile.company?.industryId,
          enableCoupa: !!companyProfile?.customerProfile?.enableCoupa,
          invoiceCreditEnabled:
            !!companyProfile?.customerProfile?.invoiceCreditEnabled,
          enableDispatch: !!companyProfile?.customerProfile?.enableDispatch,
          enableInventory: !!companyProfile?.customerProfile?.enableInventory,
          enableArea: !!companyProfile?.customerProfile?.enableArea,
          enableBudget: !!companyProfile?.customerProfile?.enableBudget,
          workorderSurvey: companyProfile?.workorderSurvey,
          advanceMobileDashboard: companyProfile?.advanceMobileDashboard,
          assignOnCall: companyProfile?.assignOnCall,
        },
      });
    }
  }, [
    userProfile?.userId,
    userProfile?.company?.id,
    companyProfile,
    userProfile,
  ]);

  useEffect(() => {
    if (
      isSuccess &&
      Array.isArray(userProfile?.permissions) &&
      companyProfile
    ) {
      const hasSPMenuPermission = hasPermission({
        userPermissions: userProfile?.permissions?.map(p => p.action),
        scopes: [P.SPMenu],
      });
      const hasGetAllCustomerPermission = hasPermission({
        userPermissions: userProfile?.permissions?.map(p => p.action),
        scopes: [P.GetAllCustomers],
      });

      if (hasSPMenuPermission) {
        if ((state as any)?.redirectedFrom) {
          navigate(`../${(state as any)?.redirectedFrom}`);
          return;
        }

        navigate(`../panel/sp/work-orders`);
      } else {
        if ((state as any)?.redirectedFrom) {
          navigate(`../${(state as any)?.redirectedFrom}`);
          return;
        }

        navigate(`../panel/dashboard`);
      }
    }
  }, [userProfile, isSuccess, match?.pathnameBase, navigate, companyProfile]);

  return (
    <>
      <ECMainTabBox
        component={'main'}
        sx={{
          display: 'flex',
          paddingTop: '33vh',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ECCircularProgress size={'8rem'} />
      </ECMainTabBox>
    </>
  );
}
