import { ECBox } from 'app/components/ECBox';
import { ECStepsContainer } from 'app/components/ECSteps';
import { ECTypography } from 'app/components/ECTypography';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Step2 } from './step-2';
import { Step1 } from './step-1';
import { themes } from 'styles/theme/themes';
import { StyleConstants } from 'styles/StyleConstants';

interface EditProps {
  onClose?: () => void;
}

export const NewStandAloneProposalForm = ({ onClose }: EditProps) => {
  const { t } = useTranslation();

  const [data, setData] = useState({});
  const [currentStep, setCurrentStep] = useState(1);

  const renderTabs = () => [
    {
      value: 1,
      label: '1',
      content: (
        <Step1
          onClose={onClose}
          existingData={data}
          onSubmit={data => {
            setData(data);
            setCurrentStep(2);
          }}
        />
      ),
    },
    {
      value: 2,
      label: '2',
      content: <Step2 existingData={data} onClose={onClose} />,
    },
  ];

  return (
    <ECBox
      display="flex"
      position="relative"
      height="100%"
      flexDirection="column"
    >
      <ECBox
        display="flex"
        justifyContent="start"
        px={4}
        height={StyleConstants.DRAWER_TITLE_HEIGHT}
        bgcolor={themes.light.palette.other.divider}
      >
        <ECTypography fontWeight="bold" variant="h4">
          Add Proposal
        </ECTypography>
      </ECBox>

      <ECStepsContainer
        stepsContainerProps={{
          px: 4,
          pt: 4,
        }}
        tabs={renderTabs()}
        currentStep={currentStep}
        onChangeStep={setCurrentStep}
        selectableSteps
      />
    </ECBox>
  );
};
