import * as React from 'react';

import Card, { CardProps } from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import {
  CardActionArea,
  CardActionAreaProps,
  CardActions,
  CardActionsProps,
  CardContent,
  CardContentProps,
  CardHeader,
  CardHeaderProps,
} from '@mui/material';
import { ECTypography } from '../ECTypography';
import { ECIcon } from '../ECIcon';
import { ECBox, ECDivider } from '..';
import { StyleConstants } from 'styles/StyleConstants';

export const ECCard = styled(Card)<CardProps>(() => ({}));

export const ECCardActionArea = styled(CardActionArea)<CardActionAreaProps>(
  () => ({}),
);

export const ECCardContent = styled(CardContent)<CardContentProps>(() => ({}));

export const ECCardActions = styled(CardActions)<CardActionsProps>(() => ({}));

export const ECCardHeader = styled(CardHeader)<CardHeaderProps>(() => ({}));

interface ECCardButtonProps {
  title: string;
  icon: React.ReactNode;
  disabled?: boolean;
  onClick: () => void;
}

export const ECCardButton = (props: ECCardButtonProps) => {
  return (
    <ECCard sx={{ flex: 1 }}>
      <CardActionArea disabled={props.disabled} onClick={props.onClick}>
        <ECCardContent
          sx={{
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 1,
            opacity: props.disabled ? 0.5 : 1,
          }}
        >
          {props.icon}
          <ECTypography
            fontSize={12}
            textTransform="uppercase"
            fontWeight="bold"
          >
            {props.title}
          </ECTypography>
        </ECCardContent>
      </CardActionArea>
    </ECCard>
  );
};

export const ECInfoCard = props => {
  const { color, icon, title, description, value } = props;
  return (
    <ECCard>
      <ECBox p={2} mx={3} display="flex" justifyContent="center">
        <ECBox
          display="grid"
          justifyContent="center"
          alignItems="center"
          sx={{
            bgColor: color,
            shadow: 'md',
          }}
          width="4rem"
          height="4rem"
          borderRadius="lg"
        >
          <ECIcon>{icon}</ECIcon>
        </ECBox>
      </ECBox>
      <ECBox pb={2} px={2} textAlign="center" lineHeight={1.25}>
        <ECTypography
          variant="h6"
          fontWeight="medium"
          textTransform="capitalize"
        >
          {title}
        </ECTypography>
        {description && (
          <ECTypography variant="caption" color="text" fontWeight="regular">
            {description}
          </ECTypography>
        )}
        {description && !value ? null : <ECDivider />}
        {value && (
          <ECTypography variant="h5" fontWeight="medium">
            {value}
          </ECTypography>
        )}
      </ECBox>
    </ECCard>
  );
};

export const ECStatisticsCard = props => {
  const { color, icon } = props;
  return (
    <ECCard
      sx={{
        overflow: 'visible',
        marginTop: 3,
        backgroundColor: 'rgba(251,249,249,1) !important',
        borderRadius: '10px !important',
      }}
    >
      <ECBox
        display="flex"
        justifyContent="space-between"
        pt={1}
        px={2}
        pb={2}
        flexDirection={'column'}
      >
        <ECBox display="flex">
          <ECCard
            sx={{
              display: 'flex',
              backgroundColor: `${color} !important`,
              borderRadius: `10px !important`,
              coloredShadow: color,
              // width: '4rem',
              height: '4rem',
              // backdropFilter: 'blur( 4px )',
              padding: 2,
              paddingRight: 3,
              justifyContent: 'flex-start',
              alignItems: 'center',
              // borderRadius: 2,
              // border: '1px solid rgba( 255, 255, 255, 0.18 )',
              marginTop: -3,
              maxWidth: '24rem',
            }}
          >
            {icon}
            <ECTypography pl={2} variant="button" color="text" display="flex">
              <ECTypography variant="subtitle1">{props.title}</ECTypography>
            </ECTypography>
          </ECCard>
        </ECBox>
        <ECBox
          display="flex"
          justifyContent="flex-end"
          mt={2}
          mb={2}
          alignItems={'center'}
        >
          {props.dataTitle && (
            <ECTypography ml={2} variant="h5">
              {props.dataTitle}
            </ECTypography>
          )}
          {props.dataTitle && <ECBox flex={1} />}
          {props.data.map(item => (
            <ECBox textAlign="right" lineHeight={1.25} mx={2}>
              <ECTypography variant="button" fontWeight="light" color="text">
                {item.title}
              </ECTypography>
              <ECTypography variant="h6">{item.value}</ECTypography>
            </ECBox>
          ))}
        </ECBox>
        {props.additionalData && <ECDivider />}
        {props.additionalData && (
          <ECBox
            display="flex"
            justifyContent="flex-end"
            mt={2}
            mb={2}
            alignItems={'center'}
          >
            {props.additionalDataTitle && (
              <ECTypography ml={2} variant="h5">
                {props.additionalDataTitle}
              </ECTypography>
            )}
            {props.dataTitle && <ECBox flex={1} />}
            {props.additionalData.map(item => (
              <ECBox textAlign="right" lineHeight={1.25} mx={2}>
                <ECTypography variant="button" fontWeight="light" color="text">
                  {item.title}
                </ECTypography>
                <ECTypography variant="h6">{item.value}</ECTypography>
              </ECBox>
            ))}
          </ECBox>
        )}
      </ECBox>
      {/*<ECDivider />*/}
    </ECCard>
  );
};

export const ECChartIconContainerCard = props => {
  let additionalSx = [];
  if (props.sx) {
    additionalSx = props.sx;
  }

  return (
    <ECCard
      sx={{
        display: 'flex',
        backgroundColor: `${props.color} !important`,
        coloredShadow: props.color,
        width: '4rem',
        height: '4rem',
        padding: 2,
        justifyContent: 'flex-start',
        alignItems: 'center',
        borderRadius: '10px !important',
        marginTop: -5,
        maxWidth: '24rem',
        ...additionalSx,
      }}
    >
      {props.children}
    </ECCard>
  );
};

export const ECChartContainerCard = props => {
  return (
    <ECCard sx={{ overflow: 'visible', marginTop: 5 }}>{props.children}</ECCard>
  );
};

interface ECReportTableCardProps {
  title?: string;
  subtitle?: string;
  subtitleIcon?: React.ReactNode;
  children?: React.ReactNode;
}

export const ECReportTableCard = (props: ECReportTableCardProps) => {
  return (
    <ECCard sx={{ pb: 1 }}>
      <ECBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={3}
      >
        <ECBox>
          {props.title && (
            <ECTypography variant="subtitle2" gutterBottom>
              {props.title}
            </ECTypography>
          )}
          <ECBox display="flex" alignItems="center" lineHeight={0}>
            {props.subtitleIcon && props.subtitleIcon}
            {props.subtitle && (
              <ECTypography
                ml={props.subtitleIcon ? 2 : 0}
                variant="body2"
                fontWeight="regular"
              >
                {props.subtitle}
              </ECTypography>
            )}
          </ECBox>
        </ECBox>
      </ECBox>
      {props.children}
    </ECCard>
  );
};

export const ECDedicatedTableCardContainer = props => {
  return (
    <ECBox sx={{ pb: 1, marginTop: 5, overflow: 'visible' }}>
      <ECBox
        display="flex"
        justifyContent="space-between"
        flexDirection={'column'}
      >
        {props.moduleRedirectElement && (
          <ECBox
            sx={{
              display: 'flex',
              backgroundColor: props.color,
              coloredShadow: props.color,
              justifyContent: 'flex-start',
              alignItems: 'center',
              borderRadius: 'xl',
              marginTop: -5,
              bgColor: props.color,
            }}
          >
            {props.moduleRedirectElement}
          </ECBox>
        )}
        <ECBox
          sx={{
            display: 'flex',
            backgroundColor: props.color,
            coloredShadow: props.color,
            justifyContent: 'flex-start',
            alignItems: 'center',
            borderRadius: 'xl',
            marginTop: -5,
            bgColor: props.color,
          }}
        >
          {props.headerContent}
        </ECBox>

        <ECBox
          sx={{
            boxShadow: 1,
            overflow: 'hidden',
            opacity: props.disableTableContent
              ? StyleConstants.OPACITY_DISABLED
              : '',
            pointerEvents: props.disableTableContent ? 'none' : '',
          }}
        >
          {props.children}
          {props.additionalChildren}
        </ECBox>
      </ECBox>
      <ECBox>{props.paginationComponent && props.paginationComponent}</ECBox>
    </ECBox>
  );
};
