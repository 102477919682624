import { emptyApi } from './emptyApi';
import { Budget } from 'types/Budget';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: ['SRBudget'],
});

// Define a service using a base URL and expected endpoints
export const budgetApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    getServiceRequestBudget: builder.query<Budget, { branchId: number }>({
      query: ({ branchId }) => {
        return {
          url: `/budget/workorder/branch/${branchId}`,
        };
      },
      providesTags: ['SRBudget'],
    }),
    getBranchBudgetByModule: builder.query<
      Budget,
      { branchId: number; moduleName: string; moduleId: number }
    >({
      query: ({ branchId, moduleName, moduleId }) => {
        const params: any = {
          type: moduleName,
          id: moduleId,
        };
        if (moduleName === 'invoice') {
          params.invoiceId = moduleId;
        }

        if (moduleName === 'proposal') {
          params.proposalId = moduleId;
        }

        if (moduleName === 'workorder') {
          params.workorderId = moduleId;
        }

        return {
          url: `/budget/branch/${branchId}`,
          params,
        };
      },
      providesTags: ['SRBudget'],
    }),
  }),
});

export const {
  useGetServiceRequestBudgetQuery,
  useLazyGetServiceRequestBudgetQuery,
  useGetBranchBudgetByModuleQuery,
  useLazyGetBranchBudgetByModuleQuery,
} = budgetApi;
