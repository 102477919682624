import { ECDrawerDetails } from '../ECDrawerDetails';
import { ECTypography } from '../ECTypography';
import { ECBox } from '../ECBox';
import { useGetHistoryListQuery } from 'services/historyApi';
import { useCallback, useMemo, useState } from 'react';
import { ECCircularProgress } from '../ECCircularProgress';
import { ECIconButton } from '../ECIconButton';
import { Close } from '@mui/icons-material';
import { ECActivityCard } from '../ECActivityCard';
import { ECButton } from '../ECButton';
import { ActivityType } from 'types/Activity';
import { StyleConstants } from 'styles/StyleConstants';
import { useGetWorkOrderAutomationPreferenceQuery } from 'services/lookupApi';

export const ECDrawerActivities = ({
  activityType,
}: {
  activityType?: ActivityType;
}) => {
  const [isActivityDrawerOpen, setIsActivityDrawerOpen] = useState(false);

  const companyId = useMemo(() => {
    const companyProfile = localStorage.getItem('userProfile');
    if (!companyProfile) return null;
    return JSON.parse(companyProfile)?.company?.id;
  }, []);

  const closeActivityDrawer = useCallback(() => {
    setIsActivityDrawerOpen(false);
  }, []);

  const activityInfo: { title: string; moduleName: string } = useMemo(() => {
    switch (activityType) {
      case ActivityType.Trade:
        return { title: 'Trades Activity', moduleName: 'trade' };
      case ActivityType.Organization:
        return { title: 'Org Chart Activity', moduleName: 'organization' };
      case ActivityType.JobTitle:
        return { title: 'Job Titles Activity', moduleName: 'job-title' };
      case ActivityType.Problems:
        return { title: 'Problem Activity', moduleName: 'problem' };
      case ActivityType.AssetTradeProblem:
        return {
          title: 'Asset - Trade - Problem Activity',
          moduleName: 'asset-trade-problem',
        };
      case ActivityType.Company:
        return {
          title: 'Settings',
          moduleName: 'company',
        };
      default:
        return { title: '', moduleName: '' };
    }
  }, [activityType]);

  const { data: activities, isLoading } = useGetHistoryListQuery(
    {
      moduleName: activityInfo.moduleName,
      moduleResourceId: companyId,
    },
    { skip: !companyId || !activityInfo.moduleName },
  );

  const {
    data: workOrderAutomationOptions,
    isSuccess: isWorkOrderAutomationOptionsSuccess,
  } = useGetWorkOrderAutomationPreferenceQuery(undefined, {
    skip: activityInfo.moduleName !== ActivityType.Company,
  });

  const companyMappedOptions = useMemo(() => {
    if (!isWorkOrderAutomationOptionsSuccess) return;
    return workOrderAutomationOptions.map(o => ({
      label: o.name,
      value: o.id,
    }));
  }, [workOrderAutomationOptions, isWorkOrderAutomationOptionsSuccess]);

  return (
    <>
      <ECButton
        variant="outlined"
        style={{
          background: 'transparent',
          paddingTop: '7px',
          paddingBottom: '7px',
          height: '40px',
        }}
        onClick={() => {
          setIsActivityDrawerOpen(true);
        }}
      >
        Activity
      </ECButton>
      <ECDrawerDetails
        open={isActivityDrawerOpen}
        anchor="right"
        onClose={() => {
          closeActivityDrawer();
        }}
      >
        <ECBox
          pt={StyleConstants.NAV_BAR_HEIGHT}
          minHeight="100vh"
          position="relative"
        >
          <>
            <ECBox
              sx={{
                display: 'flex',
                flexDirection: 'column',
                boxShadow: 'none',
              }}
            >
              <ECBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                px={3}
                sx={{
                  backgroundColor: theme => theme.palette.other.divider,
                  height: StyleConstants.DRAWER_TITLE_HEIGHT,
                }}
              >
                <ECTypography variant={'h4'}>{activityInfo.title}</ECTypography>
                <ECIconButton
                  sx={{ background: '#fff' }}
                  squared
                  onClick={() => {
                    closeActivityDrawer();
                  }}
                >
                  <Close />
                </ECIconButton>
              </ECBox>
              <ECBox
                px={3}
                pb={4}
                mt={3}
                display="flex"
                flexDirection="column"
                rowGap={4}
              >
                {isLoading && <ECCircularProgress sx={{ marginX: 'auto' }} />}

                {activities?.data.length
                  ? activities?.data?.map((entry, index) => (
                      <ECActivityCard
                        key={index}
                        entry={{
                          ...entry._doc,
                        }}
                        companyMappedOptions={companyMappedOptions}
                      />
                    ))
                  : !isLoading && (
                      <ECBox
                        style={{
                          padding: '10px',
                          textAlign: 'center',
                          marginTop: '40%',
                        }}
                      >
                        <ECTypography
                          variant="body1"
                          sx={{
                            color: theme => theme.palette.text.secondary,
                          }}
                        >
                          No records found
                        </ECTypography>
                      </ECBox>
                    )}
              </ECBox>
            </ECBox>
          </>
        </ECBox>
      </ECDrawerDetails>
    </>
  );
};
