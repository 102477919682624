import { useMemo } from 'react';
import { ECBox } from '../ECBox';
import { presetColors } from '../ECChip';
import { ECStack } from '../ECStack';
import { ECTypography } from '../ECTypography';
import { themes } from 'styles/theme/themes';

export enum WorkflowPriority {
  Emergency = 'L1 - Emergency',
  SameDay = 'L2 - Same Day',
  OneDay = 'L3 - 24 Hours',
  TwoDays = 'L4 - 48 Hours',
  OneWeek = 'L5 - One Week',
  TwoWeeks = 'L6 - Two Weeks',
  OneMonth = 'L7 - 30 Days',
  SixMonths = 'L8 - 6 Months',
}

const mapPriorityColors = {
  [WorkflowPriority.Emergency]: themes.light.palette.graphic.alert.error,
  [WorkflowPriority.SameDay]: themes.light.palette.graphic.alert.warning,
  [WorkflowPriority.OneDay]: themes.light.palette.graphic.alert.warning,
  [WorkflowPriority.TwoDays]: themes.light.palette.graphic.alert.success,
  [WorkflowPriority.OneWeek]: themes.light.palette.graphic.alert.success,
  [WorkflowPriority.TwoWeeks]: themes.light.palette.graphic.alert.success,
  [WorkflowPriority.OneMonth]: themes.light.palette.graphic.alert.success,
  [WorkflowPriority.SixMonths]: themes.light.palette.graphic.alert.success,
};

interface ECWorkflowPriorityBadgeProps {
  priority: WorkflowPriority;
  priorityColor?: string;
}

export const ECWorkflowPriorityBadge = ({
  priority,
  priorityColor,
}: ECWorkflowPriorityBadgeProps) => {
  const backgroundColor = useMemo(() => {
    return priorityColor
      ? presetColors.find(c => c.title === priorityColor)?.color
      : mapPriorityColors[priority];
  }, [priority, priorityColor]);
  return (
    <ECStack direction="row" spacing={1} height="100%">
      <ECBox width="3px" bgcolor={backgroundColor} />
      <ECTypography variant="body1">{priority}</ECTypography>
    </ECStack>
  );
};
