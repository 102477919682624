import {
  ECBox,
  ECIconButton,
  ECTable,
  ECTableBody,
  ECTableCell,
  ECTableRow,
  ECTextField,
  ECTypography,
} from 'app/components';
import { ECCheckbox } from 'app/components/ECCheckbox';
import { EmptyStatePage } from '../EmptyStatePage';
import { JobTitleRoles } from 'types/JobTitle';
import { useTranslation } from 'react-i18next';
import { memo, useEffect, useState } from 'react';
import { InputAdornment, Stack } from '@mui/material';
import { Close, Search } from '@mui/icons-material';

export interface JobTitlesRolesTabProps {
  data: JobTitleRoles[];
  jobTitleSelectedRolesList?: JobTitleRoles[];
  handleSelectedRolesChange?: (item: any, action: string) => void;
  onChange?: (item: any[], fieldname: string) => void;
  isEditMode?: boolean;
  options?: any;
}

const JobTitlesRolesTabRaw = (props: JobTitlesRolesTabProps) => {
  const {
    data = [],
    jobTitleSelectedRolesList,
    isEditMode,
    handleSelectedRolesChange,
    options,
  } = props;

  const [searchField, setSearchField] = useState<string>('');

  const filteredOptions = options
    ?.filter(
      opt =>
        opt.name !== 'Customer Workflow Link Role' &&
        opt.name !== 'Customer System Access' &&
        opt.name !== 'Service Provider System Access' &&
        opt.name !== 'Service Provider Workflow Link Role' &&
        opt.name !== 'Display Job Title in Direct Notes',
    )
    ?.filter(opt =>
      opt.name.toLowerCase().includes(searchField?.trim()?.toLowerCase()),
    )
    .sort((a, b) => {
      return (
        (data?.some(x => x.id === b.id) ? 1 : 0) -
        (data?.some(x => x.id === a.id) ? 1 : 0)
      );
    });

  useEffect(() => {
    setSearchField('');
  }, [isEditMode]);

  const handleCheck = (event, role) => {
    if (event.target.checked) {
      handleSelectedRolesChange?.(role, 'checked');
    } else {
      handleSelectedRolesChange?.(role, 'unchecked');
    }
  };

  const { t } = useTranslation();
  return (
    <ECBox
      display="flex"
      bgcolor={theme => theme.palette.background.paper}
      flexDirection="column"
      p={isEditMode ? 0 : 3}
    >
      {!isEditMode && (
        <ECTypography mt={2} mb={2} variant="h6">
          {t('translation:pages.jobTitles.roles')}
        </ECTypography>
      )}
      {isEditMode && (
        <Stack
          direction="row"
          spacing={1}
          flexGrow={1}
          alignItems="center"
          justifyContent="right"
        >
          <ECBox
            borderColor={theme => theme.palette.action.active}
            mb={2}
            sx={{
              tabIndex: '0',
              display: 'flex',
              minWidth: '100%',
              backgroundColor: '#f5f5f5',
              borderRadius: '4px',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              alignItems: 'center',
              border: 0,
              p: 0.5,
            }}
          >
            <ECTextField
              variant="standard"
              sx={{
                minWidth: '100%',
                '.MuiFormHelperText-root': {
                  color: theme => theme.palette.graphic.alert.error,
                },
              }}
              placeholder={t('translation:table.search')}
              value={searchField}
              onChange={event => {
                setSearchField(event.target.value);
              }}
              InputProps={{
                endAdornment: searchField ? (
                  <InputAdornment position="end">
                    <ECIconButton onClick={() => setSearchField('')}>
                      <Close />
                    </ECIconButton>
                  </InputAdornment>
                ) : (
                  <></>
                ),
                startAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
                disableUnderline: true,
              }}
            />
          </ECBox>
        </Stack>
      )}
      <ECBox sx={{ height: '57vh', overflowY: 'scroll' }}>
        <ECTable sx={{ padding: 0 }} size="small">
          <ECTableBody>
            {isEditMode ? (
              filteredOptions && filteredOptions.length ? (
                filteredOptions.map(role => {
                  return (
                    <ECTableRow
                      sx={{
                        '&:hover': {
                          backgroundColor: theme =>
                            theme.palette.primary.outlinedHoverBackground,
                        },
                      }}
                      key={`${role?.id}-${role?.name}`}
                    >
                      <ECTableCell>
                        <ECBox display={'flex'} alignItems="center">
                          <ECCheckbox
                            checked={jobTitleSelectedRolesList?.some(
                              x => x.id === role.id,
                            )}
                            onChange={e => {
                              handleCheck(e, role);
                            }}
                          />
                          <ECTypography fontSize={'14px'}>
                            {role.name}
                          </ECTypography>
                        </ECBox>
                      </ECTableCell>
                    </ECTableRow>
                  );
                })
              ) : (
                <EmptyStatePage />
              )
            ) : data?.length ? (
              data.map(role => {
                return (
                  <ECTableRow
                    sx={{
                      '&:hover': {
                        backgroundColor: theme =>
                          theme.palette.primary.outlinedHoverBackground,
                        //cursor: 'pointer',
                      },
                    }}
                    key={`${role?.id}-${role?.name}`}
                  >
                    <ECTableCell>
                      <ECBox display={'flex'} alignItems="center">
                        <ECCheckbox checked={true} disabled={true} />
                        <ECTypography>{role.name}</ECTypography>
                      </ECBox>
                    </ECTableCell>
                  </ECTableRow>
                );
              })
            ) : (
              <EmptyStatePage />
            )}
          </ECTableBody>
        </ECTable>
      </ECBox>
    </ECBox>
  );
};

export const JobTitlesRolesTab = memo(JobTitlesRolesTabRaw);
