import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { requirePermissionWrapper } from 'app/hoc/require-permission';
import { P } from 'types/Permission';
import { ECCircularProgress } from '../ECCircularProgress';

export const StyledButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  border: 0,
  borderRadius: 4,
  '&:hover': {
    border: 0,
  },
}));

export interface ECButtonProps extends ButtonProps {
  scopes?: P[];
  isLoading?: boolean;
  component?: any;
  loadingSpinnerColor?: string;
  sx?: any;
}

const ButtonComponent = ({
  scopes,
  component,
  isLoading,
  loadingSpinnerColor,
  disabled,
  sx,
  ...props
}: ECButtonProps) => {
  return (
    <StyledButton {...props} disabled={isLoading || disabled} sx={sx}>
      {isLoading ? (
        <ECCircularProgress
          size="24px"
          sx={{
            color: theme =>
              loadingSpinnerColor || theme.palette.background.paper,
          }}
        />
      ) : (
        props.children
      )}
    </StyledButton>
  );
};

export const ECButton =
  requirePermissionWrapper<ECButtonProps>(ButtonComponent);

export const ECButtonDelete = styled(Button)<ButtonProps>(({ theme }) => ({
  backgroundColor: theme.palette.graphic.alert.error,
}));
