import { useLocation, useNavigate } from 'react-router-dom';
import {
  ECEasyForm,
  ECBox,
  ECButton,
  ECEasyFormFieldType,
  ECTypography,
  ECCopyright,
  ECTechTrakNotLoggedInBar,
} from 'app/components';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { Link } from '@mui/material';
import * as _ from 'lodash';
import { ECModal } from 'app/components/ECModal';
import ecotrakTextLogo from '../../../assets/ecotrak-text-logo.svg';
import {
  useCreateSPMutation,
  useLazyValidateEmailQuery,
} from 'services/spAuthApi';
import { useGetIndustriesQuery, useGetSICsQuery } from 'services/lookupApi';
import { isValidEmail } from 'utils/validate-fields';
import { setSnackbar } from 'store/slice/page';
import { useDispatch } from 'react-redux';
import { CreateSPParams } from 'types/ServiceProviderTypes';
import { cleanedPhoneNumber } from 'utils/strings/phone';
import { useGetTaxClassificationsQuery } from 'services/lookupApi';
import { flattenFormFields } from 'utils/form';

const formCreateConfig = require('./fancy_form_config_create.json');

const FIELDS_PATH = {
  industry: 'taxDetails.industry',
  sic: 'taxDetails.sic',
  federalTaxClassification: 'taxDetails.federalTaxClassification',
  fein: 'taxDetails.fein',
  dba: 'companyDetails.dba',
  remittanceEmail: 'remittanceEmail',
  remittancePhone: 'remittance.remittancePhone',
  remittancePhoneExt: 'remittance.remittancePhoneExt',
  contactFirstName: 'contactUserName.contactFirstName',
};

export function CreateSPFormPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    code = '',
    companyEmail,
    companyName,
    companyPhone,
    companyPhoneExt,
    requiredFields,
  } = location?.state || {};

  useEffect(() => {
    if (!code || !companyEmail) {
      navigate('/sp-self-signup');
    }
  }, [code, companyEmail]);

  const { data: taxClassifications } = useGetTaxClassificationsQuery();
  const [isEmailAlreadyInUse, setIsEmailAlreadyInUse] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [isModalVisible, setIsModalVisbile] = useState(false);
  const [editFields, setEditFields] = useState(formCreateConfig.fields);

  const [create, { isSuccess, isLoading }] = useCreateSPMutation();

  const [
    validateEmail,
    {
      isSuccess: isSuccessEmail,
      isError: isErrorEmail,
      error,
      isLoading: isLoadingEmail,
    },
  ] = useLazyValidateEmailQuery();

  useEffect(() => {
    const allFields = flattenFormFields(editFields);

    for (const key of Object.keys(FIELDS_PATH)) {
      const fieldPath = FIELDS_PATH[key];
      const field = allFields.getField(fieldPath);

      if (!field) continue;

      if (key.startsWith('contact')) {
        const contactIsRequired = requiredFields.includes('contact');
        if (contactIsRequired) {
          allFields.fields
            .filter(f => f.key.startsWith('contact'))
            .forEach(f => {
              f.field.visible = true;
              f.field.required = true;
            });

          const contactCompanyPhoneExt = allFields.getField(
            'contactCompanyPhoneGroup.contactCompanyPhoneExt',
          );
          const contactAddressLine2 = allFields.getField(
            'contactAddress.line2',
          );
          contactCompanyPhoneExt.required = false;
          contactAddressLine2.required = false;

          const contactSection = editFields.find(
            field => field.fieldName === 'contactSection',
          );
          contactSection.visible = true;
        }
        continue;
      }

      if (requiredFields.find(f => f === key)) {
        if (key === 'remittancePhoneExt') continue;

        field.required = true;
      } else {
        field.visible = false;
        field.required = false;
      }
    }
    setEditFields(editFields);
  }, [requiredFields]);

  useEffect(() => {
    if (!isLoadingEmail && isErrorEmail) {
      setIsEmailAlreadyInUse(true);
      setIsModalVisbile(true);
    }
  }, [isErrorEmail, isLoadingEmail]);

  useEffect(() => {
    if (!isLoadingEmail && isSuccessEmail) {
      setIsEmailAlreadyInUse(false);
      setIsModalVisbile(false);
    }
  }, [isSuccessEmail, isLoadingEmail]);

  useEffect(() => {
    if (isSuccess && !isLoading) {
      navigate('/sp-self-signup/success');
    }
  }, [isSuccess, isLoading]);

  useEffect(() => {
    const companyEmailField = editFields.find(
      field => field.fieldName === 'companyEmail',
    );
    const emailField = editFields.find(field => field.fieldName === 'email');

    companyEmailField.value = companyEmail;
    emailField.value = companyEmail;

    setEditFields([...editFields]);
  }, [companyEmail]);

  useEffect(() => {
    const companyDetailsField = editFields.find(
      field => field.groupName === 'companyDetails',
    );
    const companyNameField = companyDetailsField.subFields.find(
      field => field.fieldName === 'companyName',
    );

    companyNameField.value = companyName;

    setEditFields([...editFields]);
  }, [companyName]);

  useEffect(() => {
    const taxDetailsField = editFields.find(
      field => field.groupName === 'taxDetails',
    );
    const federalTaxClassificationField = taxDetailsField.subFields.find(
      field => field.fieldName === 'federalTaxClassification',
    );
    federalTaxClassificationField.options = taxClassifications?.map(
      taxClassification => ({
        ...taxClassification,
        label: taxClassification.name,
      }),
    );
    federalTaxClassificationField.optionValues = taxClassifications?.map(
      taxClassification => taxClassification.name,
    );

    const sicField = taxDetailsField.subFields.find(
      field => field.fieldName === 'sic',
    );
    sicField.useQuery = useGetSICsQuery;
    sicField.obAlias = 'sicid.code';

    const industryField = taxDetailsField.subFields.find(
      field => field.fieldName === 'industry',
    );
    industryField.useQuery = useGetIndustriesQuery;
    industryField.obAlias = 'indtry.name';

    setEditFields([...editFields]);
  }, [taxClassifications]);

  useEffect(() => {
    const companyPhoneGroup = editFields.find(
      field => field.groupName === 'companyPhoneGroup',
    );
    const companyPhoneField = companyPhoneGroup.subFields.find(
      field => field.fieldName === 'phone',
    );
    const companyPhoneExtField = companyPhoneGroup.subFields.find(
      field => field.fieldName === 'phoneExt',
    );

    companyPhoneField.value = companyPhone;
    companyPhoneExtField.value = companyPhoneExt;

    setEditFields([...editFields]);
  }, [companyPhone, companyPhoneExt]);

  useEffect(() => {
    const emailField = editFields.find(field => field.fieldName === 'email');

    emailField.onBlur = email => {
      if (isValidEmail(email)) {
        validateEmail(email);
      }
    };

    setEditFields([...editFields]);
  }, []);

  useEffect(() => {
    const passwordField = editFields.find(
      field => field.fieldName === 'password',
    );
    passwordField.onValidatePassword = setIsValidPassword;
  }, []);

  const handleChange = useCallback(
    (output: ECEasyFormFieldType[], fieldName?: string) => {
      if (fieldName === 'email') {
        const companyEmailField = output.find(
          field => field.fieldName === 'companyEmail',
        );
        const emailField = output.find(field => field.fieldName === 'email');

        if (companyEmailField && emailField) {
          companyEmailField.value = emailField.value;
        }

        return;
      }

      const isBillingSameAsAddressCheckboxSelected = (
        output.find(field => field.fieldName === 'isBillingSameAsAddress')
          ?.value as string[]
      )?.includes('Use same for Billing Address');

      const newFields = _.clone(output) || [];

      const billingAddressFields = newFields.filter(field =>
        field?.fieldName?.includes('billingAddress'),
      );

      billingAddressFields.forEach(billingAddressField => {
        billingAddressField.visible = !isBillingSameAsAddressCheckboxSelected;
      });

      let addressCountryCode = newFields
        ?.find(field => field.groupName === 'countryCode')
        ?.subFields?.find(
          field => field.fieldName === 'mailingAddress.countryCode',
        )?.value;

      addressCountryCode = Array.isArray(addressCountryCode)
        ? addressCountryCode[0]
        : addressCountryCode?.value || addressCountryCode;

      const postalCodeFields = newFields
        .find(field => field?.fieldName === 'mailingAddressGroup')
        ?.subFields?.find(
          field => field?.fieldName === 'mailingAddress.zipCodePostalCode',
        );

      if (postalCodeFields) {
        postalCodeFields.required = !(
          addressCountryCode?.toLowerCase() === 'jm'
        );
      }

      let billingCountryCode = newFields
        ?.find(field => field.groupName === 'countryCodeBilling')
        ?.subFields?.find(
          field => field.fieldName === 'billingAddress.countryCode',
        )?.value;

      billingCountryCode = Array.isArray(billingCountryCode)
        ? billingCountryCode[0]
        : billingCountryCode?.value || billingCountryCode;

      const postalCodeBillingFields = newFields
        .find(field => field?.fieldName === 'billingAddressGroup')
        ?.subFields?.find(
          field => field?.fieldName === 'billingAddress.zipCodePostalCode',
        );

      if (postalCodeBillingFields) {
        postalCodeBillingFields.required = !(
          billingCountryCode?.toLowerCase() === 'jm'
        );
      }

      setEditFields([...newFields]);
    },
    [],
  );

  const handleCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const additionalActions = useMemo(
    () => (
      <ECButton type="button" variant="text" onClick={handleCancel}>
        {t('translation:dynamicForm.cancel')}
      </ECButton>
    ),
    [handleCancel, t],
  );
  const submitForm = useCallback(
    (formData, output) => {
      const taxDetailsField = output.find(
        field => field.groupName === 'taxDetails',
      );
      const sicField = taxDetailsField.subFields.find(
        field => field.fieldName === 'sic',
      );
      const federalTaxClassificationField = taxDetailsField.subFields.find(
        field => field.fieldName === 'federalTaxClassification',
      );
      const industryField = taxDetailsField.subFields.find(
        field => field.fieldName === 'industry',
      );
      const feinField = taxDetailsField.subFields.find(
        field => field.fieldName === 'fein',
      );

      const countryCodeField = output
        .find(field => field.groupName === 'countryCode')
        ?.subFields?.find(f => f.fieldName === 'mailingAddress.countryCode');

      const contactCountryCodeField = output
        .find(field => field.groupName === 'countryCodeContact')
        ?.subFields?.find(f => f.fieldName === 'contactAddress.countryCode');

      if (
        feinField.required &&
        (!feinField.value || feinField.value.length < 9)
      ) {
        feinField.validationMessage =
          'Please provide a valid FEIN with 9 digits';
        feinField.isValid = false;
        const newFields = _.clone(output) || [];
        setEditFields([...newFields]);
        window.scrollTo(0, 0);
        return;
      }

      if (isEmailAlreadyInUse) {
        dispatch(
          setSnackbar({
            severity: 'error',
            message: 'E-mail already in use',
          }),
        );
        return;
      }

      if (!isValidPassword) {
        return;
      }

      const isSameBillingAddress = !!formData.get('isBillingSameAsAddress')
        ?.length;
      const body: CreateSPParams = {
        companyName: formData.get('companyName'),
        companyEmail: formData.get('email'),
        phone: cleanedPhoneNumber(formData.get('phone')),
        phoneExt: formData.get('phoneExt'),
        phone24: cleanedPhoneNumber(formData.get('phone24')),
        phone24Ext: formData.get('phone24Ext'),
        remittance: {
          email: formData.get('remittanceEmail'),
          phone: formData.get('remittancePhone'),
          phoneExt: formData.get('remittancePhoneExt'),
        },
        address: {
          line1: formData.get('mailingAddress.line1'),
          line2: formData.get('mailingAddress.line2'),
          cityName: formData.get('mailingAddress.cityName'),
          stateProvinceCode: formData.get('mailingAddress.stateProvinceCode'),
          zipCodePostalCode:
            formData.get('mailingAddress.zipCodePostalCode') || null,
          countryCode: countryCodeField.value,
        },
        billingAddress: isSameBillingAddress
          ? {
              line1: formData.get('mailingAddress.line1'),
              line2: formData.get('mailingAddress.line2'),
              cityName: formData.get('mailingAddress.cityName'),
              stateProvinceCode: formData.get(
                'mailingAddress.stateProvinceCode',
              ),
              zipCodePostalCode:
                formData.get('mailingAddress.zipCodePostalCode') || null,
              countryCode: countryCodeField.value,
            }
          : {
              line1: formData.get('billingAddress.line1'),
              line2: formData.get('billingAddress.line2'),
              cityName: formData.get('billingAddress.cityName'),
              stateProvinceCode: formData.get(
                'billingAddress.stateProvinceCode',
              ),
              zipCodePostalCode: formData.get(
                'billingAddress.zipCodePostalCode',
              ),
              countryCode: countryCodeField.value,
            },

        contact: requiredFields?.includes('contact')
          ? {
              firstName: formData.get('contactFirstName'),
              lastName: formData.get('contactLastName'),
              email: formData.get('contactEmail'),
              phone: cleanedPhoneNumber(formData.get('contactCompanyPhone')),
              phoneExt: formData.get('contactCompanyPhoneExt'),
              address: {
                line1: formData.get('contactAddress.line1'),
                line2: formData.get('contactAddress.line2'),
                cityName: formData.get('contactAddress.cityName'),
                stateProvinceCode: formData.get(
                  'contactAddress.stateProvinceCode',
                ),
                zipCodePostalCode: formData.get(
                  'contactAddress.zipCodePostalCode',
                ),
                countryCode: contactCountryCodeField.value,
              },
            }
          : undefined,
        addToSPDirectory: !!formData.get('addToSPDirectory')?.length,
        inviteCode: code,
        firstName: formData.get('firstName'),
        lastName: formData.get('lastName'),
        email: formData.get('email'),
        password: formData.get('password'),
        fein: formData.get('fein'),
        dba: formData.get('dba'),
        sicId: sicField?.value?.id,
        industryId: industryField?.value?.id,
      };
      if (federalTaxClassificationField.required) {
        const federalTaxClassificationValue =
          federalTaxClassificationField?.options?.find(
            opt => opt.name === federalTaxClassificationField.value,
          );
        Object.assign(body, {
          federalTaxClassificationId: federalTaxClassificationValue?.id,
        });
      }
      if (code) {
        create(body);
      }
    },
    [isEmailAlreadyInUse, isValidPassword],
  );

  const handleCloseModal = () => setIsModalVisbile(false);

  const handleGoToLogin = () => navigate('/', { state: { code } });

  const [isCaptchaValid, setIsCaptchaValid] = useState(false);

  const [forceRerender, setForceRerender] = useState(false);
  // force rerender after 1 and a half seconds to ensure captcha is rendered
  useEffect(() => {
    const timeout = setTimeout(() => {
      setForceRerender(true);
    }, 1500);
    return () => clearTimeout(timeout);
  }, []);

  const [hasCaptchaRendered, setHasCaptchaRendered] = useState(false);
  useLayoutEffect(() => {
    window['AwsWafCaptcha']?.renderCaptcha(
      document.getElementById('my-captcha-container'),
      {
        apiKey: process.env.REACT_APP_RECAPTCHA_API_KEY,
        onSuccess: () => setIsCaptchaValid(true),
        onError: () => setIsCaptchaValid(false),
        onLoad: () => setHasCaptchaRendered(true),
      },
    );
  }, [window['AwsWafCaptcha']?.renderCaptcha]);

  return (
    <>
      <Helmet>
        <title>Sign In Page</title>
        <meta name="description" content="Welcome to Ecotrak" />
      </Helmet>
      <ECTechTrakNotLoggedInBar />
      <ECBox
        mt={4}
        display="flex"
        flexDirection="column"
        sx={{
          padding: {
            mobile: 2,
            laptop: 4,
          },
          maxWidth: '913px',
        }}
        marginLeft="auto"
        marginRight="auto"
        pb={1}
        justifyContent="center"
      >
        <img height={48} src={ecotrakTextLogo} alt="Ecotrac LLC" />
        <ECEasyForm
          pattern="modal"
          config={formCreateConfig.config}
          fields={editFields}
          fullHeight={false}
          isSendingData={isLoading}
          isLoadingForm={false}
          stickyFooter={false}
          onChange={handleChange}
          formFooter={
            <div id="my-captcha-container" style={{ marginBottom: '10px' }} />
          }
          additionalActions={additionalActions}
          saveButtonDisabled={hasCaptchaRendered && !isCaptchaValid}
          onFormSubmit={(formData, output) => {
            submitForm(formData, output);
          }}
        />
        <ECBox
          display="flex"
          flexDirection="column"
          p={4}
          pb={2}
          gap={4}
          mt={3}
        >
          <ECTypography variant="body2" align="center">
            By registering on Ecotrak, you are agreeing to Usage Fees Outlined
            here:{' '}
            <Link href="https://ecotrak.com/serviceproviderusagefees/">
              https://ecotrak.com/serviceproviderusagefees/
            </Link>
          </ECTypography>
          <ECCopyright
            variant="caption"
            color={theme => theme.palette.text.secondary}
          />
        </ECBox>
      </ECBox>
      <ECModal p={3} isOpen={isModalVisible} onClose={handleCloseModal}>
        <ECBox display="flex" flexDirection="column" gap={4}>
          <ECTypography variant="h6">This email is already in use</ECTypography>

          <ECTypography variant="body1">
            If you need help accessing your account please contact support:{' '}
            <Link href="support@ecotrak.com">support@ecotrak.com</Link>
          </ECTypography>

          <ECBox display="flex" gap={2} justifyContent="flex-end">
            <ECButton variant="text" onClick={handleCloseModal}>
              Close
            </ECButton>

            <ECButton variant="contained" onClick={handleGoToLogin}>
              Go To Login
            </ECButton>
          </ECBox>
        </ECBox>
      </ECModal>
    </>
  );
}
