import { QueryParams } from 'types/QueryParams';
import {
  AssetTradeProblem,
  AssetTradeProblemEdit,
  AssetTradeProblemUpdate,
} from 'types/AssetTradeProblem';
import { BaseType } from 'types/BaseType';

import { emptyApi } from './emptyApi';
import { TradeByAssetTypeAndProblemId } from 'types/TradeTypes';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: ['AssetTradeProblem', 'TradeByAssetType'],
});

export const assetTradeProblemApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    getAssetTradeProblem: builder.query<
      BaseType<AssetTradeProblem[]>,
      QueryParams
    >({
      query: params => {
        return {
          url: 'asset-group/trade/problem',
          params,
        };
      },
      providesTags: ['AssetTradeProblem'],
    }),
    getTradeByProblemAssetType: builder.query<
      TradeByAssetTypeAndProblemId[],
      { problemTypeId: number; assetTypeId: number }
    >({
      query: ({ assetTypeId, problemTypeId }) => {
        return {
          url: `asset-group/trade/problem/${assetTypeId}/${problemTypeId}`,
        };
      },
      providesTags: ['TradeByAssetType'],
    }),
    addAssetTradeProblem: builder.mutation<void, AssetTradeProblemEdit>({
      query: params => {
        return {
          url: `asset-group/${params.assetTypeId}/trade/problem`,
          method: 'post',
          body: params.body,
        };
      },
      invalidatesTags: ['AssetTradeProblem'],
    }),
    deleteAssetTradeProblem: builder.mutation<void, AssetTradeProblemEdit>({
      query: params => {
        return {
          url: `asset-group/${params.assetTypeId}/trade/problem`,
          method: 'delete',
          body: params.body,
        };
      },
      invalidatesTags: ['AssetTradeProblem'],
    }),
    editAssetTradeProblem: builder.mutation<void, AssetTradeProblemUpdate>({
      query: params => {
        return {
          url: `asset-group/${params.assetTypeId}/trade/problem`,
          method: 'PUT',
          body: {
            newAssetTradeProblem: params.newAssetTradeProblem,
            deletedAssetTradeProblem: params.deletedAssetTradeProblem,
          },
        };
      },
      invalidatesTags: ['AssetTradeProblem'],
    }),
  }),
});

export const {
  useGetAssetTradeProblemQuery,
  useLazyGetAssetTradeProblemQuery,
  useAddAssetTradeProblemMutation,
  useDeleteAssetTradeProblemMutation,
  useEditAssetTradeProblemMutation,
  useGetTradeByProblemAssetTypeQuery,
} = assetTradeProblemApi;
