import { ECBox, ECButton, ECTypography } from 'app/components';
import { ECCircularProgress } from 'app/components/ECCircularProgress';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  dashboardApi,
  useUpdateMultipleStatusMutation,
} from 'services/dashboardApi';
import { setSnackbar } from 'store/slice/page';
import { StatusGroupNames } from 'types/StatusGroups';
import {
  BulkApprovalStatusUpdateAction,
  StatusActions,
} from 'types/WorkflowStatus';
import { DashboardActionProps } from '.';
import { useGetApprovalActionsQuery } from 'services/approvalApi';

interface Props extends DashboardActionProps {
  statusTo: StatusGroupNames;
  errorMessage: string;
  successMessage: string;
  title: string;
  confirmationMessage: string;
}

export function ConfirmStatusChangeModal({
  onClose,
  onCancel,
  selectedRows,
  module,
  queryTag,
  title,
  confirmationMessage,
}: Props) {
  const dispatch = useDispatch();

  const [doUpdate, { data: updateStausData, isSuccess, isError, isLoading }] =
    useUpdateMultipleStatusMutation();

  const { data: actions, isSuccess: isSuccessActions } =
    useGetApprovalActionsQuery();

  const handleSubmit = useCallback(() => {
    const itemIds = selectedRows.map(row => row.id);

    const approveActionId = actions?.find(
      action => action.name === StatusActions.APPROVE,
    )?.id;

    if (!approveActionId) {
      dispatch(
        setSnackbar({
          severity: 'error',
          message: 'Approval action not found. Please try again.',
        }),
      );
      onClose();
      return;
    }

    const statusUpdateList: BulkApprovalStatusUpdateAction = {
      itemIds,
      actionId: approveActionId,
    };

    doUpdate({ module, statusUpdateList });

    if (queryTag) {
      dashboardApi.util.invalidateTags([queryTag]);
    }
  }, [doUpdate, module, selectedRows, isSuccessActions, actions, isSuccess]);

  useEffect(() => {
    const failedCount = updateStausData?.failed?.length;
    const successCount = updateStausData?.success?.length;
    const failedIds = updateStausData?.failed?.map(item => item.id);

    if (isSuccess && failedCount === 0 && successCount > 0) {
      dispatch(
        setSnackbar({
          severity: 'success',
          message: `${successCount} ${module}(s) were approved.`,
        }),
      );
      if (queryTag) {
        dispatch(dashboardApi.util.invalidateTags([queryTag]));
      }
      onClose();
    } else if (isSuccess && failedCount > 0 && successCount > 0) {
      dispatch(
        setSnackbar({
          severity: 'success',
          message: `${successCount} ${module}(s) were approved. ${failedIds?.join(
            ', ',
          )} ${module}(s) couldn't be approved.`,
        }),
      );
      if (queryTag) {
        dispatch(dashboardApi.util.invalidateTags([queryTag]));
      }
      onClose();
    } else if (isSuccess && failedCount > 0 && successCount === 0) {
      dispatch(
        setSnackbar({
          severity: 'error',
          message: `${failedIds?.join(
            ', ',
          )} ${module}(s) couldn't be approved.`,
        }),
      );
      if (queryTag) {
        dispatch(dashboardApi.util.invalidateTags([queryTag]));
      }
      onClose();
    } else if (isError) {
      dispatch(
        setSnackbar({
          severity: 'error',
          message: `Error approving some ${module}s`,
        }),
      );
      onClose();
    }
  }, [dispatch, updateStausData, isError, isSuccess, onClose]);

  return (
    <ECBox p={1} sx={{ width: '30rem', maxWidth: '100%' }}>
      <ECTypography mx={2} my={1} variant="h6">
        {title}
      </ECTypography>
      <ECTypography m={2}>{confirmationMessage}</ECTypography>
      <ECBox display="flex" justifyContent="flex-end" gap={2}>
        <ECButton onClick={onCancel}>Cancel</ECButton>
        <ECButton
          onClick={handleSubmit}
          variant="contained"
          sx={theme => ({ background: theme.palette.graphic.alert.success })}
        >
          {isLoading ? (
            <ECCircularProgress
              size={'2rem'}
              sx={{ color: 'background.paper' }}
            />
          ) : (
            'Confirm'
          )}
        </ECButton>
      </ECBox>
    </ECBox>
  );
}
