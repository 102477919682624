import { VisibilityOff, Visibility } from '@mui/icons-material';
import { FormHelperText, InputAdornment, TextFieldProps } from '@mui/material';
import React, { useState, useCallback, useEffect } from 'react';
import _ from 'lodash';
import { ECFormControl, ECIconButton, ECTextField } from 'app/components';

interface Props extends Omit<TextFieldProps, 'onChange'> {
  label?: string;
  fieldName?: string;
  sx?: any;
  inputProps?: any;
  error?: boolean;
  onChange?: (value?: any) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  placeholder?: string;
  value: any;
  helperText?: string | null;
  isNumber?: boolean;
  isValid?: boolean;
  validationMessage?: string | null;
}

export const CREDIT_CARD_MAX_LENGTH = 19;
export const CREDIT_CARD_MIN_LENGTH = 18;
export const CreditCardNumberField = ({
  fieldName,
  label,
  inputProps,
  error,
  helperText,
  sx,
  value,
  onChange,
  onBlur,
  required,
  isValid,
  validationMessage,
}: Props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState(validationMessage);

  useEffect(() => {
    setErrorMessage(validationMessage);
  }, [validationMessage]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let inputValue = event.target.value
        .replace(/\D/g, '')
        .replace(/(.{4})/g, '$1 ')
        .trim()
        .slice(0, CREDIT_CARD_MAX_LENGTH);

      onChange && onChange(inputValue);
    },
    [onChange],
  );

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    let inputValue = event.target.value
      .replace(/\D/g, '')
      .replace(/(.{4})/g, '$1 ')
      .trim();
    if (
      inputValue?.length < CREDIT_CARD_MIN_LENGTH ||
      inputValue?.length > CREDIT_CARD_MAX_LENGTH
    ) {
      setErrorMessage('Incorrect card format');
      return;
    }
    setErrorMessage(null);
    onBlur?.(event);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  return (
    <ECFormControl variant="filled" sx={sx}>
      <ECTextField
        {...inputProps}
        variant="filled"
        inputMode="numeric"
        type={showPassword ? 'text' : 'password'}
        label={required ? `${label} *` : label}
        id={fieldName}
        name={fieldName}
        value={value?.toString()}
        onChange={handleChange}
        onBlur={handleBlur}
        error={!!errorMessage || !!helperText || !!isValid}
        helperText={errorMessage || helperText}
        inputProps={{
          inputMode: 'numeric',
        }}
        placeholder="#### #### #### ####"
        InputLabelProps={value ? { shrink: true } : {}}
        InputProps={{
          ...inputProps,
          endAdornment: (
            <InputAdornment position="end">
              <ECIconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </ECIconButton>
            </InputAdornment>
          ),
        }}
        sx={{
          '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
          },
        }}
      />

      {error && helperText && (
        <FormHelperText
          sx={theme => ({
            bgcolor: 'transparent',
            color: theme.palette.graphic.alert.error,
          })}
        >
          {helperText}
        </FormHelperText>
      )}
    </ECFormControl>
  );
};
