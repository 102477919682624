import { Delete } from '@mui/icons-material';
import { ECBox, ECButton, ECGrid, ECImage, ECTypography } from 'app/components';
import EmaHelloImage from './assets/Ema_hello.png';
import moment from 'moment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export const FeatureList = ({ features }: { features: string[] }) => {
  return (
    <ECBox>
      {features.map((feature, index) => (
        <ECTypography
          key={index}
          sx={theme => ({
            display: 'flex',
            alignItems: 'center',
            marginBottom: 1,
            color: theme.palette.background.default,
          })}
        >
          <CheckCircleIcon
            sx={theme => ({
              marginRight: 1,
              color: theme.palette.background.default,
            })}
          />
          {feature}
        </ECTypography>
      ))}
    </ECBox>
  );
};

const SubscriptionPlanCard = ({ subscription }) => {
  return (
    <ECBox sx={{ paddingLeft: 2, paddingRight: 2, marginTop: -5 }}>
      {/* Header Section */}
      <ECGrid container alignItems="center">
        <ECGrid item xs={9}>
          <ECTypography variant="h6">Subscription Plan</ECTypography>
        </ECGrid>
        <ECGrid
          item
          xs={3}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <ECButton
            variant="text"
            color="error"
            startIcon={<Delete color="error" />}
            onClick={() => {}}
          >
            Cancel Subscription
          </ECButton>
        </ECGrid>
      </ECGrid>

      {/* Content Section */}
      <ECBox
        sx={theme => ({
          borderRadius: 5,
          backgroundColor: theme.palette.grey[900],
          padding: 4,
        })}
      >
        <ECGrid container alignItems="center" spacing={2}>
          {/* Image Section */}
          <ECGrid item xs={2}>
            <ECImage
              src={EmaHelloImage}
              alt="Ema Hello"
              sx={{ height: '150px', width: '150px' }}
            />
          </ECGrid>

          {/* Subscription Info */}
          <ECGrid item xs={3}>
            <ECBox
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                height: '100%',
              }}
            >
              <ECTypography
                variant="h5"
                sx={theme => ({
                  fontWeight: 'bold',
                  color: theme.palette.background.default,
                })}
              >
                Ecotrak Build
              </ECTypography>
              {subscription && Object.keys(subscription).length > 0 ? (
                <>
                  <ECTypography
                    variant="h4"
                    sx={theme => ({
                      fontWeight: 'bold',
                      display: 'inline',
                      marginTop: 2,
                      color: theme.palette.background.default,
                    })}
                  >
                    ${subscription.payPerPeriod}/
                    <ECTypography
                      variant="h5"
                      component="span"
                      sx={theme => ({
                        marginLeft: 0.5,
                        color: theme.palette.background.default,
                      })}
                    >
                      mo
                    </ECTypography>
                  </ECTypography>
                  <ECTypography
                    sx={theme => ({
                      marginTop: 1,
                      color: theme.palette.background.default,
                    })}
                  >
                    Subscription Date:{' '}
                    <ECTypography
                      component="span"
                      sx={theme => ({
                        fontWeight: 'bold',
                        color: theme.palette.background.default,
                      })}
                    >
                      {subscription.startTime
                        ? moment(
                            subscription.startTime?.split('T')?.[0],
                          ).format('MMM DD, YYYY')
                        : ''}
                    </ECTypography>
                  </ECTypography>
                  <ECTypography
                    sx={theme => ({
                      marginTop: 1,
                      color: theme.palette.background.default,
                    })}
                  >
                    Next Charge Date:{' '}
                    <ECTypography
                      component="span"
                      sx={theme => ({
                        fontWeight: 'bold',
                        color: theme.palette.background.default,
                      })}
                    >
                      {subscription?.nextPayment
                        ? moment(
                            subscription?.nextPayment?.split('T')?.[0],
                          ).format('MMM DD, YYYY')
                        : ''}
                    </ECTypography>
                  </ECTypography>
                </>
              ) : (
                <ECTypography
                  sx={theme => ({
                    height: '50px',
                    marginTop: 2,
                    fontStyle: 'italic',
                    color: theme.palette.grey[500],
                  })}
                >
                  No subscription details available
                </ECTypography>
              )}
            </ECBox>
          </ECGrid>

          {/* Features Section (Left) */}
          <ECGrid item xs={3.5}>
            <FeatureList
              features={[
                'All-in-One Command Center: Stay on top of work orders — all from one easy-to-use dashboard.',
                'Streamlined Workflows: Crush maintenance tasks to save time and headaches.',
              ]}
            />
          </ECGrid>

          {/* Features Section (Right) */}
          <ECGrid item xs={3.5}>
            <FeatureList
              features={[
                'Multi-Site Mastery: Effortlessly manage all your locations without breaking a sweat.',
                'Real-Time Support: Get answers fast and keep things moving.',
              ]}
            />
          </ECGrid>
        </ECGrid>
      </ECBox>
    </ECBox>
  );
};

export default SubscriptionPlanCard;
