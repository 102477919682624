import {
  ECBox,
  ECWorkflowTemplate,
  ECButton,
  ECRequirePermission,
} from 'app/components';
import { useParams } from 'react-router';
import { SummaryFieldTypes } from 'app/components/ECWorkflowSummaryField';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useGetAttachmentQuery } from 'services/attachmentApi';
import { AttachmentCategory, DocumentCoreTypes } from 'types/Attachment';
import {
  useUpdateProposalSPMutation,
  useGetProposalNextStatusListQuery,
  useUpdateProposalStatusMutation,
} from 'services/proposalApi';
import {
  useGetServiceProviderRatesAndTermsQuery,
  useGetServiceTermsUnitListQuery,
} from 'services/serviceProviderApi';
import { useGetFlagsListQuery } from 'services/flagsApi';
import { useGetAssetWarrantyQuery } from 'services/assetApi';
import { useTranslation } from 'react-i18next';
import { populateDropDownFields } from 'utils/pageUtils';
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'store/slice/page';
import { ProposalUpdateSP } from 'types/Proposal';
import { approvalApi, useGetApprovalHistoryQuery } from 'services/approvalApi';
import { WorkflowNextStatus } from 'types/WorkflowStatus';
import { ECUsersTableModal } from 'app/components/ECUsersTableModal';
import { Popover } from '@mui/material';
import { moduleApi, useGetModuleDetailsByIdQuery } from 'services/moduleApi';
import { ECApprovalHistoryButton } from 'app/components/ECApprovalHistory/ECApprovalHistoryButton';
import { WorkflowStatus } from 'app/components/ECWorkflowStatusBadge';
import moment from 'moment';
import _ from 'lodash';
import { Close, Description } from '@mui/icons-material';
import { P } from 'types/Permission';
import { ECModal } from 'app/components/ECModal';
import { ServiceProviderDeclineProposalModal } from './DeclineRfpModal';
import { AcceptRfpProposalButton } from './AcceptRfpProposalButton';
import { useHasPermission } from 'app/hooks/hasPermission.use-case';

const fancyFormElementsEdit = require('./fancy_form_config_edit.json');
const formElementsDetails = require('./fancy_form_config_details.json');

const editAllowedStatus = ['Pending Client Approval', 'Proposal Rejected'];

const statusRfpProposal = [
  WorkflowStatus.PendingSubmission,
  WorkflowStatus.Declined,
  WorkflowStatus.AcceptedPendingSubmission,
  WorkflowStatus.PendingReview,
];

export function ServiceProviderProposalDetailsPage() {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isDetailsTab =
    !window.location.href.includes('attachments') &&
    !window.location.href.includes('notes') &&
    !window.location.href.includes('activity');

  const hasEditProposalPermission = useHasPermission([P.EditProposal]);
  const {
    data: proposal,
    isSuccess,
    isLoading: isLoadingProposal,
    isError,
    error,
  } = useGetModuleDetailsByIdQuery(
    { id: id || '', moduleName: 'sp/proposal' },
    { skip: !id },
  );

  const [summaryFieldsValues, setSummaryFieldsValues] = useState<any>({});
  const [formFields, setFormFields] = useState<any[]>(
    fancyFormElementsEdit.fields,
  );
  const [validations, setValidations] = useState<any>([]);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const [formFieldsDetails, setFormFieldsDetails] = useState<any[]>(
    formElementsDetails.data.fields,
  );

  const { data: termsUnitList, isSuccess: isSuccesstermsUnitList } =
    useGetServiceTermsUnitListQuery(undefined, {
      skip: !isDetailsTab || !proposal,
    });

  const { data: approvalHistory, isSuccess: isSuccessApprovalHistory } =
    useGetApprovalHistoryQuery(
      {
        id: Number(id) || 0,
        module: 'proposal',
      },
      { skip: !id || !isDetailsTab },
    );

  const { data: ratesAndTerms } = useGetServiceProviderRatesAndTermsQuery(
    {
      spId: proposal?.spCompanyId,
      companyId: proposal?.companyId,
    },
    {
      skip: !proposal?.spCompanyId,
    },
  );

  // useEffect to add the view approval history button to the form
  useEffect(() => {
    // DETAILS FORM
    const approvalHistoryField = formElementsDetails.data?.fields
      ?.find(field => field.groupName === 'customButtonsAndStatus')
      ?.subFields?.find(f => f.fieldName === 'approvalHistoryButton');

    if (approvalHistoryField) {
      approvalHistoryField.value = (
        <ECApprovalHistoryButton approvalHistory={approvalHistory} />
      );

      // hide if proposal is in RFP status
      approvalHistoryField.visible = !statusRfpProposal.includes(
        proposal?.workflowStatusName,
      );
    }

    formElementsDetails.data.fields = [...formElementsDetails.data.fields];
  }, [approvalHistory, proposal?.workflowStatusName]);

  useEffect(() => {
    // EDIT FORM
    const approvalHistoryField = fancyFormElementsEdit.fields
      ?.find(field => field.groupName === 'customButtonsAndStatus')
      ?.subFields?.find(f => f.fieldName === 'approvalHistoryButton');

    if (approvalHistoryField) {
      approvalHistoryField.value = (
        <ECApprovalHistoryButton approvalHistory={approvalHistory} disabled />
      );

      // hide if proposal is in RFP status
      approvalHistoryField.visible = !statusRfpProposal.includes(
        proposal?.workflowStatusName,
      );
    }
  }, [approvalHistory, proposal?.workflowStatusName]);

  // useEffect to make the files field required based on proposal.requiredFields.proposalDocumentRequired
  useEffect(() => {
    if (proposal?.requiredFields?.proposalDocumentRequired) {
      const filesField = formFields.find(field => field.fieldName === 'files');
      if (filesField) {
        filesField.required = true;
      }
    }
  }, [proposal?.requiredFields?.proposalDocumentRequired]);

  // useEffect to hide files field if the status is not Accepted - Pending Submission
  useEffect(() => {
    const filesField = formFields.find(field => field.fieldName === 'files');
    if (filesField) {
      filesField.visible =
        proposal?.workflowStatusName ===
        WorkflowStatus.AcceptedPendingSubmission;
    }
  }, [proposal?.workflowStatusName]);

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const onClickViewUsersTable = useCallback((field, event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  }, []);

  const onCloseModal = useCallback(() => {
    setAnchorEl(null);
    setOpen(false);
  }, []);

  useEffect(() => {
    const assignedToField =
      formElementsDetails?.data?.fields[0]?.subFields?.find(
        x => x.fieldName === 'assignedTo',
      );
    if (assignedToField) {
      assignedToField.onClickViewUsersTable = onClickViewUsersTable;
    }
  }, [onClickViewUsersTable]);

  const assignedToUsers = useMemo(() => {
    return approvalHistory?.currentAssignees?.map(assignee => {
      return {
        fullName: assignee.fullName,
        jobTitle: assignee.jobTitle,
        email: assignee.email,
      };
    });
  }, [isSuccessApprovalHistory, approvalHistory]);

  const { data: proposalNextStatus, isSuccess: isSuccessProposalNextStatus } =
    useGetProposalNextStatusListQuery(Number(id) || 0, {
      skip: !id || !isDetailsTab,
    });

  const [isOpenRejectModal, setIsOpenRejectModal] = useState(false);

  const renderAcceptRfpProposalButton = useMemo(() => {
    return (
      <ECRequirePermission scopes={[P.GetProposal, P.EditProposal]}>
        <AcceptRfpProposalButton
          proposal={proposal}
          statusToId={
            proposalNextStatus?.find(
              status =>
                status.name === WorkflowStatus.AcceptedPendingSubmission,
            )?.statusTo || 0
          }
          disabled={isEditMode}
        />
      </ECRequirePermission>
    );
  }, [proposal, proposalNextStatus, isEditMode]);

  const renderDeclineRfpProposalButton = useMemo(() => {
    return (
      <ECRequirePermission scopes={[P.GetProposal, P.EditProposal]}>
        <ECButton
          variant="outlined"
          color="error"
          startIcon={
            <Close sx={{ color: theme => theme.palette.graphic.alert.error }} />
          }
          sx={theme => ({
            border: 1,
            borderColor: `${theme.palette.error.outlinedRestingBackground} !important`,
            color: `${theme.palette.graphic.alert.error} !important`,
            marginRight: 1,
          })}
          onClick={e => setIsOpenRejectModal(true)}
          disabled={isEditMode}
        >
          Decline
        </ECButton>
      </ECRequirePermission>
    );
  }, [isEditMode]);

  const [shouldGoIntoEditMode, setShouldGoIntoEditMode] = useState(false);
  useEffect(() => {
    if (!isEditMode && shouldGoIntoEditMode) {
      setShouldGoIntoEditMode(false);
    }
  }, [isEditMode, shouldGoIntoEditMode]);
  // add proposal button only enters in edit mode
  const renderAddProposalButton = useMemo(() => {
    return (
      <ECRequirePermission scopes={[P.GetProposal, P.EditProposal]}>
        <ECButton
          variant="contained"
          color="primary"
          startIcon={<Description />}
          sx={theme => ({
            border: 1,
            borderColor: `${theme.palette.primary.outlinedRestingBackground} !important`,
            color: `${theme.palette.primary.white} !important`,
            marginRight: 2,
          })}
          disabled={isEditMode}
          onClick={() => setShouldGoIntoEditMode(true)}
        >
          Add Proposal
        </ECButton>
      </ECRequirePermission>
    );
  }, [isEditMode]);

  const additionalActions = useMemo(() => {
    const actions: any[] = [];

    if (
      proposal?.workflowStatusName === WorkflowStatus.AcceptedPendingSubmission
    ) {
      actions.push(renderAddProposalButton);
    }

    if (
      proposalNextStatus?.some(
        status => status.name === WorkflowStatus.AcceptedPendingSubmission,
      )
    ) {
      actions.push(renderAcceptRfpProposalButton);
    }
    if (
      proposalNextStatus?.some(
        status => status.name === WorkflowStatus.Declined,
      )
    ) {
      actions.push(renderDeclineRfpProposalButton);
    }
    return actions;
  }, [
    proposal?.workflowStatusName,
    proposalNextStatus,
    renderAcceptRfpProposalButton,
    renderDeclineRfpProposalButton,
    renderAddProposalButton,
  ]);

  const initializeFields = () => {
    const originalSummary = {
      title: proposal?.title,
      spProposalId: proposal?.spProposalId,
    };
    setSummaryFieldsValues(originalSummary);
  };

  useEffect(() => {
    initializeFields();
  }, [proposal, isSuccess, isError, error]);

  useEffect(() => {
    initializeFields();
    setFormFields(
      fancyFormElementsEdit.fields.map(field => {
        if (field.type === 'group') {
          field.subFields.forEach(subField => {
            if (proposal?.mutableFields?.includes(subField.fieldName)) {
              subField.readOnly = false;
            } else {
              subField.readOnly = true;
            }
            return field;
          });
        }

        if (proposal?.mutableFields?.includes(field.fieldName)) {
          field.readOnly = false;
        } else {
          // if field is files, it should be always editable
          if (field.fieldName !== 'files') {
            field.readOnly = true;
          }
        }

        return field;
      }),
    );
  }, [proposal?.mutableFields]);

  useEffect(() => {
    if (isSuccesstermsUnitList && termsUnitList && formFields.length > 0) {
      setFormFields(prev => {
        const newFields = [...prev];

        let termsUnitListGroupField = newFields?.find(
          field => field.groupName === 'completionTimeGroup',
        );
        let termsUnitListField = termsUnitListGroupField?.subFields?.find(
          field => field.fieldName === 'completionPeriodCode',
        );
        termsUnitListField.options =
          termsUnitList?.map(d => ({
            label: d.description,
            value: d.code,
          })) || [];
        termsUnitListField.isLoading = false;
        termsUnitListField.optionValues = termsUnitList?.map(d => d.code) || [];

        return newFields;
      });
    }
  }, [isSuccesstermsUnitList, termsUnitList]);

  const [
    doUpdateProposal,
    {
      isError: isUpdateError,
      error: updateError,
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
    },
  ] = useUpdateProposalSPMutation();

  const useUpdateProposal = useCallback(() => {
    const doUpdate = async data => {
      if (!proposal?.id) {
        return;
      }

      if (!summaryFieldsValues.title?.trim()) {
        setValidations([
          {
            field: 'title',
            validationMessage: 'Proposal Title is required!',
          },
        ]);
        return false;
      }
      setValidations([]);

      let updateData: ProposalUpdateSP = {
        id: proposal.id,
        description: data.description,
        labor: data.labor,
        material: data.material,
        freight: data.freight,
        tax: data.tax,
        other: data.other,
        incurred: data.incurred,
        title: summaryFieldsValues.title,
        spProposalId: summaryFieldsValues.spProposalId,
        flagsIds:
          data.flagsIds?.length > 0
            ? data.flagsIds?.map(flag => flag?.id)
            : null,
        costLaborItems: data.costLaborItems?.map(item => ({
          ...item,
          costTypeId: item?.costType?.id,
          unitCount: Number(item?.unitCount),
          unitCost: Number(item?.unitCost),
          totalCost: Number(item?.totalCost),
        })),
      };

      if (data.files) {
        updateData.files = data.files.map(
          file => file.rawFile ?? file.processedFile ?? file,
        );
      }

      if (data.completionTime) {
        updateData.completionTime = data.completionTime;
      }

      if (data.completionPeriodCode) {
        updateData.completionPeriodCode = data.completionPeriodCode;
      }

      doUpdateProposal(updateData);
    };

    return [
      doUpdate,
      {
        isError: isUpdateError,
        error: updateError,
        isLoading: isUpdateLoading,
        isSuccess: isUpdateSuccess,
      },
    ];
  }, [
    isUpdateError,
    updateError,
    isUpdateLoading,
    isUpdateSuccess,
    proposal?.id,
    summaryFieldsValues,
    doUpdateProposal,
  ]);

  useEffect(() => {
    if (isUpdateSuccess && !isUpdateLoading) {
      dispatch(approvalApi.util.invalidateTags(['Approval']));
    }
  }, [isUpdateSuccess, isUpdateLoading]);

  const isAsset = useMemo(() => _.isNil(proposal?.generalAssetId), [proposal]);

  const moduleName = useMemo(() => {
    return isAsset ? 'asset' : 'general-asset';
  }, [isAsset]);

  const { data: attachments } = useGetAttachmentQuery(
    {
      module: moduleName,
      moduleId: proposal?.generalAssetId || proposal?.assetId,
      category: AttachmentCategory.Photo,
    },
    { skip: !proposal?.assetId || !isDetailsTab },
  );

  const assetProfileAttachment = useMemo(() => {
    const matchingPhotos = attachments?.others?.filter(
      attachment =>
        attachment?.documentCoreType?.code ===
        DocumentCoreTypes.assetProfilePhoto,
    );
    if (matchingPhotos && matchingPhotos.length > 0) {
      return matchingPhotos[matchingPhotos.length - 1];
    }
  }, [attachments]);

  const { data: assetWarranty } = useGetAssetWarrantyQuery(
    proposal?.assetId || null,
    { skip: !proposal?.assetId || !isDetailsTab },
  );

  // useEffect to show or hide section for the proposals for RFP rfpProposalSection
  useEffect(() => {
    if (!proposal) return;

    const detailsFields = [...formFieldsDetails];

    const rfpProposalSectionField = detailsFields?.find(
      field => field.fieldName === 'rfpProposalSection',
    );

    if (rfpProposalSectionField) {
      // costTable group should be hidden if the sectionField is visible
      const costTableGroup = detailsFields?.find(
        field => field.groupName === 'costTableGroup',
      );
      if (
        proposal?.workflowStatusName === WorkflowStatus.PendingSubmission ||
        proposal?.workflowStatusName === WorkflowStatus.Declined
      ) {
        rfpProposalSectionField.visible = true;
        rfpProposalSectionField.description =
          'Accept Proposal to start its Submission';

        if (costTableGroup) {
          costTableGroup.visible = false;
        }

        setFormFieldsDetails(detailsFields);
        return;
      } else {
        rfpProposalSectionField.visible = false;
        if (costTableGroup) {
          costTableGroup.visible = true;
        }
      }

      if (
        proposal?.workflowStatusName == WorkflowStatus.AcceptedPendingSubmission
      ) {
        if (isEditMode) {
          rfpProposalSectionField.visible = false;
          costTableGroup.visible = true;
        } else {
          rfpProposalSectionField.visible = true;
          rfpProposalSectionField.description = 'Add your Proposal';
          if (costTableGroup) {
            costTableGroup.visible = false;
          }
        }
        setFormFieldsDetails(detailsFields);
        return;
      } else {
        rfpProposalSectionField.visible = false;
        if (costTableGroup) {
          costTableGroup.visible = true;
        }
      }

      setFormFieldsDetails(detailsFields);
    }
  }, [proposal?.workflowStatusName, isEditMode]);

  const costTableData: any = useMemo(
    () => ({
      incurred: proposal?.incurred,
      showIncurred: !proposal?.rfpId,
      itemsRepair: [
        {
          label: 'Labor',
          value: proposal?.labor,
          dataPath: 'labor',
          readOnly: true,
        },
        {
          label: 'Labor Charge',
          value: proposal?.costLaborItems,
          dataPath: 'costLaborItems',
          extraData: ratesAndTerms,
          readOnly: !proposal?.mutableFields?.includes('labor'),
        },
        {
          label: 'Material',
          value: proposal?.material,
          dataPath: 'material',
          readOnly: !proposal?.mutableFields?.includes('material'),
        },
        {
          label: 'Freight',
          value: proposal?.freight,
          dataPath: 'freight',
          readOnly: !proposal?.mutableFields?.includes('freight'),
        },
        {
          label: 'Tax',
          value: proposal?.tax,
          dataPath: 'tax',
          readOnly: !proposal?.mutableFields?.includes('tax'),
        },
        {
          label: 'Other',
          value: proposal?.other,
          dataPath: 'other',
          readOnly: !proposal?.mutableFields?.includes('other'),
        },
        {
          label: 'Incurred',
          value: proposal?.incurred,
          dataPath: 'incurred',
          readOnly: !proposal?.mutableFields?.includes('incurred'),
          hidden: true,
        },
      ],
    }),
    [proposal, ratesAndTerms, isEditMode],
  );

  const handleSummaryFieldChange = (fieldName: string) => (value: any) => {
    setSummaryFieldsValues({ ...summaryFieldsValues, [fieldName]: value });
  };

  const onExitEditModeWithoutSave = () => {
    setSummaryFieldsValues({
      title: proposal?.title,
      spProposalId: proposal?.spProposalId,
    });
    setValidations([]);
  };

  const [isResubmitAction, setIsResubmitAction] = useState(false);

  const [
    doUpdateStatus,
    { isLoading: isLoadingUpdateStatus, isSuccess: isSuccessUpdateAction },
  ] = useUpdateProposalStatusMutation();

  const doUpdate = async (data: WorkflowNextStatus) => {
    setIsResubmitAction(data?.name === 'Resubmit');
    doUpdateStatus({
      id: Number(id),
      ...data,
    });
  };

  useEffect(() => {
    if (isSuccessUpdateAction && !isLoadingUpdateStatus) {
      dispatch(approvalApi.util.invalidateTags(['Approval']));
      dispatch(
        setSnackbar({
          severity: 'success',
          message: isResubmitAction
            ? 'Proposal was successfully resubmitted'
            : 'Proposal status was updated successfully',
        }),
      );
    }
  }, [isSuccessUpdateAction, isLoadingUpdateStatus]);

  useEffect(() => {
    if (
      (!isLoadingUpdateStatus && isSuccessUpdateAction) ||
      (!isUpdateLoading && isUpdateSuccess)
    ) {
      dispatch(moduleApi.util.invalidateTags(['ModuleDetails']));
    }
  }, [
    isLoadingUpdateStatus,
    isSuccessUpdateAction,
    isUpdateLoading,
    isUpdateSuccess,
  ]);

  const detailsData = useMemo(() => {
    const approvedDate =
      proposal?.workflowStatusName === WorkflowStatus.Approved
        ? moment(proposal.approvedDate).format('MM/DD/YYYY')
        : '-';

    // if BE returns assigneTo empty it means that the field should not be shown
    return {
      ...proposal,
      assignedTo: proposal?.assignedTo ? assignedToUsers : null,
      approvedDate,
    };
  }, [proposal, assignedToUsers]);

  const proposalPm = useMemo(() => {
    return proposal?.pmId
      ? {
          href: proposal?.pmId?.toString() ?? '',
          text: proposal?.pmName ?? '',
        }
      : undefined;
  }, [proposal]);

  const summaryData = useMemo(() => {
    return [
      {
        id: 'status',
        label: 'Status',
        data: proposal?.workflowStatusName,
        type: SummaryFieldTypes.Text,
      },
      {
        id: 'proposalTitle',
        label: 'Proposal Title',
        data: summaryFieldsValues?.title ?? '',
        type: SummaryFieldTypes.Text,
        isEditable: proposal?.mutableFields?.includes('title'),
        isValid: !validations?.some(v => v.field === 'title'),
        validationMessage: validations?.find(v => v.field === 'title')
          ?.validationMessage,
        onChange: handleSummaryFieldChange('title'),
      },
      {
        id: 'location',
        label: 'Location',
        data: proposal?.branchName ?? '',
        type: SummaryFieldTypes.Text,
      },
      {
        id: 'storeNumber',
        label: 'Store Number',
        data: proposal?.storeNumber ?? '',
        type: SummaryFieldTypes.Text,
      },
      {
        id: 'asset',
        label: 'Asset',
        data: proposal?.assetName ?? '',
        type: SummaryFieldTypes.Text,
      },
      {
        id: 'spProposalId',
        label: 'SP Proposal ID',
        data: summaryFieldsValues.spProposalId ?? '',
        type: SummaryFieldTypes.Text,
        isEditable: proposal?.mutableFields?.includes('spProposalId'),
        onChange: handleSummaryFieldChange('spProposalId'),
      },
      {
        id: 'customer',
        label: 'Customer',
        data: proposal?.companyName ?? '',
        type: SummaryFieldTypes.Text,
      },
      {
        id: 'category',
        label: 'Category',
        data: proposal?.category,
        type: SummaryFieldTypes.Text,
      },
    ];
  }, [proposal, summaryFieldsValues, validations, handleSummaryFieldChange]);

  return (
    <>
      <ECWorkflowTemplate
        tabsAsLinks
        title={
          t('translation:pages.proposalDetails.header') +
            ': ' +
            proposal?.id?.toString() ?? ''
        }
        summaryData={summaryData}
        actions={
          statusRfpProposal.includes(proposal?.workflowStatusName)
            ? undefined
            : proposalNextStatus
        }
        additionalActions={additionalActions}
        onActionClick={doUpdate}
        editConfig={fancyFormElementsEdit.config}
        editFields={formFields}
        detailsConfig={formElementsDetails.data.config}
        detailsFields={formFieldsDetails}
        detailsData={detailsData}
        workflowStatus={proposal?.workflowStatusName}
        imgSrc={assetProfileAttachment?.url}
        profileAttachment={assetProfileAttachment}
        moduleName={moduleName}
        moduleId={id}
        showAddCommentButton
        onEditButtonClick={setIsEditMode}
        onExitEditModeWithoutSave={onExitEditModeWithoutSave}
        warrantyBadgeType={assetWarranty?.[0]?.status}
        useUpdateMutation={useUpdateProposal}
        pm={proposalPm}
        asset={
          proposal?.assetId && !proposal?.pmId
            ? {
                href: proposal?.assetId?.toString() ?? '',
                text: proposal?.assetName,
              }
            : undefined
        }
        workOrder={
          proposal?.workorderId
            ? {
                href: proposal?.workorderId?.toString() ?? '',
                text: proposal?.workorderName,
              }
            : undefined
        }
        invoices={{
          content: proposal?.invoices.map(invoice => ({
            href: invoice.id.toString(),
            text: invoice.id?.toString(),
          })),
        }}
        proposals={{
          content: _.uniqBy(proposal?.proposals, 'id')?.map(proposal => ({
            href: (proposal as any).id.toString(),
            text: (proposal as any).title,
          })),
        }}
        rfps={
          proposal?.rfpId
            ? {
                content: [
                  {
                    href: proposal?.rfpId?.toString() ?? '',
                    text: proposal?.rfpId?.toString() ?? '',
                  },
                ],
              }
            : undefined
        }
        costTableData={costTableData}
        isLoadingActions={isLoadingUpdateStatus}
        beInEditMode={shouldGoIntoEditMode}
        isEditAllowed={
          hasEditProposalPermission[0] &&
          proposal?.workflowStatusName !== WorkflowStatus.PendingReview &&
          proposal?.workflowStatusName !== WorkflowStatus.PendingSubmission
        }
        showSkeletonLoader={isLoadingProposal}
      />
      {isDetailsTab && (
        <>
          <Popover
            id={'popover-users-table'}
            open={open}
            anchorEl={anchorEl}
            onClose={onCloseModal}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            sx={{
              minHeight: '300px',
              minWidth: '300px',
            }}
          >
            <ECBox
              minHeight={150}
              minWidth={650}
              maxHeight={400}
              sx={{
                '&::before': {
                  backgroundColor: 'white',
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  width: 12,
                  height: 12,
                  top: -6,
                  transform: 'rotate(45deg)',
                  left: 'calc(50% - 6px)',
                },
              }}
              p={2}
            >
              <ECUsersTableModal
                module="proposal"
                preExistingUsers={assignedToUsers}
                id={proposal?.id}
              />
            </ECBox>
          </Popover>
          <ECModal
            isOpen={isOpenRejectModal}
            onClose={() => setIsOpenRejectModal(false)}
            noPadding
          >
            <ServiceProviderDeclineProposalModal
              proposal={proposal}
              onCloseModal={() => setIsOpenRejectModal(false)}
              statusToId={
                proposalNextStatus?.find(
                  status => status.name === WorkflowStatus.Declined,
                )?.statusTo || 0
              }
            />
          </ECModal>
        </>
      )}
    </>
  );
}
