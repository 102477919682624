import { useMemo, useState } from 'react';
import { Check } from '@mui/icons-material';
import { ECButton } from 'app/components';
import { POConfirmationModal } from './POConfirmationModal';
import { P } from 'types/Permission';
import { PurchaseOrderStatusType } from 'app/components/ECPurchaseOrderItemsTable';

export const SetPOToComplete = ({
  purchaseOrder,
  openCreateInvoiceDrawer,
}: {
  purchaseOrder: Record<any, any>;
  openCreateInvoiceDrawer: () => void;
}) => {
  const [purchaseOrderConfirmation, setPurchaseOrderConfirmation] = useState({
    open: false,
    createNewPO: false,
  });

  const canShowCompleteButton = useMemo(() => {
    const hasItemsWithQuantityReceived =
      purchaseOrder?.purchaseOrderItems?.some(
        item => item.quantityReceived > 0,
      );

    return (
      hasItemsWithQuantityReceived &&
      [
        PurchaseOrderStatusType.PARTIALLY_RECONCILED,
        PurchaseOrderStatusType.RECONCILED,
      ].includes(purchaseOrder?.workflowStatusName)
    );
  }, [purchaseOrder]);

  const handlePOConfirm = () => {
    setPurchaseOrderConfirmation(prev => ({ ...prev, open: false }));
    openCreateInvoiceDrawer();
  };

  return (
    <>
      {canShowCompleteButton && (
        <ECButton
          startIcon={
            <Check
              sx={theme => ({ color: theme.palette.graphic.alert.success })}
            />
          }
          variant="outlined"
          onClick={() => {
            setPurchaseOrderConfirmation(prev => ({ ...prev, open: true }));
          }}
          sx={theme => ({
            color: `${theme.palette.graphic.alert.success} !important`,
            border: `1px solid  ${theme.palette.graphic.alert.success} !important`,
            marginRight: '8px',
          })}
          scopes={[P.GetPurchaseOrder, P.EditPurchaseOrder]}
        >
          Set To Complete
        </ECButton>
      )}

      <POConfirmationModal
        isOpen={purchaseOrderConfirmation.open}
        onConfirm={handlePOConfirm}
        purchaseOrder={purchaseOrder}
        closeModal={() => {
          setPurchaseOrderConfirmation(prev => ({ ...prev, open: false }));
        }}
        newPOSwitch={purchaseOrderConfirmation.createNewPO}
        setNewPOSwitch={value => {
          setPurchaseOrderConfirmation(prev => ({
            ...prev,
            createNewPO: value,
          }));
        }}
        POStatus={purchaseOrder?.workflowStatusName}
      />
    </>
  );
};
