import { useState, useEffect } from 'react';
import {
  Edit,
  Print,
  ContentCopy,
  BookmarkBorder,
  KeyboardReturn,
  Link,
  Close,
} from '@mui/icons-material';
import { ECBox } from '../ECBox';
import { ECIconButton } from '../ECIconButton';
import { ECStack } from '../ECStack';
import { ECToggleButton } from '../ECToggleButton';
import { ECTypography } from '../ECTypography';
import { ECTextField } from '../ECTextField';
import { useNavigate } from 'react-router';

interface ECWorkflowToolbarProps {
  showEditButton?: boolean;
  title: string;
  editSelected?: boolean;
  onCopyClick?: () => void;
  onEditClick?: () => void;
  onPrintButtonClick?: (e?: any) => void;
  onLinkedWOClick?: () => void;
  onChangeTitle?: (title: string) => void;
  titleProvided?: boolean;
  titleNotProvidedLabel?: string;
  showEditTitleStartAdornment?: boolean;
  isBookmarkVisible?: boolean;
  showPrintButton?: boolean;
  closeIconAsX?: boolean;
  showAddLinkedWOButton?: boolean;
}

export const ECWorkflowToolbar = ({
  title,
  editSelected: initialEditSelected,
  onCopyClick,
  onEditClick,
  onChangeTitle,
  onLinkedWOClick,
  showEditButton,
  showEditTitleStartAdornment,
  titleProvided,
  titleNotProvidedLabel,
  isBookmarkVisible = false,
  closeIconAsX = false,
  showPrintButton = false,
  showAddLinkedWOButton = false,
  onPrintButtonClick,
}: ECWorkflowToolbarProps) => {
  const [editSelected, setEditSelected] = useState(initialEditSelected);

  const navigate = useNavigate();

  useEffect(() => {
    setEditSelected(initialEditSelected);
  }, [initialEditSelected]);

  const handleBookmarkClick = () => {
    console.log('Bookmark clicked!');
  };

  const handleEditClick = () => {
    setEditSelected(!editSelected);
    onEditClick?.();
  };

  const handlePrintClick = (e: any) => {
    onPrintButtonClick?.(e);
  };

  const handleLinkedWOClick = () => {
    onLinkedWOClick?.();
  };

  const handleReturnClick = () => navigate(-1);

  const iconButtonBaseSx = {
    bgcolor: theme => theme.palette.common.white,
    borderRadius: 1,
    '&:hover': {
      bgcolor: theme => theme.palette.common.white,
    },
  };

  return (
    <ECBox
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      bgcolor={theme => theme.palette.action.hover}
      py={1}
      px={3}
    >
      <ECIconButton
        squared
        onClick={handleBookmarkClick}
        sx={{
          visibility: isBookmarkVisible ? 'visible' : 'hidden',
          ...iconButtonBaseSx,
        }}
        type="warning"
      >
        <BookmarkBorder />
      </ECIconButton>

      {onChangeTitle && editSelected ? (
        <ECTextField
          sx={{ width: '50%' }}
          hiddenLabel
          required
          variant="filled"
          defaultValue={title}
          error={!titleProvided}
          helperText={titleProvided ? undefined : titleNotProvidedLabel}
          onChange={event => {
            onChangeTitle?.(event.target.value);
          }}
        />
      ) : (
        <ECTypography fontWeight="bold" variant="h4" maxWidth="80%">
          {showEditTitleStartAdornment && editSelected
            ? `Edit ${title}`
            : title}
        </ECTypography>
      )}

      <ECStack direction="row" spacing={1} alignItems="center">
        {showEditButton && onEditClick && (
          <ECToggleButton
            value="edit"
            selected={editSelected}
            onChange={handleEditClick}
            sx={iconButtonBaseSx}
          >
            <Edit />
          </ECToggleButton>
        )}

        {onCopyClick && (
          <ECIconButton
            squared
            filled
            onClick={onCopyClick}
            sx={iconButtonBaseSx}
          >
            <ContentCopy />
          </ECIconButton>
        )}

        {showPrintButton && (
          <ECIconButton
            squared
            filled
            onClick={e => handlePrintClick(e)}
            sx={iconButtonBaseSx}
          >
            <Print />
          </ECIconButton>
        )}
        {showAddLinkedWOButton && (
          <ECToggleButton
            value="linkedWO"
            selected={false}
            onChange={handleLinkedWOClick}
            sx={iconButtonBaseSx}
          >
            <Link />
          </ECToggleButton>
        )}

        <ECIconButton
          squared
          filled
          onClick={handleReturnClick}
          sx={iconButtonBaseSx}
        >
          {closeIconAsX ? <Close /> : <KeyboardReturn />}
        </ECIconButton>
      </ECStack>
    </ECBox>
  );
};
