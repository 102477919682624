import { ECAlert, ECBox, ECGrid, ECPaper, ECTypography } from 'app/components';
import { ECEasyFormCreate } from 'app/components/ECDynamicForm';
import { ECEasyFormFieldType } from 'app/components/ECForm';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ECDrawerDetails } from 'app/components/ECDrawerDetails';
import { themes } from 'styles/theme/themes';
import _ from 'lodash';
import {
  useAllocateInventoryItemToWOMutation,
  useGetInventoryItemsListQuery,
} from 'services/inventoryApi';
import { currencyFormatter } from 'utils/strings/currency-formatter';

const fancyFormElements = require('./fancy_form_config_allocate_inventory.json');

interface AllocatedInventoryItemsProps {
  isOpen: boolean;
  onClose: () => void;
  existingData?: any;
  assetGroupId?: number;
}

export const AllocatedInventoryItems = ({
  isOpen,
  existingData,
  onClose,
  assetGroupId,
}: AllocatedInventoryItemsProps) => {
  const [formFields, setFormFields] = useState<ECEasyFormFieldType[]>(
    fancyFormElements.fields,
  );
  const [disableSaveButton, setDisableSaveButton] = useState<boolean>(true);
  const [totalCost, setTotalCost] = useState(0);
  const [exceeded, setExceeded] = useState<boolean | 0>(false);

  const [
    doAllocateInventoryItem,
    {
      data: allocateInventoryData,
      isError: isAllocateError,
      error: allocateError,
      isLoading: isAllocateLoading,
      isSuccess: isAllocateSuccess,
      reset: resetAllocateInventory,
    },
  ] = useAllocateInventoryItemToWOMutation();

  const useAllocateInventory = useCallback(() => {
    const doCreate = async data => {
      delete data.undefined;
      const transformedData: any[] =
        data?.inventoryItems?.flatMap(item =>
          item.selectedLocations.map(location => ({
            inventoryItemId: item?.id,
            inventoryStorageId: location.inventoryStorageId,
            quantity: parseFloat(location.quantity),
            price: parseFloat(location.avgPrice),
          })),
        ) || [];
      doAllocateInventoryItem({
        workOrderId: existingData?.workOrderId,
        allocateInventory: transformedData,
      });
    };

    return [
      doCreate,
      {
        data: allocateInventoryData,
        isError: isAllocateError,
        error: allocateError,
        isLoading: isAllocateLoading,
        isSuccess: isAllocateSuccess,
      },
    ];
  }, [
    allocateInventoryData,
    isAllocateError,
    allocateError,
    isAllocateLoading,
    isAllocateSuccess,
    existingData?.workOrderId,
    doAllocateInventoryItem,
  ]);

  useEffect(() => {
    if (!isOpen && fancyFormElements) {
      setFormFields(
        fancyFormElements.fields.map(field => ({
          ...field,
          value: null,
          assetGroupId: assetGroupId,
        })),
      );
      resetAllocateInventory();
    }
  }, [isOpen, assetGroupId]);

  const handleOutputChange = (
    output: ECEasyFormFieldType[],
    fieldName?: string,
  ) => {
    if (fieldName === 'inventoryItems') {
      const inventoryItemField = output.find(
        field => field.fieldName === 'inventoryItems',
      );
      const totalCostofItems =
        inventoryItemField?.value?.reduce((total, item) => {
          if (item.selectedLocations) {
            return (
              total +
              item.selectedLocations.reduce((subtotal, location) => {
                return subtotal + location.quantity * Number(location.avgPrice);
              }, 0)
            );
          }
          return total;
        }, 0) + existingData?.invoiceTotal || 0;
      const isExceed = existingData?.nte && existingData.nte < totalCostofItems;
      setDisableSaveButton(isExceed);
      setTotalCost(totalCostofItems);
      setExceeded(isExceed ?? false);
    }
  };

  const totalLabelInfo = useMemo(() => {
    if (isOpen) {
      return `(Subtotal for Repair: ${currencyFormatter.format(existingData?.invoiceTotal || 0)} + Subtotal for Inventory:  ${currencyFormatter.format(totalCost - existingData?.invoiceTotal || 0)})`;
    }
  }, [totalCost, existingData?.invoiceTotal, isOpen]);

  const nteSaveAlertObject = useMemo(() => {
    return (
      <ECGrid container alignItems="center">
        <ECGrid
          item
          xs={12}
          p={2}
          bgcolor={theme => theme.palette.background.default}
        >
          <ECBox
            display="flex"
            flexDirection="column"
            borderRadius={2}
            border={1}
            overflow="hidden"
            borderColor={theme =>
              exceeded
                ? theme.palette.error.outlinedRestingBackground
                : theme.palette.divider
            }
          >
            <ECBox
              display="flex"
              p={2}
              alignItems="center"
              justifyContent="space-between"
              borderBottom={1}
              borderColor={theme =>
                exceeded
                  ? theme.palette.error.outlinedRestingBackground
                  : theme.palette.divider
              }
            >
              <ECTypography
                color={theme => theme.palette.text.secondary}
                variant="h6"
              >
                {exceeded ? 'Total must be less than or equal to NTE' : 'Total'}
              </ECTypography>
              {!exceeded && (
                <ECTypography
                  color={theme => theme.palette.text.secondary}
                  variant="body1"
                >
                  {totalLabelInfo}
                </ECTypography>
              )}
              <ECTypography
                color={theme => theme.palette.text.secondary}
                variant="h4"
                id="ec-costtable-total"
              >
                {currencyFormatter.format(totalCost || 0)}
              </ECTypography>
            </ECBox>
            <ECBox
              display="flex"
              p={2}
              alignItems="center"
              justifyContent="space-between"
              bgcolor={theme =>
                exceeded
                  ? theme.palette.error.outlinedRestingBackground
                  : theme.palette.action.selected
              }
            >
              <ECTypography
                color={theme => theme.palette.text.secondary}
                variant="body2"
              >
                NTE
              </ECTypography>

              <ECTypography
                variant="body2"
                color={theme => theme.palette.text.secondary}
              >
                {currencyFormatter.format(existingData?.nte || 0)}
              </ECTypography>
            </ECBox>
          </ECBox>
        </ECGrid>
        {exceeded && (
          <ECGrid
            item
            xs={12}
            bgcolor={theme => theme.palette.background.default}
            sx={{ display: 'flex', justifyContent: 'center' }}
          >
            <ECAlert
              severity="error"
              variant="outlined"
              sx={{ width: '90%', margin: 1, marginTop: -1 }}
            >
              The Allocation can not be saved because it is above the NTE
            </ECAlert>
          </ECGrid>
        )}
      </ECGrid>
    );
  }, [totalCost, existingData?.nte, exceeded]);

  return (
    <ECDrawerDetails open={isOpen} anchor="right" onClose={onClose}>
      <ECPaper sx={{ height: '100%', paddingTop: '80px' }} role="presentation">
        <ECEasyFormCreate
          useCreateMutation={useAllocateInventory}
          formConfig={fancyFormElements.config}
          formFields={formFields}
          onClose={onClose}
          drawerTitleBarBGColor={themes.light.palette.other.divider}
          onChange={handleOutputChange}
          saveButtonDisabled={disableSaveButton}
          nteSaveAlertObject={nteSaveAlertObject}
          noTopMargin
        />
      </ECPaper>
    </ECDrawerDetails>
  );
};
