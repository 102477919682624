import { ECTextField } from 'app/components';
import { useEffect, useState } from 'react';

type CreditCardExpirationDateProps = {
  onChange?: (value: any) => void;
  value?: any | null;
  validationMessage?: string | null;
  fieldName: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  isValid?: boolean;
};

export const CreditCardExpirationDate = ({
  onChange,
  value,
  validationMessage,
  fieldName,
  label,
  placeholder,
  required,
  isValid,
}: CreditCardExpirationDateProps) => {
  const [errorMessage, setErrorMessage] = useState(validationMessage);

  useEffect(() => {
    setErrorMessage(validationMessage);
  }, [validationMessage]);

  return (
    <ECTextField
      id={fieldName}
      name={fieldName}
      value={value}
      error={!!errorMessage || !!isValid}
      helperText={errorMessage}
      label={required ? `${label}*` : label}
      placeholder={placeholder}
      variant="filled"
      onBlur={event => {
        let inputValue: string = event.target.value.replace(/\D/g, '').trim();
        if (inputValue.length > 5) {
          inputValue = inputValue.slice(0, 5);
        }

        if (inputValue?.length < 4) {
          setErrorMessage('Format is invalid');
          return;
        }
        if (inputValue?.length === 4) {
          const expirationMonth = Number(inputValue?.substring(0, 2));
          const expirationYear = Number(inputValue?.substring(2, 4));

          if (expirationMonth < 1 || expirationMonth > 12) {
            return setErrorMessage('Month is invalid');
          }
          if (expirationYear + 2000 < new Date().getFullYear()) {
            return setErrorMessage(
              'Looks like your card is Expired. Add another date or card.',
            );
          }
          if (expirationMonth < new Date().getMonth() + 1) {
            return setErrorMessage(
              'Looks like your card is Expired. Add another date or card.',
            );
          }
          setErrorMessage(null);
        }
      }}
      onChange={event => {
        let inputValue: string = event.target.value
          .replace(/\D/g, '')
          .trim()
          .slice(0, 5);

        onChange?.(inputValue);
      }}
      mask={'##/##'}
    />
  );
};
