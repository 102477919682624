import {
  ECBox,
  ECEasyFormCreate,
  ECEasyFormFieldType,
  ECPaper,
} from 'app/components';
import { ECDrawerDetails } from 'app/components/ECDrawerDetails';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { PaymentSelector } from '../SignUpWizardPage/paymentSelector';
import { useCreatePaymentMethodMutation } from 'services/paymentMethod';
import { useDispatch } from 'react-redux';
import { profileApi, useGetProfileQuery } from 'services/profileApi';
import { getAddressDataFromFieldValues } from 'utils/address';
import { invalidateCompanyProfile } from 'utils/invalidateCache';

const createFormElements = require('./fancy_form_config_create.json');

export function PaymentMethodCreateDrawer({
  openPaymentMethodDrawer,
  handleClose,
  shouldShowAsFullPage,
}) {
  const { data: companyProfile } = useGetProfileQuery(undefined, {
    skip: !shouldShowAsFullPage,
  });
  const [paymentMethod, setPaymentMethod] = useState<'creditcard' | 'ach'>(
    'creditcard',
  );
  const [createFormElementsFields, setCreateFormElementsFields] = useState<
    ECEasyFormFieldType[]
  >(createFormElements.fields);
  const [createFormElementsFieldsState, setCreateFormElementsFieldsState] =
    useState<ECEasyFormFieldType[]>(createFormElements.fields);
  const dispatch = useDispatch();

  const [
    doCreatePaymentMethod,
    {
      data: createPaymentMethodData,
      isError: isCreateError,
      error: createError,
      isLoading: isCreateLoading,
      isSuccess: isCreateSuccess,
    },
  ] = useCreatePaymentMethodMutation();

  const useCreatePaymentMethod = useCallback(() => {
    const doCreate = async data => {
      delete data.undefined;
      const user = {
        firstName: data['user.firstName'],
        lastName: data['user.lastName'],
        email: data['user.email'],
      };
      const company = {
        phone: data['company.phone'],
        address: getAddressDataFromFieldValues(data),
      };

      const mapPaymentType = {
        creditcard: {
          cardName: data['paymentInfo.cardName'],
          cardNumber: data['paymentInfo.cardNumber']?.replace(/\s/g, ''),
          expirationMonth: data['paymentInfo.expirationDate']?.substring(0, 2),
          expirationYear: data['paymentInfo.expirationDate']?.substring(2, 4),
          cvv: data['paymentInfo.creditCardCVV'],
        },
        ach: {
          accountingNumber: data['paymentInfo.accountingNumber'],
          routingNumber: data['paymentInfo.routingNumber'],
        },
      };
      const paymentInfo = {
        selected: data.subscriptionPaymentMethod.includes(
          'Subscription Payment Method',
        ),
        paymentType:
          paymentMethod === 'creditcard' ? ('CC' as any) : ('ACH' as any),
        ...mapPaymentType[paymentMethod],
      };
      doCreatePaymentMethod({
        user,
        company,
        paymentInfo,
        assigneeUserIds: shouldShowAsFullPage
          ? [companyProfile?.id]
          : data.assigneeUserIds,
      });
    };

    return [
      doCreate,
      {
        data: createPaymentMethodData,
        isError: isCreateError,
        error: createError,
        isLoading: isCreateLoading,
        isSuccess: isCreateSuccess,
      },
    ];
  }, [
    createPaymentMethodData,
    isCreateError,
    createError,
    isCreateLoading,
    isCreateSuccess,
    paymentMethod,
    doCreatePaymentMethod,
    shouldShowAsFullPage,
    companyProfile,
  ]);

  useEffect(() => {
    if (isCreateSuccess) {
      invalidateCompanyProfile();
      dispatch(profileApi.util.invalidateTags(['Profile']));

      if (shouldShowAsFullPage) {
        handleClose?.();
      }
    }
  }, [isCreateSuccess]);

  useEffect(() => {
    const fieldVisibilityMap = {
      creditcard: {
        visibleFields: [
          'creditCard',
          'creditCardNumberGroup',
          'creditCardFieldsCVVExpireDateGroup',
          'creditCardNameGroup',
        ],
        hiddenFields: [
          'ach',
          'paymentInfo.routingNumber',
          'paymentInfo.accountingNumber',
        ],
      },
      ach: {
        visibleFields: [
          'ach',
          'paymentInfo.routingNumber',
          'paymentInfo.accountingNumber',
        ],
        hiddenFields: [
          'creditCard',
          'creditCardNumberGroup',
          'creditCardFieldsCVVExpireDateGroup',
          'creditCardNameGroup',
        ],
      },
    };

    const { visibleFields, hiddenFields } = fieldVisibilityMap[paymentMethod];
    const updatedFields = createFormElementsFieldsState.map(field => ({
      ...field,
      visible: visibleFields.includes(field.fieldName)
        ? true
        : hiddenFields.includes(field.fieldName)
          ? false
          : field.visible,
    }));

    setCreateFormElementsFields(updatedFields);
  }, [paymentMethod]);

  const changePaymentSection = useCallback(
    (section: 'creditcard' | 'ach') => {
      setPaymentMethod(section);
    },
    [setPaymentMethod],
  );

  useEffect(() => {
    if (!openPaymentMethodDrawer) return;

    const fields = createFormElements.fields;

    const paymentSelectorField = fields
      .find(field => field.groupName === 'paymentSelectorGroup')
      ?.subFields?.find(subField => subField.fieldName === 'paymentSelector');

    if (paymentSelectorField) {
      paymentSelectorField.value = (
        <PaymentSelector onChange={changePaymentSection} />
      );
    }

    setCreateFormElementsFields([...fields]);
  }, [openPaymentMethodDrawer, changePaymentSection]);

  const handleOnChange = useCallback(output => {
    setCreateFormElementsFieldsState(output);
  }, []);

  // when it is shouldShowAsFullPage, we need to hide and add some fields
  useEffect(() => {
    if (shouldShowAsFullPage) {
      const fields = [
        {
          type: 'section',
          optionalSx: {
            gap: '24px',
            display: 'flex',
            flexDirection: 'column',
          },
          fieldName: 'sectionPayment',
          label: 'Payment method',
          description: '',
          visible: true,
          value: null,
        },
        ...createFormElementsFields,
      ];

      const checkboxSubscriptionPaymentMethodField = fields.find(
        field => field.fieldName === 'subscriptionPaymentMethod',
      );

      if (checkboxSubscriptionPaymentMethodField) {
        checkboxSubscriptionPaymentMethodField.visible = false;
      }

      createFormElements.config.submitTitle = 'Log In';
      setCreateFormElementsFields(fields as any);
    }
  }, [shouldShowAsFullPage]);

  const easyFormContent = useMemo(() => {
    return (
      <ECEasyFormCreate
        useCreateMutation={useCreatePaymentMethod}
        showTitle={!shouldShowAsFullPage}
        showWideSaveButton={shouldShowAsFullPage}
        showSaveButton={!shouldShowAsFullPage}
        onClose={handleClose}
        formConfig={createFormElements.config}
        formFields={createFormElementsFields}
        onChange={handleOnChange}
        noTopMargin
      />
    );
  }, [
    handleClose,
    createFormElementsFields,
    handleOnChange,
    useCreatePaymentMethod,
    shouldShowAsFullPage,
  ]);

  return (
    <>
      {shouldShowAsFullPage ? (
        <ECBox>{easyFormContent}</ECBox>
      ) : (
        <ECDrawerDetails
          open={openPaymentMethodDrawer}
          anchor="right"
          onClose={handleClose}
        >
          <ECPaper
            sx={{
              height: '100%',
              paddingTop: '80px',
              boxShadow: 'none',
            }}
            role="presentation"
          >
            {easyFormContent}
          </ECPaper>
        </ECDrawerDetails>
      )}
    </>
  );
}
