import { ArrowBackIos } from '@mui/icons-material';
import { StyleConstants } from 'styles/StyleConstants';
import { useMediaQuery, useTheme } from '@mui/material';
import {
  ECBox,
  ECButton,
  ECCopyright,
  ECMainBox,
  ECNotLoggedInBar,
  ECPoliceAndTerms,
  ECLoginBanner,
  ECLink,
  ECLoginFooterLinks,
} from '..';
import Logo from '../../../assets/ecotrak-big-logo.svg';

export interface ECLoginTemplateProps {
  onGoBack?: () => void;
  onGoBackText?: string;
  children: React.ReactNode;
}

export const ECLoginTemplate = ({
  onGoBack,
  onGoBackText,
  children,
}: ECLoginTemplateProps) => {
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <ECMainBox
      component={'main'}
      sx={theme => ({
        display: 'flex',
        width: '100vw',
        height: '100vh',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: theme.palette.marketingSuggestion.marketingSuggestion,
        overflowX: 'hidden',
        position: 'relative',
      })}
    >
      <ECNotLoggedInBar />

      <ECBox
        display="flex"
        mt={'3.75rem'}
        width="100%"
        p={2}
        position="absolute"
      >
        <ECButton
          sx={theme => ({
            visibility: onGoBack ? 'visible' : 'hidden',
            color: `${theme.palette.common.white} !important`,
            padding: isMobileSize ? '6px 0' : null,
          })}
          onClick={onGoBack}
          startIcon={<ArrowBackIos />}
        >
          {onGoBackText}
        </ECButton>
      </ECBox>
      <ECLink href="https://ecotrak.com/">
        <img
          src={Logo}
          alt="logo"
          style={{
            height: isMobileSize ? '5rem' : '205px',
            zIndex: 1,
            marginTop: isMobileSize ? '-2.5rem' : StyleConstants.NAV_BAR_HEIGHT,
          }}
        />
      </ECLink>
      <ECBox sx={{ width: '474px', maxWidth: '90%', marginTop: '8px' }}>
        {children}
      </ECBox>
      <ECLoginBanner />
      <ECCopyright sx={{ mt: 8, mb: 0, bottom: 0, marginTop: 'auto' }} />
      <ECPoliceAndTerms sx={{ mb: 4 }} />

      <ECLoginFooterLinks visible={isMobileSize} />
    </ECMainBox>
  );
};
