import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { ECBox } from '../../ECBox';
import {
  useGetApprovalActionsQuery,
  useLazyGetApprovalStepsQuery,
} from 'services/approvalApi';
import { useGetJobTitlesListQuery } from 'services/jobTitleApi';
import { useTranslation } from 'react-i18next';
import {
  ECEasyTable,
  ECTypography,
  ECTabContainer,
  ECAlert,
  ECButton,
} from '../..';
import { ECEasyFormFieldType } from '../..';
import { Add } from '@mui/icons-material';
import * as _ from 'lodash';
import { arrayMove } from '@dnd-kit/sortable';
import {
  ApprovalStepForm,
  ApprovalStepsType,
  StepsLkpModule,
} from '../../../../types/Approval';
import { ECCheckbox } from 'app/components/ECCheckbox';
import { StatusActions } from 'types/WorkflowStatus';
import ECCurrencyField from 'app/components/ECCurrencyField';
import { ECConfirmationDialog } from 'app/components/ECDialog';

export interface StepsTabProps {
  sx?: any;
  row: any;
  onChange?: (
    output: ECEasyFormFieldType[],
    {
      isAllJobTitleMustReviewSelected,
    }: {
      isAllJobTitleMustReviewSelected: boolean;
      isAutoApproveSelected: boolean;
      isAutoApproveLimitSelected: boolean;
      autoApprovalLimit: number;
    },
  ) => void;
  editSelected?: boolean;
  isCreateNewStep?: boolean;
  lkpModule?: StepsLkpModule;
  initialApprovalData?: any;
  setInitialApprovalData?: any;
}

export interface StepsTabDetailsProps {
  sx?: any;
  row: any;
  onChange?: (
    output: ECEasyFormFieldType[],
    {
      isAllJobTitleMustReviewSelected,
    }: {
      isAllJobTitleMustReviewSelected: boolean;
      isAutoApproveSelected: boolean;
      isAutoApproveLimitSelected: boolean;
      autoApprovalLimit: number;
    },
  ) => void;
  editSelected?: boolean;
  isCreateNewStep?: boolean;
  lkpModule: StepsLkpModule;
  initialApprovalData?: any;
  setInitialApprovalData?: any;
}

export enum ApprovalRoleTypeIds {
  Required = 1,
  SuperApprover = 2,
  Previewer = 3,
  Reviewer = 4,
}

const emptyWorkflowRow: ApprovalStepForm = {
  approvalProcessId: null,
  approvalRoleTypeId: ApprovalRoleTypeIds.Previewer,
  jobTitleId: null,
  limit: 0,
  activeDays: 0,
  order: 1,
  step: 1,
  jobTitle: {
    id: null,
    name: '',
  },
  approvalRoleType: {
    id: ApprovalRoleTypeIds.Previewer,
    name: 'Previewer',
  },
  actions: [],
};

const emptySpecialApproverRow = {
  id: [],
  approvalProcessId: null,
  approvalRoleTypeId: ApprovalRoleTypeIds.Required,
  jobTitleId: null,
  limit: 0,
  activeDays: 0,
  order: null,
  jobTitle: [],
  approvalRoleType: {
    id: ApprovalRoleTypeIds.Required,
    name: 'Required',
  },
  actions: [],
};

const tableConfigWorkflowRaw = require('./tableConfigWorkflow.json');
const tableConfigSuperApproverRaw = require('./tableConfigSuperApprover.json');
const tableConfigSpecialApproverRaw = require('./tableConfigSpecialApprover.json');

const StepsTabDetails = (props: StepsTabDetailsProps) => {
  const {
    row,
    onChange,
    editSelected,
    isCreateNewStep = false,
    lkpModule,
    initialApprovalData,
    setInitialApprovalData,
  } = props;

  const tableConfigWorkflow = _.cloneDeep(tableConfigWorkflowRaw);
  const tableConfigSuperApprover = _.cloneDeep(tableConfigSuperApproverRaw);
  const tableConfigSpecialApprover = _.cloneDeep(tableConfigSpecialApproverRaw);

  const { t } = useTranslation();

  const [superApproverSteps, setSuperApproverSteps] = useState<any[]>([]);
  const [specialApproverSteps, setSpecialApproverSteps] = useState<any[]>([]);
  const [workflowSteps, setWorkflowSteps] = useState<any[]>([]);
  const [isAllJobTitleMustReviewSelected, setIsAllJobTitleMustReviewSelected] =
    useState<boolean>(
      lkpModule === StepsLkpModule.Invoice
        ? !!row?.requireStepsInvoice
        : !!row?.requireStepsProposal,
    );
  const [isAutoApproveSelected, setIsAutoApproveSelected] = useState<boolean>(
    row?.autoApprovalOnProposal,
  );
  const [isAutoApproveLimitSelected, setIsAutoApproveLimitSelected] =
    useState<boolean>(
      lkpModule === StepsLkpModule.Invoice
        ? row?.autoApprovalLimitInvoice > 0
        : row?.autoApprovalLimitProposal > 0,
    );
  const [autoApprovalLimit, setAutoApprovalLimit] = useState(
    lkpModule === StepsLkpModule.Invoice
      ? row?.autoApprovalLimitInvoice
      : row?.autoApprovalLimitProposal,
  );

  const [triggerJobTitleDropdown, setTriggerJobTitleDropdown] =
    useState<boolean>(false);

  const [configWorkflow, setConfigWorkflow] = useState(tableConfigWorkflow);
  const [configSuperApprover, setConfigSuperApprover] = useState(
    tableConfigSuperApprover,
  );
  const [configSpecialApprover, setConfigSpecialApprover] = useState(
    tableConfigSpecialApprover,
  );

  const [steps, setSteps] = useState<any[]>([]);
  const [isLoadingSteps, setIsLoadingSteps] = useState<boolean>(false);

  const { data: actions, isSuccess: isSuccessActions } =
    useGetApprovalActionsQuery();

  //const { data: roleTypes, isLoading: isLoadingRoleTypes } =
  //  useGetApprovalRoleTypesQuery();

  const emptySuperApproverRow = useMemo(() => {
    const baseActions = [
      {
        name: 'APPROVE',
        id: 1,
      },
      {
        name: 'REJECT',
        id: 2,
      },
    ];

    if (lkpModule !== StepsLkpModule.Proposal) {
      baseActions.push({
        name: 'NOTFIXED',
        id: 3,
      });
    }

    return {
      id: [],
      approvalProcessId: null,
      approvalRoleTypeId: ApprovalRoleTypeIds.SuperApprover,
      jobTitleId: null,
      limit: 0,
      activeDays: 0,
      isNew: true,
      order: null,
      jobTitle: [],
      approvalRoleType: {
        id: ApprovalRoleTypeIds.SuperApprover,
        name: 'Super Approver',
      },
      actions: baseActions,
    };
  }, [lkpModule]);

  useEffect(() => {
    if (isCreateNewStep) {
      setSuperApproverSteps([emptySuperApproverRow]);
      setWorkflowSteps([{ ...emptyWorkflowRow }]);
    }
  }, []);

  const [trigger, result] = useLazyGetApprovalStepsQuery();

  useEffect(() => {
    if (!isCreateNewStep) {
      trigger({ id: row?.id, module: lkpModule });
    }
  }, [isCreateNewStep]);

  useEffect(() => {
    if (result) {
      const {
        data: stepsData,
        isLoading: isLoadingStepsData,
        isSuccess: isSuccessStepsData,
      } = result;

      if (stepsData) {
        // in the proposal tab we do not have not fixed
        setSteps(
          stepsData.data?.map(step => {
            const filteredActions =
              lkpModule === StepsLkpModule.Proposal
                ? step.actions.filter(
                    action => action.name !== StatusActions.NOTFIXED,
                  )
                : step.actions;
            return { ...step, actions: filteredActions };
          }),
        );
      }
      setIsLoadingSteps(isLoadingStepsData);
    }
  }, [result]);

  const { data, isSuccess } = useGetJobTitlesListQuery({});

  const setApprovalStepsInInitialData = (initialData, lkpModule, type) => {
    setInitialApprovalData(prevData => {
      const newData = { ...prevData };

      if (lkpModule === StepsLkpModule.Invoice) {
        if (type === ApprovalStepsType.SuperApproval) {
          newData.invoice.superApproval = initialData;
        } else if (type === ApprovalStepsType.SpecialApproval) {
          newData.invoice.specialApproval = initialData;
        } else if (type === ApprovalStepsType.WorkFlow) {
          newData.invoice.workFlow = initialData;
        }
      } else if (lkpModule === StepsLkpModule.Proposal) {
        if (type === ApprovalStepsType.SuperApproval) {
          newData.proposal.superApproval = initialData;
        } else if (type === ApprovalStepsType.SpecialApproval) {
          newData.proposal.specialApproval = initialData;
        } else if (type === ApprovalStepsType.WorkFlow) {
          newData.proposal.workFlow = initialData;
        }
      }
      return newData;
    });
  };

  const updateSuperApproverSteps = useCallback(() => {
    const superApprovers = steps?.filter(
      step => step.approvalRoleTypeId === ApprovalRoleTypeIds.SuperApprover,
    );
    if (superApprovers?.length) {
      let superGroupedByJobTitle = { ...superApprovers[0] };
      superGroupedByJobTitle.jobTitle =
        superApprovers.map(reviewer => {
          return {
            id: reviewer.jobTitle?.id || reviewer.jobTitleId,
            name: reviewer.jobTitle?.name,
          };
        }) || [];

      superGroupedByJobTitle.id = superApprovers.map(reviewer => reviewer.id);

      setSuperApproverSteps([superGroupedByJobTitle]);
      setApprovalStepsInInitialData(
        [superGroupedByJobTitle],
        lkpModule,
        ApprovalStepsType.SuperApproval,
      );
    } else {
      setSuperApproverSteps([...(editSelected ? [emptySuperApproverRow] : [])]);
    }
  }, [steps]);

  useEffect(() => {
    //super
    updateSuperApproverSteps();

    if (steps?.length) {
      const stepsWithNoSuperApprover = steps.filter(
        step => step.approvalRoleTypeId !== ApprovalRoleTypeIds.SuperApprover,
      );
      //special
      const specialApproverList: any[] = [];
      //reviewer
      const reviewerSteps = stepsWithNoSuperApprover.filter(
        step => step.approvalRoleTypeId === ApprovalRoleTypeIds.Reviewer,
      );

      let reviewerGroupedByJobTitle;

      if (reviewerSteps.length) {
        reviewerGroupedByJobTitle = { ...reviewerSteps[0] };

        reviewerGroupedByJobTitle.jobTitle = reviewerSteps.map(reviewer => {
          return {
            id: reviewer.jobTitle?.id || reviewer.jobTitleId,
            name: reviewer.jobTitle?.name,
          };
        });
        reviewerGroupedByJobTitle.id = reviewerSteps.map(
          reviewer => reviewer.id,
        );
        specialApproverList.push(reviewerGroupedByJobTitle);
      }
      //required
      const requiredSteps = stepsWithNoSuperApprover.filter(
        step => step.approvalRoleTypeId === ApprovalRoleTypeIds.Required,
      );

      let requiredGroupedByJobTitle;

      if (requiredSteps.length) {
        requiredGroupedByJobTitle = { ...requiredSteps[0] };
        requiredGroupedByJobTitle.jobTitle = requiredSteps.map(required => {
          return {
            id: required.jobTitle?.id || required.jobTitleId,
            name: required.jobTitle?.name,
          };
        });
        requiredGroupedByJobTitle.id = requiredSteps.map(
          required => required.id,
        );

        specialApproverList.push(requiredGroupedByJobTitle);
      }

      setSpecialApproverSteps(specialApproverList);
      setApprovalStepsInInitialData(
        specialApproverList,
        lkpModule,
        ApprovalStepsType.SpecialApproval,
      );

      //workflow
      const filteredWorkFlow = stepsWithNoSuperApprover
        .filter(
          step =>
            step.approvalRoleTypeId !== ApprovalRoleTypeIds.Required &&
            step.approvalRoleTypeId !== ApprovalRoleTypeIds.Reviewer,
        )
        .sort((a, b) => a.order - b.order);
      setWorkflowSteps(filteredWorkFlow);
      setApprovalStepsInInitialData(
        filteredWorkFlow,
        lkpModule,
        ApprovalStepsType.WorkFlow,
      );
      setTriggerJobTitleDropdown(!triggerJobTitleDropdown);
    }
  }, [steps]);

  useEffect(() => {
    if (actions) {
      // in the proposal tab we do not have not fixed state
      const filteredActions = (actions || []).filter(
        d =>
          !(
            lkpModule === StepsLkpModule.Proposal &&
            d.name === StatusActions.NOTFIXED
          ),
      );
      const actionsDataOptions =
        filteredActions?.map(d => ({ label: d.name })) || [];
      const actionsDataOptionValues = filteredActions?.map(d => d.name) || [];

      const newConfigWorkflow = { ...configWorkflow };
      const newConfigSuperApprover = { ...configSuperApprover };
      const newConfigRoleSteps = { ...configSpecialApprover };

      const editSelectFieldActionsWorkflow =
        newConfigWorkflow.data.config.cols.find(
          field => field.fieldName === 'actions',
        );
      const editSelectFieldActionsRoleSteps =
        newConfigRoleSteps.data.config.cols.find(
          field => field.fieldName === 'actions',
        );
      editSelectFieldActionsWorkflow.options = actionsDataOptions;
      editSelectFieldActionsWorkflow.optionValues = actionsDataOptionValues;
      editSelectFieldActionsRoleSteps.options = actionsDataOptions;
      editSelectFieldActionsRoleSteps.optionValues = actionsDataOptionValues;

      setConfigWorkflow(newConfigWorkflow);
      setConfigSuperApprover(newConfigSuperApprover);
      setConfigSpecialApprover(newConfigRoleSteps);
    }
  }, [actions, isSuccessActions]);

  const populateJobTitlesDropdown = () => {
    const jobTitlesDataRaw = (data as any)?.data ?? data;
    const jobTitlesData = jobTitlesDataRaw?.filter(
      jobTitle =>
        !jobTitle?.roles
          ?.map(({ code }) => code)
          ?.includes('STANDARD_APPROVER'),
    );
    if (jobTitlesData) {
      const newConfigWorkflow = { ...configWorkflow };
      const newConfigSuperApprover = { ...configSuperApprover };
      const newConfigRoleSteps = { ...configSpecialApprover };

      const jobTitlesDataOptions =
        jobTitlesData.map(d => ({
          id: d.id,
          label: d.name,
          disabled:
            superApproverSteps?.some(
              sprAppr =>
                Array.isArray(sprAppr?.jobTitle) &&
                sprAppr?.jobTitle?.some(jt => jt.id === d.id),
            ) ||
            specialApproverSteps?.some(
              spcAppr =>
                Array.isArray(spcAppr?.jobTitle) &&
                spcAppr?.jobTitle?.some(jt => jt.id === d.id),
            ) ||
            workflowSteps?.some(wkflw => wkflw.jobTitleId === d.id),
        })) || [];
      const jobTitlesDataOptionValues = jobTitlesData.map(d => d.id) || [];

      const editSelectFieldJobTitleWorkflow =
        newConfigWorkflow.data.config.cols.find(
          field => field.fieldName === 'jobTitle',
        );
      const editSelectFieldJobTitleSuperApprover =
        newConfigSuperApprover.data.config.cols.find(
          field => field.fieldName === 'jobTitle',
        );
      const editSelectFieldJobTitleRoleSteps =
        newConfigRoleSteps.data.config.cols.find(
          field => field.fieldName === 'jobTitle',
        );
      editSelectFieldJobTitleWorkflow.options = jobTitlesDataOptions;
      editSelectFieldJobTitleWorkflow.optionValues = jobTitlesDataOptionValues;
      editSelectFieldJobTitleSuperApprover.options = jobTitlesDataOptions;
      editSelectFieldJobTitleSuperApprover.optionValues =
        jobTitlesDataOptionValues;
      editSelectFieldJobTitleRoleSteps.options = jobTitlesDataOptions;
      editSelectFieldJobTitleRoleSteps.optionValues = jobTitlesDataOptionValues;

      setConfigWorkflow(newConfigWorkflow);
      setConfigSuperApprover(newConfigSuperApprover);
      setConfigSpecialApprover(newConfigRoleSteps);
    }
  };

  useEffect(() => {
    onChange?.(
      [
        ...superApproverSteps?.map(step => ({
          ...step,
          lkpModuleName: lkpModule,
        })),
        ...specialApproverSteps?.map(step => ({
          ...step,
          lkpModuleName: lkpModule,
        })),
        ...workflowSteps?.map(step => ({
          ...step,
          lkpModuleName: lkpModule,
        })),
      ],
      {
        isAllJobTitleMustReviewSelected,
        isAutoApproveSelected,
        isAutoApproveLimitSelected,
        autoApprovalLimit,
      },
    );
  }, [
    specialApproverSteps,
    isAllJobTitleMustReviewSelected,
    isAutoApproveLimitSelected,
    isAutoApproveSelected,
    autoApprovalLimit,
    superApproverSteps,
    workflowSteps,
  ]);

  useEffect(() => {
    populateJobTitlesDropdown();
  }, [
    data,
    isSuccess,
    triggerJobTitleDropdown,
    superApproverSteps,
    specialApproverSteps,
    workflowSteps,
  ]);

  const handleOnChangeSpecialApprover = useCallback(
    (value, col, row, config, index, action) => {
      setSpecialApproverSteps(prev => {
        let newRoleSteps = _.cloneDeep(prev);

        let currentRow;

        if (Array.isArray(row?.id)) {
          currentRow = newRoleSteps.findIndex(
            x => x.approvalRoleTypeId === row.approvalRoleTypeId,
          );
        } else {
          currentRow = newRoleSteps.findIndex(x => x.id === row?.id);
        }

        newRoleSteps[currentRow].lkpModuleName = lkpModule;
        newRoleSteps[currentRow].approvalProcessId = props.row?.id;

        if (col.fieldName === 'jobTitle') {
          const jobTitlesData = (data as any)?.data ?? data;

          if (action === 'removeOption') {
            const removedValue = newRoleSteps[currentRow]['jobTitle'].findIndex(
              jt => value.every(val => val.id !== jt.id),
            );
            const deletedRowId =
              newRoleSteps[currentRow]['id'][Math.max(removedValue, 0)];

            let currentDeletedRow;

            if (typeof deletedRowId === 'string') {
              currentDeletedRow = {
                id: deletedRowId,
                deleted: true,
                lkpModuleName: lkpModule,
              };
            } else {
              currentDeletedRow = {
                ...steps.find(step => step.id === deletedRowId),
              };
              currentDeletedRow.deleted = true;
            }

            newRoleSteps[currentRow]['jobTitle'].splice(removedValue, 1);
            newRoleSteps[currentRow]['id'].splice(removedValue, 1);

            setTriggerJobTitleDropdown(!triggerJobTitleDropdown);
            return newRoleSteps;
          } else {
            const newValue: string = value[value.length - 1]?.label;
            if (!newValue) {
              const newRow = { ...newRoleSteps[currentRow] };
              newRow[col.fieldName] = undefined;
              newRow['jobTitleId'] = undefined;
              newRoleSteps[currentRow][col.fieldName] = [];
              newRoleSteps[currentRow].id = [];
              return newRoleSteps;
            }

            const newJobTitle = jobTitlesData?.find(
              job => job.name === newValue,
            );

            const newRow = { ...newRoleSteps[currentRow] };

            newRow[col.fieldName] = newJobTitle;
            newRow['jobTitleId'] = newJobTitle.id;
            newRow.id = undefined;
            newRow.isNew = true;

            newRoleSteps[currentRow].isNew = true;
            newRoleSteps[currentRow][col.fieldName] = [
              ...newRoleSteps[currentRow][col.fieldName],
              newJobTitle,
            ];
            newRoleSteps[currentRow].id = [
              ...newRoleSteps[currentRow].id,
              `${newValue}`,
            ];

            setTriggerJobTitleDropdown(!triggerJobTitleDropdown);
            return newRoleSteps;
          }
        } else if (col.fieldName === 'actions') {
          const newValue = value.map(action =>
            actions?.find(
              x =>
                x.name === (typeof action === 'object' ? action.label : action),
            ),
          );

          newRoleSteps[currentRow][col.fieldName] = newValue;
        } else if (value === 'delete') {
          newRoleSteps[currentRow]['deleted'] = true;
          newRoleSteps.splice(currentRow, 1);
          return newRoleSteps;
        } else {
          newRoleSteps[currentRow][col.fieldName] = value;
        }

        return newRoleSteps;
      });
    },
    [
      specialApproverSteps,
      data,
      actions,
      triggerJobTitleDropdown,
      configWorkflow,
      configSuperApprover,
      configSpecialApprover,
    ],
  );

  const updateWorkflowStepsOrder = useCallback(() => {
    setWorkflowSteps(prev => {
      return _.cloneDeep(prev).map((step, index) => ({
        ...step,
        order: index + 1,
      }));
    });
  }, []);

  const handleOnChangeWorkflow = useCallback(
    (value, col, row) => {
      setWorkflowSteps(prev => {
        let newRoleSteps = _.cloneDeep(prev);

        const currentRow = newRoleSteps.findIndex(x => x.id === row?.id);

        newRoleSteps[currentRow].lkpModuleName = lkpModule;

        if (col.fieldName === 'jobTitle') {
          const jobTitlesData = (data as any)?.data ?? data;
          const newValue = jobTitlesData.find(jt => jt.id === value);

          newRoleSteps[currentRow][col.fieldName] = newValue;
          newRoleSteps[currentRow]['jobTitleId'] = newValue.id;

          setTriggerJobTitleDropdown(!triggerJobTitleDropdown);
        } else if (col.fieldName === 'actions') {
          const newValue = value.map(action =>
            actions?.find(
              x =>
                x.name === (typeof action === 'object' ? action.label : action),
            ),
          );

          newRoleSteps[currentRow][col.fieldName] = newValue;
          newRoleSteps[currentRow].isNew = false;
        } else if (value === 'delete') {
          newRoleSteps[currentRow]['deleted'] = true;
          newRoleSteps.splice(currentRow, 1);
          return newRoleSteps;
        } else {
          newRoleSteps[currentRow][col.fieldName] = value;
        }
        if (_.isNil(newRoleSteps?.[currentRow]?.id)) {
          newRoleSteps[currentRow].id = newRoleSteps[currentRow]?.order;
        }

        return newRoleSteps;
      });

      updateWorkflowStepsOrder();
    },
    [
      workflowSteps,
      data,
      actions,
      triggerJobTitleDropdown,
      configWorkflow,
      configSuperApprover,
      configSpecialApprover,
    ],
  );

  const handleOnChangeSuperApprover = useCallback(
    (value, col, row, config, index, action) => {
      setSuperApproverSteps(prev => {
        let newRoleSteps = _.cloneDeep(prev);
        const currentRow = newRoleSteps.findIndex(
          x => x.approvalRoleTypeId === row.approvalRoleTypeId,
        );

        newRoleSteps[currentRow].lkpModuleName = lkpModule;
        newRoleSteps[currentRow].approvalProcessId = props.row?.id;

        if (col.fieldName === 'jobTitle') {
          const jobTitlesData = (data as any)?.data ?? data;

          if (action === 'removeOption') {
            const removedValue = newRoleSteps[currentRow]['jobTitle'].findIndex(
              jt => !value.map(val => val.label).includes(jt.name),
            );

            const deletedRowId = newRoleSteps[currentRow]['id'][removedValue];

            let currentDeletedRow;

            if (typeof deletedRowId === 'string') {
              currentDeletedRow = {
                id: deletedRowId,
                deleted: true,
                lkpModuleName: lkpModule,
              };
            } else {
              currentDeletedRow = {
                ...steps.find(step => step.id === deletedRowId),
              };
              currentDeletedRow.deleted = true;
            }

            newRoleSteps[currentRow]['jobTitle'].splice(removedValue, 1);
            newRoleSteps[currentRow]['id'].splice(removedValue, 1);

            setTriggerJobTitleDropdown(!triggerJobTitleDropdown);
            return newRoleSteps;
          } else {
            const newValue: string = value[value.length - 1]?.label;
            if (!newValue) {
              newRoleSteps[currentRow][col.fieldName] = [];
              newRoleSteps[currentRow].id = [];
              return newRoleSteps;
            }

            const newJobTitle = jobTitlesData.find(jt => jt.name === newValue);

            const newRow = { ...newRoleSteps[currentRow] };

            delete newRow.id;
            newRow.isNew = true;
            newRow[col.fieldName] = newJobTitle;
            newRow['jobTitleId'] = newJobTitle.id;

            newRoleSteps[currentRow][col.fieldName] = [
              ...newRoleSteps[currentRow][col.fieldName],
              newJobTitle,
            ];
            newRoleSteps[currentRow].id = [
              ...newRoleSteps[currentRow].id,
              `${newValue}`,
            ];

            setTriggerJobTitleDropdown(!triggerJobTitleDropdown);
            return newRoleSteps;
          }
        } else if (value === 'delete') {
          newRoleSteps[currentRow]['deleted'] = true;
          newRoleSteps.splice(currentRow, 1);
          return newRoleSteps;
        }

        return newRoleSteps;
      });
    },
    [
      superApproverSteps,
      data,
      actions,
      triggerJobTitleDropdown,
      configWorkflow,
      configSuperApprover,
      configSpecialApprover,
    ],
  );

  const handleAddNewWorkflow = useCallback(() => {
    let emptyWorkflowApprover = { ...emptyWorkflowRow };
    emptyWorkflowApprover.approvalProcessId = row?.id;
    emptyWorkflowApprover.order = workflowSteps.length + 1;
    emptyWorkflowApprover.id = new Date().getTime();
    emptyWorkflowApprover.isNew = true;
    setWorkflowSteps(prev => [...prev, emptyWorkflowApprover]);
    updateWorkflowStepsOrder();
  }, [workflowSteps, row?.id]);

  const handleAddNewSpecialApprover = useCallback(() => {
    let emptySpecialApprover = { ...emptySpecialApproverRow };

    if (
      specialApproverSteps.length &&
      specialApproverSteps[0].approvalRoleTypeId ===
        ApprovalRoleTypeIds.Required &&
      lkpModule !== StepsLkpModule.Invoice
    ) {
      emptySpecialApprover.approvalRoleTypeId = ApprovalRoleTypeIds.Reviewer;
      emptySpecialApprover.approvalRoleType = {
        id: ApprovalRoleTypeIds.Reviewer,
        name: 'Reviewer',
      };
    }
    emptySpecialApprover.approvalProcessId = row?.id;
    setSpecialApproverSteps(prev => [...prev, emptySpecialApprover]);
  }, [specialApproverSteps, row?.id]);

  const renderAddSpecialApproverButton = () => (
    <ECBox sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <ECButton
        onClick={handleAddNewSpecialApprover}
        startIcon={<Add />}
        variant="outlined"
      >
        {t('translation:dynamicPageTemplate.config.create')}
      </ECButton>
    </ECBox>
  );

  const handleOnDragEnd = event => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setWorkflowSteps(items => {
        const oldIndex = items.findIndex(x => x.id === active.id);
        const newIndex = items.findIndex(x => x.id === over.id);

        const arrayMoveResult = arrayMove(items, oldIndex, newIndex);

        const newOrderArray = arrayMoveResult.map((step, index) => {
          return { ...step, order: index + 1 };
        });

        return newOrderArray;
      });
    }
  };

  return (
    <ECBox display="flex" flexDirection="column" p={2}>
      {lkpModule === StepsLkpModule.Invoice && (
        <ECCheckbox
          name="autoApprove"
          value="autoApprove"
          label="Auto-Approve Invoice if Proposal is Approved"
          checked={isAutoApproveSelected}
          checkedLabel="Enabled"
          uncheckedLabel="Disabled"
          onChange={event => {
            setIsAutoApproveSelected(event.target.checked);
          }}
          readOnly={!editSelected}
          withBorder
          sx={{ mb: 2 }}
        />
      )}

      <ECBox sx={{ padding: '0 16px' }}>
        <ECCheckbox
          name="autoApproveLimit"
          value="autoApproveLimit"
          customComponent={
            <ECBox display="flex" gap={2} alignItems="center">
              <ECTypography variant="body1" color="black">
                Auto-Approval Limit
              </ECTypography>

              <ECCurrencyField
                id="autoApprovalLimit"
                onChange={(_, newValue) => {
                  setAutoApprovalLimit(newValue);
                }}
                hiddenLabel
                value={autoApprovalLimit}
                variant={editSelected ? 'filled' : 'standard'}
                readOnly={!editSelected}
              />
            </ECBox>
          }
          checked={isAutoApproveLimitSelected}
          checkedLabel="Enabled"
          uncheckedLabel="Disabled"
          onChange={event => {
            setIsAutoApproveLimitSelected(event.target.checked);
          }}
          readOnly={!editSelected}
          sx={{ mb: 2 }}
        />
      </ECBox>

      <ECTypography
        sx={{
          fontSize: '20px',
          height: '70px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {t('translation:pages.approval.superApprover')}
      </ECTypography>

      {superApproverSteps?.length ? (
        <ECEasyTable
          config={configSuperApprover.data.config}
          data={superApproverSteps}
          isLoading={isLoadingSteps}
          headerHeight={50}
          isEditable={editSelected}
          onChange={handleOnChangeSuperApprover}
          hideSearchHeadContent
          shouldNotUseActiveFilter
        />
      ) : (
        <ECAlert severity="warning">
          {t('translation:pages.approval.noSuperApproverWarning')}
        </ECAlert>
      )}
      <ECTypography
        sx={{
          fontSize: '20px',
          height: '70px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {t('translation:pages.approval.specialApprovers')}
      </ECTypography>

      {specialApproverSteps?.length ? (
        <>
          <ECEasyTable
            config={configSpecialApprover.data.config}
            data={specialApproverSteps}
            isLoading={isLoadingSteps}
            headerHeight={50}
            isEditable={editSelected}
            onChange={handleOnChangeSpecialApprover}
            hideSearchHeadContent
            shouldNotUseActiveFilter
          />
        </>
      ) : (
        <ECAlert severity="warning">
          {t('translation:pages.approval.noSpecialApproversWarning')}
        </ECAlert>
      )}
      {lkpModule !== StepsLkpModule.Invoice
        ? specialApproverSteps.length < 2 &&
          editSelected &&
          renderAddSpecialApproverButton()
        : specialApproverSteps.length < 1 &&
          editSelected &&
          renderAddSpecialApproverButton()}

      <ECTypography
        sx={{
          height: '70px',
          display: 'flex',
          alignItems: 'center',
          fontSize: '20px',
        }}
      >
        {t('translation:pages.approval.workflow')}
      </ECTypography>

      <ECCheckbox
        name="allJobTitlesMustReview"
        value="allJobTitlesMustReview"
        label="All job titles must review up to the total amount"
        checked={isAllJobTitleMustReviewSelected}
        onChange={event => {
          setIsAllJobTitleMustReviewSelected(event.target.checked);
        }}
        readOnly={!editSelected}
        withBorder
        sx={{ mb: 2 }}
      />

      {workflowSteps?.length ? (
        <>
          <ECEasyTable
            config={configWorkflow.data.config}
            data={workflowSteps || []}
            isLoading={isLoadingSteps}
            headerHeight={50}
            isEditable={editSelected}
            onChange={handleOnChangeWorkflow}
            isDraggable={editSelected && !isCreateNewStep}
            onDragEnd={handleOnDragEnd}
            hideSearchHeadContent
            shouldNotUseActiveFilter
          />
        </>
      ) : (
        <ECAlert severity="warning">
          {t('translation:pages.approval.noWorkflowWarning')}
        </ECAlert>
      )}
      {editSelected && (
        <ECBox sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <ECButton
            onClick={handleAddNewWorkflow}
            startIcon={<Add />}
            variant="outlined"
          >
            {t('translation:dynamicPageTemplate.config.create')}
          </ECButton>
        </ECBox>
      )}
    </ECBox>
  );
};

const StepsTabRaw = (props: StepsTabProps) => {
  const { t } = useTranslation();
  return (
    <ECTabContainer
      p={2}
      variant="standard"
      type="contained"
      noPadding
      tabs={[
        {
          value: '0',
          label: t('translation:pages.approval.invoices'),
          content: StepsTabDetails({
            ...props,
            lkpModule: StepsLkpModule.Invoice,
          }),
        },
        {
          value: '1',
          label: t('translation:pages.approval.proposals'),
          content: StepsTabDetails({
            ...props,
            lkpModule: StepsLkpModule.Proposal,
          }),
        },
      ]}
    />
  );
};

export const StepsTab = memo(StepsTabRaw);
