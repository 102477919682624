import {
  ECBox,
  ECButton,
  ECCard,
  ECDynamicPageTemplate,
  ECGrid,
  ECStack,
  ECTypography,
} from 'app/components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Add } from '@mui/icons-material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { PaymentMethodCreateDrawer } from './PaymentMethodCreateDrawer';
import { useGetProfileQuery } from 'services/profileApi';
import { limitStringMaxCharacters } from 'utils/strings/limit-string-max-characters';
import { useLazyGetCompanyTransactionsQuery } from 'services/userApi';
import SubscriptionPlanCard from './SubscriptionPlanCard';
import { PaymentMethodEditDrawer } from './PaymentMethodEditDrawer';
import { P } from 'types/Permission';

export function BillingPage() {
  const { t } = useTranslation();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<any>(null);
  const [isOpenCreateDrawer, setIsOpenCreateDrawer] = useState(false);

  const { data: companyDetails, isSuccess: isSuccessCompanyDetails } =
    useGetProfileQuery();
  const [subscriptionItem, setSubscriptionItem] = useState<any>({});

  useEffect(() => {
    if (!companyDetails?.paymentInfo) return;

    const subscription = companyDetails.paymentInfo.find(
      paymentInfo => paymentInfo.subscription,
    )?.subscription;

    if (subscription) {
      setSubscriptionItem(subscription);
    }
  }, [companyDetails]);

  const HeaderBox = ({ title }: { title: string }) => (
    <ECBox
      display="flex"
      alignItems="center"
      justifyContent="center"
      px={4}
      py={4}
      bgcolor={theme => theme.palette.grey[300]}
      width="100%"
      height={4}
    >
      <ECTypography variant="h6">{title}</ECTypography>
    </ECBox>
  );

  const useEmptyCreation = useCallback(() => {
    return [
      () => {},
      {
        data: null,
        isError: false,
        error: null,
        isLoading: false,
        isSuccess: false,
      },
    ];
  }, []);

  const paymentMethods = useMemo(() => {
    return companyDetails?.paymentInfo?.length ? (
      companyDetails?.paymentInfo?.map(paymentMethod => {
        const hasSubscription =
          paymentMethod?.subscription &&
          Object.keys(paymentMethod.subscription).length > 0;
        const maxNameLength = hasSubscription ? 6 : 30;

        return (
          <ECCard
            sx={theme => ({
              flex: '1 1 calc(100% - 16px)',
              p: 2,
              width: '100%',
              borderRadius: '4px',
              border: '1px solid rgba(169, 167, 167, 0.26)',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              display: 'flex',
              alignItems: 'center',
              bgcolor: theme.palette.common.white,
              minWidth: '250px',
            })}
            square
            key={`card-${paymentMethod.id}`}
          >
            <ECBox
              display="flex"
              alignItems="center"
              justifyContent="center"
              width={24}
              height={24}
              flexShrink={0}
            >
              {paymentMethod.type === 'ACH' ? (
                <AccountBalanceIcon fontSize="large" />
              ) : (
                <CreditCardIcon fontSize="large" />
              )}
            </ECBox>
            <ECBox
              display="flex"
              flexDirection="column"
              flex="1"
              marginLeft="16px"
            >
              <ECTypography
                variant="body1"
                sx={{
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
              >
                {limitStringMaxCharacters(
                  paymentMethod?.name ?? '',
                  maxNameLength,
                )}
                {hasSubscription && (
                  <ECTypography
                    variant="body2"
                    component="span"
                    color="text.secondary"
                    sx={{
                      marginLeft: '4px',
                      fontSize: '12px',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                  >
                    (Subscription)
                  </ECTypography>
                )}
              </ECTypography>
              <ECBox
                display="flex"
                flexDirection="row"
                flex="1"
                sx={{ gap: '10px' }}
              >
                <ECTypography variant="body2" color="text.secondary">
                  ****{paymentMethod.lastFour}
                </ECTypography>
                <ECTypography variant="body2" color="text.secondary">
                  {paymentMethod?.expiration
                    ? `${paymentMethod?.expiration?.slice(4)}/${paymentMethod?.expiration?.slice(0, 4)}`
                    : ''}
                </ECTypography>
              </ECBox>
            </ECBox>

            {/* Action Section */}
            <ECBox>
              <ECButton
                onClick={() => {
                  setSelectedPaymentMethod(paymentMethod);
                }}
                variant="text"
              >
                View
              </ECButton>
            </ECBox>
          </ECCard>
        );
      })
    ) : (
      <ECBox
        style={{
          padding: '10px',
          textAlign: 'center',
          marginTop: '50%',
        }}
      >
        <ECTypography
          variant="body1"
          sx={{ color: '#90caf9', fontWeight: '400' }}
        >
          No records found
        </ECTypography>
      </ECBox>
    );
  }, [companyDetails?.paymentInfo]);

  const handleClose = useCallback(() => {
    setIsOpenCreateDrawer(false);
  }, []);

  return (
    <>
      <ECBox display="flex" flexDirection="column">
        <ECBox flex="1" display="flex" flexDirection="row" gap={0.5}>
          {/* Left Column: Payment Methods */}
          <ECBox
            flex="0 0 300px"
            display="flex"
            flexDirection="column"
            minWidth="300px"
          >
            <HeaderBox title="Payments Methods" />
            <ECBox
              display="flex"
              alignItems="flex-start"
              mb={1}
              px={1}
              py={1}
              flex={1}
            >
              <ECStack spacing={2} width="100%">
                <ECButton
                  variant="outlined"
                  color="error"
                  scopes={[P.AddPaymentMethod]}
                  startIcon={
                    <Add sx={{ color: theme => theme.palette.info.dark }} />
                  }
                  sx={theme => ({
                    border: 1,
                    borderColor: `${theme.palette.info.main} !important`,
                    color: `${theme.palette.info.dark} !important`,
                    width: '100%',
                    height: '42px',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  })}
                  onClick={() => {
                    setIsOpenCreateDrawer(true);
                  }}
                >
                  ADD PAYMENT METHOD
                </ECButton>
                {paymentMethods}
              </ECStack>
            </ECBox>
          </ECBox>

          {/* Right Column: All Transactions */}
          <ECBox
            flex="1"
            display="flex"
            flexDirection="column"
            borderLeft={1}
            pl="5px"
            borderColor={theme => theme.palette.grey[300]}
            sx={{
              overflowX: 'auto',
              width: '95%',
            }}
          >
            <HeaderBox title="All Transactions" />
            <ECBox
              display="flex"
              alignItems="flex-start"
              mt={-10}
              minHeight={525}
            >
              <ECDynamicPageTemplate
                pageTitle={t('translation:workflow.sidebar.company')}
                useLazyGetListQuery={useLazyGetCompanyTransactionsQuery}
                customParams={{
                  t: 10,
                }}
                useCreateMutation={useEmptyCreation}
                hasDetails={false}
                withDrawer={false}
                showDrawerDetailTitle={false}
                hideSimpleSearchBar={true}
              />
            </ECBox>
          </ECBox>
        </ECBox>

        {/* Bottom Section: Subscription Plan */}
        <ECBox mt={2} pt="16px" pb="16px">
          {isSuccessCompanyDetails && (
            <SubscriptionPlanCard subscription={subscriptionItem} />
          )}
        </ECBox>
      </ECBox>

      {isOpenCreateDrawer && (
        <PaymentMethodCreateDrawer
          openPaymentMethodDrawer={isOpenCreateDrawer}
          handleClose={handleClose}
          shouldShowAsFullPage={false}
        />
      )}
      {selectedPaymentMethod && (
        <PaymentMethodEditDrawer
          openPaymentMethodDrawer={selectedPaymentMethod}
          handleClose={() => {
            setSelectedPaymentMethod(null);
          }}
          existingData={selectedPaymentMethod}
        />
      )}
    </>
  );
}
