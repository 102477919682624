import { isNil } from 'lodash';
import { SpendBudgetCard } from './SpendBudget';
import { useGetServiceRequestBudgetQuery } from 'services/budgetApi';

export enum BudgetLegendEnum {
  Green = '#A5D7A7',
  Orange = '#F6B580',
  Red = '#FAA09A',
}

function ECBudgetCard({
  branchId,
  location,
}: {
  branchId: number;
  location?: string;
}) {
  const isInvalidBranchId = isNil(branchId) || isNaN(branchId);
  const { data: budget } = useGetServiceRequestBudgetQuery(
    { branchId },
    { skip: isInvalidBranchId },
  );

  if (isInvalidBranchId) {
    return null;
  }

  return <SpendBudgetCard {...budget} location={location} />;
}

export default ECBudgetCard;
