import {
  useLazyGetAssetTypesListQuery,
  useCreateAssetTypeMutation,
  useUpdateAssetTypeMutation,
  useGetCoreAssetTypesListQuery,
  useGetPeriodEULListQuery,
  useGetPriorityListQuery,
} from 'services/assetTypeApi';
import { ECDynamicPageTemplate, ECEasyFormFieldType } from 'app/components';
import { populateDropDownFields } from 'utils/pageUtils';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { loadPageScopes } from 'utils/pageScopes';
import { useDispatch } from 'react-redux';
import { setFilterEndpoint } from 'store/slice/page';
import { useCompanyCustomerProfile } from 'app/hooks/useCompanyCustomerProfile.use-case';

const fancyFormElementsDetail = require('./fancy_form_config_details.json');
const fancyFormElementsCreate = require('./fancy_form_config_create.json');
const fancyFormElementsEdit = require('./fancy_form_config_edit.json');

const EUL_TYPE_FIELDNAME = 'period';
const EUL_TYPE_FIELDNAME_EDIT = 'period.description';

export function AssetTypePage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [row, setRow] = useState<any>();
  const [refrigerantTracking, setRefrigerantTracking] = useState(false);

  const { data: eulPeriodData, isSuccess: isEulPeriodSuccess } =
    useGetPeriodEULListQuery();
  const [createFormFields] = useState<ECEasyFormFieldType[]>(
    fancyFormElementsCreate.fields,
  );
  const [editFormFields] = useState<ECEasyFormFieldType[]>(
    fancyFormElementsEdit.fields,
  );

  const configRefrigerantTrackingEnabled =
    useCompanyCustomerProfile()?.enableRefrigerantTracking;

  // hide refrigerant tracking field if the feature is disabled
  useEffect(() => {
    const refrigerantTrackingFieldCreate = fancyFormElementsCreate.fields.find(
      field => field.fieldName === 'refrigerantTracking',
    );
    refrigerantTrackingFieldCreate.visible = !!configRefrigerantTrackingEnabled;

    const refrigerantTrackingFieldEdit = fancyFormElementsEdit.fields.find(
      field => field.fieldName === 'refrigerantTracking',
    );
    refrigerantTrackingFieldEdit.visible = !!configRefrigerantTrackingEnabled;

    const refrigerantTrackingFieldDetail = fancyFormElementsDetail.fields.find(
      field => field.fieldName === 'refrigerantTracking',
    );
    refrigerantTrackingFieldDetail.visible = !!configRefrigerantTrackingEnabled;
  }, [configRefrigerantTrackingEnabled]);

  useEffect(() => {
    loadPageScopes('assetType');
  }, []);

  useEffect(() => {
    const createCoreAssetGroupField = fancyFormElementsCreate.fields.find(
      field => field.fieldName === 'coreAssetType',
    );
    const editCoreAssetGroupField = fancyFormElementsEdit.fields.find(
      field => field.fieldName === 'coreAssetType',
    );

    createCoreAssetGroupField.useQuery = useGetCoreAssetTypesListQuery;
    createCoreAssetGroupField.obAlias = 'coreAssetType.name';
    createCoreAssetGroupField.sbAlias = 'coreAssetType.name';

    editCoreAssetGroupField.useQuery = useGetCoreAssetTypesListQuery;
    editCoreAssetGroupField.obAlias = 'coreAssetType.name';
    editCoreAssetGroupField.sbAlias = 'coreAssetType.name';
  }, []);

  const { data: priorityData, isSuccess: idPrioritySuccess } =
    useGetPriorityListQuery({});

  const [
    doAddAssetType,
    {
      data: createAssetTypeData,
      isError: isAddError,
      error: addError,
      isLoading: isAddLoading,
      isSuccess: isAddSuccess,
      reset: resetAdd,
    },
  ] = useCreateAssetTypeMutation();

  const [
    doUpdateAssetType,
    {
      data: updateAssetTypeData,
      isError: isUpdateError,
      error: updateError,
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
      reset: resetUpdate,
    },
  ] = useUpdateAssetTypeMutation();

  useEffect(() => {
    if (idPrioritySuccess && priorityData) {
      populateDropDownFields({
        responseData: priorityData.data,
        createFormFields,
        editFormFields,
        dataOptionsFieldName: 'name',
        dataValuesFieldName: 'id',
        formFieldName: 'priority.name',
        isPopulateForChipsComponent: true,
      });
    }
  }, [createFormFields, editFormFields, idPrioritySuccess, priorityData]);

  useEffect(() => {
    if (isEulPeriodSuccess && eulPeriodData) {
      const periodValues = eulPeriodData?.map(period => ({
        label: period.description,
        id: period.code,
      }));
      const eulGroupFieldCreate = fancyFormElementsCreate.fields.find(
        field => field.type === 'group',
      );
      if (eulGroupFieldCreate) {
        const periodFieldCreate = eulGroupFieldCreate.subFields.find(
          subField => subField.fieldName === EUL_TYPE_FIELDNAME,
        );
        periodFieldCreate.options = periodValues;
        periodFieldCreate.value = 'M';
      }

      const eulGroupFieldEdit = fancyFormElementsEdit.fields.find(
        field => field.type === 'group',
      );
      if (eulGroupFieldEdit) {
        const periodFieldEdit = eulGroupFieldEdit.subFields.find(
          subField => subField.fieldName === EUL_TYPE_FIELDNAME_EDIT,
        );
        periodFieldEdit.options = periodValues;
      }
    }
  }, [eulPeriodData, isEulPeriodSuccess, editFormFields, createFormFields]);

  const useCreateAssetType = useCallback(() => {
    const doCreate = async data => {
      const periodCode =
        eulPeriodData?.find(period => period.code === data.period)?.code ?? '';
      data.periodCode = periodCode;

      doAddAssetType(data);
    };

    return [
      doCreate,
      {
        data: createAssetTypeData,
        isError: isAddError,
        error: addError,
        isLoading: isAddLoading,
        isSuccess: isAddSuccess,
        reset: resetAdd,
      },
    ];
  }, [
    eulPeriodData,
    createAssetTypeData,
    addError,
    isAddError,
    isAddLoading,
    isAddSuccess,
    resetAdd,
    doAddAssetType,
  ]);

  const useUpdateAssetType = useCallback(() => {
    const doUpdate = async data => {
      const periodCode = data.periodCode || data.period?.id;
      data.periodCode = periodCode;
      doUpdateAssetType(data);
    };

    return [
      doUpdate,
      {
        data: updateAssetTypeData,
        isError: isUpdateError,
        error: updateError,
        isLoading: isUpdateLoading,
        isSuccess: isUpdateSuccess,
        reset: resetUpdate,
      },
    ];
  }, [
    updateAssetTypeData,
    updateError,
    isUpdateError,
    isUpdateLoading,
    isUpdateSuccess,
    resetUpdate,
    doUpdateAssetType,
  ]);

  useEffect(() => {
    dispatch(setFilterEndpoint('/filter/assetType'));
  }, [dispatch]);

  const handleEditButtonClick = useCallback(
    (data: any) => {
      if (data) {
        const refrigerantTrackingField = fancyFormElementsEdit?.fields?.find(
          field => field.fieldName === 'refrigerantTracking',
        );
        refrigerantTrackingField.readOnly = row?.refrigerantTracking;
      }
    },
    [row?.refrigerantTracking],
  );

  const onDrawerClose = useCallback(() => {
    setRow({});
    setRefrigerantTracking(false);
  }, []);

  const handleChange = (output: ECEasyFormFieldType[], fieldName: any) => {
    if (fieldName !== 'refrigerantTracking') return;

    const refrigerantTrackingField = output.find(
      field => field.fieldName === 'refrigerantTracking',
    );

    if (row?.id) {
      setRefrigerantTracking(!!refrigerantTrackingField?.value);
    }
  };

  return (
    <ECDynamicPageTemplate
      useLazyGetListQuery={useLazyGetAssetTypesListQuery}
      showStatusActiveFilter={true}
      pageTitle={t('translation:pages.assetTypes.title')}
      useCreateMutation={useCreateAssetType}
      useUpdateMutation={useUpdateAssetType}
      createFormConfig={fancyFormElementsCreate.config}
      createFormFields={fancyFormElementsCreate.fields}
      editFormConfig={fancyFormElementsEdit.config}
      editFormFields={fancyFormElementsEdit.fields}
      detailsConfig={fancyFormElementsDetail.config}
      detailsFields={fancyFormElementsDetail.fields}
      onChange={handleChange}
      downloadFileName="asset-type.csv"
      enableExport={true}
      enableTableCsvExport
      marginTop={false}
      shouldNotUseActiveFilter
      onEditButtonClick={handleEditButtonClick}
      onRowClick={setRow}
      onDrawerClose={onDrawerClose}
      submissionConfirmationModal={{
        buttonText: 'Confirm',
        cancelButtonText: 'Cancel',
        title: 'Confirm you want to enable the Refrigerant Tracking',
        bodyText:
          'Refrigerant Tracking is only need for assets that have regularly scheduled EPA check-ins, such as a HVAC unit. Once you turn on the feature, it cannot be reverted.',
        bodyInfoAlert: true,
        isVisible: refrigerantTracking,
      }}
    />
  );
}
