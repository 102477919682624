import { ChangeEvent, useCallback, useEffect } from 'react';
import { ECBox } from '../ECBox';
import { ECTextField } from '../ECTextField';
import { ECSelect } from '../ECSelect';
import { ECMenuItem } from '../ECSelect';
import { ECFormControl } from '../ECForm';
import { FormHelperText, InputAdornment, InputLabel } from '@mui/material';

export interface ECHourETAValues {
  value: number;
  timeframe: string;
  validationMessageValue?: string;
  validationMessageTimeframe?: string;
}

export const enum TIMEFRAME {
  Hour = 'Hour',
  Day = 'Day',
}

const timeframeOptions = [TIMEFRAME.Hour, TIMEFRAME.Day];

interface Props {
  onChange: (etaObject: ECHourETAValues) => void;
  onChangeOptionValue?: (etaObject: ECHourETAValues) => void;
  maxLength?: number;
  value: number | ECHourETAValues;
  readOnly: boolean;
  options?: any;
  optionValue?: any;
  field?: any;
  variant?: 'standard' | 'filled' | 'outlined' | undefined;
  selectTitle?: string;
  baseSx?: any;
  required?: boolean;
}

export const ECHourETA: React.FC<Props> = ({
  maxLength,
  value,
  readOnly,
  onChange,
  options,
  optionValue,
  onChangeOptionValue,
  field,
  variant,
  selectTitle,
  baseSx,
  required,
}: Props) => {
  const onChangeEta = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value?.replace(/[^0-9]/g, '');
      if (maxLength && newValue.length > maxLength) return false;
      //const numeric = isNaN(+newValue) ? 0 : +newValue;
      typeof value === 'object' && onChange({ ...value, value: +newValue });
    },
    [maxLength, onChange, value],
  );

  const onChangeTimeframe = useCallback(
    (newTimeframe: string) => {
      if (typeof value === 'object') {
        onChange({ ...value, timeframe: newTimeframe });
        onChangeOptionValue?.({ ...value, timeframe: newTimeframe });
      } else {
        onChange({ value, timeframe: newTimeframe });
        onChangeOptionValue?.({ value, timeframe: newTimeframe });
      }
    },
    [onChange, value, optionValue, onChangeOptionValue],
  );

  const convertToObject = useEffect(() => {
    if (typeof value !== 'object') {
      if ((value / 24) % 1 !== 0) {
        onChange({ value, timeframe: TIMEFRAME.Hour });
      } else {
        onChange({ value: value / 24, timeframe: TIMEFRAME.Day });
      }
    }
  }, [onChange, value]);

  const optionsArray = options || timeframeOptions;

  useEffect(() => {
    if (!value) {
      onChangeTimeframe(optionsArray[0]);
    }
    if (optionValue) {
      onChangeTimeframe(optionValue);
    }
  }, []);

  return readOnly ? (
    <ECTextField
      inputProps={{
        readOnly: true,
      }}
      value={typeof value === 'object' && `${value.value} ${value.timeframe}`}
    />
  ) : (
    <ECBox display={'flex'} marginRight="16px">
      <ECTextField
        value={(typeof value === 'object' ? value.value : value) || ''}
        variant="filled"
        onChange={onChangeEta}
        label={required ? field.label + ' *' : field.label}
        sx={{ ...baseSx, marginRight: '5px' }}
        inputProps={{
          maxLength: maxLength || 9,
        }}
        InputProps={{
          startAdornment: field.startAdornment && (
            <InputAdornment position="start">
              {field.startAdornment}
            </InputAdornment>
          ),
        }}
        error={
          typeof value === 'object' && value?.validationMessageValue
            ? true
            : false
        }
        helperText={
          typeof value === 'object' && value?.validationMessageValue
            ? value?.validationMessageValue
            : null
        }
      />
      <ECFormControl variant={variant}>
        {selectTitle && <InputLabel id={selectTitle}>{selectTitle}</InputLabel>}
        <ECSelect
          variant="filled"
          value={(value as ECHourETAValues)?.timeframe || optionValue}
          defaultValue={optionsArray[0]}
          onChange={e => onChangeTimeframe(e.target.value as any)}
          sx={{ ...baseSx, minWidth: '120px' }}
        >
          {optionsArray.map(option => (
            <ECMenuItem key={option} value={option}>
              {option}
            </ECMenuItem>
          ))}
        </ECSelect>
        {typeof value === 'object' && value?.validationMessageTimeframe && (
          <FormHelperText
            sx={theme => ({
              color: theme.palette.graphic.alert.error,
            })}
          >
            {value?.validationMessageTimeframe}
          </FormHelperText>
        )}
      </ECFormControl>
    </ECBox>
  );
};
