import { ECBox } from '../ECBox';
import { ECChip } from '../ECChip';
import { ECTableCell } from '../ECTable';
import { ECTypography } from '../ECTypography';

interface Rank {
  position: number;
  name: string;
}

interface ECRankTableCellProps {
  ranks: Rank[];
  placeholder?: string;
}

export const ECRankTableCell = ({
  ranks,
  placeholder,
}: ECRankTableCellProps) => (
  <ECTableCell
    sx={{ height: '100%', alignItems: 'center', p: 0 }}
    onClick={e => e.stopPropagation()}
  >
    <ECBox display="flex" flexDirection="column" p={1}>
      {ranks.length > 0 ? (
        ranks.map(rank => (
          <ECBox display="flex" p={1} alignItems="center">
            <ECTypography mr={1}>Rank-{rank.position}</ECTypography>
            <ECChip label={rank.name} variant="filled" textColor="white" />
          </ECBox>
        ))
      ) : (
        <ECBox display="flex" p={1} alignItems="center">
          <ECTypography
            color={theme => theme.palette.graphic.alert.error}
            mr={1}
          >
            {placeholder}
          </ECTypography>
        </ECBox>
      )}
    </ECBox>
  </ECTableCell>
);
