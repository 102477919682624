import { Budget } from 'types/Budget';
import { ECBox } from '../ECBox';
import { ECTypography } from '../ECTypography';
import { ECAlert } from '../ECAlert';
import { BudgetLegendEnum } from '.';
import { isNil, isString } from 'lodash';

interface SpendBudgetProps extends Budget {
  location?: string;
  message?: string;
}

const BUDGET_INFO_MESSAGE =
  "It looks like your budget information hasn't been configured yet. To track budget spending contact your administrator.";

const BudgetInfoAlert = ({
  message = BUDGET_INFO_MESSAGE,
}: {
  message?: string;
}) => (
  <ECAlert
    severity="info"
    sx={{
      height: 68,
      alignItems: 'center',
      borderRadius: 3,
    }}
  >
    <ECTypography variant="body2">{message}</ECTypography>
  </ECAlert>
);

export const SpendBudgetCard: React.FC<SpendBudgetProps> = ({
  location,
  currentPeriodBudget,
  currentPeriodColor,
  currentPeriodSpend,
  yearToYearBudget,
  yearToYearColor,
  yearToYearSpend,
  budgetColor,
  message,
}) => {
  const currentPeriodMaxValue = Math.max(
    currentPeriodBudget ?? 0,
    currentPeriodSpend ?? 0,
  );
  const yearToYearMaxValue = Math.max(
    yearToYearBudget ?? 0,
    yearToYearSpend ?? 0,
  );

  const currentBudgetPercentage =
    ((currentPeriodBudget ?? 0) / currentPeriodMaxValue) * 100;
  const currentSpendPercentage =
    ((currentPeriodSpend ?? 0) / currentPeriodMaxValue) * 100;

  const yearToYearBudgetPercentage =
    ((yearToYearBudget ?? 0) / yearToYearMaxValue) * 100;
  const yearToYearSpendPercentage =
    ((yearToYearSpend ?? 0) / yearToYearMaxValue) * 100;

  const noYearToYearBudget =
    isNil(yearToYearBudget) || isString(yearToYearBudget);
  const noCurrentPeriodBudget =
    isNil(currentPeriodBudget) || isString(currentPeriodBudget);

  const hideYearToYearGraph = noYearToYearBudget && !yearToYearSpend;

  return (
    <ECBox
      mx={1}
      mt={1}
      mb={2}
      display="flex"
      flexDirection="column"
      borderColor={theme => theme.palette.other.divider}
      boxShadow={2}
      p={2}
      border="1px"
      borderRadius={2}
      bgcolor={theme => theme.palette.common.white}
    >
      <ECTypography
        variant="h6"
        fontWeight={400}
        color={theme => theme.palette.text.header}
      >
        Spend vs Budget for {location}
      </ECTypography>
      {!message ? (
        <>
          <ECBox mt={2.5}>
            <ECTypography
              minWidth={100}
              variant="subtitle2"
              mb={1}
              color={theme => theme.palette.text.header}
            >
              Current Period
            </ECTypography>
            <ECBox display="flex" alignItems="center">
              <ECTypography
                minWidth={100}
                variant="subtitle2"
                color={theme => theme.palette.text.tertiary}
                mr={2}
              >
                Spend
              </ECTypography>
              <ECBox
                sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}
              >
                <ECBox
                  sx={{
                    width: `${noCurrentPeriodBudget ? 50 : currentSpendPercentage}%`,
                    minWidth: '.1%',
                    backgroundColor: `${
                      noCurrentPeriodBudget ? budgetColor : currentPeriodColor
                    }
                    `,
                    height: 24,
                    borderRadius: 1,
                  }}
                />
                <ECTypography
                  variant="body1"
                  color={theme => theme.palette.text.tertiary}
                  ml={2}
                >
                  ${currentPeriodSpend?.toLocaleString()}
                </ECTypography>
              </ECBox>
            </ECBox>

            <ECBox display="flex" alignItems="center" mt={1}>
              <ECTypography
                minWidth={100}
                variant="subtitle2"
                color={theme => theme.palette.text.tertiary}
                mr={2}
              >
                Budget
              </ECTypography>
              <ECBox
                sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}
              >
                {noCurrentPeriodBudget ? (
                  <BudgetInfoAlert />
                ) : (
                  <>
                    <ECBox
                      sx={{
                        width: `${currentBudgetPercentage}%`,
                        minWidth: '.1%',
                        backgroundColor: budgetColor,
                        height: 24,
                        borderRadius: 1,
                      }}
                    />
                    <ECTypography
                      variant="body1"
                      color={theme => theme.palette.text.tertiary}
                      ml={2}
                    >
                      ${currentPeriodBudget?.toLocaleString()}
                    </ECTypography>
                  </>
                )}
              </ECBox>
            </ECBox>
          </ECBox>

          {!hideYearToYearGraph && (
            <ECBox mt={2.5}>
              <ECTypography
                minWidth={100}
                variant="subtitle2"
                mb={1}
                color={theme => theme.palette.text.header}
              >
                Year-to-Year
              </ECTypography>
              <ECBox display="flex" alignItems="center">
                <ECTypography
                  minWidth={100}
                  variant="subtitle2"
                  color={theme => theme.palette.text.tertiary}
                  mr={2}
                >
                  Spend
                </ECTypography>
                <ECBox
                  sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}
                >
                  <ECBox
                    sx={{
                      width: `${noYearToYearBudget ? 50 : yearToYearSpendPercentage}%`,
                      backgroundColor: `${
                        noYearToYearBudget ? budgetColor : yearToYearColor
                      }
                    `,
                      minWidth: '.1%',
                      height: 24,
                      borderRadius: 1,
                    }}
                  />
                  <ECTypography
                    variant="body1"
                    color={theme => theme.palette.text.tertiary}
                    ml={2}
                  >
                    ${yearToYearSpend?.toLocaleString()}
                  </ECTypography>
                </ECBox>
              </ECBox>

              <ECBox display="flex" alignItems="center" mt={1}>
                <ECTypography
                  minWidth={100}
                  variant="subtitle2"
                  color={theme => theme.palette.text.tertiary}
                  mr={2}
                >
                  Budget
                </ECTypography>
                <ECBox
                  sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}
                >
                  {noYearToYearBudget ? (
                    <BudgetInfoAlert />
                  ) : (
                    <>
                      <ECBox
                        sx={{
                          width: `${yearToYearBudgetPercentage}%`,
                          minWidth: '.1%',
                          backgroundColor: budgetColor,
                          height: 24,
                          borderRadius: 1,
                        }}
                      />
                      <ECTypography
                        variant="body1"
                        color={theme => theme.palette.text.tertiary}
                        ml={2}
                      >
                        ${yearToYearBudget?.toLocaleString()}
                      </ECTypography>
                    </>
                  )}
                </ECBox>
              </ECBox>
            </ECBox>
          )}

          <ECBox sx={{ display: 'flex', marginTop: 3 }}>
            <ECBox
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginRight: '1rem',
              }}
            >
              <ECBox
                sx={{
                  width: 10,
                  height: 10,
                  backgroundColor: BudgetLegendEnum.Green,
                  marginRight: 1,
                  borderRadius: '50%',
                }}
              />
              <ECTypography
                variant="caption"
                color={theme => theme.palette.text.tertiary}
              >
                &lt;69%
              </ECTypography>
            </ECBox>
            <ECBox
              sx={{ display: 'flex', alignItems: 'center', margin: '0 1rem' }}
            >
              <ECBox
                sx={{
                  width: 10,
                  height: 10,
                  backgroundColor: BudgetLegendEnum.Orange,
                  marginRight: 1,
                  borderRadius: '50%',
                }}
              />
              <ECTypography
                variant="caption"
                color={theme => theme.palette.text.tertiary}
              >
                70% - 89%
              </ECTypography>
            </ECBox>
            <ECBox
              sx={{ display: 'flex', alignItems: 'center', margin: '0 1rem' }}
            >
              <ECBox
                sx={{
                  width: 10,
                  height: 10,
                  backgroundColor: BudgetLegendEnum.Red,
                  marginRight: 1,
                  borderRadius: '50%',
                }}
              />
              <ECTypography
                variant="caption"
                color={theme => theme.palette.text.tertiary}
              >
                +90%
              </ECTypography>
            </ECBox>
          </ECBox>
        </>
      ) : (
        <ECBox mt={2.5}>
          <BudgetInfoAlert message={message} />
        </ECBox>
      )}
    </ECBox>
  );
};
