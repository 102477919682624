import { ArrowDropDown } from '@mui/icons-material';
import { ECBox } from '../ECBox';
import { ECButton, ECButtonProps } from '../ECButton';

interface ECDeferredButtonProps extends ECButtonProps {
  isDeferredSelected?: boolean;
  setSelectDeferred?: () => void;
}

export const ECDeferredButton = ({
  isDeferredSelected,
  setSelectDeferred,
  sx = {},
  ...props
}: ECDeferredButtonProps) => {
  const baseSx = {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    flexGrow: 1,
  };
  const { width, height, ...otherSx } = sx;

  return (
    <ECBox
      sx={{
        display: 'flex',
        columnGap: 0.25,
        position: 'relative',
        width: width || 'auto',
        height: height || 'auto',
      }}
    >
      <ECButton {...props} sx={{ ...baseSx, ...otherSx }} />
      <ECBox
        sx={theme => ({
          backgroundColor: isDeferredSelected
            ? theme.palette.button.active
            : theme.palette.button.actionPrimary,
          width: '41px',
          maxWidth: '41px',
          borderRadius: 4,
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        })}
        onClick={() => {
          setSelectDeferred?.();
        }}
      >
        <ArrowDropDown
          sx={theme => ({ color: theme.palette.primary.contrast })}
        />
      </ECBox>
      {isDeferredSelected && (
        <ECBox
          sx={theme => ({
            position: 'absolute',
            top: '-56px',
            right: 0,
            width: '260px',
            height: '56px',
            zIndex: 20,
            backgroundColor: theme.palette.other.white,
            alignItems: 'center',
            display: 'flex',
            paddingLeft: 6,
            borderRadius: 4,
          })}
          boxShadow={4}
        >
          Defer
        </ECBox>
      )}
    </ECBox>
  );
};
