import React, { ReactNode, useState } from 'react';
import { ECPopover } from '../ECPopover';
import { get } from 'lodash';
import { camelCaseToPhrase } from 'utils/strings/camelcase-to-phrase';
import { ECChip, ECTableCell, ECBox, ECTypography } from '..';
import { themes } from 'styles/theme/themes';
interface Column {
  title: string;
  fieldName: string;
  dataPath: string;
}

interface ECChipTextTableCellProps {
  col: Column;
  row: any;
  customPopupHeader?: string;
  extraComponent?: ReactNode;
  shouldShowModalOnClick?: boolean;
}

const ECChipTextTableCell: React.FC<ECChipTextTableCellProps> = ({
  col,
  row,
  customPopupHeader,
  extraComponent,
  shouldShowModalOnClick = false,
}) => {
  const [openDetails, setOpenDetails] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const data = get(row, col.dataPath)?.replace(/<p>|<\/p>|&nbsp;|\n/g, '');
  const headerText = camelCaseToPhrase(col.dataPath?.split('.').pop() ?? '');

  const handleChipClick = event => {
    const customPopupHeaderLength = customPopupHeader?.length || 0;
    const dataLength = data?.length || 0;
    if (dataLength + customPopupHeaderLength <= 25 && !shouldShowModalOnClick)
      return;
    event.stopPropagation();

    setOpenDetails(!openDetails);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = event => {
    event.stopPropagation();
    setOpenDetails(false);
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <ECTableCell
        key={col.title}
        scope="row"
        sx={{ height: '100%', alignItems: 'center' }}
      >
        <ECChip
          color={
            openDetails ? 'Dark Blue' : themes.light.palette.background.tertiary
          }
          label={customPopupHeader ? `${customPopupHeader}: ${data}` : data}
          sx={{
            color: openDetails ? 'white' : 'black',
            fontWeight: 'bold',
            height: '30px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '14rem',
          }}
          onClick={handleChipClick}
          aria-describedby="text-details-modal"
        />
      </ECTableCell>
      <ECPopover
        id={'text-details-modal'}
        open={openDetails}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={handleClose}
      >
        <ECBox
          p={2}
          sx={{
            width: '30rem',
          }}
        >
          <ECTypography variant="h6" textTransform="capitalize">
            {headerText}
          </ECTypography>
          <ECTypography variant="body1">{data}</ECTypography>
          {extraComponent ? extraComponent : null}
        </ECBox>
      </ECPopover>
    </React.Fragment>
  );
};

export default React.memo(ECChipTextTableCell);
