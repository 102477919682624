import { useState } from 'react';
import {
  ECBox,
  ECButton,
  ECChip,
  ECGrid,
  ECImage,
  ECPhotoField,
  ECTypography,
} from 'app/components';
import { ECVideoField } from 'app/components/ECVideoField';
import { ECRichTextField } from 'app/components/ECRichTextField';
import { useTranslation } from 'react-i18next';
import { AssetTypeProblemTroubleShoot } from 'types/AssetType';
import { useAddTroubleshootSuccessMutation } from '../../../../services/troubleshootingTipsApi';
import { TroubleshootHistoryCreate } from '../../../../types/Troubleshoot';
import { PDFObject } from 'react-pdfobject';

interface StepTroubleshootsProps {
  existingData?: any;
  troubleshootInformation?: AssetTypeProblemTroubleShoot[];
  onClose?: () => void;
  onSubmit?: () => void;
}

export const StepTroubleshoots = ({
  existingData,
  troubleshootInformation,
  onSubmit,
  onClose,
}: StepTroubleshootsProps) => {
  const { t } = useTranslation();

  const [tradeIndex, setTradeIndex] = useState(0);
  const [troubleshootIndex, setTroubleshootIndex] = useState(0);

  const [doAddTroubleshootHistory] = useAddTroubleshootSuccessMutation();

  const validTroubleshootInfo = troubleshootInformation?.filter(tInfo =>
    tInfo.troubleshoots?.some(t => !!t),
  );

  if (!validTroubleshootInfo?.length) {
    return <></>;
  }

  const problemName = validTroubleshootInfo?.[tradeIndex]?.problem?.name;
  const tradeName = validTroubleshootInfo?.[tradeIndex]?.trade?.name;

  const troubleshoots = validTroubleshootInfo?.[
    tradeIndex
  ]?.troubleshoots?.filter(t => t!!);

  const currentTroubleshoot = troubleshoots[troubleshootIndex];
  const { id, name, image, others, video, description } = currentTroubleshoot;

  const isLastTroubleshoot = troubleshootIndex === troubleshoots.length - 1;
  const isLastTrade = tradeIndex === validTroubleshootInfo.length - 1;

  const handleContinue = () => {
    if (isLastTrade && isLastTroubleshoot) {
      onSubmit?.();
      return;
    }

    if (isLastTroubleshoot) {
      setTradeIndex(prevIndex => prevIndex + 1);
      setTroubleshootIndex(0);
    } else {
      setTroubleshootIndex(troubleshootIndex + 1);
    }
  };

  const handleSolvedProblem = () => {
    const newTroubleshootHistory: TroubleshootHistoryCreate = {
      assetId: existingData?.asset?.id,
      problemId: validTroubleshootInfo?.[tradeIndex]?.problem?.id,
      troubleshootId: id,
      branchId: existingData?.branch?.id,
      isGeneralAsset: existingData?.asset?.isGeneralAsset,
    };
    doAddTroubleshootHistory(newTroubleshootHistory);
    onClose?.();
  };

  return (
    <ECBox
      display="flex"
      flexDirection="column"
      height="100%"
      width="100%"
      overflow="visible"
      flex={1}
      justifyContent="space-between"
      gap={3}
      sx={{ padding: '2rem' }}
    >
      <ECBox display="flex" flexDirection="column" alignContent="space-between">
        <ECTypography
          variant="h6"
          marginBottom="1rem"
          sx={{ borderBottom: '1px solid grey' }}
        >
          {name}
        </ECTypography>
        <ECGrid container mb={1}>
          <ECGrid container mb={3}>
            <ECGrid item xs={6}>
              <ECTypography variant="body1">
                {t(
                  'translation:createServiceRequest.troubleshooting.problemsRelated',
                )}
              </ECTypography>
            </ECGrid>
            <ECChip label={problemName} variant="outlined" />
          </ECGrid>

          <ECGrid container mb={3}>
            <ECGrid item xs={6}>
              <ECTypography variant="body1">
                {t(
                  'translation:createServiceRequest.troubleshooting.tradeRelated',
                )}
              </ECTypography>
            </ECGrid>
            <ECChip label={tradeName} variant="outlined" />
          </ECGrid>
        </ECGrid>
        {others && others.length > 0 ? (
          others.map(item => {
            if (
              item.fileName?.split('.')?.pop()?.toLowerCase() === 'pdf' ||
              item.originalName?.split('.')?.pop()?.toLowerCase() === 'pdf'
            ) {
              return (
                <ECBox
                  id={'pdf-' + item?.id}
                  style={{ width: '100%', height: '800px' }}
                >
                  <PDFObject
                    containerId={'pdf-' + item?.id}
                    url={item?.url + '#toolbar=0'}
                    containerProps={{
                      style: {
                        width: '100%',
                        height: '800px',
                      },
                    }}
                    pdfOpenParams={{
                      zoom: 'scale,left,top',
                    }}
                  />
                </ECBox>
              );
            }
            return (
              <ECPhotoField
                isReadOnly
                photos={[
                  { ...item, preview: item.url, name: item.originalName },
                ]}
                title={''}
                moduleName={'troubleshoot'}
                isCustomerOwnRequiredDocs={false}
                isDownloadEnabled={true}
              />
            );
          })
        ) : image ? (
          <ECImage
            sx={{
              height: '100%',
              width: '100%',
            }}
            src={image}
            hideImageOnError={true}
          />
        ) : null}
        {video && <ECVideoField videoUrl={video} isReadOnly={true} />}
        {description && (
          <ECRichTextField
            readOnlyContent={description}
            isReadOnly={true}
            isSendButtonVisible={false}
          />
        )}
      </ECBox>

      <ECBox
        display="flex"
        flexDirection="column"
        height="100%"
        width="100%"
        flex={1}
        overflow="visible"
        justifyContent="space-between"
      >
        <ECBox
          display="flex"
          bgcolor={theme => theme.palette.background.primary}
          p={3}
          gap={2}
          justifyContent="center"
        >
          <ECButton
            type="submit"
            variant="contained"
            color="success"
            onClick={handleSolvedProblem}
          >
            {t(
              'translation:createServiceRequest.troubleshooting.yesFixedProblem',
            )}
          </ECButton>
          <ECButton variant="contained" onClick={handleContinue}>
            {isLastTrade && isLastTroubleshoot
              ? t(
                  'translation:createServiceRequest.troubleshooting.noPlaceServiceRequest',
                )
              : t(
                  'translation:createServiceRequest.troubleshooting.noContinue',
                )}
          </ECButton>
        </ECBox>
      </ECBox>
    </ECBox>
  );
};
