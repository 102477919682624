import { useCallback, useMemo } from 'react';
import { ECBox } from '../ECBox';
import { ECTypography } from '../ECTypography';
import { formatDateForHistory } from 'utils/strings/formatDate';
import { ECGrid } from '../ECGrid';
import { camelCaseToWords } from 'utils/common';
import { P } from 'types/Permission';
import {
  getHistoryEntry,
  isValidHistoryEntry,
} from '../ECHistoryEntry/historyUtil';
import { TradeAssignmentActivityList } from 'types/Activity';
import { ArrowForward } from '@mui/icons-material';
import { ECAssetTradeProblemActivity } from '../ECAssetTradeProblemActivity';
import { CompanyPaidOptionsWOMaintenanceAllocatedTime } from './CompanyPaidOptionsWOMaintenanceAllocatedTime';

export interface ECActivityProps {
  entry: TradeAssignmentActivityList;
  companyMappedOptions?: { label: string; value: number }[];
}

export enum ActivityEntryTypeEnum {
  Region = 'Region',
  District = 'District',
  RegionDistrict = 'Region District',
  Trade = 'Trade',
  TradeAssetWorkflow = 'Trade Asset Workflow',
  JobTitle = 'Job Title',
  Problem = 'Problem',
  AssetTradeProblem = 'AssetTradeProblem',
  Settings = 'Settings',
}

const booleanFieldsToTransform = [
  'adminOnly',
  'displayRestrictedProblemsOnServiceRequest',
  'displayTroubleshootingOnServiceRequest',
  'displayJobTitleInDirectComments',
  'displayRank1ServiceProviderOnly',
  'status',
  'restrictProblem',
  'invoiceDateRequired',
  'invoiceDocumentRequired',
  'workorderSurvey',
  'proposalDocumentRequired',
  'enableInvoicePdfAttachment',
  'enableArea',
  'enableBudget',
  'enableRejectReason',
  'spCheckInTimeEnabled',
  'woUnassignCommentRequired',
  'workorderSurvey',
  'assignOnCall',
];

const activeInactiveModuleTransformFields = [
  'status:problem',
  'restrictProblem:problem',
  'status:trade',
  'status:jobTitle',
  'invoiceDateRequired:company',
  'invoiceDocumentRequired:company',
  'workorderSurvey:company',
  'proposalDocumentRequired:company',
  'enableInvoicePdfAttachment:company',
  'enableArea:company',
  'enableBudget:company',
  'enableRejectReason:company',
  'spCheckInTimeEnabled:company',
  'woUnassignCommentRequired:company',
  'workorderSurvey:company',
  'assignOnCall:company',
];

const fieldsWithMappedValue = [
  'pmRejectWoAutomationPreferenceId:company',
  'pmPriorityWoAutomationPreferenceId:company',
  'priorityWoAutomationPreferenceId:company',
  'spRejectWoAutomationPreferenceId:company',
];

const targetPermissionCheck = [
  'ADD_PROBLEM_TYPE',
  'ADD_ORG',
  'ADD_TRADE',
  'ADD_JOB_TITLE',
  'ADD_TRADE_PROBLEM_TYPE_TO_ASSET_TYPE',
  'ADD_PROBLEM_TYPE_TO_ASSET_TYPE',
];

const showHeaderLabelPermissions = [
  P.UpdateOrganization,
  P.AddOrganization,
  P.AddTrade,
  P.EditTrade,
  P.AddProblemType,
  P.EditProblemType,
  P.GetOrg,
  P.AddJobTitle,
  P.EditJobTitle,
  P.AddProblemToAssetType,
  P.AddTradeProblemToAssetType,
  P.GetCompanyConfiguration,
  P.EditCompanyConfiguration,
  P.AddCompanyConfiguration,
  P.DeleteTradeProblemFromAssetType,
];

function mapBodyKey(bodyKey, module) {
  const keyMap = {
    company: {
      enableInvoicePdfAttachment: 'addInvoicePdfAsAnAttachment',
      workorderSurvey: 'customerSurvey',
      woUnassignCommentRequired:
        'requireCommentWhenSettingAWorkOrderUnassigned',
      pmRejectWoAutomationPreferenceId:
        'maintenanceWorkOrderIsRejectedByTheServiceProvider',
      pmPriorityWoAutomationPreferenceId:
        'maintenanceWorkOrderIsNotAcceptedWithinTheAllottedTime',
      priorityWoAutomationPreferenceId:
        'workOrderIsNotAcceptedWithinTheAllottedTime',
      spRejectWoAutomationPreferenceId:
        'workOrderIsRejectedByTheServiceProvider',
    },
  };
  return keyMap[module]?.[bodyKey] || bodyKey;
}

export const ECActivityCard = ({
  entry,
  companyMappedOptions,
}: ECActivityProps) => {
  const {
    beforeBody,
    createdAt: date,
    fullName: author,
    jobTitle,
    email,
    company,
    body,
    targetPermission,
    method,
    endpoint,
    module,
    id,
  } = entry;

  const transformedValue = useCallback(
    (value: boolean, bodyKey: string) => {
      const shouldUseActiveInactiveTransform =
        activeInactiveModuleTransformFields.includes(`${bodyKey}:${module}`);
      if (shouldUseActiveInactiveTransform) {
        return value ? 'Active' : 'Inactive';
      } else {
        return value ? 'Yes' : 'No';
      }
    },
    [module],
  );

  const parseValue = useCallback(
    (value: any, bodyKey: string, otherValue?: any) => {
      if (fieldsWithMappedValue.includes(`${bodyKey}:${module}`)) {
        if (companyMappedOptions) {
          const mappedValue = companyMappedOptions.find(
            option => option.value === value,
          );
          return mappedValue?.label ?? '';
        }
      }
      if (booleanFieldsToTransform.includes(bodyKey)) {
        if (typeof value === 'string') {
          return transformedValue(value === 'true', bodyKey);
        } else {
          return transformedValue(value, bodyKey);
        }
      }

      if (Array.isArray(value)) {
        if (otherValue && Array.isArray(otherValue)) {
          value = value.filter(
            (item, itemIndex) => item !== otherValue[itemIndex],
          );
        }
        return value.join('\n');
      }
      return value;
    },
    [transformedValue],
  );

  const entryType = useMemo(() => {
    const endpointArr = endpoint.split('/');
    const isRegionDistrict =
      endpointArr[2] === 'region' &&
      endpointArr[3] === 'district' &&
      (beforeBody?.company || body?.company);
    if (isRegionDistrict) {
      return 'Region District';
    }
    const isRegion = body?.regionId || beforeBody?.regionId;
    const isDistrict = body?.districtId || beforeBody?.districtId;
    const isTrade = endpoint[0] === 'trade';
    const isTradeAssetWorkflow = endpointArr[1] === 'trade';
    const isJobTitle = endpoint.includes('job');
    const isProblemActivity =
      endpoint.includes('problem') && endpointArr[4] !== 'problem';
    const isSettingsActivity = endpoint.includes('configuration');
    const isAssetTradeProblemActivity =
      endpointArr[1] === 'asset-group' &&
      endpointArr[3] === 'trade' &&
      endpointArr[4] === 'problem';
    return isRegion
      ? 'Region'
      : isDistrict
        ? 'District'
        : isTrade
          ? 'Trade'
          : isTradeAssetWorkflow
            ? 'Trade Asset Workflow'
            : isJobTitle
              ? 'Job Title'
              : isProblemActivity
                ? 'problem'
                : isAssetTradeProblemActivity
                  ? 'AssetTradeProblem'
                  : isSettingsActivity
                    ? 'Settings'
                    : '';
  }, [endpoint, beforeBody, body]);

  const headerCardLabel = useMemo(() => {
    const action =
      method === 'PUT'
        ? 'Updated'
        : targetPermissionCheck.includes(targetPermission?.[0]) ||
            entryType === 'Region District'
          ? 'Created'
          : entryType === ActivityEntryTypeEnum.AssetTradeProblem
            ? 'Deleted'
            : 'Removed';

    switch (entryType) {
      case 'Region':
        const regionId = body?.regionId ?? beforeBody?.regionId;
        return {
          label1: `Region ID: ${regionId} `,
          label2: `Region ${action}`,
        };
      case 'District':
        const districtId = body?.districtId ?? beforeBody?.districtId;
        return {
          label1: `District ID: ${districtId} `,
          label2: `District ${action}`,
        };
      case 'Region District':
        return {
          label1: `Organization ID: ${id} `,
          label2: `Region District ${action}`,
        };
      case 'Trade':
        return { label1: '', label2: `Trade Assignment Updated` };
      case 'Trade Asset Workflow':
        const tradeId = body?.id ?? beforeBody?.id;
        return {
          label1: `Trade ID: ${tradeId || ''}`,
          label2: `Trade ${action}`,
        };
      case 'Job Title':
        const jobTitleId = body?.id ?? beforeBody?.id;
        return {
          label1: `Job Title ID: ${jobTitleId} `,
          label2: `Job Titles ${action}`,
        };

      case 'problem':
        const problemId = body?.problemId ?? beforeBody?.problemId;
        return {
          label1: `Problem ID: ${problemId} `,
          label2: `Problem ${action}`,
        };
      case 'AssetTradeProblem':
        const assetTradeProblemId =
          body?.assetGroupId ?? beforeBody?.assetGroupId;
        return {
          label1: `ATP ID: ${assetTradeProblemId} `,
          label2: `Asset - Trade - Problem ${action}`,
        };
      case 'Settings':
        return {
          label1: 'General Configuration',
          label2: `General Configuration ${action}`,
        };
      default:
        return { label1: '', label2: '' };
    }
  }, [body, beforeBody, method, entryType, id, targetPermission]);

  const isNewProblemActivity =
    entryType === 'problem' && targetPermission?.[0] === P.AddProblemType;

  const renderActivityWithArrayField = useMemo(() => {
    let { id, regionId, districtId, problemId, ...otherBodyFields } =
      body ?? {};

    if (isNewProblemActivity) {
      otherBodyFields?.status && delete otherBodyFields.status;
    } else {
      entryType === 'problem' &&
        (otherBodyFields = {
          addedBy: email,
          ...otherBodyFields,
        });
    }
    let {
      id: beforeId,
      regionId: beforeRegionId,
      districtId: beforeDistrictId,
      problemId: beforeProblemId,
      ...otherBeforeFields
    } = beforeBody ?? {};

    if (module === 'company') {
      otherBodyFields?.companyId && delete otherBodyFields.companyId;
      otherBeforeFields?.companyId && delete otherBeforeFields.companyId;
    }

    const isFieldUpdated = (beforeValue: any, newValue: any) => {
      if (Array.isArray(beforeValue)) {
        return JSON.stringify(beforeValue) !== JSON.stringify(newValue);
      }

      return beforeValue !== newValue;
    };

    const loopThroughField = Object.keys(otherBodyFields).length
      ? otherBodyFields
      : otherBeforeFields;
    const isBodyFieldAvailable = !!Object.keys(otherBodyFields).length;
    const isBeforeFieldAvailable = !!Object.keys(otherBeforeFields).length;
    const arrowAppearIndex =
      Object.keys(loopThroughField).length === 1
        ? 0
        : Math.ceil(Object.keys(loopThroughField).length / 2) - 1;

    return Object.keys(loopThroughField ?? {}).map((bodyKey, index) => {
      const handlePositionShiftedArrayData = () => {
        if (
          (bodyKey === 'districts' || bodyKey === 'roles') &&
          (entryType === 'Region District' || entryType === 'Job Title') &&
          otherBeforeFields &&
          otherBeforeFields &&
          (Array.isArray(otherBodyFields?.districts) ||
            Array.isArray(otherBodyFields?.roles)) &&
          (Array.isArray(otherBeforeFields?.districts) ||
            Array.isArray(otherBeforeFields?.roles))
        ) {
          const beforeData =
            entryType === 'Region District'
              ? otherBeforeFields?.districts
              : otherBeforeFields?.roles;
          const newData =
            entryType === 'Region District'
              ? otherBodyFields?.districts
              : otherBodyFields?.roles;
          const existInBothData = newData.reduce((acc, value) => {
            if (beforeData.includes(value)) {
              acc.push(value);
            }
            return acc;
          }, []);

          if (existInBothData.length) {
            const notInSamePosition = existInBothData.reduce((acc, value) => {
              if (beforeData.indexOf(value) !== newData.indexOf(value)) {
                acc.push({ value, position: beforeData.indexOf(value) });
              }
              return acc;
            }, []);
            if (notInSamePosition.length) {
              const samePositionValueArr = notInSamePosition.map(
                item => item.value,
              );
              const serializedDistrict = newData.filter(
                item => !samePositionValueArr.includes(item),
              );
              for (const index in notInSamePosition) {
                const trackCount =
                  notInSamePosition[index].position - serializedDistrict.length;
                if (trackCount > 0) {
                  const toAdd = Array(trackCount).fill('');
                  serializedDistrict.splice(
                    notInSamePosition[index].position,
                    0,
                    ...toAdd,
                    notInSamePosition[index].value,
                  );
                } else {
                  serializedDistrict.splice(
                    notInSamePosition[index].position,
                    0,
                    notInSamePosition[index].value,
                  );
                }
              }

              return {
                canUseParsedDistricts: notInSamePosition.length,
                notInSamePosition,
                newParsedDistrict: serializedDistrict,
              };
            }
          }
        }
        return {};
      };
      if (
        bodyKey === 'workOrderAllotedTime' ||
        bodyKey === 'maintenanceWorkOrderAllotedTime' ||
        bodyKey === 'paidOptions'
      ) {
        return (
          <CompanyPaidOptionsWOMaintenanceAllocatedTime
            field={
              bodyKey === 'workOrderAllotedTime'
                ? 'workOrderAllottedTime'
                : bodyKey === 'maintenanceWorkOrderAllotedTime'
                  ? 'maintenanceWorkOrderAllottedTime'
                  : bodyKey
            }
            beforeData={otherBeforeFields?.[bodyKey]}
            bodyData={otherBodyFields?.[bodyKey]}
            parseValue={parseValue}
          />
        );
      }

      return (
        <>
          {isValidHistoryEntry(otherBeforeFields, bodyKey) && (
            <ECBox display="flex" width="100%">
              <ECBox display="flex" flex={1} width={0}>
                <ECTypography
                  variant="subtitle2"
                  minWidth="20vw"
                  width="23vw"
                  maxWidth="26vw"
                  color={theme => theme.palette.text.secondary}
                >
                  {camelCaseToWords(mapBodyKey(bodyKey, module))}
                  :&nbsp;
                </ECTypography>

                <ECBox display="flex" flex={1} justifyContent="center">
                  <ECBox
                    display="flex"
                    flex={1}
                    width={0}
                    visibility={
                      (isBeforeFieldAvailable &&
                        booleanFieldsToTransform.includes(bodyKey)) ||
                      otherBeforeFields?.[bodyKey]
                        ? 'visible'
                        : 'hidden'
                    }
                    marginLeft={2}
                  >
                    <ECTypography
                      variant="subtitle2"
                      maxWidth="100%"
                      color={theme => theme.palette.error.dark}
                      sx={{
                        textDecoration: 'line-through',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      {isFieldUpdated(
                        otherBeforeFields[bodyKey],
                        handlePositionShiftedArrayData()?.canUseParsedDistricts
                          ? handlePositionShiftedArrayData()?.newParsedDistrict
                          : otherBodyFields?.[bodyKey],
                      ) &&
                        parseValue(
                          otherBeforeFields?.[bodyKey],
                          bodyKey,
                          handlePositionShiftedArrayData()
                            ?.canUseParsedDistricts
                            ? handlePositionShiftedArrayData()
                                ?.newParsedDistrict
                            : otherBodyFields?.[bodyKey],
                        )}
                    </ECTypography>
                  </ECBox>

                  <ECBox
                    display="flex"
                    visibility={isBodyFieldAvailable ? 'visible' : 'hidden'}
                  >
                    <ArrowForward
                      sx={theme => ({
                        color:
                          index === arrowAppearIndex
                            ? theme.palette.action.active
                            : 'transparent',
                        mx: 1,
                      })}
                      fontSize="small"
                    />
                  </ECBox>

                  <ECBox
                    display="flex"
                    flex={1}
                    width={0}
                    justifyContent={'flex-start'}
                  >
                    <ECTypography
                      variant="subtitle2"
                      maxWidth="100%"
                      color={theme =>
                        bodyKey?.toLowerCase().includes('removed')
                          ? theme.palette.error.dark
                          : theme.palette.success.dark
                      }
                      sx={
                        bodyKey === 'districts' || bodyKey === 'roles'
                          ? { whiteSpace: 'pre-wrap' }
                          : undefined
                      }
                    >
                      {parseValue(
                        handlePositionShiftedArrayData()?.canUseParsedDistricts
                          ? handlePositionShiftedArrayData()?.newParsedDistrict
                          : otherBodyFields?.[bodyKey],
                        bodyKey,
                        otherBeforeFields?.[bodyKey],
                      )}
                    </ECTypography>
                  </ECBox>
                </ECBox>
              </ECBox>
            </ECBox>
          )}
        </>
      );
    });
  }, []);

  const renderChanges = useCallback(() => {
    return (
      <>
        {body && (
          <>
            <ECBox>
              <ECBox overflow="visible">
                {
                  <>
                    {targetPermission.some(perm =>
                      showHeaderLabelPermissions.includes(perm),
                    ) && (
                      <>
                        {headerCardLabel.label1 && (
                          <ECTypography
                            variant="subtitle2"
                            width="fit-content"
                            color={theme => theme.palette.text.primary}
                            fontSize={13}
                            sx={{
                              fontWeight: 'bold',
                            }}
                          >
                            {headerCardLabel.label1}
                          </ECTypography>
                        )}
                        <ECTypography
                          variant="subtitle2"
                          width="fit-content"
                          color={theme => theme.palette.text.secondary}
                          fontSize={13}
                          sx={{
                            background: theme => theme.palette.other.divider,
                            paddingX: 2,
                            paddingY: 0.5,
                            mb: 1,
                            fontWeight: 'bold',
                          }}
                        >
                          {headerCardLabel.label2}
                        </ECTypography>
                      </>
                    )}
                    {entryType === 'Trade' ||
                    entryType === ActivityEntryTypeEnum.TradeAssetWorkflow ? (
                      Object.keys(body).map((bodyKey, index) => {
                        const _bodyKey =
                          bodyKey === 'spName'
                            ? 'Service Provider'
                            : bodyKey === 'position'
                              ? 'Rank No'
                              : bodyKey.replace('Name', '');

                        return (
                          <>
                            {isValidHistoryEntry(body, bodyKey) && (
                              <ECBox
                                display="flex"
                                width="100%"
                                visibility={
                                  beforeBody?.[bodyKey] === body?.[bodyKey]
                                    ? 'hidden'
                                    : entryType ===
                                          ActivityEntryTypeEnum.TradeAssetWorkflow &&
                                        bodyKey === 'id'
                                      ? 'hidden'
                                      : 'visible'
                                }
                                sx={{
                                  display:
                                    beforeBody?.[bodyKey] === body?.[bodyKey]
                                      ? 'none'
                                      : entryType ===
                                            ActivityEntryTypeEnum.TradeAssetWorkflow &&
                                          bodyKey === 'id'
                                        ? 'none'
                                        : 'flex',
                                }}
                              >
                                <ECBox display="flex" flex={1} width={0}>
                                  <ECTypography
                                    variant="subtitle2"
                                    minWidth="15vw"
                                    maxWidth="20vw"
                                    color={theme =>
                                      theme.palette.text.secondary
                                    }
                                  >
                                    {camelCaseToWords(
                                      entryType ===
                                        ActivityEntryTypeEnum.TradeAssetWorkflow &&
                                        bodyKey === 'name'
                                        ? 'Trade'
                                        : _bodyKey,
                                    )}
                                    :&nbsp;
                                  </ECTypography>

                                  <ECBox
                                    display="flex"
                                    flex={1}
                                    justifyContent="center"
                                  >
                                    {!Array.isArray(beforeBody?.[bodyKey]) && (
                                      <ECBox
                                        display="flex"
                                        flex={1}
                                        width={0}
                                        visibility={
                                          beforeBody?.[bodyKey]
                                            ? 'visible'
                                            : 'hidden'
                                        }
                                        marginLeft={2}
                                      >
                                        <ECTypography
                                          variant="subtitle2"
                                          maxWidth="100%"
                                          color={theme =>
                                            theme.palette.error.dark
                                          }
                                          sx={{
                                            textDecoration: 'line-through',
                                          }}
                                        >
                                          {body[bodyKey] !==
                                            beforeBody?.[bodyKey] &&
                                            parseValue(
                                              JSON.stringify(
                                                beforeBody?.[bodyKey],
                                              ),
                                              bodyKey,
                                            )}
                                        </ECTypography>
                                      </ECBox>
                                    )}

                                    {!Array.isArray(beforeBody?.[bodyKey]) && (
                                      <ECBox display="flex">
                                        <ArrowForward
                                          sx={theme => ({
                                            color:
                                              index === 2
                                                ? theme.palette.action.active
                                                : 'transparent',
                                            mx: 1,
                                          })}
                                          fontSize="small"
                                        />
                                      </ECBox>
                                    )}

                                    <ECBox
                                      display="flex"
                                      flex={1}
                                      width={0}
                                      justifyContent={'flex-start'}
                                      visibility={
                                        beforeBody?.[bodyKey] ===
                                        body?.[bodyKey]
                                          ? 'hidden'
                                          : 'visible'
                                      }
                                    >
                                      <ECTypography
                                        variant="subtitle2"
                                        maxWidth="100%"
                                        color={theme =>
                                          bodyKey
                                            ?.toLowerCase()
                                            .includes('removed')
                                            ? theme.palette.error.dark
                                            : theme.palette.success.dark
                                        }
                                      >
                                        {parseValue(
                                          getHistoryEntry(body, bodyKey),
                                          bodyKey,
                                        )}
                                      </ECTypography>
                                    </ECBox>
                                  </ECBox>
                                </ECBox>
                              </ECBox>
                            )}
                          </>
                        );
                      })
                    ) : entryType ===
                      ActivityEntryTypeEnum.AssetTradeProblem ? (
                      <ECAssetTradeProblemActivity
                        entry={entry}
                        parseValue={parseValue}
                      />
                    ) : (
                      renderActivityWithArrayField
                    )}
                  </>
                }
              </ECBox>
            </ECBox>
          </>
        )}
      </>
    );
  }, [body, beforeBody, entry, targetPermission]);

  return (
    <>
      {entryType !== '' && (
        <ECBox display="flex" flexDirection="column" flexGrow={1}>
          <ECBox sx={{ flexGrow: 1 }}>
            <ECGrid container spacing={2}>
              <ECGrid item xs>
                <ECBox display="flex" alignItems="center">
                  <ECBox
                    height={12}
                    width={12}
                    borderRadius={6}
                    bgcolor={theme => theme.palette.primary.dark}
                    mr={2}
                  />
                  <ECBox display="flex" flexDirection="column">
                    <ECTypography variant="subtitle2" fontWeight="bold">
                      {author}
                    </ECTypography>

                    <ECTypography
                      variant="body2"
                      color={theme => theme.palette.primary.main}
                    >
                      {email}
                    </ECTypography>
                  </ECBox>
                </ECBox>
              </ECGrid>

              <ECGrid item xs>
                <ECBox display="flex" flexDirection="column">
                  <ECTypography variant="body2" fontWeight="bold">
                    {jobTitle}
                  </ECTypography>

                  <ECTypography
                    variant="subtitle2"
                    color={theme => theme.palette.text.secondary}
                  >
                    {company}
                  </ECTypography>
                </ECBox>
              </ECGrid>

              <ECGrid item xs>
                <ECBox display="flex" flexDirection="column">
                  <ECTypography
                    variant="body2"
                    color={theme => theme.palette.text.secondary}
                  >
                    {formatDateForHistory(date)}
                  </ECTypography>
                </ECBox>
              </ECGrid>
            </ECGrid>
          </ECBox>

          <ECBox display="flex" gap={4}>
            <ECBox
              bgcolor={theme => theme.palette.grey[400]}
              width="1px"
              display="flex"
              sx={{ marginLeft: '6px !important' }}
            />
            <ECBox
              display="flex"
              flexDirection="column"
              boxShadow={2}
              mt={3}
              borderRadius="md"
              minWidth={0}
              width="100%"
              p={2}
              position="relative"
              overflow="hidden"
            >
              {renderChanges()}
            </ECBox>
          </ECBox>
        </ECBox>
      )}
    </>
  );
};
