import {
  ECBox,
  ECGrid,
  ECStack,
  ECDynamicPageTemplate,
  ECButton,
  ECTypography,
  ECFormControlLabel,
  ECRadio,
  ECChipAutocomplete,
} from 'app/components';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setFilterEndpoint, setSnackbar } from 'store/slice/page';
import {
  useGetUserByIdQuery,
  useGetSPUserAssignedLocationsOptionQuery,
  useLazyGetSPUserAssignedLocationsQuery,
  useUpdateSpUserOrganizationsMutation,
} from 'services/userApi';
import _ from 'lodash';
import { useOutletContext, useParams, useSearchParams } from 'react-router-dom';
import { requirePermissionWrapper } from 'app/hoc/require-permission';
import { EmptyType } from '../EmptyStatePage';

interface LocationAssignmentProps {
  onlyEdit?: boolean;
  onClose?: () => void;
  userId?: string;
  userFullName?: string;
}

const SPLocationAssignmentContent = ({
  onlyEdit: isEditModeFromProps = false,
  onClose: onCloseFromProps = undefined,
  userId = '',
  userFullName = '',
}: LocationAssignmentProps) => {
  const props: any = useOutletContext();
  const {
    onlyEdit: isEditModeFromOutletContext,
    onClose: onCloseFromOutletContext,
  } = props;

  const isEditMode = isEditModeFromProps || isEditModeFromOutletContext;
  const onClose = onCloseFromProps || onCloseFromOutletContext;

  const { id } = useParams();
  const [_searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  const { data: user } = useGetUserByIdQuery(id ? +id : 0);
  const {
    data: options,
    isFetching: isFetchingOptions,
    isSuccess: isSuccessFetchingOptions,
  } = useGetSPUserAssignedLocationsOptionQuery({
    userId: user?.id ?? (id ? +id : 0),
    allCustomers: isEditMode ? 1 : undefined,
  });

  const [selectedAssignOption, setSelectedAssignOption] = useState('');
  const [selectedCustomers, setSelectedCustomers] = useState<any[]>([]);
  const [assignedCustomers, setAssignedCustomers] = useState<any[]>([]);
  const [newOrgs, setNewOrgs] = useState<any[]>([]);
  const [deletedOrgs, setDeletedOrgs] = useState<any[]>([]);
  const [initialOptions, setInitialOptions] = useState<any>({});
  const [branchIds, setBranchIds] = useState<any[]>([]);

  useEffect(() => {
    if (options?.assignedToAllCustomers) {
      setSelectedAssignOption('assignToAll');
    } else if (options?.assignedToSpecificCustomers) {
      setSelectedAssignOption('assignToCustomers');
    } else if (options?.assignedToSpecificBranches) {
      setSelectedAssignOption('assignToBranches');
    }
  }, [options, isEditMode]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setFilterEndpoint('/filter/location-assignment'));
  }, []);

  const [updateOrgs, resultUpdateOrgs] = useUpdateSpUserOrganizationsMutation();

  const {
    isLoading: isLoadingUpdateOrgs,
    isSuccess: isSuccessUpdateOrgs,
    reset: resetUpdateOrgs,
  } = resultUpdateOrgs;

  useEffect(() => {
    if (isSuccessUpdateOrgs) {
      dispatch(
        setSnackbar({
          severity: 'success',
          message: `User ${t('translation:dynamicForm.updateSuccess')}`,
        }),
      );
      onClose?.();
    }
  }, [isSuccessUpdateOrgs, dispatch, t]);

  const [triggerOrgBranches, resultOrgBranhces] =
    useLazyGetSPUserAssignedLocationsQuery();

  const {
    data: orgBranches,
    isError: isOrgBranchesError,
    error: orgBranchesError,
    isLoading: isLoadingOrgBranches,
    isSuccess: isSuccessOrgBranches,
    originalArgs: originalArgsOrgBranches,
  } = resultOrgBranhces;

  useEffect(() => {
    if (isSuccessOrgBranches && orgBranches) {
      const initialBranches = orgBranches.data.filter(
        item => item.assigned === true,
      );
      setBranchIds(initialBranches);
    }
  }, [isSuccessOrgBranches, orgBranches]);

  useEffect(() => {
    if (isSuccessUpdateOrgs && !isLoadingUpdateOrgs) {
      resetUpdateOrgs();
    }
  }, [isSuccessUpdateOrgs, isLoadingUpdateOrgs]);

  const orgBranchesRefined = useMemo(() => {
    if (!orgBranches) {
      return undefined;
    }

    return {
      ...orgBranches,
      data: orgBranches?.data?.map(orgBranch => ({
        ...orgBranch,
        assigned:
          initialOptions?.assignedToAllCustomers ||
          initialOptions?.assignedToSpecificCustomers
            ? false
            : branchIds
                ?.map(org => org.organizationId)
                ?.includes(orgBranch?.organizationId),
      })),
    };
  }, [orgBranches, initialOptions, branchIds]);

  const useLazyGetOrgBranches = useCallback(() => {
    const doGet = async ({ ...queryParams }) => {
      triggerOrgBranches({
        id: user?.id ?? (id ? +id : 0),
        allBranches: 1,
        queryParams: {
          ...queryParams,
          st: 1,
        },
      });
    };

    return [
      doGet,
      {
        data: orgBranchesRefined,
        isError: isOrgBranchesError,
        error: orgBranchesError,
        isLoading: isLoadingOrgBranches,
        isSuccess: isSuccessOrgBranches,
        originalArgs: originalArgsOrgBranches,
      },
    ];
  }, [
    triggerOrgBranches,
    id,
    user?.id,
    orgBranchesRefined,
    isOrgBranchesError,
    orgBranchesError,
    isLoadingOrgBranches,
    isSuccessOrgBranches,
    originalArgsOrgBranches,
  ]);

  useEffect(() => {
    if (!isEditMode) {
      setAssignedCustomers(
        options?.assignedToSpecificCustomers ? options?.customers ?? [] : [],
      );
      setNewOrgs([]);
      setDeletedOrgs([]);
      setInitialOptions(options);
      setBranchIds([]);
    }
  }, [options, isEditMode]);

  const handleUpdateOrgs = useCallback(() => {
    if (selectedAssignOption === 'assignToAll') {
      updateOrgs({
        userId: user?.id?.toString() ?? userId,
        assignToAllCustomers: true,
        newCustomerIds: _.map(options?.customers, 'id'),
      });
    }

    if (selectedAssignOption === 'assignToCustomers') {
      updateOrgs({
        userId: user?.id?.toString() ?? userId,
        assignToSpecificCustomers: true,
        newCustomerIds: _.difference(
          _.map(selectedCustomers, 'id'),
          _.map(assignedCustomers, 'id'),
        ),
        deleteCustomerIds: _.difference(
          _.map(assignedCustomers, 'id'),
          _.map(selectedCustomers, 'id'),
        ),
      });
    }

    if (selectedAssignOption === 'assignToBranches') {
      updateOrgs({
        userId: user?.id?.toString() ?? userId,
        assignToSpecificBranches: true,
        newOrgIds: _.map(newOrgs, 'organizationId'),
        deleteOrgIds: _.map(deletedOrgs, 'organizationId'),
      });
    }
  }, [
    selectedAssignOption,
    assignedCustomers,
    user,
    userId,
    options?.customers,
    selectedCustomers,
    newOrgs,
    deletedOrgs,
  ]);

  const headerTexts = useMemo(() => {
    if (isEditMode) {
      if (selectedAssignOption === 'assignToAll') {
        return {
          title: 'Customer Locations',
          description: 'All the customers locations are assigned to this user',
        };
      } else if (selectedAssignOption === 'assignToCustomers') {
        return {
          title: 'Select Customers to Assigned Locations',
          description:
            'All the locations from these Customers are assigned to this user.',
        };
      } else if (selectedAssignOption === 'assignToBranches') {
        return {
          title: 'Select Locations',
          description:
            'Assign Locations by checking the checkboxes of each row.',
        };
      }
    } else {
      if (options?.assignedToAllCustomers) {
        return {
          title: 'Assigned to All Customers Accounts',
          description: 'All the customers locations are assigned to this user.',
        };
      } else if (options?.assignedToSpecificCustomers) {
        return {
          title: 'Assigned to Specific Customers',
          description:
            'All locations from these Customers are assigned to this user.',
        };
      } else if (options?.assignedToSpecificBranches) {
        return {
          title: 'Assigned to Specific Customer Locations',
          description:
            'Specific locations from these Customers are assigned to this user.',
        };
      }
    }
  }, [options, isEditMode, selectedAssignOption]);

  const assignOptions = [
    {
      label: 'Assign to All Customers Accounts',
      value: 'assignToAll',
    },
    {
      label: 'Assign to Specific Customers',
      value: 'assignToCustomers',
    },
    {
      label: 'Assign to Specific Customer Locations',
      value: 'assignToBranches',
    },
  ];

  useEffect(() => {
    if (!isEditMode) {
      setSearchParams({ page: '0' });
    }
  }, [isEditMode]);

  const customSimpleSelectFilterOptions = useMemo(() => {
    return (
      options?.customers?.map(customer => ({
        id: customer.id,
        label: customer.name,
        fieldName: customer.name,
        isInitialSelected: true,
      })) || []
    );
  }, [options?.customers]);

  useEffect(() => {
    if (!isEditMode) {
      return;
    }

    if (initialOptions?.assignedToSpecificCustomers) {
      setSelectedCustomers(
        assignedCustomers?.map(customer => ({
          id: customer.id,
          label: customer.name,
        })) || [],
      );
    } else {
      setSelectedCustomers([]);
    }
  }, [
    isEditMode,
    assignedCustomers,
    initialOptions?.assignedToSpecificCustomers,
  ]);

  const handleDeselectAllButtonClicked = () => {
    setDeletedOrgs(prev => [
      ...prev,
      ...(orgBranches?.data?.filter(orgBranch => orgBranch?.assigned) ?? []),
    ]);
    setBranchIds([]);
  };

  return (
    <ECBox
      display="flex"
      flexDirection="column"
      flex={1}
      minWidth={0}
      width="100%"
    >
      {isEditMode && (
        <>
          <ECBox
            display="flex"
            flexDirection="column"
            gap="10px"
            px={4}
            mt={2}
            py={1}
          >
            <ECTypography variant="h6">Assign Locations by</ECTypography>

            <ECBox display="flex" flexDirection="row">
              {assignOptions?.map((option, index) => {
                return (
                  <ECFormControlLabel
                    key={`${option?.value}-${index}`}
                    control={
                      <ECRadio
                        value={option.value}
                        checked={option.value === selectedAssignOption}
                        onChange={e => {
                          if (e.target.checked) {
                            setSelectedAssignOption(option.value);
                          }
                        }}
                      />
                    }
                    sx={{
                      my: 1,
                      '& .MuiFormControlLabel-label': { width: '100%' },
                    }}
                    value={option.value}
                    label={option.label}
                  />
                );
              })}
            </ECBox>
          </ECBox>

          <ECBox
            display="flex"
            flexDirection="column"
            gap="10px"
            px={4}
            py={1}
            borderTop={1}
            borderBottom={1}
            borderColor={theme => theme.palette.other.divider}
          >
            {selectedAssignOption === 'assignToBranches' && (
              <ECChipAutocomplete
                variant="standard"
                options={customSimpleSelectFilterOptions}
                values={customSimpleSelectFilterOptions}
                readOnly
                title="Company"
                sx={{ width: '70%' }}
              />
            )}

            <ECTypography variant="h6">{headerTexts?.title}</ECTypography>

            <ECTypography variant="subtitle1">
              {headerTexts?.description ?? ''}
            </ECTypography>

            {selectedAssignOption === 'assignToAll' && (
              <ECChipAutocomplete
                variant="standard"
                options={customSimpleSelectFilterOptions}
                values={customSimpleSelectFilterOptions}
                loading={isFetchingOptions}
                readOnly
                title="Company"
                sx={{ width: '70%' }}
              />
            )}

            {selectedAssignOption === 'assignToCustomers' && (
              <ECChipAutocomplete
                variant="filled"
                options={customSimpleSelectFilterOptions}
                values={selectedCustomers}
                onChange={setSelectedCustomers}
                title="Company"
                sx={{ width: '70%' }}
              />
            )}

            {selectedAssignOption === 'assignToBranches' && (
              <ECDynamicPageTemplate
                marginTop={false}
                pageTitle={t('translation:pages.locationAssignment.title')}
                useLazyGetListQuery={useLazyGetOrgBranches}
                useCreateMutation={{}}
                withDrawer={false}
                preSelectRowsFieldname="assigned"
                multiSelectTable
                showSelectAllPageRows={false}
                emptyType={EmptyType.EMPTY_STATE_LOCATION_ASSIGNMENT}
                onMultiTableSelect={(leaves, isUnselecting, row) => {
                  if (isUnselecting) {
                    if (row?.assigned) {
                      setDeletedOrgs(prev =>
                        _.compact(_.uniqBy([...prev, row], 'organizationId')),
                      );
                    }
                    setNewOrgs(prev =>
                      prev.filter(
                        org => org?.organizationId !== row?.organizationId,
                      ),
                    );
                  } else {
                    if (!row?.assigned) {
                      setNewOrgs(prev =>
                        _.compact(_.uniqBy([...prev, row], 'organizationId')),
                      );
                    }
                    setDeletedOrgs(prev =>
                      prev.filter(
                        org => org?.organizationId !== row?.organizationId,
                      ),
                    );
                  }
                }}
                customMultiSelectActionButton={rows => (
                  <ECStack direction="row" spacing={2}>
                    <ECTypography
                      mt="10px !important"
                      sx={theme => ({
                        color: theme.palette.text.secondary,
                      })}
                    >
                      {rows?.length} Location
                      {!rows?.length || rows?.length === 1 ? '' : 's'} selected
                    </ECTypography>
                    <ECButton
                      variant="text"
                      sx={{ textTransform: 'none' }}
                      onClick={handleDeselectAllButtonClicked}
                    >
                      Deselect All
                    </ECButton>
                  </ECStack>
                )}
                showCustomFilter
                customSimpleSelectFilterOptions={
                  customSimpleSelectFilterOptions
                }
                customSimpleSelectFilterLabel="Customers"
                customSimpleSelectFilterName="customersIds"
                shouldNotUseActiveFilter
              />
            )}
          </ECBox>
        </>
      )}

      {!isEditMode && (
        <>
          {!!options?.customers?.length && (
            <ECBox
              display="flex"
              flexDirection="column"
              gap="10px"
              px={4}
              py={1}
              mt={2}
              borderTop={1}
              borderBottom={1}
              borderColor={theme => theme.palette.other.divider}
            >
              <ECTypography variant="h6">{headerTexts?.title}</ECTypography>

              <ECTypography variant="subtitle1">
                {headerTexts?.description ?? ''}
              </ECTypography>

              <ECChipAutocomplete
                variant="standard"
                options={customSimpleSelectFilterOptions}
                values={customSimpleSelectFilterOptions}
                readOnly
                title="Company"
                sx={{ width: '70%' }}
              />
            </ECBox>
          )}
          <ECBox display="flex" flex={1}>
            <ECGrid
              mt={0}
              overflow="hidden"
              container
              spacing={1}
              columns={4}
              minWidth={0}
              width="100%"
              wrap="nowrap"
            >
              <ECGrid item xs p={2} minWidth={0} width="100%">
                <ECDynamicPageTemplate
                  marginTop={false}
                  emptyType={EmptyType.EMPTY_STATE_LOCATION_ASSIGNMENT}
                  pageTitle={t('translation:pages.locationAssignment.title')}
                  useLazyGetListQuery={useLazyGetSPUserAssignedLocationsQuery}
                  idToGetListBy={user?.id ?? (id ? +id : 0)}
                  useCreateMutation={{}}
                  withDrawer={false}
                  showCustomFilter
                  shouldTriggerLazyQuery={
                    isSuccessFetchingOptions && !isFetchingOptions
                  }
                  customSimpleSelectFilterOptions={
                    customSimpleSelectFilterOptions
                  }
                  customSimpleSelectFilterLabel="Customers"
                  customSimpleSelectFilterName="customersIds"
                  showStatusActiveFilter={true}
                  shouldNotUseActiveFilter
                />
              </ECGrid>
            </ECGrid>
          </ECBox>
        </>
      )}

      {isEditMode && (
        <ECBox display="flex" width="100%">
          <ECStack
            bgcolor={theme => theme.palette.background.primary}
            direction="row"
            justifyContent="right"
            p={2}
            spacing={2}
            width="100%"
            bottom={0}
          >
            <ECButton variant="text" onClick={onClose}>
              Cancel
            </ECButton>
            <ECButton
              variant="contained"
              onClick={handleUpdateOrgs}
              isLoading={isLoadingUpdateOrgs}
            >
              Save
            </ECButton>
          </ECStack>
        </ECBox>
      )}
    </ECBox>
  );
};

export const SPLocationAssignment =
  requirePermissionWrapper<LocationAssignmentProps>(
    SPLocationAssignmentContent,
  );
