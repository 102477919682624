import {
  ECTypography,
  ECMainTabBox,
  ECBox,
  ECButton,
  ECLink,
  ECNotLoggedInBar,
} from 'app/components';
import { history } from 'utils/history';
import SuspendedImage from './assets/suspended.svg';
import { Help, Payment } from '@mui/icons-material';
import { P } from 'types/Permission';
import { useHasPermission } from 'app/hooks/hasPermission.use-case';
import { useNavigate } from 'react-router-dom';
import {
  CompanySubscriptionStatusCode,
  useCompanySubscriptionStatusCode,
} from 'app/hooks/useCompanySubscriptionStatusCode.use-case';

export function SuspendedPage() {
  //const navigate = history?.navigate;
  const location = history?.location as any;
  const statusCode = location?.state?.statusCode;

  const hasBillingPermission = useHasPermission([P.AddPaymentMethod]);

  const navigate = useNavigate();

  const customerProfileSubscriptionCode = useCompanySubscriptionStatusCode();

  const isCompanySuspended =
    customerProfileSubscriptionCode === CompanySubscriptionStatusCode.Suspended;

  if (!isCompanySuspended) {
    return null;
  }

  return (
    <ECMainTabBox
      component="main"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      mt={10}
    >
      <ECNotLoggedInBar />
      <ECBox>
        <ECTypography
          variant="h1"
          fontWeight="bold"
          color={theme => theme.palette.primary.main}
        >
          {statusCode}
        </ECTypography>
      </ECBox>
      <ECBox>
        <ECTypography variant="h6">
          <ECBox
            component="img"
            // @ts-ignore-next-line
            src={SuspendedImage}
          />
        </ECTypography>
      </ECBox>
      <ECBox>
        <ECTypography variant="h3" fontWeight="bold">
          Subscription Suspended!
        </ECTypography>
      </ECBox>
      <ECBox maxWidth={560}>
        <ECTypography variant="body1" mt={4} textAlign={'center'}>
          We’re sorry, your subscription is Suspended due to Payment Issues.
          Please contact&nbsp;
          <ECLink href="https://helpcenter.ecotrak.com/en">
            Ecotrak Support
          </ECLink>
          &nbsp;to reactivate your account.
        </ECTypography>
      </ECBox>
      <ECBox>
        {hasBillingPermission ? (
          <ECButton
            variant="contained"
            onClick={() => navigate?.('/add-payment')}
            sx={{ marginTop: 4, marginBottom: 4, width: '560px' }}
            size="large"
            startIcon={<Payment />}
          >
            Add New Payment Method
          </ECButton>
        ) : (
          <ECLink href="https://helpcenter.ecotrak.com/en">
            <ECButton
              variant="contained"
              sx={{ marginTop: 4, marginBottom: 4, width: '560px' }}
              size="large"
              startIcon={<Help />}
            >
              Support Center
            </ECButton>
          </ECLink>
        )}
      </ECBox>
    </ECMainTabBox>
  );
}
