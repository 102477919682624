import Chip, { ChipProps } from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import { ForwardedRef, forwardRef, useMemo } from 'react';

export const ChipStyled = styled(Chip)<ChipProps>(({ theme, ref }) => ({
  color: theme.palette.primary.contrastText,
  ref: ref,
}));

export const presetColors = [
  {
    title: 'Red',
    color: '#e31b0c',
  },
  {
    title: 'Pink',
    color: '#ec76ae',
  },
  {
    title: 'Orange',
    color: '#ed6c02',
  },
  {
    title: 'Yellow',
    color: '#ffb400',
  },
  {
    title: 'Dark Green',
    color: '#3bb23e',
  },
  {
    title: 'Light Green',
    color: '#62bd66',
  },
  {
    title: 'Dark Blue',
    color: '#61899F',
  },
  {
    title: 'Light Blue',
    color: '#6cbfff',
  },
  {
    title: 'Purple',
    color: '#6c3ff6',
  },
  {
    title: 'Grey',
    color: '#5c5c5c',
  },
  {
    title: 'Light Grey',
    color: '#9E9E9E',
  },
];

export const mapChipType = Object.assign(
  {},
  ...presetColors.map(presetColor => ({
    [presetColor.title]: presetColor.color,
  })),
);

interface ECChipProps extends Omit<ChipProps, 'color'> {
  color?: string;
  textColor?: string;
  borderColor?: string;
}

const MAX_LABEL_LENGTH = 30;

export const ECChip = forwardRef(
  (
    {
      textColor,
      borderColor,
      color = 'Dark Blue',
      variant,
      sx,
      label: labelFromProps,
      ...chipProps
    }: ECChipProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const label = useMemo(() => {
      if (typeof labelFromProps === 'string' && labelFromProps?.length > 30) {
        const labelSliced = labelFromProps?.slice(0, MAX_LABEL_LENGTH);

        return `${labelSliced}...`;
      }

      return labelFromProps;
    }, [labelFromProps]);

    return (
      <ChipStyled
        ref={ref}
        {...chipProps}
        label={label}
        variant={variant}
        sx={{
          cursor: chipProps?.disabled ? 'not-allowed !important' : 'pointer',
          borderColor: borderColor ?? (mapChipType[color] || color),
          bgcolor: theme =>
            (variant === 'outlined' ? 'transparent' : mapChipType[color]) ||
            color,
          color: theme =>
            variant === 'outlined'
              ? textColor ?? theme.palette.text.primary
              : textColor ?? theme.palette.primary.contrastText,
          '& .MuiSvgIcon-root': {
            color: theme =>
              variant === 'outlined'
                ? mapChipType[color]
                : theme.palette.grey[400],
          },
          ...sx,
        }}
      />
    );
  },
);
