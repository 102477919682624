import { useCallback, useEffect, useState } from 'react';
import { ECBox } from '../ECBox';
import { ECTextField } from '../ECTextField';
import * as _ from 'lodash';
import { ECButton } from '../ECButton';
import ECCurrencyField from '../ECCurrencyField';
import { FormHelperText, InputAdornment } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { isNumeric } from 'utils/is_numeric';

export interface ECMarkupMaterialValues {
  id: number;
  percentage: number;
  range: number;
  rateAndTermsId: number;
  validationMessagePercentage?: string;
  validationMessageRange?: string;
  value?: number;
}
interface Props {
  values: any[];
  readOnly: boolean;
  field;
  baseSx;
  options;
  onChange;
  variant;
}

export const ECMarkupMaterial: React.FC<Props> = ({
  values,
  field,
  baseSx,
  options,
  onChange,
  variant,
}: Props) => {
  const { t } = useTranslation();

  const [data, setData] = useState(values);

  useEffect(() => {
    onChange?.(data);
  }, [data]);

  const handleAddNew = useCallback(() => {
    setData(prev => [
      ...prev,
      {
        percentage: 0,
        range: (Number(prev[prev.length - 1]?.range) || 0) + 0.01 || 0.0,
      },
    ]);
  }, [data]);

  const handleRemove = useCallback(
    index => {
      setData(prev => {
        const newData = _.cloneDeep(prev);

        newData.splice(index, 1);

        return newData;
      });
    },
    [data],
  );

  return (
    <ECBox>
      {data?.map((material, ind, array) => {
        const isEmptyMaterialRange = !material.range || material.range == 0;
        return (
          <ECBox display="flex" justifyContent="space-between" key={ind}>
            <ECCurrencyField
              id={`range-start-${ind}`}
              readOnly={true}
              variant={'filled'}
              disabled={true}
              label={t('translation:form.markupMaterial.rangeStart')}
              value={(Number(array[ind - 1]?.range) || 0) + 0.01 || 0.0}
              style={{ marginRight: '16px', width: '30%' }}
            />

            <ECBox style={{ marginRight: '16px', width: '30%' }}>
              <ECCurrencyField
                id={`material-${ind}`}
                error={
                  isEmptyMaterialRange ||
                  material.range <= array[ind - 1]?.range
                }
                variant={'filled'}
                style={{ width: '100%' }}
                label={t('translation:form.markupMaterial.rangeEnd')}
                value={material.range}
                onChange={(event, value) => {
                  setData(prev => {
                    const newData = _.cloneDeep(prev);
                    newData[ind].range = value;
                    return newData;
                  });
                }}
              />
              {material.range <= array[ind - 1]?.range &&
                !isEmptyMaterialRange && (
                  <FormHelperText
                    sx={theme => ({
                      color: theme.palette.graphic.alert.error,
                    })}
                  >
                    {`${t('translation:form.markupMaterial.rangeEndWarning')}`}
                  </FormHelperText>
                )}
              {isEmptyMaterialRange && (
                <FormHelperText
                  sx={theme => ({
                    color: theme.palette.graphic.alert.error,
                  })}
                >
                  Range can't be zero or empty
                </FormHelperText>
              )}
            </ECBox>

            <ECTextField
              sx={{ ...baseSx, width: '30%' }}
              variant={variant}
              inputProps={{
                maxLength: 3,
                readOnly: false,
              }}
              error={material.validationMessagePercentage}
              helperText={
                material.validationMessagePercentage
                  ? material.validationMessagePercentage
                  : null
              }
              label={t('translation:form.markupMaterial.percentMarkup')}
              placeholder={t('translation:form.markupMaterial.percentMarkup')}
              value={material.percentage}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">%</InputAdornment>
                ),
              }}
              onChange={event => {
                let v: number | string = event.target.value;
                // If it is numeric and the value is not a number, don't change the value
                v = v.replace(/[^0-9.]/g, '');
                if (v.length > 0 && isNumeric(v)) {
                  v = Number(v);
                  if (v > 100) return;
                }
                setData(prev => {
                  const newData = _.cloneDeep(prev);
                  const eventValue = v;
                  newData[ind].percentage = eventValue;
                  return newData;
                });
              }}
            />

            <ECBox
              minWidth={'100px'}
              display="flex"
              alignItems={'center'}
              justifyContent="center"
            >
              <ECButton variant="text" onClick={() => handleRemove(ind)}>
                {t('translation:form.markupMaterial.remove')}
              </ECButton>
            </ECBox>
          </ECBox>
        );
      })}
      {data.length < 6 && (
        <ECBox>
          <ECButton variant="text" onClick={handleAddNew}>
            {t('translation:form.markupMaterial.addMoreRanges')}
          </ECButton>
        </ECBox>
      )}
    </ECBox>
  );
};
