import { QueryParams } from 'types/QueryParams';
import { BaseType, DataType } from 'types/BaseType';
import {
  ProposalBase,
  ProposalCreate,
  ProposalDetails,
  ProposalTableRow,
  ProposalUpdate,
  ProposalUpdateSP,
} from 'types/Proposal';
import {
  WorkflowNextStatus,
  WorkflowStatusUpdate,
  WorkflowStepActionUpdate,
  BulkApprovalStatusUpdateAction,
  BulkActionStatusUpdateResponse,
} from 'types/WorkflowStatus';
import daysPassedSince from 'utils/datediff';

import { emptyApi } from './emptyApi';
import { convertJsonToFormData } from '../utils/form';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: [
    'Proposal',
    'PendingProposals',
    'ProposalById',
    'ProposalStatusList',
    'SPProposal',
  ],
});

export const proposalApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    getProposalList: builder.query<BaseType<ProposalTableRow[]>, QueryParams>({
      query: params => {
        if (!Object.values(params)?.includes('wrkflwstts.name')) {
          return {
            url: '',
          };
        }

        return {
          url: 'proposal',
          params: {
            ...params,
            ob: params.ob || 'prpsl.id',
          },
        };
      },
      transformResponse: (response: BaseType<ProposalDetails[]>) => {
        const tableData: BaseType<ProposalTableRow[]> = {
          config: response.config,
          data: response.data.map(entry => ({
            ...entry,
            moduleName: 'proposal',
            id: entry.id,
            viewId: entry.viewId,
            title: entry.title,
            workorderViewId: entry.request.workorders?.[0]?.id?.toString(),
            statusId: entry.workflowStatusId,
            status: entry.workflowStatus?.name,
            assignedTo: entry.assignedTo,
            serviceProvider: entry.serviceProvider?.name,
            spPhone: entry.serviceProvider?.phone,
            location: entry.request?.asset?.branch?.name || '',
            storeNumber: entry.request?.asset?.branch?.storeNumber || '',
            asset: entry.request.asset?.name,
            assetGroup: entry.request.asset?.assetType?.name,
            category: entry.request.category?.name,
            problem: entry.request.problem?.name,
            budget: '',
            budgetPeriod: '',
            budgetRemaining: '',
            onSiteTime: '',
            createdAt: entry.createdAt,
            woDate: entry.woDate,
            approvedDate: entry.approvedDate,
            updatedAt: entry.updatedAt,
            flags: entry.flags,
          })),
        };
        return tableData;
      },
      providesTags: ['Proposal', 'PendingProposals'],
    }),
    createProposal: builder.mutation<any, ProposalCreate>({
      query: body => {
        const formData = new FormData();

        if (body?.companyId)
          formData.append('customerId', body?.companyId?.toString() || '');
        if (body?.company?.id)
          formData.append('customerId', body?.company?.id?.toString() || '');

        formData.append('title', body?.title || '');
        formData.append('spProposalId', body?.spProposalId || '');
        formData.append('description', body?.description || '');
        formData.append('requestedBy', body?.requestBy || '');
        formData.append('incurred', body?.Incurred?.toString() || '');
        formData.append('material', body?.costMaterial?.toString() || '');
        formData.append('freight', body?.costFreight?.toString() || '');
        formData.append('tax', body?.costTax?.toString() || '');
        formData.append('other', body?.costOther?.toString() || '');
        formData.append('problemId', body?.problem?.id?.toString() || '');
        formData.append('priorityId', body?.priority?.id?.toString() || '');
        formData.append(
          body?.branch?.id ? 'generalAssetId' : 'assetId',
          body?.id?.toString() || '',
        );
        formData.append('completionTime', body?.completionTime || '');
        formData.append(
          'completionPeriodCode',
          body?.completionTimeUnit?.id?.toString() || '',
        );
        if (body?.branch?.id) {
          formData.append('branchId', body?.branch?.id?.toString() || '');
        }
        body?.files?.forEach(file => {
          formData.append('files', file.processedFile);
        });
        body?.costLaborItems?.forEach((item, index) => {
          formData.append(
            `costLaborItems[${index}][costTypeId]`,
            item.costTypeId,
          );
          formData.append(
            `costLaborItems[${index}][unitCount]`,
            item.unitCount,
          );
          formData.append(`costLaborItems[${index}][unitCost]`, item.unitCost);
          formData.append(
            `costLaborItems[${index}][totalCost]`,
            item.totalCost,
          );
        });

        return {
          url: 'proposal',
          method: 'post',
          body: formData,
        };
      },
      invalidatesTags: ['Proposal'],
    }),
    createProposalForWorkorder: builder.mutation<void, ProposalBase>({
      query: body => {
        body.completionPeriodCode = body.completionPeriodCode.id;

        const formData = convertJsonToFormData(body);

        body.files?.forEach(file => {
          formData.append('files', file);
        });

        return {
          url: `proposal/workorder/${body.workorderId}/withAttachment`,
          method: 'post',
          body: formData,
        };
      },
    }),
    updateProposal: builder.mutation<void, ProposalUpdate>({
      query: ({ id, ...body }) => {
        return {
          url: `proposal/${id}`,
          method: 'put',
          body,
        };
      },
      invalidatesTags: ['ProposalById', 'ProposalStatusList'],
    }),
    getProposalById: builder.query<ProposalDetails & DataType, number>({
      query: id => {
        return {
          url: `proposal/${id}`,
        };
      },
      transformResponse: (response: ProposalDetails) => {
        return {
          ...response,
          moduleName: 'proposal',
        };
      },
      providesTags: ['ProposalById'],
    }),
    getProposalNextStatusList: builder.query<WorkflowNextStatus[], number>({
      query: id => {
        return {
          url: `proposal/${id}/status/next`,
        };
      },
      providesTags: ['ProposalStatusList'],
    }),
    updateProposalStatus: builder.mutation<void, WorkflowStatusUpdate>({
      query: ({ id, statusTo, actionId, declineReason, recipientIds }) => {
        const updatedWorkOrderStatus = {
          statusTo,
          actionId,
          declineReason,
          recipientIds,
        };
        return {
          url: `proposal/${id}/status/change`,
          method: 'put',
          body: updatedWorkOrderStatus,
        };
      },
      invalidatesTags: ['ProposalById', 'ProposalStatusList'],
    }),
    updateProposalAction: builder.mutation<void, WorkflowStepActionUpdate>({
      query: ({ id, ...rawBody }) => {
        const body = {
          approvalProcessStepId: rawBody.approvalProcessStepId,
          approvalProcessId: rawBody.approvalProcessId,
          statusTo: rawBody.statusTo,
          approvalProcessActionId: rawBody.approvalProcessActionId,
          workflowLinkId: rawBody.workflowLinkId,
        };

        return {
          url: `proposal/${id}/action`,
          body,
          method: 'put',
        };
      },
      invalidatesTags: ['ProposalById', 'ProposalStatusList'],
    }),

    // SERVICE PROVIDER
    getServiceProviderProposalList: builder.query<
      BaseType<ProposalTableRow[]>,
      QueryParams
    >({
      query: params => {
        if (!Object.values(params)?.includes('wrkflwstts.name')) {
          return {
            url: '',
          };
        }

        return {
          url: 'sp/proposal',
          params: {
            ...params,
            ob: params.ob || 'prpsl.id',
          },
        };
      },
      transformResponse: (response: BaseType<ProposalTableRow[]>) => {
        const dataWithModuleName = response.data.map(row => ({
          ...row,
          moduleName: 'proposal',
        }));

        return {
          ...response,
          data: dataWithModuleName,
        };
      },
      providesTags: ['SPProposal'],
    }),
    getSPProposalById: builder.query<ProposalDetails & DataType, number>({
      query: id => {
        return {
          url: `sp/proposal/${id}`,
        };
      },
      transformResponse: (response: ProposalDetails) => {
        return {
          ...response,
          workorderAge: daysPassedSince(response.workorderCreatedAt),
          completionAfterApproval:
            response.completionTime && response.completionPeriod
              ? `${response.completionTime} ${response.completionPeriod} after Approval`
              : '',
          moduleName: 'proposal',
        };
      },
      providesTags: ['ProposalById'],
    }),
    updateProposalSP: builder.mutation<void, ProposalUpdateSP>({
      query: ({ id, ...body }) => {
        const bodyWithoutFiles = { ...body };
        delete bodyWithoutFiles.files;
        const formData = convertJsonToFormData(bodyWithoutFiles);

        body.files?.forEach(file => {
          formData.append('files', file);
        });

        return {
          url: `sp/proposal/${id}/withAttachment`,
          method: 'put',
          body: formData,
        };
      },
      invalidatesTags: ['ProposalById', 'ProposalStatusList'],
    }),
    updateBulkProposalStatus: builder.mutation<
      BulkActionStatusUpdateResponse,
      BulkApprovalStatusUpdateAction
    >({
      query: ({
        itemIds,
        statusTo,
        actionId,
        latitude,
        longitude,
        declineTypeId,
        declineReason,
        note,
        recipientsIds,
      }) => ({
        url: 'proposal/status/change',
        body: {
          itemIds,
          statusTo,
          actionId,
          latitude,
          longitude,
          declineTypeId,
          declineReason,
          note,
          recipientsIds,
        },
        method: 'put',
      }),
    }),
    bulkProposalFlagsChange: builder.mutation<void, WorkflowStatusUpdate>({
      query: body => {
        return {
          url: 'proposal/flag/change',
          method: 'put',
          body,
        };
      },
      invalidatesTags: ['Proposal', 'PendingProposals'],
    }),

    declineRfpProposal: builder.mutation<void, WorkflowStatusUpdate>({
      query: ({ statusTo, id, declineReason, recipientIds }) => {
        return {
          url: `/sp/proposal/${id}/rfp/decline`,
          method: 'put',
          body: {
            statusTo,
            declineReason,
            recipientIds,
          },
        };
      },
      invalidatesTags: ['ProposalById', 'ProposalStatusList'],
    }),
    acceptRfpProposal: builder.mutation<void, WorkflowStatusUpdate>({
      query: ({ statusTo, id }) => {
        return {
          url: `/sp/proposal/${id}/rfp/approve`,
          method: 'put',
          body: {
            statusTo,
          },
        };
      },
      invalidatesTags: ['ProposalById', 'ProposalStatusList'],
    }),

    awardRfpProposal: builder.mutation<void, number>({
      query: id => {
        return {
          url: `/proposal/${id}/rfp/award`,
          method: 'put',
        };
      },
      invalidatesTags: ['ProposalStatusList', 'Proposal'],
    }),

    rejectRfpProposal: builder.mutation<void, WorkflowStatusUpdate>({
      query: ({ id, declineReason, recipientIds }) => {
        return {
          url: `/proposal/${id}/rfp/reject`,
          method: 'put',
          body: {
            declineReason,
            recipientIds,
          },
        };
      },
      invalidatesTags: ['ProposalStatusList', 'Proposal'],
    }),
  }),
});

export const {
  useGetProposalListQuery,
  useLazyGetProposalListQuery,
  useGetProposalByIdQuery,
  useLazyGetProposalByIdQuery,
  useCreateProposalMutation,
  useUpdateProposalMutation,
  useGetProposalNextStatusListQuery,
  useLazyGetProposalNextStatusListQuery,
  useUpdateProposalStatusMutation,
  useUpdateProposalActionMutation,
  useAwardRfpProposalMutation,
  useRejectRfpProposalMutation,

  //SERVICE PROVIDER
  useLazyGetServiceProviderProposalListQuery,
  useGetSPProposalByIdQuery,
  useLazyGetSPProposalByIdQuery,
  useUpdateProposalSPMutation,
  useCreateProposalForWorkorderMutation,
  useUpdateBulkProposalStatusMutation,
  useDeclineRfpProposalMutation,
  useAcceptRfpProposalMutation,

  // BULK
  useBulkProposalFlagsChangeMutation,
} = proposalApi;
