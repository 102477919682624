import { emptyApi } from './emptyApi';
import { PaymentMethodMutate } from 'types/paymentMethod';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: ['paymentMethod'],
});

export const paymentMethodApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    createPaymentMethod: builder.mutation<void, PaymentMethodMutate>({
      query: body => {
        return {
          url: 'payment-method',
          method: 'post',
          body: body,
        };
      },
    }),
  }),
});

export const { useCreatePaymentMethodMutation } = paymentMethodApi;
