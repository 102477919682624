import {
  ECBox,
  ECButton,
  ECDynamicPageTemplate,
  ECGrid,
  ECRequirePermission,
  ECStack,
  ECTypography,
} from 'app/components';
import { useCallback, useMemo, useState } from 'react';
import { Close, StarsRounded } from '@mui/icons-material';
import { useLazyGetProposalsListByRfpIdQuery } from 'services/requestForPricingApi';
import { currencyFormatter } from 'utils/strings/currency-formatter';
import ECChipTextTableCell from 'app/components/ECChipTextTableCell';
import { WorkflowStatus } from 'app/components/ECWorkflowStatusBadge';
import { RequestForPricingItem } from 'types/RequestForPricing';
import { AwardRfpProposalButton } from './AwardRfpProposalButton';
import { RejectRfpProposalModal } from './RejectRfpProposalModal';
import { P } from 'types/Permission';
import { useTranslation } from 'react-i18next';

interface RFPProposalsTableProps {
  rfpItem: RequestForPricingItem;
}

function renderStatusBox(
  backgroundColor: string,
  iconColor: string | ((theme: any) => string),
  text: string,
) {
  return (
    <ECBox
      sx={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor,
        borderRadius: theme => theme.shape.borderRadius,
        padding: '4px 8px',
        marginLeft: '10px !important',
      }}
    >
      <StarsRounded
        sx={{
          color: iconColor,
        }}
      />
      <ECTypography variant="subtitle2" color={iconColor}>
        &nbsp;{text}
      </ECTypography>
    </ECBox>
  );
}

export function RFPProposalsTable({ rfpItem }: RFPProposalsTableProps) {
  const { t } = useTranslation();
  const [selectedProposal, setSelectedProposal] = useState<any>(null);

  // custom actions for AWARD and REJECT
  const renderActions = useCallback(row => {
    return (
      <ECRequirePermission scopes={[P.ReviewRFPProposal]}>
        <ECBox key={`${row?.id}-wo-actions-grid-view`} pl={1}>
          <AwardRfpProposalButton
            proposalId={row.id || 0}
            serviceProviderName={row.serviceProviderName}
          />
          <ECButton
            variant="outlined"
            color="error"
            startIcon={
              <Close
                sx={{ color: theme => theme.palette.graphic.alert.error }}
              />
            }
            sx={theme => ({
              border: 1,
              borderColor: `${theme.palette.error.outlinedRestingBackground} !important`,
              color: `${theme.palette.graphic.alert.error} !important`,
              marginRight: 1,
            })}
            onClick={() => setSelectedProposal(row)}
          >
            Reject
          </ECButton>
        </ECBox>
      </ECRequirePermission>
    );
  }, []);

  const renderApproveAndRejectActionButton = (row: any) => {
    if (!row?.workflowStatus) return null;

    const { workflowStatus } = row;
    const canAwardOrReject = workflowStatus.canAwardOrReject;

    if (canAwardOrReject) {
      return renderActions(row);
    }

    if (workflowStatus.name === WorkflowStatus.Rejected) {
      return renderStatusBox(
        '#fef3eb',
        theme => theme.palette.warning.dark,
        'Not Awarded',
      );
    }

    if (
      workflowStatus.name === WorkflowStatus.Approved ||
      workflowStatus.name === WorkflowStatus.ProposalPendingApproval
    ) {
      return renderStatusBox(
        '#ecf4ec',
        theme => theme.palette.success.dark,
        'Awarded',
      );
    }
    if (workflowStatus.name === WorkflowStatus.Declined) {
      const col = {
        title: `action${row?.id}`,
        fieldName: `declineReason${row?.id}`,
        dataPath: 'declineReason',
      };

      return (
        <ECChipTextTableCell
          key={`${col.title}-${col.fieldName}-${row?.id}`}
          col={col}
          row={row}
          customPopupHeader="Decline Reason"
        />
      );
    }
    return null;
  };
  const assetNTE = useMemo(() => {
    return (
      rfpItem?.request?.asset?.nte ||
      rfpItem?.request?.asset?.assetType?.nte ||
      0
    );
  }, []);

  return (
    <ECBox
      sx={{
        marginRight: 30,
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      <ECGrid container spacing={1} key={'proposalTable'}>
        <ECGrid item xs={10}>
          <ECTypography
            variant="h6"
            sx={{
              marginLeft: 2,
              marginTop: -5,
              position: 'relative',
              zIndex: 1,
            }}
          >
            Proposals
          </ECTypography>
        </ECGrid>
        <ECGrid item xs={2}>
          <ECStack
            direction="row"
            spacing={1}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: -5,
              position: 'relative',
              zIndex: 1,
              whiteSpace: 'nowrap',
            }}
          >
            <ECTypography
              fontSize={22}
              fontWeight={400}
              variant="body1"
              sx={theme => ({ color: theme.palette.grey[600] })}
            >
              Asset NTE:&nbsp;
            </ECTypography>
            <ECTypography fontSize={20}>
              {currencyFormatter.format(assetNTE)}
            </ECTypography>
          </ECStack>
        </ECGrid>
        <ECGrid
          item
          xs={12}
          sx={{
            marginLeft: 1,
            marginTop: -10,
            position: 'relative',
          }}
        >
          <ECDynamicPageTemplate
            marginTop={false}
            pageTitle={t('translation:pages.requestForPricing.title')}
            useLazyGetListQuery={useLazyGetProposalsListByRfpIdQuery}
            idToGetListBy={rfpItem.id}
            useCreateMutation={() => false}
            useUpdateMutation={() => false}
            createFormConfig={undefined}
            createFormFields={[]}
            moduleName="rfp"
            withDrawer={false}
            shouldNotUseActiveFilter
            hideSimpleSearchBar
            renderCustomActionComponent={renderApproveAndRejectActionButton}
          />
        </ECGrid>
      </ECGrid>
      {selectedProposal && (
        <RejectRfpProposalModal
          proposalId={selectedProposal?.id}
          serviceProviderName={selectedProposal?.serviceProviderName}
          onCloseModal={() => setSelectedProposal(null)}
          isOpen={!!selectedProposal}
        />
      )}
    </ECBox>
  );
}
