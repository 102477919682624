import { FormHelperText } from '@mui/material';
import { ECBox, ECButton, ECTextField, ECTypography } from 'app/components';
import { ECPopover } from 'app/components/ECPopover';
import { WorkflowStatusGroupName } from 'app/components/ECWorkflowStatusBadge';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  proposalApi,
  useUpdateProposalStatusMutation,
} from 'services/proposalApi';
import { setSnackbar } from 'store/slice/page';
import { PrposalActionEnum } from 'types/WorkflowStatus';

const ProposalActions = props => {
  const { type, handleClose, open, anchorEl, data } = props;

  const [inputData, setInputData] = useState<string | null>(null);
  const dispatch = useDispatch();

  const [
    doUpdateStatus,
    { isLoading: isLoadingUpdateStatus, isSuccess: isSuccessUpdateAction },
  ] = useUpdateProposalStatusMutation();

  const header = {
    approve: 'Confirm you want to Approve Proposal',
    reject: 'Add Rejection Reason to Reject Proposal',
    comment: 'Add Comment',
  };

  useEffect(() => {
    if (isSuccessUpdateAction) {
      dispatch(
        proposalApi.util.invalidateTags(['Proposal', 'PendingProposals']),
      );

      dispatch(
        setSnackbar({
          severity: 'success',
          message: 'Proposal updated successfully.',
        }),
      );
      setInputData(null);
      handleClose();
    }
  }, [isSuccessUpdateAction]);

  const handleChange = () => {
    const params = {
      id: Number(data?.id),
    };

    if (type === PrposalActionEnum.Reject) {
      if (!inputData || inputData?.length === 0) {
        setInputData('');
        return;
      }
      const statusParam = data?.actions?.find(
        status =>
          status?.statusGroupName === WorkflowStatusGroupName.Rejected ||
          status?.name?.toLowerCase() === 'reject',
      );
      doUpdateStatus({
        ...params,
        declineReason: inputData,
        statusTo: statusParam?.statusTo,
        actionId: statusParam?.actionId,
      });
    }

    if (type === PrposalActionEnum.Approve) {
      const statusParam = data?.actions?.find(
        status =>
          status?.statusGroupName === WorkflowStatusGroupName.Approved ||
          status?.name?.toLowerCase() === 'approve',
      );
      doUpdateStatus({
        ...params,
        statusTo: statusParam?.statusTo,
        actionId: statusParam?.actionId,
      });
    }
  };

  const isLoading = isLoadingUpdateStatus;

  return (
    <ECPopover
      id={'proposal-action-modal'}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      onClose={handleClose}
    >
      <ECBox
        p={2}
        sx={{
          width: '30rem',
        }}
      >
        <ECTypography variant="h6" textTransform="capitalize">
          {header[type]}
        </ECTypography>
        {[PrposalActionEnum.Comment, PrposalActionEnum.Reject]?.includes(
          type,
        ) && (
          <ECBox mt={2}>
            <ECTextField
              variant={'filled'}
              fullWidth
              placeholder={
                type === PrposalActionEnum.Reject
                  ? 'Rejection Reason'
                  : 'Comment'
              }
              onChange={e => {
                if (e.target.value?.length > 1000) return;
                setInputData(e.target.value);
              }}
              value={inputData || ''}
              inputProps={{
                maxLength: 1000,
              }}
              error={typeof inputData === 'string' && inputData?.length === 0}
            />
            {typeof inputData === 'string' && inputData?.length === 0 && (
              <FormHelperText
                sx={theme => ({
                  color: theme.palette.graphic.alert.error,
                  marginTop: '0.5em',
                })}
              >
                Reject Reason is required*
              </FormHelperText>
            )}
          </ECBox>
        )}
        <ECBox mt={4} display="flex" gap={2} justifyContent="flex-end">
          <ECButton variant="text" onClick={handleClose}>
            Cancel
          </ECButton>
          <ECButton
            variant="contained"
            onClick={handleChange}
            color={
              type === PrposalActionEnum.Approve
                ? 'success'
                : type === PrposalActionEnum.Reject
                  ? 'error'
                  : 'primary'
            }
            isLoading={isLoading}
          >
            {type === PrposalActionEnum.Approve
              ? 'Approve'
              : type === PrposalActionEnum.Reject
                ? 'Reject'
                : 'Save'}
          </ECButton>
        </ECBox>
      </ECBox>
    </ECPopover>
  );
};

export default React.memo(ProposalActions);
