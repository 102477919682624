import { useMemo, useState, useEffect, useRef } from 'react';
import { ECDrawerDetails } from '../ECDrawerDetails';
import { t } from 'i18next';
import { ECTypography } from '../ECTypography';
import { ECBox } from '../ECBox';
import { StyleConstants } from 'styles/StyleConstants';
import { ECIconButton } from '../ECIconButton';
import { ArrowForward, Close } from '@mui/icons-material';
import { ECEasyForm, ECEasyFormFieldType, FieldTypes } from '../ECForm';
import { ECImage } from '../ECImage';
import { BookValueCard } from './BookValueCard';
import { SpendReplacementCard } from './SpendReplacementCard';
import { PreviousInvoiceCard } from './PreviousInvoiceCard';
import { useLazyGetAndCalculateAssetInsightsQuery } from '../../../services/assetApi';
import { WarrantyBadgeTypes } from '../ECWarrantyBadge';

interface WarrantyComponent {
  duration: number;
  resolution: {
    name: string;
  };
  repair: {
    name: string;
  };
  period: {
    description: string;
  };
}

export interface Invoice {
  id: number;
  invoiceDate: string;
  warrantyComponents: WarrantyComponent[];
}

interface Asset {
  estimatedUsefulLife?: number | null;
  originalValue?: number | null;
  replacementCost?: number | null;
  ageOfAsset?: number | null;
  totalSpent?: number | null;
  estimateRepair?: number | null;
}

interface BookValue {
  percentage: string;
}

interface SpendVsReplacement {
  percentage: string;
  color: string;
  spend: number;
}

interface HexCodes {
  Green: string;
  Orange: string;
  Red: string;
}

interface ECInsightsData {
  asset: Asset;
  bookValue: BookValue;
  spendVsReplacement: SpendVsReplacement;
  hexCodes: HexCodes;
  invoices: Invoice[];
  warrantyTag: WarrantyBadgeTypes;
}

interface ECInsightsDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  insightsData: ECInsightsData | null;
  asset: any;
}

export const ECInsightsDrawer: React.FC<ECInsightsDrawerProps> = ({
  isOpen,
  onClose,
  insightsData,
  asset,
}) => {
  const formRef = useRef<HTMLFormElement>(null);
  const [triggerQuery, { data: updatedData, isLoading }] =
    useLazyGetAndCalculateAssetInsightsQuery();
  const [initialFormValues, setInitialFormValues] = useState(
    insightsData?.asset,
  );
  const [formValues, setFormValues] = useState(insightsData?.asset);

  useEffect(() => {
    if (insightsData) {
      setInitialFormValues(insightsData.asset);
      setFormValues(insightsData.asset);
    }
  }, [insightsData]);

  useEffect(() => {
    if (updatedData) {
      setFormValues(updatedData.asset);
    }
  }, [updatedData]);

  const handleClose = () => {
    if (initialFormValues) {
      setFormValues(initialFormValues);

      triggerQuery({
        id: asset.id,
        params: {
          originalValue: initialFormValues.originalValue || 0,
          replacementCost: initialFormValues.replacementCost || 0,
          estimatedUsefulLife: initialFormValues.estimatedUsefulLife || 0,
          totalSpent: initialFormValues.totalSpent || 0,
          estimateRepair: initialFormValues.estimateRepair || 0,
          ageOfAsset: initialFormValues.ageOfAsset || 0,
        },
      });
    }
    onClose?.();
  };

  const handleUpdateValues = () => {
    if (
      !formValues?.originalValue ||
      !formValues?.replacementCost ||
      !formValues?.estimatedUsefulLife ||
      !formValues?.totalSpent ||
      !formValues?.estimateRepair
    ) {
      if (formRef.current) {
        formRef.current.dispatchEvent(
          new Event('submit', { cancelable: true, bubbles: true }),
        );
      }
      return;
    }

    triggerQuery({
      id: asset.id,
      params: {
        originalValue: formValues.originalValue,
        replacementCost: formValues.replacementCost,
        estimatedUsefulLife: formValues.estimatedUsefulLife,
        totalSpent: formValues.totalSpent,
        estimateRepair: formValues.estimateRepair,
        ageOfAsset: formValues.ageOfAsset,
      },
    });
  };

  const formFields = useMemo(() => {
    return [
      {
        type: FieldTypes.Currency,
        label: 'Original Value',
        fieldName: 'originalValue',
        value: formValues?.originalValue || 0,
        variant: 'filled',
        onChange: (currentOutput: ECEasyFormFieldType[], value?: any) => {
          setFormValues(prev => ({ ...prev, originalValue: value }));
        },
        required: true,
      },
      {
        type: FieldTypes.Currency,
        label: 'Replacement Cost ',
        fieldName: 'replacementCost',
        value: formValues?.replacementCost || 0,
        variant: 'filled',
        onChange: (currentOutput: ECEasyFormFieldType[], value?: any) => {
          setFormValues(prev => ({ ...prev, replacementCost: value }));
        },
        required: true,
      },
      {
        type: FieldTypes.Text,
        label: 'Estimated Useful Life (Months)',
        fieldName: 'estimatedUsefulLife',
        value: formValues?.estimatedUsefulLife || 0,
        variant: 'filled',
        isNumeric: true,
        onChange: (currentOutput: ECEasyFormFieldType[], value?: any) => {
          const newValue = value?.replace(/[^0-9]/g, '');
          setFormValues(prev => ({ ...prev, estimatedUsefulLife: newValue }));
        },
        required: true,
        maxLength: 3,
        maxVal: 999,
      },
      {
        type: FieldTypes.Currency,
        label: 'Total Spend',
        fieldName: 'totalSpent',
        value: formValues?.totalSpent,
        variant: 'filled',
        onChange: (currentOutput: ECEasyFormFieldType[], value?: any) => {
          setFormValues(prev => ({ ...prev, totalSpent: value }));
        },
        required: true,
      },
      {
        type: FieldTypes.Currency,
        label: 'Estimate Repair',
        fieldName: 'estimateRepair',
        value: formValues?.estimateRepair || 0,
        variant: 'filled',
        onChange: (currentOutput: ECEasyFormFieldType[], value?: any) => {
          setFormValues(prev => ({ ...prev, estimateRepair: value }));
        },
        required: true,
      },
      {
        type: FieldTypes.Text,
        label: 'Age of Asset (Months)',
        fieldName: 'ageOfAsset',
        value: formValues?.ageOfAsset || 0,
        variant: 'filled',
        isNumeric: true,
        onChange: (currentOutput: ECEasyFormFieldType[], value?: any) => {
          const newValue = value?.replace(/[^0-9]/g, '');
          setFormValues(prev => ({ ...prev, ageOfAsset: newValue }));
        },
        maxLength: 3,
        maxVal: 999,
        disabled: true,
      },
      {
        type: FieldTypes.FieldButton,
        fieldName: 'updateValues',
        fieldButtonProps: {
          label: t('translation:assetInsights.drawerUpdateButton'),
          variant: 'outlined',
          size: 'large',
          fullWidth: true,
          icon: <ArrowForward />,
          iconPosition: 'end',
          onClick: handleUpdateValues,
        },
      },
    ];
  }, [formValues]);

  return (
    <ECDrawerDetails open={isOpen} anchor="right" onClose={handleClose}>
      <ECBox
        display="flex"
        flexDirection="column"
        position="relative"
        bgcolor={theme => theme.palette.background.primary}
        sx={{
          paddingTop: StyleConstants.NAV_BAR_HEIGHT,
        }}
      >
        <ECBox
          display="flex"
          justifyContent="space-between"
          px={4}
          py={2}
          bgcolor={theme => theme.palette.action.focus}
        >
          <ECTypography variant="h4">
            {t('translation:assetInsights.drawerTitle')}
          </ECTypography>

          <ECIconButton withBorder squared onClick={handleClose}>
            <Close />
          </ECIconButton>
        </ECBox>

        <ECBox display="flex" height="100%">
          <ECBox
            display="flex"
            flexDirection="column"
            alignItems="center"
            height="100%"
            bgcolor="white"
          >
            <ECBox
              display="flex"
              flexDirection="column"
              sx={{
                padding: '1rem 1rem 0 1rem',
              }}
              width={315}
            >
              <ECBox
                display="flex"
                justifyContent="space-between"
                p={2}
                bgcolor={theme => theme.palette.background.primary}
              >
                <ECImage
                  src={asset?.photo}
                  alt="Asset Image"
                  sx={{
                    height: 60,
                    minWidth: 60,
                    maxWidth: 60,
                    borderRadius: '4px',
                    backgroundColor: 'grey',
                  }}
                />
                <ECBox
                  display="flex"
                  flexDirection="column"
                  width={315}
                  pl={2}
                  justifyContent="center"
                >
                  <ECTypography
                    variant="body2"
                    fontWeight={400}
                    color={theme => theme.palette.action.active}
                  >
                    Asset Name
                  </ECTypography>
                  <ECTypography variant="body1">{asset?.name}</ECTypography>
                </ECBox>
              </ECBox>
              <ECTypography
                variant="subtitle2"
                fontWeight={400}
                mt={2}
                pl={2}
                color={theme => theme.palette.info.dark}
              >
                Update Values to view the Spend vs. Replacement
              </ECTypography>
            </ECBox>

            <ECBox display="flex">
              <ECBox display="column" width={315}>
                <ECEasyForm
                  fullHeight={false}
                  showTitle={false}
                  config={{
                    cols: 1,
                  }}
                  fields={formFields as ECEasyFormFieldType[]}
                  showSaveButton={false}
                  isSendingData={isLoading}
                  isLoadingForm={false}
                  formRefFromParent={formRef}
                ></ECEasyForm>
              </ECBox>
            </ECBox>
          </ECBox>

          <ECBox
            display="flex"
            flexDirection="column"
            alignItems="center"
            height="100%"
            p={2}
          >
            <BookValueCard
              percentage={
                updatedData?.bookValue?.percentage ||
                insightsData?.bookValue?.percentage
              }
              colors={updatedData?.hexCodes || insightsData?.hexCodes}
            />
            <SpendReplacementCard
              spendColor={
                updatedData?.spendVsReplacement?.color ||
                insightsData?.spendVsReplacement?.color
              }
              legendColors={updatedData?.hexCodes || insightsData?.hexCodes}
              spend={
                updatedData?.spendVsReplacement?.spend ||
                insightsData?.spendVsReplacement?.spend
              }
              replacementCost={
                updatedData?.asset?.replacementCost ||
                insightsData?.asset?.replacementCost
              }
            />
            <PreviousInvoiceCard
              data={updatedData?.invoices || insightsData?.invoices}
              warrantyBadgeType={insightsData?.warrantyTag}
            />
          </ECBox>
        </ECBox>
      </ECBox>
    </ECDrawerDetails>
  );
};

export default ECInsightsDrawer;
