import { BoxProps } from '@mui/material';
import { ECBox } from '../ECBox';
import DefaultErrorImage from '../../../assets/default-empty-image.svg';
import LogoImage from '../../../assets/ecotrak-big-logo.svg';
import { useState, useEffect, useCallback } from 'react';

interface ECImageProps extends BoxProps {
  alt?: string;
  src?: string;
  showLogoOnError?: boolean;
  sx?: any;
  hideImageOnError?: boolean;
}

export const ECImage = ({
  src,
  alt = 'resource',
  sx,
  showLogoOnError,
  hideImageOnError = false,
}: ECImageProps) => {
  const [showImage, setShowImage] = useState(true);
  const [imgSrc, setImgSrc] = useState<any>(
    src || (showLogoOnError ? LogoImage : DefaultErrorImage),
  );

  useEffect(() => {
    setImgSrc(src || (showLogoOnError ? LogoImage : DefaultErrorImage));
  }, [src]);

  const handleError = useCallback(() => {
    if (!hideImageOnError) {
      setImgSrc(showLogoOnError ? LogoImage : DefaultErrorImage);
    } else {
      setShowImage(false);
    }
  }, [hideImageOnError, showLogoOnError]);

  return (
    <>
      {showImage && (
        <ECBox
          component="img"
          sx={sx}
          // @ts-ignore-next-line
          alt={alt}
          src={imgSrc}
          onError={handleError}
        />
      )}
    </>
  );
};
