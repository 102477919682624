import { Cancel } from '@mui/icons-material';
import { ECButton } from 'app/components';
import { ECConfirmWorkflowStatusChangeModal } from 'app/components/ECConfirmWorkflowStatusChange';
import { useEffect, useState } from 'react';
import { usePurchaseOrderActionClickMutation } from 'services/purchaseOrderApi';
import { themes } from 'styles/theme/themes';

export const ECCancelPOButton = ({ id, cancelStatusId }) => {
  const [isOpen, setIsOpen] = useState(false);

  const [
    doUpdatePurchaseOrderStatus,
    { isLoading: isLoadingAction, isSuccess: isSuccessUpdateAction, reset },
  ] = usePurchaseOrderActionClickMutation();

  useEffect(() => {
    if (!isLoadingAction && isSuccessUpdateAction) {
      setIsOpen(false);
      reset();
    }
  }, [isLoadingAction, isSuccessUpdateAction]);

  return (
    <>
      <ECButton
        variant="outlined"
        color="error"
        startIcon={
          <Cancel sx={{ color: theme => theme.palette.graphic.alert.error }} />
        }
        sx={theme => ({
          border: 1,
          borderColor: `${theme.palette.error.outlinedRestingBackground} !important`,
          color: `${theme.palette.graphic.alert.error} !important`,
          marginRight: 1,
        })}
        onClick={() => setIsOpen(true)}
        isLoading={isLoadingAction}
        loadingSpinnerColor={themes.light.palette.graphic.alert.success}
      >
        Cancel
      </ECButton>

      <ECConfirmWorkflowStatusChangeModal
        title={`Confirm you want to 'Cancel' this Purchase Order #${id}`}
        onConfirm={() => {
          doUpdatePurchaseOrderStatus({
            id: +(id || 0),
            statusTo: cancelStatusId,
          });
        }}
        onCancel={() => setIsOpen(false)}
        isDestructiveForm
        isOpen={isOpen}
        isLoading={isLoadingAction}
      />
    </>
  );
};
