import { FilterAlt } from '@mui/icons-material';
import {
  ECBox,
  ECButton,
  ECDivider,
  ECIconButton,
  ECSwitch,
  ECTypography,
} from 'app/components';
import { ECDashboardCategoryPopoverContent } from 'app/components/ECDashboardCategoryPopoverContent';
import { ECModal } from 'app/components/ECModal';
import { ECPopover } from 'app/components/ECPopover';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DashboardTableData } from 'types';
import { BaseType } from 'types/BaseType';
import { Category } from 'types/Category';
import { P } from 'types/Permission';
import { QueryParams } from 'types/QueryParams';
import { DashboardTables } from '.';
import { DashboardAction, QueryTag } from './actions';
import MultiselectTable from './MultiselectTable';

export interface DashboardTableFilter extends QueryParams {
  table: DashboardTables;
}

export interface DashboardTableProps {
  actions?: DashboardAction[];
  tableData: DashboardTableData;
  dashboardId: DashboardTables;
  filters?: DashboardTableFilter;
  categories?: BaseType<Category[]>;
  queryTag: QueryTag | QueryTag[];
  clearSelection?: boolean;
  onExpand: (target: DashboardTables) => void;
  onFiltersChange?: (filter: DashboardTableFilter) => void;
  count?: number | string;
}

export default function DashboardTable({
  actions,
  tableData,
  dashboardId,
  filters,
  categories,
  queryTag,
  clearSelection = false,
  onExpand,
  onFiltersChange,
  count,
}: DashboardTableProps) {
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [actionModal, setActionModal] = useState<string>();

  const openActionModal = useCallback((actionName: string) => {
    setActionModal(actionName);
  }, []);

  const actionButtons = useMemo(
    () =>
      actions &&
      actions.map(action => (
        <ECButton
          disabled={action.disabledRule(
            selectedRows.length,
            selectedRows?.[0]?.hasOwnProperty('canReassign')
              ? selectedRows?.[0]?.canReassign
              : undefined,
          )}
          key={action.buttonLabel}
          onClick={() => openActionModal(action.buttonLabel)}
          scopes={action.scopes}
        >
          {action.buttonLabel}
        </ECButton>
      )),
    [actions, openActionModal, selectedRows.length, filters?.assignedToMe],
  );

  const selectedAction = useMemo(
    () => actions?.find(action => action.buttonLabel === actionModal),
    [actionModal, actions],
  );

  const selectedAdditionalData = useMemo(
    () => selectedRows.length > 0 && tableData.additionalData?.[0],
    [selectedRows.length, tableData.additionalData],
  );

  useEffect(() => {
    if (clearSelection) {
      setSelectedRows([]);
    }
  }, [clearSelection]);

  const handleSelect = useCallback((rows: any[]) => {
    setSelectedRows(rows);
  }, []);

  const onClose = useCallback(() => {
    setActionModal(undefined);
    setSelectedRows([]);
  }, []);

  const onCancel = useCallback(() => {
    setActionModal(undefined);
  }, []);

  const renderCategoryFilters = useMemo(() => {
    return (
      <ECDashboardCategoryFilter
        categories={categories}
        dashboardId={dashboardId}
        initialSelected={filters?.cat}
        onFiltersChange={newFilter => {
          onFiltersChange?.({
            ...filters,
            ...newFilter,
          });
        }}
      />
    );
  }, [categories, dashboardId, filters, onFiltersChange]);

  const renderAssignedToMeFilter = useMemo(() => {
    if (filters?.assignedToMe === undefined) {
      return <></>;
    }

    return (
      <ECBox display="flex" alignItems="center">
        <ECSwitch
          id="dashboard-assigned-to-me-filter-id"
          name="dashboard-assigned-to-me-filter-name"
          checked={Boolean(filters?.assignedToMe)}
          value={Boolean(filters?.assignedToMe)}
          onChange={() => {
            onFiltersChange?.({
              ...filters,
              table: dashboardId,
              assignedToMe: Boolean(filters?.assignedToMe) ? 0 : 1,
            });
          }}
        />
        <ECTypography
          sx={theme => ({
            color: theme.palette.text.primary,
          })}
        >
          Assigned To Me
        </ECTypography>
      </ECBox>
    );
  }, [dashboardId, filters, onFiltersChange]);

  return (
    <>
      <ECBox
        border="1px solid rgba(0, 0, 0, .26)"
        borderRadius={1}
        overflow="hidden"
        height="100%"
        position="relative"
      >
        <ECBox
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexDirection="row"
          gap={1}
          p={1}
        >
          <ECBox
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            flexDirection="row"
            gap={2}
          >
            <ECBox
              bgcolor={theme => theme.palette.other.backgroundTable}
              borderRadius={1}
              p={1}
            >
              <ECTypography variant="h4">
                {count ?? tableData.totalCount}
              </ECTypography>
            </ECBox>
            <ECTypography variant="h5" fontWeight="bold">
              {tableData.title}
            </ECTypography>
          </ECBox>
          <ECDivider orientation="vertical" flexItem />
          <ECBox display="flex" justifyContent="flex-end" gap={2}>
            <ECBox>{actionButtons}</ECBox>
            {renderCategoryFilters}
            {renderAssignedToMeFilter}
          </ECBox>
        </ECBox>
        <MultiselectTable
          tableData={tableData}
          selected={selectedRows}
          onSelect={handleSelect}
          sx={{ marginBottom: '2em' }}
          minRows={5}
          maxRows={5}
        />
        <ECTypography
          position="absolute"
          bottom={0}
          width="100%"
          fontSize={14}
          align="center"
          my={0.5}
          onClick={() => onExpand(dashboardId)}
          color={theme => theme.palette.primary.main}
          sx={{
            cursor: 'pointer',
          }}
          textTransform="uppercase"
        >
          See More
        </ECTypography>
      </ECBox>

      {selectedAction && (
        <ECModal isOpen={!!actionModal} onClose={onClose} noPadding>
          <selectedAction.modalContent
            onClose={onClose}
            onCancel={onCancel}
            selectedRows={selectedRows}
            module={tableData.module}
            scopes={[P.AddComment]}
            queryTag={queryTag}
            additionalData={selectedAdditionalData}
          />
        </ECModal>
      )}
    </>
  );
}

interface ECDashboardCategoryFilterProps {
  dashboardId: DashboardTables;
  initialSelected?: number[];
  categories?: BaseType<Category[]>;
  onFiltersChange?: (filter: DashboardTableFilter) => void;
}

export const ECDashboardCategoryFilter = ({
  dashboardId,
  initialSelected = [],
  categories,
  onFiltersChange,
}: ECDashboardCategoryFilterProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFilterModal = () => {
    setAnchorEl(null);
  };

  const isFilterModalOpen = Boolean(anchorEl);
  const id = isFilterModalOpen ? 'filter-popover' : undefined;

  const [selectedFilters, setSelectedFilters] =
    useState<number[]>(initialSelected);

  useEffect(() => {
    setSelectedFilters(initialSelected);
  }, [initialSelected]);

  const handleChange = (selectedValues: number[]) => {
    setSelectedFilters(selectedValues);
    onFiltersChange?.({
      table: dashboardId,
      cat: selectedValues,
    });
  };

  return (
    <ECBox>
      <ECIconButton onClick={handleFilterClick} size="small">
        <FilterAlt />
      </ECIconButton>

      <ECPopover
        id={id}
        open={isFilterModalOpen}
        anchorEl={anchorEl}
        onClose={handleCloseFilterModal}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <ECBox display="flex" p={2} flexDirection="column">
          <ECDashboardCategoryPopoverContent
            selectedValues={selectedFilters}
            categories={categories}
            onChange={handleChange}
          />
        </ECBox>
      </ECPopover>
    </ECBox>
  );
};
