import { useSigninUserMutation } from 'services/authApi';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';

import {
  ECAlertError,
  ECEasyForm,
  FieldTypes,
  ECLoginTemplate,
  ECBox,
  ECButton,
} from 'app/components';
import { Helmet } from 'react-helmet-async';
import { jwtDecode } from 'jwt-decode';
import { LockOpen } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import moment from 'moment';

export function LoginPage() {
  const location = useLocation();
  const { sentEmailLogin } = location.state || {
    sentEmailLogin: null,
    redirectTo: null,
  };

  const [userPassword, setUserPassword] = useState<string | null>(null);

  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('md'));

  let [searchParams, setSearchParams] = useSearchParams();

  const errorMessage = useMemo(() => {
    return searchParams?.get('message');
  }, [searchParams]);

  useEffect(() => {
    if (!sentEmailLogin) {
      const newUrl = errorMessage ? `/?message=${errorMessage}` : '/';
      window.location.replace(newUrl);
    }
  }, [sentEmailLogin]);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [signinUser, { data, isError, error, isLoading, isSuccess }] =
    useSigninUserMutation({});

  const idToken = data?.idToken?.jwtToken;
  const refreshToken = data?.refreshToken?.token;
  const userName = data?.accessToken?.payload?.username;
  const email = data?.idToken?.payload?.email;
  const authTimeout = data?.company?.authTimeout;

  const isMFA = data?.isMFA;
  const mfaInfo = {
    challengeName: data?.mfaInfo?.challengeName,
    email: data?.mfaInfo?.email,
    session: data?.mfaInfo?.session,
    username: data?.mfaInfo?.username,
  };

  useEffect(() => {
    if (isSuccess && idToken) {
      localStorage.setItem('idToken', idToken);
      localStorage.setItem('refreshToken', refreshToken);
      localStorage.setItem('sessionInfo', email);
      localStorage.setItem('sessionInfoUserName', userName);
      if (authTimeout > 0) {
        localStorage.setItem(
          'customSessionTimeoutTimestamp',
          moment().add(authTimeout, 'hours').toDate().getTime().toString(),
        );
      } else {
        localStorage.removeItem('customSessionTimeoutTimestamp');
      }
      navigate('/home', { state: location.state });
    }
    if (isSuccess && isMFA) {
      localStorage.setItem('challengeName', mfaInfo.challengeName);
      localStorage.setItem('email', mfaInfo.email);
      localStorage.setItem('session', mfaInfo.session);
      localStorage.setItem('username', mfaInfo.username);
      navigate('/mfa', {
        state: { ...location.state, password: userPassword },
      });
    }
  }, [isSuccess, idToken]);

  let err = '';
  if (isError) {
    err =
      (error as any)?.data?.message || (error as any)?.error || 'Unknown Error';
  }
  useEffect(() => {
    if (window.location.hash) {
      const i = window.location.hash.indexOf('id_token=');
      const idToken = window.location.hash.substring(i + 'id_token='.length);

      const accessToken = window.location.hash.substring(
        '#access_token='.length,
      );
      const decoded: any = jwtDecode(accessToken);

      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('idToken', idToken);
      localStorage.setItem('sessionInfo', decoded?.username);
      localStorage.setItem('sessionInfoUserName', decoded?.username);
      navigate('/panel/work-orders');
    }
  }, [window.location.hash]);

  const fields = useMemo(() => {
    return [
      {
        type: FieldTypes.Text,
        label: 'Email',
        placeholder: 'Email',
        value: sentEmailLogin,
        isNumeric: false,
        required: true,
        minLength: 3,
        fieldName: 'email',
        readOnly: true,
        disabled: true,
      },
      {
        type: FieldTypes.Password,
        label: 'Password',
        placeholder: 'Password',
        value: '',
        isNumeric: false,
        required: true,
        minLength: 8,
        fieldName: 'password',
      },
    ];
  }, [sentEmailLogin, isError]);

  return (
    <>
      <Helmet>
        <title>Sign In Page</title>
        <meta name="description" content="Welcome to Ecotrak" />
      </Helmet>
      <ECLoginTemplate
        onGoBack={() => navigate('/')}
        onGoBackText={t('translation:pages.Login.goBackButton')}
      >
        <ECEasyForm
          pattern="standard"
          drawerTitleBarBGColor="transparent"
          config={{
            variant: 'standard',
            title: 'Login',
            subtitle: '',
            cols: 1,
            submitTitle: 'Login',
            titleStyle: {
              textAlign: isMobileSize ? 'center' : 'left',
            },
          }}
          fullHeight={false}
          fields={fields}
          isSendingData={isLoading}
          isLoadingForm={false}
          onFormSubmit={(formData, fields) => {
            const password = formData.get('password');
            setUserPassword(password as string);
            signinUser({
              username: sentEmailLogin?.toLowerCase(),
              password,
            });
          }}
        />

        {(isError || errorMessage) && (
          <ECAlertError> {err || errorMessage} </ECAlertError>
        )}

        <ECBox
          p={2}
          px={4}
          display="flex"
          justifyContent={isMobileSize ? 'center' : 'space-between'}
        >
          <ECButton
            variant="text"
            startIcon={<LockOpen />}
            onClick={() => navigate('/forgot-password/send-link')}
            sx={theme => ({
              color: `${theme.palette.common.white} !important`,
            })}
          >
            Reset password
          </ECButton>
        </ECBox>
      </ECLoginTemplate>
    </>
  );
}
