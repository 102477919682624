import { useCallback, useEffect, useMemo, useState } from 'react';
import { ECEasyFormCreate } from 'app/components/ECDynamicForm';
import { useGetPrioritiesListQuery } from 'services/prioritiesApi';
import { ECBox, ECTypography, ECButton } from 'app/components';
import { useGetDefaultRequestCategoryQuery } from 'services/requestApi';
import { ECModal } from 'app/components/ECModal';
import { useGetSPsForServiceRequestQuery } from 'services/tradeApi';
import { WorkflowPriority } from '../../../../app/components/ECWorkflowPriorityBadge';
import { useHasPermission } from 'app/hooks/hasPermission.use-case';
import { P } from 'types/Permission';
import { useGetCategoryListQuery } from 'services/categoryApi';
import { useGetAssetTypesListByIdQuery } from 'services/assetTypeApi';
import { useGetTradeByProblemAssetTypeQuery } from 'services/assetTradeProblemApi';
import { themes } from 'styles/theme/themes';

interface Step3Props {
  tradeId?: number;
  existingData: any;
  isLoading?: boolean;
  isGeneralAsset?: boolean;
  onClose?: () => void;
  onSubmit?: (data) => void;
}

const fancyFormElementsCreate = require('./fancy_form_config_step3_create.json');

export const Step3 = ({
  tradeId,
  existingData,
  isLoading,
  onClose,
  onSubmit,
  isGeneralAsset,
}: Step3Props) => {
  const [formFields, setFormFields] = useState(fancyFormElementsCreate.fields);
  const [enableQuery, setEnableQuery] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [previousPriority, setPreviousPriority] = useState<any>(
    existingData?.asset?.priority?.name,
  );
  const [newFormFields, setNewFormFields] = useState<any>([
    fancyFormElementsCreate.fields,
  ]);
  const [isDeferredSelected, setSelectDeferred] = useState(false);

  const hasDeferWorkorderPermission = useHasPermission([P.DeferWorkOrder])?.[0];

  const [hasEditPriorityPermission] = useHasPermission([
    P.EditPriorityOnWorkOrder,
  ]);

  const { data: serviceProviders, isSuccess: isSuccessServiceProviders } =
    useGetSPsForServiceRequestQuery(
      {
        assetId: !isGeneralAsset ? existingData?.asset?.id : undefined,
        problemId: existingData?.problem?.id,
        generalAssetId: isGeneralAsset ? existingData?.asset?.id : undefined,
        branchId: isGeneralAsset ? existingData?.branch?.id : undefined,
      },
      {
        skip: !enableQuery,
      },
    );

  const {
    data: problemAndAssetTypeTrade,
    isSuccess: isSuccessTrade,
    isLoading: isLoadingTrade,
  } = useGetTradeByProblemAssetTypeQuery(
    {
      assetTypeId: existingData?.asset?.assetTypeId,
      problemTypeId: existingData?.problem?.id,
    },
    {
      skip: !existingData?.problem?.id || !existingData?.asset?.assetTypeId,
    },
  );

  useEffect(() => {
    if (
      !isLoadingTrade &&
      isSuccessTrade &&
      !!problemAndAssetTypeTrade?.length
    ) {
      const _updatedFormFields = [...formFields];
      const tradeField = _updatedFormFields.find(
        field => field.fieldName === 'trade',
      );
      const trade = problemAndAssetTypeTrade[0]?.trade;
      tradeField.label = trade?.name ?? tradeField.label;
      setFormFields([..._updatedFormFields]);
    }
  }, [problemAndAssetTypeTrade]);

  const { data: priorities, isSuccess: isSuccessPriorities } =
    useGetPrioritiesListQuery({
      ob: 'prrt.name',
      o: 'desc',
    });
  const assetTypeId =
    existingData?.asset?.assetTypeId || existingData?.asset?.assetType?.id;

  const { data: requestCategories, isSuccess: isSuccessCategories } =
    useGetCategoryListQuery({ st: 1, t: 0 });

  const {
    data: defaultRequestCategory,
    isSuccess: isSuccessDefaultRequestCategory,
  } = useGetDefaultRequestCategoryQuery(
    {
      problemId: existingData?.problem?.id,
      tradeId,
      assetTypeId,
    },
    {
      skip:
        !existingData?.problem?.id ||
        (!existingData?.asset?.assetTypeId &&
          !existingData?.asset?.assetType?.id),
    },
  );

  const handleCloseModal = () => setIsModalVisible(false);

  const handleCancelModal = () => {
    const updatedFormFields = [...newFormFields];
    const priorityFieldIndex = updatedFormFields.findIndex(
      field => field.fieldName === 'priority',
    );
    if (previousPriority && priorityFieldIndex !== -1) {
      updatedFormFields[priorityFieldIndex].value = previousPriority;
      setFormFields([...updatedFormFields]);
    }
    setIsModalVisible(false);
  };

  const handleOtApproved = () => {
    const updatedFormFields = [...newFormFields];
    const otApprovedIndex = updatedFormFields.findIndex(
      field => field.fieldName === 'otApproved',
    );
    updatedFormFields[otApprovedIndex] = {
      ...updatedFormFields[otApprovedIndex],
      value: ['Over Time Approved'],
    };

    const priorityIndex = updatedFormFields.findIndex(
      field => field.fieldName === 'priority',
    );
    updatedFormFields[priorityIndex] = {
      ...updatedFormFields[priorityIndex],
      value: WorkflowPriority.Emergency,
    };

    setFormFields([...updatedFormFields]);
    setIsModalVisible(false);
  };

  const submit = useCallback(() => {
    const doSubmit = async data => {
      const priority = priorities?.data.find(
        priority => priority.name === data['priority'].id,
      );

      const requestCategory = requestCategories?.data?.find(
        request => request.name === data['requestCategory'].id,
      );

      onSubmit?.({
        ...data,
        isDeferred: isDeferredSelected ? 1 : 0,
        priority,
        serviceProvider: +data['serviceProvider'],
        requestCategory,
      });
    };

    return [
      doSubmit,
      {
        isLoading,
      },
    ];
  }, [onSubmit, isLoading, priorities, requestCategories, isDeferredSelected]);

  const [hasServiceProviders, setHasServiceProviders] =
    useState<boolean>(false);

  useEffect(() => {
    if (isSuccessServiceProviders) {
      let serviceProvidersData = [...serviceProviders];
      const designatedServiceProvider = existingData?.asset?.spWarrantyCompany;
      const designatedSPExpirationDate =
        existingData?.asset?.defaultSpExpirationDate;
      if (
        designatedServiceProvider &&
        (!designatedSPExpirationDate ||
          new Date(designatedSPExpirationDate) > new Date())
      ) {
        const includedServiceProvider = {
          ...designatedServiceProvider,
        };
        const alreadyPresentSPIndex = serviceProvidersData.findIndex(
          sp => sp.id === includedServiceProvider.id,
        );
        if (alreadyPresentSPIndex !== -1) {
          [
            serviceProvidersData[0],
            serviceProvidersData[alreadyPresentSPIndex],
          ] = [
            serviceProvidersData[alreadyPresentSPIndex],
            serviceProvidersData[0],
          ];
        } else {
          serviceProvidersData = [
            includedServiceProvider,
            ...serviceProvidersData,
          ];
        }
      }

      let createFormFields = [...formFields];
      let spField = createFormFields.find(
        field => field.fieldName === 'serviceProvider',
      );
      spField.options = serviceProvidersData;
      spField.value = serviceProvidersData[0]?.id;

      setFormFields(createFormFields);
      setHasServiceProviders(serviceProvidersData.length > 0);
      setEnableQuery(false);
    }
  }, [serviceProviders, isSuccessServiceProviders]);

  useEffect(() => {
    if (!isSuccessPriorities) return;
    const editSelectField = formFields.find(
      field => field.fieldName === 'priority',
    );
    if (editSelectField) {
      const priorityData = priorities?.data || [];

      // Map options and option values
      editSelectField.options = priorityData.map(d => ({
        label: d.name,
        colorName: d.color,
      }));
      editSelectField.optionValues = priorityData.map(d => d.name);

      // Set default value based on existing data or assetTypePriorityName
      editSelectField.value =
        existingData?.asset?.priority?.name ||
        existingData?.asset?.assetTypePriorityName;

      // Set disabled state based on permissions
      editSelectField.disabled = !hasEditPriorityPermission;

      setFormFields([...formFields]);
    }
  }, [
    priorities,
    isSuccessPriorities,
    existingData?.asset?.priority?.name,
    existingData?.asset?.assetTypePriorityName,
    hasEditPriorityPermission,
  ]);

  useEffect(() => {
    if (isSuccessCategories && isSuccessDefaultRequestCategory) {
      const editSelectField = formFields.find(
        field => field.fieldName === 'requestCategory',
      );
      editSelectField.options =
        requestCategories?.data?.map(d => ({
          label: d.name,
        })) || [];
      editSelectField.optionValues =
        requestCategories?.data?.map(d => d.name) || [];

      // CATEGORY IS ALWAYS READ ONLY
      editSelectField.disabled = true;

      // check if there is any default category from the defaultCategory endpoint
      if (defaultRequestCategory.requestCategoryId) {
        const existingDefaultRequestCategory = requestCategories?.data?.find(
          category => category.id === defaultRequestCategory.requestCategoryId,
        );
        // if the endpoint returned a category, check if exist in options,
        if (existingDefaultRequestCategory) {
          editSelectField.value = existingDefaultRequestCategory?.name;
        } else {
          // otherwise add it
          if (defaultRequestCategory.categoryName) {
            editSelectField.value = defaultRequestCategory.categoryName;
            editSelectField.options?.push({
              label: defaultRequestCategory.categoryName,
            });
            editSelectField.optionValues?.push(
              defaultRequestCategory.categoryName,
            );
          } else {
            // this else is the most edge case
            // in case we find no categories whatsoever we set the field editable and let user choose
            editSelectField.disabled = false;
          }
        }
      } else {
        // if no default category, check if Repair exists in options
        const hasRepairCategory = requestCategories?.data?.find(
          category => category.name === 'Repair',
        );

        // if has repair, set as Repair, otherwise set as editable
        if (hasRepairCategory) {
          editSelectField.value = 'Repair';
        } else {
          // this else is the most edge case
          // in case we find no categories whatsoever we set the field editable and let user choose
          editSelectField.disabled = false;
        }
      }

      const fieldIndex = formFields.findIndex(
        field => field.fieldName === 'requestCategory',
      );
      formFields[fieldIndex] = editSelectField;

      setFormFields([...formFields]);
    }
  }, [
    requestCategories,
    isSuccessCategories,
    defaultRequestCategory,
    isSuccessDefaultRequestCategory,
  ]);

  const handleChangeFormFields = useCallback(
    (fields, fieldName) => {
      if (fieldName !== 'priority') return;

      const priorityField = fields.find(
        field => field.fieldName === 'priority',
      );

      const oldPriorityField = formFields.find(
        field => field.fieldName === 'priority',
      );

      if (priorityField == oldPriorityField) return;

      setNewFormFields([...fields]);

      const updatedFormFields = [...fields];

      setPreviousPriority(oldPriorityField?.value);

      if (priorityField?.value === WorkflowPriority.Emergency) {
        const otApprovedIndex = updatedFormFields.findIndex(
          field => field.fieldName === 'otApproved',
        );
        updatedFormFields[otApprovedIndex] = {
          ...updatedFormFields[otApprovedIndex],
          value: ['Over Time Approved'],
        };
      }

      if (
        oldPriorityField?.value !== WorkflowPriority.Emergency &&
        priorityField?.value === WorkflowPriority.Emergency
      ) {
        setIsModalVisible(true);
      }

      if (
        oldPriorityField?.value === WorkflowPriority.Emergency &&
        priorityField?.value !== WorkflowPriority.Emergency
      ) {
        const otApprovedIndex = updatedFormFields.findIndex(
          field => field.fieldName === 'otApproved',
        );
        updatedFormFields[otApprovedIndex] = {
          ...updatedFormFields[otApprovedIndex],
          value: [],
        };
      }
      setFormFields([...updatedFormFields]);
    },
    [formFields],
  );

  return (
    <>
      <ECEasyFormCreate
        useCreateMutation={submit}
        formConfig={fancyFormElementsCreate.config}
        formFields={formFields}
        saveButtonDisabled={!hasServiceProviders}
        onClose={onClose}
        onChange={handleChangeFormFields}
        deferredButtonData={{
          isVisible: hasDeferWorkorderPermission,
          isDeferredSelected,
          setSelectDeferred: () => {
            setSelectDeferred(prevSelectedDefer => !prevSelectedDefer);
          },
        }}
        submissionConfirmationModal={{
          title: 'Confirm you want to set your Work Order to Deferred Status',
          isVisible: isDeferredSelected,
          bodyInfoAlert: true,
          bodyText:
            'Your work order will not be assigned to a Service Provider until you move it from a deferred status and assign it to a Service Provider to perform the work.',
          contentWrapperSx: {
            width: '509px',
            height: '237.41px',
          },
          bodyTextSx: {
            color: themes.light.palette.text.alert.info,
          },
          alertSx: {
            alignItems: 'center',
          },
          titleSx: {
            fontWeight: 700,
          },
        }}
      />

      <ECModal
        isOpen={isModalVisible}
        onClose={handleCloseModal}
        sx={{ p: 2, left: 'calc(100% - 450px)', minWidth: '500px' }}
      >
        <ECBox display="flex" flexDirection="column" padding={'.5rem'}>
          <ECTypography variant="h6">
            Are you sure you want to proceed?
          </ECTypography>
          <ECBox
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            marginTop="1rem"
          >
            <ECBox>
              <ECTypography
                lineHeight={2}
                variant="overline"
                fontWeight="bold"
                color="text.secondary"
              >
                By choosing {` `}
              </ECTypography>
              <ECTypography lineHeight={2} variant="overline" fontWeight="bold">
                {WorkflowPriority.Emergency}
              </ECTypography>
              <ECTypography
                lineHeight={2}
                variant="overline"
                fontWeight="bold"
                color="text.secondary"
              >
                , overtime rates will be automatically approved. If this is
                after-hours, we recommend calling your Service Provider after
                you have created the Service Request.
              </ECTypography>
            </ECBox>
          </ECBox>
          <ECBox display="flex" justifyContent="flex-end" marginTop={'1rem'}>
            <ECButton
              sx={{ marginRight: '.5rem' }}
              variant="text"
              onClick={handleCancelModal}
            >
              Cancel
            </ECButton>
            <ECButton variant="contained" onClick={handleOtApproved}>
              YES, PROCEED
            </ECButton>
          </ECBox>
        </ECBox>
      </ECModal>
    </>
  );
};
