import { useState, useMemo } from 'react';
import { useResetPasswordMutation } from 'services/authApi';
import PasswordChecklist from 'react-password-checklist';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@mui/material';

import {
  ECEasyForm,
  FieldTypes,
  ECAlertError,
  ECAlertSuccess,
  ECBox,
  ECImage,
} from 'app/components';
import { useQuery } from 'app/hooks/usequery';

import ChecklistError from '../../../assets/password-checklist-error.svg';
import ChecklistSuccess from '../../../assets/password-checklist-success.svg';

const webPage: string = process.env.REACT_APP_URL || '';

export default function ResetPasswordForm() {
  const [passwordFirst, setPassword] = useState('');
  const [passwordAgain, setPasswordAgain] = useState('');
  const [isValidChecklistPassword, setChecklistPassword] = useState(false);

  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('md'));

  const { t } = useTranslation();

  const query = useQuery();
  const token = useMemo(() => query?.get('token'), [query]);

  const [resetPassword, { isError, error, isLoading, isSuccess }] =
    useResetPasswordMutation();

  const err = useMemo(
    () =>
      (error as any)?.data?.message || (error as any)?.error || 'Unknown Error',
    [error],
  );
  const errorStatus = useMemo(() => (error as any)?.data, [error]);

  if (isSuccess) {
    setTimeout(function () {
      window.location.replace('/');
    }, 2500);
  }

  const onChangeSetValues = e => {
    if (e[0].value) setPassword(e[0].value);
    if (e[1].value) setPasswordAgain(e[1].value);
  };

  function checkIfTrailingSpace(str: string) {
    return str?.startsWith(' ') || str?.endsWith(' ');
  }

  const hasTrailingSpacePassword = checkIfTrailingSpace(passwordFirst);

  return (
    <>
      <ECEasyForm
        pattern="standard"
        drawerTitleBarBGColor="transparent"
        fullHeight={false}
        saveButtonDisabled={isSuccess}
        config={{
          variant: 'standard',
          title: 'Reset Password',
          cols: 1,
          submitTitle: 'Reset Password',
        }}
        fields={[
          {
            type: FieldTypes.Password,
            label: 'New Password',
            placeholder: 'New password',
            value: passwordFirst,
            isNumeric: false,
            required: true,
            fieldName: 'passwordFirst',
          },
          {
            type: FieldTypes.Password,
            label: 'Re-enter Password',
            placeholder: 'Re-enter Password',
            value: passwordAgain,
            isNumeric: false,
            required: true,
            fieldName: 'passwordAgain',
          },
        ]}
        onChange={e => {
          onChangeSetValues(e);
        }}
        isSendingData={isLoading}
        isLoadingForm={false}
        onFormSubmit={formData => {
          const passwordFirst = formData.get('passwordFirst');
          if (isValidChecklistPassword && !hasTrailingSpacePassword) {
            resetPassword({
              token: String(token)?.trim(),
              password: passwordFirst,
              redirectUrl: encodeURIComponent(webPage),
            });
          }
        }}
      />
      <PasswordChecklist
        rules={[
          'number',
          'specialChar',
          'capital',
          'lowercase',
          'minLength',
          'match',
        ]}
        minLength={10}
        value={passwordFirst}
        valueAgain={passwordAgain}
        onChange={isValid => {
          setChecklistPassword(isValid);
        }}
        style={{
          color: 'white',
          fontSize: isMobileSize ? 12 : 16,
          columns: 2,
          maxWidth: '90%',
          margin: '0 auto',
          fontFamily: 'Roboto',
        }}
      />
      <ECBox
        sx={{
          color: 'white',
          maxWidth: '90%',
          fontSize: '16px',
          margin: '0px auto',
          fontFamily: 'Roboto',
        }}
      >
        {hasTrailingSpacePassword ? (
          <ECImage src={ChecklistError} alt="Checklist Error" />
        ) : (
          <ECImage src={ChecklistSuccess} alt="Checklist Success" />
        )}
        <span
          style={{
            opacity: !hasTrailingSpacePassword ? '1' : '0.5',
            marginLeft: '5px',
          }}
        >
          No Trailing or Leading Spaces
        </span>
      </ECBox>
      <br></br>
      {isSuccess && (
        <ECAlertSuccess>
          {' '}
          {t('resetAndUpdatePassword.success.update')}{' '}
        </ECAlertSuccess>
      )}
      {errorStatus && isError && <ECAlertError> {err} </ECAlertError>}{' '}
    </>
  );
}
